import App from "./App";
import ReactDOM from "react-dom";
import "./i18n";

import * as Sentry from "@sentry/react";
  
if (process.env.NODE_ENV !== "development") {
  console.log = () => { };
}

if(window.REACT_APP_API_URL){
  Sentry.init({
    dsn: "https://e8c2bdef17354db89f37414794d1293d@o4504111123988480.ingest.sentry.io/4504201759948800",
    integrations: [new Sentry.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
