import { Box } from "@chakra-ui/react";
import { useState } from "react";
import "./styles.css";

interface IZoomableOrderImageProps {
    imageUrl: string;
}

const ZoomableOrderImage: React.FC<IZoomableOrderImageProps> = ({ imageUrl }) => {
    const [zoomStyle, setZoomStyle] = useState({});

    const handleZoomImage = (e: any) => {
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = ((e.clientX - left) / width) * 100;
        const y = ((e.clientY - top) / height) * 100;

        const newZoomStyle = {
            clipPath: `inset(
                calc((1 - 1/2 * (50%))
                calc((1 - 1/2 * (100% - 50%))
                calc((1 - 1/2 * (100% - 50%))
                calc((1 - 1/2 * (50%))
            )`,
            transformOrigin: `${x}% ${y}%`,
            transform: 'scale(3)'
        };

        setZoomStyle(newZoomStyle);
    }

    return <Box className="image_container">
    {
        imageUrl && 
        <img style={zoomStyle}
            src={imageUrl}
            onMouseMove={handleZoomImage} 
            onMouseLeave={() => setZoomStyle({})} 
        />
    }
</Box>
}

export default ZoomableOrderImage;