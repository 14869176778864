import React, { useEffect, useRef, useState, useCallback } from "react";
import { Box, Button, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { IDropDownItem } from "src/types/dropdownitem";
import Checkbox from "../ManageZoneRidersModal/components/Checkbox";
import { useOutsideAlerter } from "src/hooks/useOutsideAlerter";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import "./styles.css";
import HorizontalLoading from "../HorizontalLoading";

interface IFilterDropDownProps {
  hasLabel?: boolean;
  loading?: boolean;
  filterBoxTitle: string;
  data: IDropDownItem[];
  defaultSelectedValues: string[];
  onChange?: (items: string[]) => void;
  onScroll?: (offset: number) => void;
  onSerach?: (keywoard: string | undefined) => void;
}

export const FilterDropDown: React.FC<IFilterDropDownProps> = (props) => {
  const {
    hasLabel = true,
    loading = false,
    filterBoxTitle,
    data,
    defaultSelectedValues,
    onChange,
    onScroll = () => {},
    onSerach,
  } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [items, setItems] = useState<IDropDownItem[]>(data);
  const [selectedItems, setSelectedItems] = useState<string[]>(
    defaultSelectedValues
  );

  const [searchTerm, setSearchTerm] = useState<string>("");
  const filterContainerRef = useRef<any>();
  const clicked = useOutsideAlerter(filterContainerRef);
  const listScrollerRef = useRef<any>();
  const sentinelRef = useRef<any>(null);
  const offset = useRef<number>(0);

  const moveSelectedItemsToStartOfItemsArray = () => {
    let itemsClone = [...items];
    let selectedItemsArr: IDropDownItem[] = [];
    selectedItems.forEach((sItem) => {
      const foundElm = itemsClone.find((el) => el.id === sItem);
      if (foundElm) {
        const foundIdx = itemsClone.findIndex((el) => el.id === sItem);
        selectedItemsArr.push(itemsClone.splice(foundIdx, 1)[0]);
      }
    });
    itemsClone.sort((a, b) =>
      a.text.toLowerCase().localeCompare(b.text.toLowerCase())
    );
    itemsClone.unshift(...selectedItemsArr);
    setItems(itemsClone);
    if (listScrollerRef.current) {
      listScrollerRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (clicked && isVisible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
      if (searchTerm && onSerach) onSerach(undefined);
      setSearchTerm("");
      moveSelectedItemsToStartOfItemsArray();
    }
  }, [clicked, isVisible]);

  useEffect(() => {
    setItems(data);
    setSelectedItems(defaultSelectedValues);
  }, [data, defaultSelectedValues]);

  const toggleItemSelection = (item: IDropDownItem) => {
    let newSelectedItems = [...selectedItems];
    if (selectedItems.includes(item.id)) {
      newSelectedItems = newSelectedItems.filter((i) => i !== item.id);
    } else {
      newSelectedItems.push(item.id);
    }
    setSelectedItems(newSelectedItems);
    onChange && onChange(newSelectedItems);
  };

  const renderList = () => {
    let filteredItems = items;
    if (searchTerm.trim() !== "" || !!onSerach) {
      filteredItems = items.filter((item) => {
        let searchableFields = item.searchableFields;
        for (let key in searchableFields) {
          if (
            searchableFields[key].trim().toLowerCase().includes(searchTerm) ||
            !!onSerach
          ) {
            return item;
          }
        }
        return null;
      });
    }

    return (
      <>
        {filteredItems.map((item) => (
          <Box
            display="flex"
            alignItems="center"
            mb="1"
            onClick={() => toggleItemSelection(item)}
            key={item.id}
          >
            <Checkbox selected={selectedItems.includes(item.id)} />
            <Box ml="1" fontSize="0.875rem" sx={item.stylesOfEachData ?? {}}>
              {item.text}
            </Box>
          </Box>
        ))}
        {filteredItems.length > 0 && <Box ref={sentinelRef} height="1px" />}
      </>
    );
  };

  const handleUnselectAll = () => {
    setSelectedItems([]);
    onChange && onChange([]);
  };

  // Use IntersectionObserver to load more data when the sentinel is visible
  const loadMoreData = useCallback(() => {
    if (!onScroll) return;
    if (!!onScroll) {
      onScroll(offset.current);
    }
  }, [onScroll]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreData();
      }
    });

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [loadMoreData]);

  return (
    <FormControl pos="relative">
      {hasLabel && <FormLabel htmlFor="toDate">{filterBoxTitle}</FormLabel>}
      {loading && <HorizontalLoading />}
      <Box ref={filterContainerRef}>
        <Button
          className="filter_field"
          bg="white"
          _hover={{ bg: "white" }}
          fontWeight="400"
          fontSize="0.95rem"
          minWidth="178px"
          onClick={() => setIsVisible(!isVisible)}
        >
          {selectedItems.length > 0
            ? `${selectedItems.length} Selected ${filterBoxTitle}`
            : `Select ${filterBoxTitle}`}
          <Box as="span" ml="1" mr="-2">
            <Icon size="18px" path={mdiChevronDown} />
          </Box>
        </Button>
        <Box
          className="filter_drop_down"
          bg="white"
          pos="absolute"
          top={hasLabel ? "73px" : "50px"}
          display={isVisible ? "block" : "none"}
        >
          <Input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (!!onSerach) {
                onSerach(e.target.value);
              }
            }}
          />
          <Box mt="2" className="items_container" ref={listScrollerRef}>
            {renderList()}
          </Box>
          <Box
            mt="3"
            borderTop="1px solid #e6e6e6"
            display="flex"
            flexDirection="row-reverse"
          >
            <Button size="sm" mt="2" onClick={handleUnselectAll}>
              Clear All
            </Button>
          </Box>
        </Box>
      </Box>
    </FormControl>
  );
};
