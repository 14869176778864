import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import StaticPageGeneralContent from "src/components/app/StaticPageGeneralContent";
import { useTitle } from "src/hooks/useTitle";

const data = {
    en:{
        title:"Privacy Policy",
        content:`
            <h2>Google – Play Store</h2>
            <h2>Privacy Policy</h2>
            <p>
            Beepbeep team has adopted this privacy policy (“Privacy Policy”) to explain how Beepbeep collects, stores, and uses the information collected in connection with Beepbeep’s Services.    
            </p>
            <p>
            BY INSTALLING, USING, REGISTERING TO OR OTHERWISE ACCESSING THE SERVICES, YOU AGREE TO THIS PRIVACY POLICY AND GIVE AN EXPLICIT AND INFORMED CONSENT TO THE PROCESSING OF YOUR PERSONAL DATA IN ACCORDANCE WITH THIS PRIVACY POLICY. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT INSTALL, USE, REGISTER TO OR OTHERWISE ACCESS THE SERVICES.    
            </p>
            <p>
            Beepbeep reserves the right to modify this Privacy Policy at reasonable times, so please review it frequently. If Beepbeep makes material or significant changes to this Privacy Policy, Beepbeep may post a notice on Beepbeep’s website along with the updated Privacy Policy. Your continued use of Services will signify your acceptance of the changes to this Privacy Policy.
            </p>
            <h2>Non-personal data</h2>
            <p>
            For purposes of this Privacy Policy, “non-personal data” means information that does not directly identify you. The types of non-personal data Beepbeep may collect and use include, but are not limited to: application properties, including, but not limited to application name, package name and icon installed on your device. Your checkin (include like, recommendation) of a game will be disclosed to all Beepbeep users.
            </p>
            <p>
            Beepbeep may use and disclose to Beepbeep’s partners and contractors the collected non-personal data for purposes of analyzing usage of the Services, advertisement serving, managing and providing the Services and to further develop the Services and other Beepbeep services and products.
            </p>
            <p>
            You recognize and agree that the analytics companies utilized by Beepbeep may combine the information collected with other information they have independently collected from other services or products relating to your activities. These companies collect and use information under their own privacy policies.
            </p>
            <h2>Personal Data</h2>
            <p>
            For purposes of this Privacy Policy, “personal data” means personally identifiable information that specifically identifies you as an individual.
            Personal information collected by Beepbeep is information voluntarily provided to us by you when you create your account or change your account information.
            The information includes your facebook id, name, gender, location and your friends’id on facebook. Beepbeep also stores your game checkins, likes, dislikes, recommendations and messages.
            </p>
            <p>
            Beepbeep may use collected personal data for purposes of analyzing usage of the Services, providing customer and technical support, managing and providing Services (including managing advertisement serving) and to further develop the Services and other Beepbeep services and products. Beepbeep may combine non-personal data with personal data.
            </p>
            <p>
            Please note that certain features of the Services may be able to connect to your social networking sites to obtain additional information about you.
            In such cases, Beepbeep may be able to collect certain information from your social networking profile when your social networking site permits it,
            and when you consent to allow your social networking site to make that information available to Beepbeep.
            </p>
            <p>
            This information may include, but is not limited to, your name, profile picture, gender, user ID, email address, your country, your language,
            your time zone, the organizations and links on your profile page, the names and profile pictures of your social networking site “friends” and other information you have included in your social networking site profile. 
            Beepbeep may associate and/or combine as well as use information collected by Beepbeep and/or obtained through such social networking sites in accordance with this Privacy Policy.
            </p>
            <h2>Disclosure and Transfer of Personal Data</h2>
            <p>
            Beepbeep collects and processes personal data on a voluntary basis and it is not in the business of selling your personal data to third parties. 
            Personal data may, however, occasionally be disclosed in accordance with applicable legislation and this Privacy Policy. 
            Additionally, Beepbeep may disclose personal data to its parent companies and its subsidiaries in accordance with this Privacy Policy.
            </p>
            <p>
            Beepbeep may hire agents and contractors to collect and process personal data on Beepbeep’s behalf and in such cases such agents and contractors will be instructed to comply with our Privacy Policy and to use personal data only for the purposes for which the third party has been engaged by Beepbeep. 
            These agents and contractors may not use your personal data for their own marketing purposes.
            </p>
            <p>
            Beepbeep may use third party service providers such as credit card processors, e-mail service providers, shipping agents, data analyzers and business intelligence providers. 
            Beepbeep has the right to share your personal data as necessary for the aforementioned third parties to provide their services for Beepbeep. 
            Beepbeep is not liable for the acts and omissions of these third parties, except as provided by mandatory law.
            </p>
            <p>
            Beepbeep may disclose your personal data to third parties as required by law enforcement or other government officials in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose you or Beepbeep to legal liability. 
            Beepbeep may also disclose your personal data to third parties when Beepbeep has a reason to believe that a disclosure is necessary to address potential or actual injury or interference with Beepbeep’s rights, property, operations, users or others who may be harmed or may suffer loss or damage, or Beepbeep believes that such disclosure is necessary to protect Beepbeep ’s rights, combat fraud and/or comply with a judicial proceeding, court order, or legal process served on Beepbeep. 
            To the extent permitted by applicable law, Beepbeep will make reasonable efforts to notify you of such disclosure through Beepbeep’s website or in another reasonable manner.
            </p>
            <h2>Safeguards</h2>
            <p>
            Beepbeep follows generally accepted industry standards and maintains reasonable safeguards to attempt to ensure the security, integrity and privacy of the information in Beepbeep’s possession.
            </p>
            <p>
            Only those persons with a need to process your personal data in connection with the fulfillment of their tasks in accordance with the purposes of this Privacy Policy and for the purposes of performing technical maintenance, have access to your personal data in Beepbeep’s possession. Personal data collected by Beepbeep is stored in secure operating environments that are not available to the public. 
            </p>
            <p>
            To prevent unauthorized on-line access to personal data, Beepbeep maintains personal data behind a firewall-protected server. However, no system can be 100% secure and there is the possibility that despite Beepbeep’s reasonable efforts, there could be unauthorized access to your personal data. By using the Services, you assume this risk.
            </p>
            <h2>Other</h2>
            <p>
            Please be aware of the open nature of certain social networking and other open features of the Services Beepbeep may make available to you. 
            You may choose to disclose data about yourself in the course of contributing user generated content to the Services. 
            Any data that you disclose in any of these forums, blogs, chats or the like is public information, and there is no expectation of privacy or confidentiality. 
            Beepbeep is not responsible for any personal data you choose to make public in any of these forums.
            </p>
            <p>
            If you are under 15 years of age or a minor in your country of residence, please ask your legal guardian’s permission to use or access the Services. 
            Beepbeep takes children’s privacy seriously, and encourages parents and/or guardians to play an active role in their children's online experience at all times. 
            Beepbeep does not knowingly collect any personal information from children below the aforementioned age and if Beepbeep learns that Beepbeep has inadvertently gathered personal data from children under the aforementioned age, Beepbeep will take reasonable measures to promptly erase such personal data from Beepbeep’s records.
            </p>
            <p>
            Beepbeep may store and/or transfer your personal data to its affiliates and partners in and outside of EU/EEA member states and the United States in accordance with mandatory legislation and this Privacy Policy. 
            Beepbeep may disclose your personal data to third parties in connection with a corporate merger, consolidation, restructuring, the sale of substantially all of Beepbeep’s stock and/or assets or other corporate change, including, without limitation, during the course of any due diligence process provided, however, that this Privacy Policy shall continue to govern such personal data.
            </p>
            <p>
            Beepbeep regularly reviews its compliance with this Privacy Policy. 
            If Beepbeep receives a formal written complaint from you, it is Beepbeep’s policy to attempt to contact you directly to address any of your concerns. 
            Beepbeep will cooperate with the appropriate governmental authorities, including data protection authorities, to resolve any complaints regarding the collection, use, transfer or disclosure of personal data that cannot be amicably resolved between you and Beepbeep.
            </p>
            <h2>3rd party services</h2>
            <p>
            We use 3rd party services in our apps. These services collect usage data in compliance with their Privacy Policies. The services are described below.
            </p>
            <h2>Advertising</h2>
            <p>
            3rd party ad serving systems allow user data to be utilized for advertising communication purposes displayed in the form of banners and other advertisements on Beepbeep apps, possibly based on user interests.
            </p>
            <h2>Admob</h2>
            <p>
            We use Admob by Google as the main ad server. Please see Admob Privacy Policy – <a href="https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/</a>
            </p>
            <h2>Analytics</h2>
            <p>
            3rd party analytics services allow us to monitor and analyze app usage, better understand our audience and user behavior.
            </p>
            <h2>Flurry</h2>
            <p>
            We use Flurry Analytics to collect, monitor and analyze log data, including frequency of use, length of time spent in the app, in order to improve functionality and user-friendliness of our apps. Please see Flurry Privacy Policy – <a href="http://www.flurry.com/privacy-policy.html">http://www.flurry.com/privacy-policy.html</a>
            </p>
            <h2>Google Analytics</h2>
            <p>
            Google Analytics is an analysis service provided by Google Inc. Google utilizes the collected data to track and examine the use of Beepbeep Apps, to prepare reports on user activities and share them with other Google services. Google may use the data to contextualize and personalize the ads of its own advertising network.
            <a href="http://www.google.com/intl/en/policies/privacy/">(http://www.google.com/intl/en/policies/privacy/)</a>
            </p>
            <h2>Children’s Online Privacy Protection Act Compliance</h2>
            <p>
            We are in compliance with the requirements of COPPA, we do not collect any personal information from anyone under 13 years of age. Our products and services are all directed to people who are at least 13 years old or older.
            </p>

            <hr class="section"/>

            <h2>Apple – Store</h2>
            <h2>Privacy Policy</h2>
            <p>
            Beepbeep Services built the Beepbeep Services app as a Commercial app. This SERVICE is provided by Beepbeep Services and is intended for use as is.
            This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.
            </p>
            <p>
            If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. 
            The Personal Information that we collect is used for providing and improving the Service. 
            We will not use or share your information with anyone except as described in this Privacy Policy.
            </p>
            <p>
            The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Beepbeep Services unless otherwise defined in this Privacy Policy.
            </p>
            <h2>Information Collection and Use</h2>
            <p>
            For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. 
            The information that we request will be retained by us and used as described in this privacy policy.
            The app does use third party services that may collect information used to identify you.
            </p>
            <p>
            Link to privacy policy of third party service providers used by the app <a href="https://www.google.com/policies/privacy/">Google Play Services</a>
            </p>
            <h2>Log Data</h2>
            <p>
            We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.
            </p>
            <h2>Cookies</h2>
            <p>
            Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
            </p>
            <p>
            This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
            </p>
            <h2>Service Providers</h2>
            <p>
            We may employ third-party companies and individuals due to the following reasons:
            <ul>
                <li>To facilitate our Service;</li>
                <li>To provide the Service on our behalf;</li>
                <li>To perform Service-related services; or</li>
                <li>To assist us in analyzing how our Service is used.</li>
            </ul>
            </p>
            <p>
            We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. 
            However, they are obligated not to disclose or use the information for any other purpose.
            </p>
            <h2>Security</h2>
            <p>
            We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. 
            But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
            </p>
            <h2>Links to Other Sites</h2>
            <p>
            This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. 
            Therefore, we strongly advise you to review the Privacy Policy of these websites. 
            We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
            </p>
            <h2>Children’s Privacy</h2>
            <p>
            These Services do not address anyone under the age of 13. 
            We do not knowingly collect personally identifiable information from children under 13. 
            In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. 
            If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
            </p>
            <h2>Changes to This Privacy Policy</h2>
            <p>
            We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. 
            We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
            </p>
        `
    },
    ar:{
        title:" سياسة خاصة",
        content:`
            <h2>جمع البيانات من المستخدمين</h2>
            <p>
            لکن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد تكمن السعاده فيما نتحمله من كد وأسي.
            </p>
            <p>
            و سأعرض مثال حي لهذا، من منا لم يتحمل جهد بدني شاق إلا من أجل الحصول على ميزة أو فائدة؟ ولكن من لديه الحق أن ينتقد شخص ما أراد أن يشعر بالسعادة التي لا تشوبها عواقب أليمة أو آخر أراد أن يتجنب الألم الذي ربما تنجم عنه بعض المتعة ؟ 
علي الجانب الآخر نشجب ونستنكر هؤلاء الرجال المفتونون بنشوة اللحظة الهائمون في رغباتهم فلا يدركون ما يعقبها من الألم والأسي المحتم، واللوم كذلك يشمل هؤلاء الذين أخفقوا في واجباتهم نتيجة لضعف إرادتهم فيتساوي مع هؤلاء الذين يتجنبون وينأون عن تحمل الكدح والألم
            </p>
            <h2>إشعار قانوني</h2>
            <p>
            و سأعرض مثال حي لهذا، من منا لم يتحمل جهد بدني شاق إلا من أجل الحصول على ميزة أو فائدة؟ ولكن من لديه الحق أن ينتقد شخص ما أراد أن يشعر بالسعادة التي لا تشوبها عواقب أليمة أو آخر أراد أن يتجنب الألم الذي ربما تنجم عنه بعض المتعة ؟ 
علي الجانب الآخر نشجب ونستنكر هؤلاء الرجال المفتونون بنشوة اللحظة الهائمون في رغباتهم فلا يدركون ما يعقبها من الألم والأسي المحتم، واللوم كذلك يشمل هؤلاء الذين أخفقوا في واجباتهم نتيجة لضعف إرادتهم فيتساوي مع هؤلاء الذين يتجنبون وينأون عن تحمل الكدح والألم . من المفترض أن نفرق بين هذه الحالات بكل سهولة ومرونة. في ذاك الوقت عندما تكون قدرتنا علي الاختيار غير مقيدة بشرط وعندما لا نجد ما يمنعنا أن نفعل الأفضل فها نحن نرحب بالسرور والسعادة ونتجنب كل ما يبعث إلينا الألم. في بعض الأحيان ونظراً للالتزامات التي يفرضها علينا الواجب والعمل سنتنازل غالباً ونرفض الشعور بالسرور ونقبل ما يجلبه إلينا الأسى. الإنسان الحكيم عليه أن يمسك زمام الأمور ويختار إما أن يرفض مصادر السعادة من أجل ما هو أكثر أهمية أو يتحمل الألم من أجل ألا يتحمل ما هو أسوأ.
            </p>
        `
    }
}

const PrivacyPolicy:React.FC = ()=>{
    const [search] = useSearchParams();
    const [lang] = useState<"en" | "ar">(search.get("lang") === "ar"?"ar":"en");
    useTitle(data[lang].title);
    return <Box>
        <StaticPageGeneralContent content={data[lang].content} rtl={lang==="ar"}/>
    </Box>
}

export default PrivacyPolicy;