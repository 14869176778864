import { object, string } from "yup";

const urlPattern = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/;
export const thirdPartyValidation = (t: any) => {
    return object().shape({
        companyNameEn: string().trim()
            .required(t("validationMessages.fieldIsRequired", { field: t("fields.nameEn") }))
            .min(1, t("validationMessages.minLengthValidation", { field: t("fields.nameEn"), validLength: 1 })),
        companyNameAr: string().trim().nullable(),
        domain: string().trim().test("check-empty",
            t("validationMessages.validURL"),
            (value) => { return (value && value.length > 0) ? urlPattern.test(value) : true },
        ),
        changeStatusRoute: string().trim().test("check-empty",
            t("validationMessages.validURL"),
            (value) => { return (value && value.length > 0) ? urlPattern.test(value) : true },
        ),
        contactNo: string().trim().nullable(),
        address: string().trim().nullable()
    });
}
