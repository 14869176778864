import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text } from "@chakra-ui/react";
import React, { ChangeEvent, useRef, useState } from "react";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useTranslation } from "react-i18next";
import { base64ToFile } from "src/utils/base64ToFile";
import "./styles.css";
import { mdiCamera } from "@mdi/js";
import { Icon } from "@mdi/react";

export interface ICroppedImage {
    cropper: Cropper
}

export interface IUploadImageProps {
    avatarUrl:string;
    onChange:(file:Blob)=> void;
}

export const SelectImage:React.FC<IUploadImageProps> = (props) => {
    const {avatarUrl , onChange} = props;
    const [showCropperModal , setShowCropperModal] = useState<boolean>(false);
    const [croppedImage , setCroppedImage] = useState<[boolean , string]>([false , ""]);
    const fileInput = useRef<HTMLInputElement>(null);

    const [preview , setPreview] = useState<any>();
    const cropperRef = useRef<HTMLImageElement>(null);

    const {t} = useTranslation();


    const OpenCropperModal = (e:ChangeEvent<HTMLInputElement>) => {
        setShowCropperModal(true);
        if (!e.target.files || e.target.files.length === 0) {
            setPreview(undefined);
            setShowCropperModal(false);
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setPreview(reader.result);
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const closeCropperModal = () => {
        setShowCropperModal(false);
        fileInput.current!.value = "";
    }

    const handleCropImage = async () => {
        const imageElement = (cropperRef.current as unknown) as ICroppedImage;
        const cropper = imageElement.cropper.getCroppedCanvas().toDataURL();
        
        setCroppedImage([true , cropper]);
        setShowCropperModal(false);

        const base64File = await base64ToFile(cropper , "avatar.png" , "image/png");
        onChange(base64File);
        fileInput.current!.value = "";
    }

    return <>
        <Box display="flex" alignItems="end" mb="4">
            <Box className="avatar_preview" mr="2">
                <label id="avatarFileInput">
                    <input type="file"  ref={fileInput} onChange={OpenCropperModal} accept="image/png,image/gif,image/jpeg"/>
                    <Text as="span" id="cameraIcon"><Icon path={mdiCamera} size="26px"/></Text>
                </label>
                {
                    (avatarUrl && !croppedImage[0]) 
                        ? <img id="avatarImage" alt="avatar" src={avatarUrl}/>
                        : croppedImage[0] && <img id="avatarImage" alt="avatar" src={croppedImage[1]}/>
                }
            </Box>
        </Box>
        {
            showCropperModal &&
            <Modal isOpen={showCropperModal} onClose={closeCropperModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody mt="3" width="100%">
                        <Cropper
                            ref={cropperRef}
                            src={preview}
                            style={{ height: 400, width: "100%" }}
                            aspectRatio={1}
                            guides={true}
                            minCropBoxWidth={50}
                            minCropBoxHeight={50}
                            draggable={false}
                            responsive={true}
                        />
                    </ModalBody>
                    <ModalFooter display="flex" gap="1">
                        <Button colorScheme="green" onClick={handleCropImage}>{t("actions.done")}</Button>
                        <Button colorScheme="orange" onClick={closeCropperModal}>{t("actions.cancel")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        }
    </>
}