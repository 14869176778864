import React, { useEffect } from "react";
import { Text, Center, Box, IconButton } from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import { panelZonesService } from "src/api/services/zones";
import { IZoneListItem } from "src/api/types/zones";
import ContentView, {
  ContentViewBody,
  ContentViewHeader,
} from "src/components/app/ContentView";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import FormRow from "src/components/app/FormRow";
import { MapChooseLocation } from "src/components/app/Map/MapChooseLocation";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import { BrowserBack } from "src/components/app/BrowserBack";
import ZoneSwitchOTPState from "../components/ZoneSwitchOTPState";
import SPSpinner from "src/components/app/SPSpinner";
import { useAbortController } from "src/hooks/useAbortController";
import { useAsync } from "src/hooks/useAsync";

const ZonesDetails: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.zoneDetails"));

  const params = useParams<{ id: string }>();
  const { getAbortSignal } = useAbortController();

  const {
    run: getZoneDetails,
    isLoading: loading,
    data: zoneDetails,
  } = useAsync<IZoneListItem, any>(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await panelZonesService.get(
          params.id!,
          getAbortSignal("fetchZoneDetails").signal
        );
        resolve(response.data.data as IZoneListItem);
      } catch (e) {
        reject([]);
      }
    });
  });

  useEffect(() => {
    if (params.id) {
      getZoneDetails({});
    }
  }, [params.id]);

  const handleChangeOTPStatus = () => {
    getZoneDetails({});
  };

  return (
    <>
      <Box display="flex" flexDirection="row">
        <ContentView>
          {loading && (
            <Center>
              <SPSpinner size="xl" />
            </Center>
          )}
          {zoneDetails && (
            <>
              <ContentViewHeader>
                <Box display="flex">
                  <Text as="h3" display="inline-block" fontSize="1.4em">
                    {t("titles.zoneDetails")}
                  </Text>
                  <IconButton
                    aria-label="Edit Zone"
                    as={Link}
                    to={
                      ROUTE_CONSTANTS.DASHBOARD.ZONES.UPDATE.BY_DATA(
                        zoneDetails.id
                      ).ABSOLUTE
                    }
                    ml="4"
                    icon={<Icon path={mdiPencil} size="28px" />}
                    colorScheme="primary"
                    variant="outline"
                    size="sm"
                  />
                  <BrowserBack />
                </Box>
              </ContentViewHeader>
              <ContentViewBody>
                <ZoneSwitchOTPState
                  zoneDetail={zoneDetails}
                  handleUpdateDetails={handleChangeOTPStatus}
                />
                <FormRow
                  bidirectional
                  leftContent={
                    <Box>
                      <Text as="span" fontWeight="500">
                        {t("fields.nameEn")}:{" "}
                      </Text>
                      <Text as="span" ml="2">
                        {zoneDetails?.nameEn || "---"}
                      </Text>
                    </Box>
                  }
                  rightContent={
                    <Box>
                      <Text as="span" fontWeight="600" display="inline-block">
                        {t("fields.nameAr")}:{" "}
                      </Text>
                      <Text as="span" mr="2">
                        {zoneDetails?.nameAr || "---"}
                      </Text>
                    </Box>
                  }
                ></FormRow>
                <FormRow
                  bidirectional
                  leftContent={
                    <Box>
                      <Text as="div" fontWeight="500">
                        {t("fields.descriptionEn")}:{" "}
                      </Text>
                      <Text as="span">
                        {zoneDetails?.descriptionEn || "---"}
                      </Text>
                    </Box>
                  }
                  rightContent={
                    <Box>
                      <Text as="div" fontWeight="600">
                        {t("fields.descriptionAr")}:{" "}
                      </Text>
                      <Text as="span">
                        {zoneDetails?.descriptionAr || "---"}
                      </Text>
                    </Box>
                  }
                ></FormRow>
                <Box>
                  <FormRow
                    bidirectional
                    leftContent={
                      <Box mb="2">
                        <Text as="span" fontWeight="500">
                          Group:{" "}
                        </Text>
                        <Text as="span" ml="2">
                          {zoneDetails?.group.nameEn || "---"}
                        </Text>
                      </Box>
                    }
                    rightContent=""
                  ></FormRow>
                </Box>
                <Box mb="6">
                  <Text as="span" fontWeight="500">
                    Valid Radius (m):{" "}
                  </Text>
                  <Text as="span" ml="2">
                    {zoneDetails?.validRadius || "---"}
                  </Text>
                </Box>
                <Box mb="6">
                  <Text as="span" fontWeight="500">
                    Paging Radius (m):{" "}
                  </Text>
                  <Text as="span" ml="2">
                    {zoneDetails?.pagingRadius || "---"}
                  </Text>
                </Box>
                <Box mb="6">
                  <Text as="span" fontWeight="500">
                    Expected Order:{" "}
                  </Text>
                  <Text as="span" ml="2">
                    {zoneDetails?.expectedOrderNumberForRegularDays || 0}
                  </Text>
                </Box>
                <Box mb="6">
                  <Text as="span" fontWeight="500">
                    Expected weekend Order:{" "}
                  </Text>
                  <Text as="span" ml="2">
                    {zoneDetails?.expectedOrderNumberForWeekends || 0}
                  </Text>
                </Box>
                <FormRow
                  bidirectional
                  leftContent={
                    <Box>
                      <Text as="span" fontWeight="500">
                        {t("fields.addressEn")}:{" "}
                      </Text>
                      <Text as="span" ml="2">
                        {zoneDetails?.addressEn || "---"}
                      </Text>
                    </Box>
                  }
                  rightContent={
                    <Box>
                      <Text as="span" fontWeight="600" display="inline-block">
                        {t("fields.addressAr")}:{" "}
                      </Text>
                      <Text as="span" mr="2">
                        {zoneDetails?.addressAr || "---"}
                      </Text>
                    </Box>
                  }
                ></FormRow>
                {/* map */}
                <MapChooseLocation
                  zoom={16}
                  center={{
                    lat: zoneDetails.location.latitude,
                    lng: zoneDetails.location.longitude,
                  }}
                  marker={{
                    lat: zoneDetails.location.latitude,
                    lng: zoneDetails.location.longitude,
                  }}
                  zoneRadius={zoneDetails.validRadius}
                  fullscreenControl={true}
                  editable={false}
                ></MapChooseLocation>
              </ContentViewBody>
            </>
          )}
        </ContentView>
      </Box>
    </>
  );
};

export default ZonesDetails;
