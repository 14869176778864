import React from "react";
import OrderDataProvider from "../OrdersDataContext";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../../../hooks/useTitle";
import { Box } from "@chakra-ui/react";
import OrderHistoryList from "../components/OrderHistoryList";

const OrderHistory: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitle.pagingOrders"));

  return (
    <Box>
      <OrderDataProvider>
        <OrderHistoryList />
      </OrderDataProvider>
    </Box>
  );
};

export default OrderHistory;
