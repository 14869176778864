import { API_ROUTES } from "src/constants/api-routes";
import {AxiosInstance} from "../index";
import { IAuthenticatedUser } from "../types/auth";
import { IAxiosResponse } from "../types/axios-response";
export const panelUserService = {
    getProfile(signal:AbortSignal){
        return AxiosInstance.get<IAxiosResponse<IAuthenticatedUser>>(API_ROUTES.AUTH.PROFILE,{
            signal
        });
    }
};