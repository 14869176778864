import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import HorizontalLoading from "src/components/app/HorizontalLoading";
import { panelAttendanceLogService } from "src/api/services/attendance-log";
import dateHelper from "src/utils/date-helper";
import { IAttendanceSummationItem } from "src/api/types/attendance-log";
import { toast } from "src/utils/toast";
import { useSearchParams } from "react-router-dom";
import { API_ROUTES } from "src/constants/api-routes";
import { ApiConfig } from "src/api/config";
import { exportFile } from "src/utils/export-file";
import { FilterDropDown } from "src/components/app/FilterDropdown/FilterDropDown";
import { IDropDownItem } from "src/types/dropdownitem";
import { panelZonesService } from "src/api/services/zones";
import { panelGroupsService } from "src/api/services/groups";
import {
  IFiltersItem,
  AggregationRowRecord,
} from "./components/AggregationRowRecord";
import {
  DEFAULT_COUNT,
  DEFAULT_OFFSET,
} from "src/constants/api-default-values";
import { NotFoundResult } from "src/components/app/NotFoundResult";
import DatePicker from "react-datepicker";
import { ZoneSorts } from "src/api/types/zones";
import Icon from "@mdi/react";
import { mdiTrayArrowDown } from "@mdi/js";
import { useSearch } from "src/hooks/useSearch";
import "./styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Pagination } from "src/components/app/Pagination";
import { useRidersFilter } from "src/hooks/useRidersFilter";

const AttendanceReport: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.attendanceReport"));

  const [attendanceLogs, setAttendanceLogs] = useState<
    IAttendanceSummationItem[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [getAbortController] = useState<AbortController>(new AbortController());
  const exportButton = useRef<any>();
  const [searchParams] = useSearchParams();
  const [totalCheckInDuration, setTotalCheckInDuration] = useState<number>(0);
  const selectedRiders = useRef<string[]>(
    searchParams.getAll("RiderIds") || []
  );

  const [zonesList, setZonesList] = useState<IDropDownItem[]>([]);
  const selectedZones = useRef<string[]>(searchParams.getAll("ZoneIds") || []);

  const [groupsList, setGroupsList] = useState<IDropDownItem[]>([]);
  const selectedGroups = useRef<string[]>(
    searchParams.getAll("GroupIds") || []
  );

  const { searchQuery, updateSearchParameters } = useSearch({
    isStoreOnLocalStorage: false,
    entity: "record",
    fields: {
      size: 10,
      offset: 0,
      fromDate: dateHelper.getStartEndFromThisMonth(true).start,
      toDate: dateHelper.getStartEndFromThisMonth(true, "23:45").end,
    },
  });
  const [fromDate, setFromDate] = useState<string>(searchQuery.fromDate);
  const [toDate, setToDate] = useState<string>(searchQuery.toDate);
  const [searchFilters, setSearchFilters] = useState<IFiltersItem>({
    count: DEFAULT_COUNT,
    fromDate: fromDate,
    toDate: toDate,
  });
  const {
    riders,
    isLoading: isLoadingRiders,
    handleScroll: handleScrollRidersFilter,
    handleSearch: handleSearchRiders,
  } = useRidersFilter();

  const [pageChanged, setPageChanged] = useState<boolean>(true);
  const [showNoResultFound, setShowNoResultFound] = useState<boolean>(false);
  const [pageTotalCount, setPageTotalCount] = useState<number>(0);

  const fetchLogs = async (
    offset: number,
    size: number,
    fromDateArg: string,
    toDateArg: string,
    riderIds: string = "",
    zoneIds: string = "",
    groupIds: string = ""
  ) => {
    try {
      setLoading(true);
      if (fromDateArg > toDateArg) {
        toast.error("End date should be bigger than start date");
      } else {
        const dates = dateHelper.getStartEndByDatesAndTimes(
          fromDateArg,
          toDateArg
        );
        const result = await panelAttendanceLogService.getSummationReports(
          offset,
          size,
          dates.start,
          dates.end,
          riderIds,
          zoneIds,
          groupIds,
          getAbortController.signal
        );
        setAttendanceLogs(result.data.data.items);
        setPageTotalCount(result.data.data.totalCount);
        setTotalCheckInDuration(result.data.data.totalCheckInDuration!);
        setPageChanged(!pageChanged);
        result.data.data.items.length > 0
          ? setShowNoResultFound(false)
          : setShowNoResultFound(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs(searchQuery.offset, searchQuery.size, fromDate, toDate);
  }, []);

  const generateListMap = (item: { id: string; nameEn: string }) => {
    return {
      id: item.id,
      text: item.nameEn,
      searchableFields: {
        nameEn: item.nameEn.trim(),
      },
    };
  };

  const fetchZonesList = useCallback(async () => {
    const res = await panelZonesService.getAll(
      DEFAULT_OFFSET,
      DEFAULT_COUNT,
      ZoneSorts.ByNameEnAsc,
      getAbortController.signal
    );
    const zonesListMap = res.data.data.items.map((item) =>
      generateListMap(item)
    );
    setZonesList(zonesListMap);
  }, [getAbortController]);

  const fetchGroupsList = useCallback(async () => {
    const res = await panelGroupsService.getAll(
      DEFAULT_OFFSET,
      DEFAULT_COUNT,
      getAbortController.signal
    );
    const groupsListMap = res.data.data.items.map((item) =>
      generateListMap(item)
    );
    setGroupsList(groupsListMap);
  }, [getAbortController]);

  useEffect(() => {
    fetchZonesList();
  }, [fetchZonesList]);

  useEffect(() => {
    fetchGroupsList();
  }, [fetchGroupsList]);

  const exportCSV = () => {
    const dates = dateHelper.getStartEndByDatesAndTimes(fromDate, toDate);
    exportFile(
      ApiConfig.baseURL + API_ROUTES.ATTENDANCE_LOG.EXPORT,
      {
        FromDate: dates.start,
        ToDate: dates.end,
        count: "" + DEFAULT_COUNT,
        RiderIds: selectedRiders.current,
        ZoneIds: selectedZones.current,
        GroupIds: selectedGroups.current,
      },
      exportButton,
      `AttendanceReport-${dateHelper.formatDate(
        fromDate,
        "YYYY-MM-DDTHH:mm"
      )}|${dateHelper.formatDate(toDate, "YYYY-MM-DDTHH:mm")}`
    );
  };

  const onRidersDropDownChanged = (data: string[]) => {
    selectedRiders.current = data;
  };

  const onZonesDropDownChanged = (data: string[]) => {
    selectedZones.current = data;
  };

  const onGroupDropDownChanged = (data: string[]) => {
    selectedGroups.current = data;
  };

  const createFiltersQuery = () => {
    let ridersQuery = "";
    selectedRiders.current.forEach((riderId) => {
      ridersQuery += `RiderIds=${riderId}&`;
    });

    let zonesQuery = "";
    selectedZones.current.forEach((zoneId) => {
      zonesQuery += `ZoneIds=${zoneId}&`;
    });

    let groupsQuery = "";
    selectedGroups.current.forEach((groupId) => {
      groupsQuery += `GroupIds=${groupId}&`;
    });

    return [ridersQuery, zonesQuery, groupsQuery];
  };

  const handleSearch = () => {
    setSearchFilters({ ...searchFilters, fromDate, toDate });
    updateSearchParameters({ offset: 0 });
    fetchLogs(0, searchQuery.size, fromDate, toDate, ...createFiltersQuery());
  };

  const handlePassPaginateValues = (offset: number, size: number) => {
    updateSearchParameters({ offset, size });
    fetchLogs(offset, size, fromDate, toDate);
  };

  return (
    <Box>
      <Box alignItems="center">
        <Text as="h3" fontSize="3xl">
          {t("titles.attendanceReport")}
        </Text>
      </Box>
      <Box className="flex_filters" mb="4" mt="4">
        <Box className="filters_container">
          <Box className="reactDatePicker_container">
            <label>From Date</label>
            <DatePicker
              className="reactDatePicker"
              selected={new Date(fromDate)}
              onChange={(date: Date) => {
                let updatedFromDate = dateHelper.formatDate(
                  date.toString(),
                  "YYYY-MM-DDTHH:mm:ssZ"
                );
                setFromDate(updatedFromDate);
                updateSearchParameters({ fromDate: updatedFromDate });
              }}
              showTimeSelect
              dateFormat="MM/dd/yyyy hh:mm aa"
              timeIntervals={15}
              timeFormat="HH:mm"
            />
          </Box>
          <Box className="reactDatePicker_container">
            <label>To Date</label>
            <DatePicker
              className="reactDatePicker"
              selected={new Date(toDate)}
              onChange={(date: Date) => {
                let updatedToDate = dateHelper.formatDate(
                  date.toString(),
                  "YYYY-MM-DDTHH:mm:ssZ"
                );
                setToDate(updatedToDate);
                updateSearchParameters({ toDate: updatedToDate });
              }}
              showTimeSelect
              dateFormat="MM/dd/yyyy hh:mm aa"
              timeIntervals={15}
              timeFormat="HH:mm"
            />
          </Box>
          {/* RidersSelect Dropdown Filter */}
          <FilterDropDown
            filterBoxTitle="Rider(s)"
            data={riders?.items || []}
            defaultSelectedValues={selectedRiders.current}
            onChange={onRidersDropDownChanged}
            onScroll={handleScrollRidersFilter}
            onSerach={handleSearchRiders}
            loading={isLoadingRiders}
          />

          {/* Zones Dropdown Filter */}
          <FilterDropDown
            filterBoxTitle="Zone(s)"
            data={zonesList}
            defaultSelectedValues={selectedZones.current}
            onChange={onZonesDropDownChanged}
          />

          {/* Groups Dropdown Filter */}
          <FilterDropDown
            filterBoxTitle="Group(s)"
            data={groupsList}
            defaultSelectedValues={selectedGroups.current}
            onChange={onGroupDropDownChanged}
          />

          <Box>
            <Button
              colorScheme="red"
              pr="20px"
              pl="20px"
              id="search_btn"
              onClick={() => handleSearch()}
            >
              Search
            </Button>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            bg="#F39905"
            color="#333"
            ml="2"
            pr="20px"
            pl="20px"
            ref={exportButton}
            onClick={() => exportCSV()}
          >
            <Icon path={mdiTrayArrowDown} size="25px" color="#333" />
            &nbsp;Export
          </Button>
        </Box>
      </Box>
      <Box height="5px" mb="1">
        {loading && <HorizontalLoading />}
      </Box>
      {totalCheckInDuration > 0 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          mb="2"
        >
          <Text as="span" fontSize="0.875rem" mr="2" ml="1">
            Sum (hours):
          </Text>
          <Text as="span" fontWeight="500">
            {dateHelper.getDuration(Math.ceil(totalCheckInDuration), false)}
          </Text>
        </Box>
      )}
      <Box className="table_container">
        {!showNoResultFound ? (
          <Table
            variant="simple"
            colorScheme="blackAlpha"
            size="md"
            boxShadow="md"
            backgroundColor="white"
            borderRadius="md"
          >
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Employee Id</Th>
                <Th>{t("fields.fullName")}</Th>
                <Th>Group</Th>
                <Th>Zone</Th>
                <Th>Total Hours</Th>
                <Th>Number of Exit</Th>
                <Th>Number of Enterance</Th>
              </Tr>
            </Thead>
            <Tbody>
              {attendanceLogs.map((report) => (
                <AggregationRowRecord
                  pageChanged={pageChanged}
                  index={`${report.rider.id}-${report.zone.id}`}
                  rowData={report}
                  key={`${report.rider.id}-${report.zone.id}`}
                  filters={searchFilters}
                />
              ))}
            </Tbody>
          </Table>
        ) : (
          <NotFoundResult />
        )}
      </Box>
      <Pagination
        entityTitle="record"
        pageTotalCount={pageTotalCount}
        perPageItems={searchQuery.size}
        passPaginateValues={handlePassPaginateValues}
      />
    </Box>
  );
};

export default AttendanceReport;
