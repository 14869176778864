import { Box } from "@chakra-ui/react";
import React from "react";
import "./styles.css";

export enum classColors {
    red = "red",
    blue = "blue",
    green = "green",
    yellow = "yellow",
    black = "gray",
    shineGreen = "lightgreen",
    crimson = "crimson"
}

interface IBulletType {
    size?: number;
    classColor: classColors;
    style?: Record<string, string>;
}

const BulletType: React.FC<IBulletType> = ({ size = 22, classColor, style }) => {
    const bulletSize = `${size}px`;
    const bulletChildSize = `${size - 10 > 0 ? Math.round(size - 10) : 2}px`;

    return <Box className={`bullet_type_wrapper ${classColor}`} width={bulletSize} height={bulletSize} style={style}>
        <Box className="bullet_type" width={bulletChildSize} height={bulletChildSize}></Box>
    </Box>;
}

export default BulletType;