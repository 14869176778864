import { Box } from "@chakra-ui/react";
import React from "react";
import clsx from "clsx";
import { useDashboardLayout } from "../../DashboardLayoutContext";
import Menu from "../Menu";
import { adminMenuData } from "./menuData";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import LogoMinimal from "src/components/app/LogoMinimal";


const Drawer: React.FC = () => {
    const { drawerIsOpen, setDrawerIsOpen } = useDashboardLayout();
    const onCloseClicked = () => {
        setDrawerIsOpen(false);
    }

    return (
        <>
            <Box className={clsx("d-back-drop", drawerIsOpen && "show")} onClick={onCloseClicked}></Box>
            <Box className={clsx("drawer", drawerIsOpen && "expand")}>
                <span className="d-close-btn" onClick={onCloseClicked}>
                    <Icon path={mdiClose} size="24px" />
                </span>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <LogoMinimal />
                </Box>
                <Menu key={adminMenuData.title} title={adminMenuData.title} icon={adminMenuData.icon}
                    navLinks={adminMenuData.navLinks} />
            </Box>
        </>
    );
}

export default Drawer;