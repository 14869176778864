import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import Icon from "@mdi/react";
import { Box, Center, Input, Text } from "@chakra-ui/react";
import { mdiAlertCircleOutline, mdiBicycle, mdiCar } from "@mdi/js";
import { panelVehiclesServices } from "src/api/services/vehicle";
import { IVehicleListItem, VehicleType } from "src/api/types/vehicle";
import { DEFAULT_COUNT, DEFAULT_OFFSET } from "src/constants/api-default-values";
import SPSpinner from "../../SPSpinner";

interface IVehicleAssignBodyProps {
  selectedVehicle: IVehicleListItem;
  setSelectedVehicle: (vehicle: IVehicleListItem) => void;
}

const VehicleAssignBody: React.FC<IVehicleAssignBodyProps> = ({ selectedVehicle, setSelectedVehicle }) => {
  const [getAbortController] = useState<AbortController>(new AbortController());
  const [vehicles, setVehicles] = useState<IVehicleListItem[]>([]);
  const [filteredVehicles, setFilteredVehicles] = useState<IVehicleListItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getAllVehicles = useCallback(async () => {
    setLoading(true);
    try {
      const result = await panelVehiclesServices.getAll(DEFAULT_OFFSET, DEFAULT_COUNT, getAbortController.signal);
      setVehicles(result.data.data.items);
      setFilteredVehicles(result.data.data.items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getAllVehicles();
  }, [getAllVehicles]);

  const selectVehicle = (vehicle: IVehicleListItem) => {
    setSelectedVehicle(vehicle);
  }

  const setVehicleType = (vehicle: IVehicleListItem) => {
    let vType = vehicle.vehicleType === VehicleType.Car ? VehicleType.Car : VehicleType.Bike;
    let vTypeIcon = vehicle.vehicleType === VehicleType.Car ? mdiCar : mdiBicycle;

    return [vType, vTypeIcon];
  }

  const showVehicleType = (vehicle: IVehicleListItem, selected: boolean) => {
    const [vType, vTypeIcon] = setVehicleType(vehicle);

    return <Box display="flex" alignItems="center">
      <Text display="flex" alignItems="flex-end">
        <Text as="span"><Icon size="20px" path={vTypeIcon} /></Text>
        <Text as="span" ml="1">{vType}</Text>
      </Text>
      <Text as="span" ml="1" color={selected ? "#ccc" : "#f00"} fontSize="0.9em">{(vehicle.rider) ? `(${vehicle.rider.firstName} ${vehicle.rider.lastName})` : ""}</Text>
    </Box>
  }

  const filterVehicleList = (e: ChangeEvent<HTMLInputElement>) => {
    let search = e.target.value;

    let delayTimer;
    clearTimeout(delayTimer);
    delayTimer = setTimeout(function () {
      let filteredList = vehicles.filter(vehicle => {
        return vehicle.plateNumber.toLowerCase().includes(search) || vehicle.chassis.toLowerCase().includes(search);
      });

      setFilteredVehicles(filteredList);
    }, 800);
  }

  const showSelectedVehicle = () => {
    if (selectedVehicle) {
      const [vType, vTypeIcon] = setVehicleType(selectedVehicle);
      return <Text display="flex" alignItems="center">
        <Text as="span"><Icon size="22px" path={vTypeIcon} /></Text>
        <Text as="span" ml="1" fontWeight="500">{vType}</Text>
        <Text as="span" ml="2" color="#666">{selectedVehicle?.plateNumber} - {selectedVehicle?.chassis}</Text>
        {selectedVehicle.rider ? <Text ml="2" color="#f00" fontSize="0.875rem">({`${selectedVehicle.rider.firstName} ${selectedVehicle.rider.lastName}`})</Text> : ""}
      </Text>;
    }

    return <Text>- Please select a vehicle from below list -</Text>;
  }

  const showAlreadyAssignAlert = () => {
    if (selectedVehicle?.rider) {
      return <Box p="3" bg="rgba(248,209,210,.4)" border="1px solid #d76363" mt="2" mb="2" borderRadius="6">
        <Text fontSize="0.875rem" display="flex" alignItems="center" gap="1">
          <Icon size="22px" path={mdiAlertCircleOutline} color="#cb2e2e" />
          The selected vehicle is already assigned to <Text as="span" fontWeight="500">{`${selectedVehicle?.rider?.firstName} ${selectedVehicle?.rider?.lastName}`}</Text>
        </Text>
      </Box>
    }

    return <></>;
  }

  return <>
    <Box className="vehicleAssignBody" mb={4}>
      <Box className="vehicleInfoViewerBox">
        {showSelectedVehicle()}
      </Box>
      {showAlreadyAssignAlert()}
      <Box className="vehiclesDropDownContainer">
        {
          loading
            ? <Center>
              <SPSpinner size="lg" />
            </Center>
            : <>
              <Input type="text" placeholder="Search vehicle" mb={2} onChange={(e) => filterVehicleList(e)} />
              <Box className="vehicleItemsBox">
                {
                  filteredVehicles.map(vehicle => {
                    let isSelected = selectedVehicle && selectedVehicle.id === vehicle.id ? true : false;
                    return <Box key={vehicle.id} className={isSelected ? "vehicleItem selected" : "vehicleItem"} onClick={() => selectVehicle(vehicle)}>
                      {showVehicleType(vehicle, isSelected)}
                      <Text>{`${vehicle.plateNumber} - ${vehicle.chassis}`}</Text>
                    </Box>;
                  })
                }
              </Box>
            </>
        }
      </Box>
    </Box>
  </>
}

export default VehicleAssignBody;