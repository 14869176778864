import { HubConnectionBuilder, HubConnection, LogLevel, HubConnectionState, HttpTransportType } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { ApiConfig } from "src/api/config";
import { getAuthorization } from "src/utils/get-authorization";

interface ISignalRProps {
    urlPath: string;
}

export type TConnectionState = HubConnectionState.Connected | HubConnectionState.Connecting | HubConnectionState.Disconnected | HubConnectionState.Disconnecting | HubConnectionState.Reconnecting;

const useSignalR = ({ urlPath }: ISignalRProps): {
    connection: HubConnection | undefined,
    connectionStatus: TConnectionState
} => {
    const [connection, setConnection] = useState<HubConnection>();
    const [connectionStatus, setConnectionStatus] = useState<TConnectionState>(HubConnectionState.Disconnected);
    let intervalID: any;

    const buildNewHubConnection = () => {
        try {
            const token = getAuthorization()?.split(" ")[1];
            const wsConnection = new HubConnectionBuilder()
                .withUrl(`${ApiConfig.baseURL}${urlPath}`, {
                    accessTokenFactory: () => {
                        return `${token}`
                    }
                })
                .withAutomaticReconnect()
                .configureLogging(LogLevel.None)
                .build();

            wsConnection?.start().then(() => {
                console.log(`-> ${new Date().toLocaleTimeString()} - Connection Started: `, connection?.state);
                setConnectionStatus(wsConnection?.state);
            }).catch((error) => {
                connection?.stop();
                console.log(`Error on Starting: ${connection?.state}`);
                console.log(error);
            });

            setConnection(wsConnection);
            setConnectionStatus(wsConnection?.state);
            // console.log(`-> ${new Date().toLocaleTimeString()} Builded: `, wsConnection?.state);

            return wsConnection;
        } catch (error) {
            console.log("An error was occured in the WebSocket connection...");
        }
    }

    useEffect(() => {
        let connectionInstance = buildNewHubConnection();

        return () => {
            console.log("-- DESTROYING SIGNALR --", connection?.state);
            clearInterval(intervalID);
            setConnection(undefined);
            connectionInstance?.stop().catch((err) => console.error(err.toString()));
            console.log(`-> ${new Date().toLocaleTimeString()} - Connection Stopped: `, connection?.state);
        }
    }, []);

    const handleOnCloseConnection = () => {
        connection?.onclose(() => {
            console.log(`-> ${new Date().toLocaleTimeString()} - Connection Closed: `, connection?.state);
            setConnectionStatus(connection?.state);
        });
    }

    const handleOnReconnected = () => {
        connection?.onreconnected(() => {
            console.log(`-> ${new Date().toLocaleTimeString()} - Reconnected: `, connection?.state);
            setConnectionStatus(connection?.state);
        });
    }

    const handleOnReconnecting = () => {
        connection?.onreconnecting(() => {
            console.log(`-> ${new Date().toLocaleTimeString()} - Reconnecting: `, connection?.state);
            setConnectionStatus(connection?.state);
        });
    }

    useEffect(() => {
        handleOnCloseConnection();
        handleOnReconnected();
        handleOnReconnecting();
    }, [connection]);

    // useEffect(() => {
    //     if (connection?.state === HubConnectionState.Disconnected) {
    //         connection.stop();
    //         intervalID = setInterval(() => {
    //             console.log(`<<< ${new Date().toLocaleTimeString()} AFTER Disconnected >>>`);
    //             buildNewHubConnection();
    //         }, 8000);
    //     }
    // }, [connection?.state]);

    return { connection, connectionStatus };
}

export default useSignalR;