import React from "react";
import { useManageZoneRidersContext } from "../../ManageZoneRidersContext";
import AddRiders from "../AddRiders";
import CurrentRiders from "../CurrentRiders";

const ModalContent:React.FC = ()=>{
    const {addRidersMode} = useManageZoneRidersContext();
    return <div>
        {
            addRidersMode ? <AddRiders /> : <CurrentRiders />
        }
    </div>
}

export default ModalContent;