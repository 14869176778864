import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import DashBoardStatistics from "./components/Statistics";
import TopZonesPerRiders from "./components/TopZonesPerRiders";
import TopZonesPerTime from "./components/TopZonesPerTime";
import RiderListTabs from "./components/RiderListTabs";
import "./styles.css";

const Home: React.FC = () => {
  const [timestamp, setTimestamp] = useState<number>(new Date().getTime());

  const handleRiderNumbers = (refreshedTimestamp: number) => {
    setTimestamp(refreshedTimestamp);
  };

  return (
    <Box className="dashboard_container">
      <DashBoardStatistics timestamp={timestamp} />
      <RiderListTabs />
      <Box className="charts_container">
        <TopZonesPerTime />
        <TopZonesPerRiders />
      </Box>
    </Box>
  );
};

export default Home;
