import { API_ROUTES } from "src/constants/api-routes";
import {AxiosInstance} from "../index";
import { ILoginPayload } from "../types/auth";
import { IAxiosResponse } from "../types/axios-response";

export const authService = {
    login(email:string,password:string,abortSignal:AbortSignal){
        return AxiosInstance.post<IAxiosResponse<ILoginPayload>>(API_ROUTES.AUTH.LOGIN,{
            email,
            password
        },{
            signal:abortSignal
        });
    }
};