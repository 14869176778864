import { Box, Button, ModalBody , Text } from "@chakra-ui/react"
import { mdiCheck } from "@mdi/js"
import Icon from "@mdi/react"
import React from "react"

interface ISuccessStateProps {
    selectedFile:File | null;
    handleClose:(mustBeClose:boolean) => void;
    message:string;
}
export const ShowSuccessState:React.FC<ISuccessStateProps> = ({selectedFile , handleClose , message}) => {
    return <ModalBody className="bulkFileUploaderContainer bulkFileUploaderSuccessTemp">
        <Text className="success_icon"><Icon path={mdiCheck} size="40px" color="#1CC089"/></Text>
        <Text color="#999999" mb="1" fontSize="1.1rem">{selectedFile?.name}</Text>
        <Text color="#333" fontWeight="500">{message}</Text>
        <Box alignSelf="flex-end" mt="2">
            <Button bg="blackAlpha.300" color="#333" onClick={() => handleClose(true)}>Done</Button>
        </Box>
    </ModalBody>
}