import { AxiosResponse } from "axios";
import { DEFAULT_COUNT, DEFAULT_OFFSET } from "src/constants/api-default-values";
import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from ".."
import { IStatisticsData, IDashBoardChartData, ITopZonesKeyValues } from "../types/dashboard";
import { IIdleRidersPayload, IInactiveRidersData, IUnreachableRidersPayload, RiderSorts } from "../types/riders";

export const panelDashboardService = {
	getStatistics(abortSignal: AbortSignal) {
		return AxiosInstance.get<AxiosResponse<IStatisticsData>>(API_ROUTES.DASHBOARD.STATISTICS,{signal:abortSignal});
	},
	getAllInactiveRiders(abortSignal: AbortSignal) {
		let query = `count=${DEFAULT_COUNT}&offset=${DEFAULT_OFFSET}&sort=${RiderSorts.ByNameAsc}`;

		return AxiosInstance.get<AxiosResponse<IInactiveRidersData>>(`${API_ROUTES.RIDERS.INACTIVE_RIDERS}?${query}`, { signal: abortSignal });
	},
	getTopZonePerRiders(count:number, abortSignal: AbortSignal) {
		let query = `count=${count}&offset=${DEFAULT_OFFSET}`;
		return AxiosInstance.get<AxiosResponse<ITopZonesKeyValues[]>>(`${API_ROUTES.DASHBOARD.TOPZONESPERRIDERS}?${query}`, {signal: abortSignal});
	},
	getTopZonePerCheckinTimes(count:number,fromDate:string,toDate:string, abortSignal: AbortSignal) {
		let query = `count=${count}&offset=${DEFAULT_OFFSET}&FromDate=${encodeURIComponent(fromDate)}&ToDate=${encodeURIComponent(toDate)}`;
		return AxiosInstance.get<AxiosResponse<IDashBoardChartData>>(`${API_ROUTES.DASHBOARD.TOPZONESPERRTIME}?${query}`, {signal: abortSignal});
	},
	getIdleRiders(abortSignal: AbortSignal) {
		let query = `count=${DEFAULT_COUNT}&offset=${DEFAULT_OFFSET}&sort=${RiderSorts.ByNameAsc}`;

		return AxiosInstance.get<AxiosResponse<IIdleRidersPayload>>(`${API_ROUTES.RIDERS.IDLE_RIDERS}?${query}`, { signal: abortSignal });
	},
	getUnreachableRiders(abortSignal: AbortSignal) {
		let query = `count=${DEFAULT_COUNT}&offset=${DEFAULT_OFFSET}&sort=${RiderSorts.ByNameAsc}`;

		return AxiosInstance.get<AxiosResponse<IUnreachableRidersPayload>>(`${API_ROUTES.RIDERS.UNREACHABLE_RIDERS}?${query}`, { signal: abortSignal });
	},
}