import { Avatar, Box, Text } from "@chakra-ui/react";
import React from "react";
import ContentView, { ContentViewBody } from "src/components/app/ContentView";
import { useGlobal } from "src/global-context/GlobalContext";

const Profile: React.FC = () => {
    const { user } = useGlobal();
    return <ContentView>
        <ContentViewBody>
            <Box>
                <Box display="flex" alignItems="center" mb="4">
                    <Avatar name={`${user?.firstName} ${user?.lastName}`} src={user?.avatar} size="xl" mr="3" />
                    <Box>
                        <Text as="h2" fontSize="2xl">{user.firstName} {user.lastName}</Text>
                        <Text color="gray.600">{user.email}</Text>
                    </Box>
                </Box>
                <Box>
                    <Text as="span" mr="3">Role:</Text>
                    <Text as="span" textTransform="capitalize">{user.role}</Text>
                </Box>
            </Box>
        </ContentViewBody>
    </ContentView>;
}

export default Profile;