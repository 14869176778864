import React, { createRef, useEffect } from "react";
import { Avatar, Box, Text } from "@chakra-ui/react";
import clsx from "clsx";
import { ILocationLog } from "src/api/types/rider-locations";
import dateHelper from "src/utils/date-helper";
import { useExtendedLiveMapState } from "../../ExtendedLiveMapContext";
import { STATUS_COLORS } from "../../status-data";
import Icon from "@mdi/react";
import { mdiHomeMapMarker } from "@mdi/js";


interface IRiderItemProps {
    data: ILocationLog;
    onClick?: (sender: ILocationLog) => void;
}

export const RiderItem: React.FC<IRiderItemProps> = ({ data, onClick }) => {
    const { activeLocationLog } = useExtendedLiveMapState();
    let riderItemRef = createRef<any>();

    useEffect(() => {
        if (activeLocationLog && data.rider.id === activeLocationLog?.rider?.id) {
            riderItemRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [activeLocationLog]);

    const onRiderClicked = () => {
        onClick && onClick(data);
    }

    const getLatestActivity = () => {
        if (data.status !== "None") {
            const seconds = dateHelper.compareDateWithToday(data.date, "seconds");
            return `${dateHelper.getDuration(seconds, false)} ago`;
        }
        return "";
    }

    return <div ref={riderItemRef} className={clsx("l-m-rider-item", activeLocationLog?.rider?.id === data.rider.id && "active")} onClick={onRiderClicked}>
        <div className="l-m-rider-item-header">
            <Avatar src={data.rider.avatar} name={`${data.rider.firstName} ${data.rider.lastName}`} mr="2" size="sm" bg="gray.200" color="#333" />
            <Box display="flex" flexDir="column" justifyContent="center">
                <Text className="l-m-rider-item-name">{`${data.rider.firstName} ${data.rider.lastName}`}</Text>
                <Text fontSize="12px" color="#333333" display="block">ID&nbsp;{data.rider.employeeId}</Text>
            </Box>
        </div>
        <div>
            <Box className="l-m-rider-item-status">
                <Box className={clsx("l-m-rider-item-status-bullet", STATUS_COLORS[data.status])}></Box>
                <Box display="flex" flexDir="column" fontSize="1em">
                    <Text fontSize="0.82rem" fontWeight="500">{data.status}</Text>
                    <Text fontSize="0.75rem" color="#898787">{getLatestActivity()}</Text>
                </Box>
            </Box>
            <Box className="l-m-rider-item-zone" mt="1">
                <Icon className="icon" path={mdiHomeMapMarker} size="20px" />
                <Text fontSize="0.82rem" fontWeight="500">{data.zoneName}</Text>
            </Box>
        </div>
    </div>;
};