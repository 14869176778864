import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import FormBox from "../components/FormBox";
import { Box, Button, FormControl, FormLabel, Input, FormErrorMessage, Alert, AlertIcon, AlertDescription } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { mdiArrowLeft } from '@mdi/js';
import Icon from "@mdi/react";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { useForm } from "react-hook-form";
import { IForgotPasswordForm } from "./types";
import { forgotPasswordValidationSchema } from "src/validation-schemas/forgot-password";
import { yupResolver } from "@hookform/resolvers/yup";

const ForgotPassword: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.forgotPassword"));
    const [loading] = useState<boolean>(false);
    const [showSuccessMessage] = useState<boolean>(false);

    const {
        handleSubmit,
        register,
        formState: { errors }
    } = useForm<IForgotPasswordForm>({
        resolver: yupResolver(forgotPasswordValidationSchema(t)),
        mode: "all",
        defaultValues: {
            email: ''
        }
    });

    const onFormSubmit = async (data: IForgotPasswordForm) => {
        // try {
        //     setLoading(true);
        //     const result = await authService.forgotPassword(data.email);
        //     if (result.status === HTTP_STATUS_CODES.CREATED) {
        //         setShowSuccessMessage(true);
        //     }
        // } catch (err) {
        //     console.log(err);
        // } finally {
        //     setLoading(false);
        // }
    }

    return (
        <FormBox title={t("titles.forgotPassword")}>
            {
                !showSuccessMessage && (
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                        <FormControl mb="4" isInvalid={!!errors.email}>
                            <FormLabel htmlFor="email">{t("content.forgotPasswordHint")}</FormLabel>
                            <Input id="email" type="email" size="lg" {...register("email")} />
                            <FormErrorMessage>
                                {!!errors.email && errors.email.message}
                            </FormErrorMessage>
                        </FormControl>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb="8">
                            <Button colorScheme="brand" isFullWidth size="lg" type="submit"
                                isLoading={loading}>{t("actions.resetPassword")}</Button>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" as={Link} to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE} color="gray.600" mb="4">
                            <Icon path={mdiArrowLeft} size="24px" />
                            {t("actions.backToLogin")}
                        </Box>
                    </form>
                )
            }
            {
                showSuccessMessage && (
                    <Box>
                        <Alert status="success">
                            <AlertIcon />
                            <AlertDescription>{t("content.resetPasswordLinkSent")}</AlertDescription>
                        </Alert>
                        <Box display="flex" justifyContent="center" alignItems="center" as={Link}
                            mt="6"
                            to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE} color="gray.600" mb="4">
                            <Icon path={mdiArrowLeft} size="24px" />
                            {t("actions.backToLogin")}
                        </Box>
                    </Box>
                )
            }
        </FormBox>
    );
}

export default ForgotPassword;