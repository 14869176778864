import React, { useEffect, useMemo, useRef } from "react";
import { Box, Text, IconButton, Button } from "@chakra-ui/react";
import Table from "src/components/app/Table";
import Icon from "@mdi/react";
import { mdiPencil, mdiPlus, mdiTrayArrowDown } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import { IGroupListItem } from "src/api/types/groups";
import { panelGroupsService } from "src/api/services/groups";
import { Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { exportFile } from "src/utils/export-file";
import { ApiConfig } from "src/api/config";
import { API_ROUTES } from "src/constants/api-routes";
import dateHelper from "src/utils/date-helper";
import { Pagination } from "src/components/app/Pagination";
import { DEFAULT_COUNT } from "src/constants/api-default-values";
import { SearchInput } from "src/components/app/SearchInput";
import { useAsync } from "src/hooks/useAsync";
import { IFetchDataResponse } from "src/types/general";
import useQueryParam from "src/hooks/useSearchQuery";
import { useAbortController } from "src/hooks/useAbortController";
import { useTable } from "src/hooks/useTable";
import { ICustomOption } from "src/types/table";
import { modalActions } from "src/global-context/modals";
import { MODAL_TYPES } from "src/types/modals";

interface IFetchGroupsFilters {
  offset: number;
  size: number;
  query?: string;
}

type TCustomOptions = {
  actions: ICustomOption<{ id: string }>;
  showZoneAction: ICustomOption<IGroupListItem>;
};

const Groups: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.groups"));

  const columnsTable = useMemo(
    () => [
      {
        title: t("fields.nameEn"),
        dataIndex: "nameEn",
        isSortable: true,
        isHidden: false,
      },
      {
        title: t("fields.nameAr"),
        dataIndex: "nameAr",
        isSortable: true,
        isHidden: false,
      },
    ],
    []
  );

  const customOption: TCustomOptions = useMemo(
    () => ({
      actions: {
        title: "",
        dataIndex: "actions",
        render: (_, item) => (
          <IconButton
            aria-label="Test"
            icon={<Icon path={mdiPencil} size="28px" />}
            color="gray.100"
            bg="blue.300"
            as={Link}
            to={
              ROUTE_CONSTANTS.DASHBOARD.GROUPS.UPDATE.BY_DATA(item.id).ABSOLUTE
            }
          />
        ),
      },
      showZoneAction: {
        title: "",
        dataIndex: "showZoneAction",
        render: (_, item) => (
          <Button
            type="button"
            size="sm"
            fontWeight="normal"
            onClick={() =>
              modalActions.addModal(MODAL_TYPES.GROUP_ZONES_MODAL, item)
            }
          >
            Show Zones
          </Button>
        ),
      },
    }),
    []
  );

  const exportButton = useRef<any>();
  const { getAbortSignal } = useAbortController();
  const { queryParams, updateQueryParams, initialized } = useQueryParam({
    initialFields: {
      size: 10,
      offset: 0,
      query: "",
    },
  });

  const {
    run: fetchGroups,
    isLoading,
    data: groupsData,
  } = useAsync<IFetchDataResponse<IGroupListItem>, IFetchGroupsFilters>(
    (options) => {
      const { offset, size, query = queryParams.query } = options;
      return new Promise(async (resolve, reject) => {
        try {
          const response = await panelGroupsService.getAll(
            offset,
            size,
            getAbortSignal("fetchGroups").signal,
            query
          );
          resolve(response.data.data as IFetchDataResponse<IGroupListItem>);
        } catch (e) {
          reject([]);
        }
      });
    },
    {
      defaultValue: undefined,
    }
  );

  const { columnsArray } = useTable<any>({
    columnsTable: columnsTable,
    loading: isLoading,
    suffixColNames: ["actions", "showZoneAction"],
    customOption: customOption,
  });

  useEffect(() => {
    if (initialized) {
      const { offset, size, query } = queryParams;
      fetchGroups({
        offset,
        size,
        query,
      });
    }
  }, [initialized]);

  const search = (searched: string) => {
    updateQueryParams({
      query: searched.toLocaleLowerCase().trim(),
      offset: 0,
    });
    fetchGroups({
      offset: 0,
      size: queryParams.size,
      query: searched.toLocaleLowerCase().trim(),
    });
  };

  const exportCSV = () => {
    exportFile(
      ApiConfig.baseURL + API_ROUTES.GROUPS.EXPORT,
      {
        count: "" + DEFAULT_COUNT,
        keyword: "" + queryParams.query,
      },
      exportButton,
      `Groups-${dateHelper.getToday()}`
    );
  };

  const handlePassPaginateValues = (offset: number, size: number) => {
    updateQueryParams({ offset: offset, size: size });
    fetchGroups({ offset, size });
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Text as="h3" fontSize="3xl">
          {t("titles.groups")}
        </Text>
        <IconButton
          aria-label="New Group"
          as={Link}
          to={ROUTE_CONSTANTS.DASHBOARD.GROUPS.CREATE.ABSOLUTE}
          ml="4"
          icon={<Icon path={mdiPlus} size="28px" />}
          colorScheme="primary"
          variant="outline"
          size="sm"
        />
      </Box>
      <Box
        mb="4"
        mt="4"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SearchInput
          searchPlaceholder={t("actions.search")}
          value={queryParams.query}
          onSearchInput={search}
        />
        <Button
          bg="#F39905"
          color="#333"
          ml="2"
          pr="20px"
          pl="20px"
          ref={exportButton}
          onClick={() => exportCSV()}
        >
          <Icon path={mdiTrayArrowDown} size="25px" color="#333" />
          &nbsp;Export
        </Button>
      </Box>
      <Table
        loading={isLoading}
        dataSource={groupsData?.items}
        columns={columnsArray || []}
      />
      <Pagination
        entityTitle="Group"
        loading={isLoading}
        pageTotalCount={groupsData?.totalCount || 0}
        perPageItems={queryParams.size || 10}
        passPaginateValues={handlePassPaginateValues}
      />
    </Box>
  );
};
export default Groups;
