import { Box } from "@chakra-ui/react";
import React from "react";
import { IAttendanceLogItem } from "src/api/types/attendance-log";
import { TimeLineItem } from "./TimeLineItem";
import "./TimeLine.css";
import SPSpinner from "src/components/app/SPSpinner";


interface ITimeLineProps {
    items: IAttendanceLogItem[];
    loading?:boolean;
}

const TimeLine: React.FC<ITimeLineProps> = (props) => {
    const { items,loading = false } = props;

    return <>
        {
            items.map((it, ind) => (
                <TimeLineItem key={ind} data={it}/>
            ))
        }
        {
            loading && <Box className="loading">
                <SPSpinner size="xl"/>
            </Box>
        }
        </>;
}

export default TimeLine;