import { Box, Button, Center, Table, Tbody, Td, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ordersService } from "src/api/services/orders";
import { IOrderStatusEnum, IPendingOrder, OrderPaymentType } from "src/api/types/orders";
import { VehicleType } from "src/api/types/vehicle";
import { NotFoundResult } from "src/components/app/NotFoundResult";
import { DEFAULT_COUNT, DEFAULT_OFFSET } from "src/constants/api-default-values";
import dateHelper from "src/utils/date-helper";
import { useOrderDataDispatch, useOrderDataState } from "../../OrdersDataContext";
import { OrdersDataStoreActionKind } from "../../OrdersDataStore/OrdersDataStore-Actions";
import { mdiAccountCheckOutline, mdiAccountMultipleOutline, mdiBicycle, mdiCar, mdiCurrencyUsd, mdiWalletOutline } from "@mdi/js";
import EligibleRiders from "../EligibleRiders";
import SPSpinner from "src/components/app/SPSpinner";

interface IOngoingPendingOrderListProps {
    allowToCallAPI: boolean;
    tabIsActive: boolean;
}

const OngoingPendingOrderList: React.FC<IOngoingPendingOrderListProps> = ({ allowToCallAPI, tabIsActive }) => {
    const { t } = useTranslation();
    const { pendingOrders } = useOrderDataState();
    const dispatch = useOrderDataDispatch();


    const [loading, setLoading] = useState<boolean>(false);
    const [getAbortController] = useState<AbortController>(new AbortController());
    const [showNoResultFound, setShowNoResultFound] = useState<boolean>(false);
    const [selectedOrder, setSelectedOrder] = useState<IPendingOrder>({} as IPendingOrder);
    const [isOpenEligibleRiderModal, setIsOpenEligibleRiderModal] = useState<boolean>(false);

    const getPendingOrderList = useCallback(async () => {
        console.log("<<=== Pending Order List API ===>>");

        const {start, end} = dateHelper.getFromStartUntilXHoursAgo(new Date(), 2);
        const fromToRange = dateHelper.getStartEndByDatesAndTimes(start, end);
        setLoading(true);
        try {
            const result = await ordersService.getOrdersByStatus({
                count: DEFAULT_COUNT,
                offset: DEFAULT_OFFSET,
                fromDate: fromToRange.end,
                toDate: fromToRange.start,
                statusId: IOrderStatusEnum.Pending
            }, getAbortController.signal);

            dispatch({
                type: OrdersDataStoreActionKind.SET_PENDING_ORDERS,
                data: {
                    orders: result.data.data.items,
                    count: result.data.data.items.length
                }
            });
            result.data.data.items.length > 0 ? setShowNoResultFound(false) : setShowNoResultFound(true);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [getAbortController]);

    useEffect(() => {
        pendingOrders.length > 0 ? setShowNoResultFound(false) : setShowNoResultFound(true);
    }, [pendingOrders]);

    useEffect(() => {
        if (allowToCallAPI && tabIsActive) {
            getPendingOrderList();
        }
        // when user change tab
        if (!tabIsActive) {
            // console.log("----->", tabIsActive);
            setShowNoResultFound(false);
            setLoading(true);
        }
    }, [getPendingOrderList, allowToCallAPI, tabIsActive]);

    useEffect(() => {
        return () => {
            getAbortController.abort();
        }
    }, []);

    const renderOrderVehicleType = (orderItem: IPendingOrder) => {
        return orderItem.vehicleType === VehicleType.Bike
            ? <Icon path={mdiBicycle} size="20px" style={{ minWidth: "20px", marginRight: "4px" }} />
            : <Icon path={mdiCar} size="20px" style={{ minWidth: "20px", marginRight: "4px" }} />;
    }

    const handleOrderValue = (orderItem: IPendingOrder) => {
        const icon = orderItem.paymentType === OrderPaymentType.Paid
            ? <Icon path={mdiWalletOutline} size="20px" style={{ marginRight: "4px" }} />
            : <Icon path={mdiCurrencyUsd} size="20px" style={{ marginRight: "4px" }} />;

        return <Tooltip label={orderItem.paymentType}>
            <Box display="flex" alignItems="center">{icon}{orderItem.cost}</Box>
        </Tooltip>
    }

    const renderActionButttons = (orderItem: IPendingOrder) => {
        let disableAcceptedRiderBtn: boolean = false;

        return <Box display="flex" alignItems="center">
            {/* Eligible Rider Button */}
            <Tooltip label="Available Riders" sx={{ borderRadius: "8px" }}>
                <Button variant="link" onClick={() => {
                    setIsOpenEligibleRiderModal(true);
                    setSelectedOrder(orderItem);
                }}>
                    <Icon path={mdiAccountMultipleOutline} size="25px" />
                </Button>
            </Tooltip>
            {/* Accepted Rider Button*/}
            <Tooltip label="Accepted Rider" sx={{ borderRadius: "8px" }}>
                <Button variant="link" disabled={true}>
                    <Icon path={mdiAccountCheckOutline} size="25px" />
                </Button>
            </Tooltip>
        </Box>;
    }

    return loading
        ? <Center minHeight="200px">
            <SPSpinner size="lg"/>
        </Center>
        : <>
        {/* <TableContainer mt={1}> */}
            {!showNoResultFound
                ? <Table
                    variant="simple"
                    colorScheme="blackAlpha"
                    size="md"
                    boxShadow="md"
                    backgroundColor="white"
                    borderRadius="md"
                >
                    <Thead bg="#E6E6E6" color="#808080" position="sticky" top="60px" zIndex={2}>
                        <Tr>
                            <Th>{t("fields.orderId")}</Th>
                            <Th>{t("fields.OTP")}</Th>
                            <Th>{t("fields.orderValue")} (KD)</Th>
                            <Th>{t("fields.groupId")}</Th>
                            <Th>{t("fields.zoneName")}</Th>
                            <Th>{t("fields.pending")}</Th>
                            <Th>{t("fields.accepted")}</Th>
                            <Th>{t("fields.pickedUp")}</Th>
                            <Th>{t("fields.onTheWay")}</Th>
                            <Th>{t("fields.delivered")}</Th>
                            <Th>{t("fields.action")}</Th>
                        </Tr>
                    </Thead>
                    <Tbody border="1px solid #F9F9F9">
                        {
                            pendingOrders.map(orderItem => {
                                return <Tr key={orderItem.id}>
                                    <Td sx={{ maxWidth: "130px", wordWrap: "break-word" }}>
                                        <Box display="flex" alignItems="center">{renderOrderVehicleType(orderItem)}{orderItem.code}</Box>
                                    </Td>
                                    <Td>{orderItem.hasOtp ? orderItem.otpCode : "-"}</Td>
                                    <Td>{handleOrderValue(orderItem)}</Td>
                                    <Td>{orderItem.groupName}</Td>
                                    <Td>{orderItem.applicant}</Td>
                                    <Td>
                                        <Tooltip label={dateHelper.formatDate(orderItem.date, "DD/MM/YYYY HH:mm")}>
                                            {dateHelper.formatDate(orderItem.date, "DD/MM/YYYY HH:mm")}
                                        </Tooltip>
                                    </Td>
                                    <Td>-</Td>
                                    <Td>-</Td>
                                    <Td>-</Td>
                                    <Td>-</Td>
                                    <Td>{renderActionButttons(orderItem)}</Td>
                                </Tr>;
                            })
                        }
                    </Tbody>
                </Table> : <NotFoundResult />}
        {/* </TableContainer> */}
        {/* Accepted Rider Modal */}
        <EligibleRiders
            orderItem={selectedOrder}
            isOpen={isOpenEligibleRiderModal}
            handleClose={() => setIsOpenEligibleRiderModal(false)} />
    </>;
}

export default OngoingPendingOrderList;