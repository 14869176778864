import { useEffect, useState } from "react";
import { IColumns } from "../types/table";
import {
  makeSuffixTable,
  makeTableFromMeta,
  sortColumns,
} from "../utils/table";

type ColumnByName<T> = Record<string, IColumns<T>>;
type Options<T> = {
  columnsTable: IColumns<T>[];
  customOption?: ColumnByName<T>;
  customSort?: string[];
  suffixColNames?: string[];
  loading: boolean;
};

export function useTable<T>(options: Options<T>) {
  const {
    columnsTable = [],
    customOption = {},
    customSort = [],
    suffixColNames = [],
  } = options || {};
  const [columnsArray, setColumnsArray] = useState<IColumns<T>[]>([]);

  useEffect(() => {
    if (columnsTable.length) {
      handleTable();
    }
  }, [columnsTable, customOption]);

  function handleTable() {
    let newCols: IColumns<T>[] = columnsTable.map((column) => {
      return {
        ...makeTableFromMeta(column),
        ...(customOption[column.dataIndex]
          ? customOption[column.dataIndex]
          : {}),
      };
    });
    if (suffixColNames.length)
      suffixColNames.forEach((suffixCol) =>
        newCols.push({
          ...makeSuffixTable(suffixCol),
          ...(customOption[suffixCol] ? customOption[suffixCol] : {}),
        })
      );
    newCols = sortColumns(newCols, customSort);
    setColumnsArray(newCols);
  }

  const modifyColumn = (columnKey: string, value: Partial<IColumns<T>>) => {
    setColumnsArray((prev) =>
      prev.map((column) =>
        columnKey !== column.key
          ? column
          : {
              ...column,
              ...value,
            }
      )
    );
  };

  const toggleColumn = (columnKey: string) => {
    setColumnsArray((prev) =>
      prev.map((column) => {
        if (column.key !== columnKey) return column;
        else
          return {
            ...column,
            isHidden: !column.isHidden,
          };
      })
    );
  };

  return {
    columnsArray: columnsArray.filter((col) => !col.isHidden) || [],
    modifyColumn,
    handleTable,
    toggleColumn,
  };
}
