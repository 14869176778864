import { Box, Text } from "@chakra-ui/react";
import React from "react";
import Checkbox from "src/components/app/ManageZoneRidersModal/components/Checkbox";
interface IRiderItemProps {
  data: any;
  onChange: (sender: any, selected: boolean) => void;
  disabled?: boolean;
}

export const ZoneItem: React.FC<IRiderItemProps> = (props) => {
  const { data, onChange, disabled = false } = props;

  const onItemClicked = () => {
    if (disabled) return;
    onChange(data, !data.selected);
  };

  return (
      <Box
          display="flex"
          justifyContent="space-between"
          fontWeight="500"
          alignItems="center"
          pt="2"
          pb="2"
          pl="4"
          pr="4"
          cursor={disabled ? "not-allowed" : "pointer"}
          userSelect="none"
          onClick={onItemClicked}
          opacity={disabled ? 0.7 : 1}
          _hover={{
            backgroundColor: disabled ? "transparent" : "gray.50"
          }}
      >
        <Box display="flex" alignItems="center">
          <Checkbox selected={data.selected} />
          <Box>
            <Text as="span" ml="2" color={disabled ? "gray.400" : "inherit"}>
              {data.text}
            </Text>
          </Box>
        </Box>
      </Box>
  );
};

