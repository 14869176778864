import { Avatar, Box, Center, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { mdiBellOff, mdiBellOutline, mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import { useDashboardLayout } from "../../DashboardLayoutContext";
import { useGlobal } from "src/global-context/GlobalContext";
import { ACCESS_TOKEN } from "src/constants/access-token";
import { useNavigate, Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { useOutsideAlerter } from "src/hooks/useOutsideAlerter";
import { panelNotificationService } from "src/api/services/notifications";
import { GET_NOTIFICATION_TIMER } from "src/constants/timer";
import { INotificationListItem, NotificationTypeEnum } from "src/api/types/notifications";
import { DEFAULT_COUNT, DEFAULT_OFFSET } from "src/constants/api-default-values";
import dateHelper from "src/utils/date-helper";
import "./styles.css";
import SPSpinner from "src/components/app/SPSpinner";

const Navbar: React.FC = () => {
    const { setDrawerIsOpen } = useDashboardLayout();
    const { user, setUser } = useGlobal();
    const navigate = useNavigate();
    const notificationDialogueRef = useRef<any>();
    const clicked = useOutsideAlerter(notificationDialogueRef);
    const [notificationCount, setNotificationCount] = useState<number>(0);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [getNoticeCountAbortController] = useState<AbortController>(new AbortController());
    const [getNotificationsAbortController] = useState<AbortController>(new AbortController());
    const [notificationItems, setNotificationItems] = useState<INotificationListItem[]>([]);
    const [loadingNotifications, setLoadingNotifications] = useState<boolean>(false);
    const [lastNotificationDate, setLastNotificationDate] = useState<string>(dateHelper.formatDate(new Date().getTime().toString(), "YYYY-MM-DDTHH:mm:ssZ"));

    const getNotificationCount = async () => {
        try {
            const result = await panelNotificationService.getNotificationCount(getNoticeCountAbortController.signal);
            setNotificationCount(result.data.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getNotifications = async () => {
        setLoadingNotifications(true);
        try {
            const result = await panelNotificationService.getNotifications(DEFAULT_OFFSET, DEFAULT_COUNT, getNotificationsAbortController.signal);
            let notifications = result.data.data.items;
            setNotificationItems(notifications);
            notifications.length > 0 && setLastNotificationDate(notifications[0].createdAt);
            setNotificationCount(0);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingNotifications(false);
        }
    }

    const handleReadNotifications = async (date: string) => {
        try {
            setNotificationCount(0);
            await panelNotificationService.readNotification({ date });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getNotificationCount();
        let getNotificationInterval = setInterval(() => getNotificationCount(), GET_NOTIFICATION_TIMER);

        return () => {
            clearInterval(getNotificationInterval);
            getNoticeCountAbortController.abort();
            getNotificationsAbortController.abort();
        }
    }, []);

    useEffect(() => {
        let visibility: boolean = false;
        visibility = clicked && isVisible ? true : false;
        if (visibility) {
            getNotifications();
        }
        setIsVisible(visibility);
    }, [clicked, isVisible]);

    useEffect(() => {
        !isVisible && handleReadNotifications(lastNotificationDate);
    }, [isVisible]);

    const onMenuHandlerClicked = () => {
        setDrawerIsOpen((value) => !value)
    }

    const onSignOutClicked = () => {
        setUser(undefined);
        localStorage.removeItem(ACCESS_TOKEN);
        navigate(ROUTE_CONSTANTS.ROOT.ABSOLUTE);
    }

    const handleToggleNotification = () => {
        setIsVisible(!isVisible);
    }

    const calcNotificationDuration = (elapsedSeconds: number): string => {
        let descriptiveDuration = "";
        if (Math.floor(elapsedSeconds / 3600) === 0) {
            descriptiveDuration = `${Math.round(elapsedSeconds / 60)} minute${Math.round(elapsedSeconds / 60) < 2 ? "" : "s"} ago`
        } else {
            descriptiveDuration = `${Math.round(elapsedSeconds / 3600)} hour${Math.round(elapsedSeconds / 3600) < 2 ? "" : "s"} ago`;
        }

        return descriptiveDuration;
    }

    const renderNotificationItems = () => {
        if (loadingNotifications) {
            return <Center mt={4} mb={4}>
                <SPSpinner />
            </Center>;
        }

        if (notificationItems.length === 0) {
            return <Box className="nd_padder" pt={4} pb={4} textAlign="center" color="#999">
                <Box display="flex" justifyContent="center"><Icon path={mdiBellOff} size="22px" /> &nbsp; There is no notification!</Box>
            </Box>;
        }

        return notificationItems.map((ni, ind) => {
            let spliteMsg = ni.message.split("#");
            let createdAtDate = dateHelper.formatDate(ni.createdAt, "D MMM YYYY");
            let createdAtTime = dateHelper.formatDate(ni.createdAt, "HH:mm A");
            let duration = calcNotificationDuration(ni.checkoutPeriodSeconds);
            let message = "";
            switch (ni.type) {
                case NotificationTypeEnum.SystematicTooLateCheckIn:
                    message = "Systematic Checked Out";
                    break;
                case NotificationTypeEnum.SystematicTooLateExit:
                    message = "Checked Out";
                    break;
                case NotificationTypeEnum.IdleRider:
                    message = "Idled";
                    break;
            }

            return <Box key={ind}>
                <Box className={ni.status === "Seen" ? "nd_padder notification_item seen" : "nd_padder notification_item"}>
                    <Box display="flex" alignItems="center">
                        <Avatar name={spliteMsg[0]} size="sm" />
                        <Box fontSize="0.875rem" color="#333" ml="2"><Text as="span" fontWeight={500}>{spliteMsg[0]}</Text><Text as="span"> {message} on {createdAtDate} at {createdAtTime}</Text></Box>
                    </Box>
                    <Box fontSize="0.85rem" className="created_time" ml="10">{duration}</Box>
                </Box>
                {(ind !== notificationItems.length - 1) && <Box className="spliter_line"></Box>}
            </Box>
        });
    }

    const renderNoticeBadge = (count: number): JSX.Element => {
        let badgeElm: JSX.Element;
        if (count > 99) {
            badgeElm = <Box className="notification_badge">+99</Box>;
        } else {
            badgeElm = notificationCount === 0 ? <></> : <Box className="notification_badge">{count}</Box>;
        }

        return badgeElm;
    }

    return (
        <Box className="navbar">
            <Box className="brand">
                <IconButton aria-label="Test" icon={<Icon path={mdiMenu} size="28px" />}
                    color="gray.700"
                    bg="transparent"
                    _hover={{ bg: "transparent" }}
                    onClick={onMenuHandlerClicked} />
            </Box>
            <Box display="flex" alignItems="center">
                <Box pos="relative" mr="8" ref={notificationDialogueRef}>
                    <Box>
                        <button className="notification_btn" onClick={handleToggleNotification}>
                            <Icon path={mdiBellOutline} size="30px" />
                        </button>
                        <Box className="notification_badge_container">
                            <Box>{renderNoticeBadge(notificationCount)}</Box>
                        </Box>
                    </Box>
                    <Box className={isVisible ? "notification_dialogue show" : "notification_dialogue"}>
                        <Box className="notification_dialogue_header nd_padder">
                            <Text fontSize="1.1rem" fontWeight="500">Notifications</Text>
                        </Box>
                        <Box className="notification_items_container">
                            {renderNotificationItems()}
                        </Box>
                    </Box>
                </Box>
                <Menu>
                    <MenuButton>
                        <Avatar name={`${user?.firstName} ${user?.lastName}`} src={user?.avatar} />
                    </MenuButton>
                    <MenuList>
                        <Box p={2} display="flex" alignItems="center" justifyContent="center" flexDir="column">
                            <Avatar name={`${user?.firstName} ${user?.lastName}`} mb={2} src={user?.avatar} />
                            <Box>{`${user?.firstName} ${user?.lastName}`}</Box>
                        </Box>
                        <MenuItem as={Link} to={ROUTE_CONSTANTS.DASHBOARD.PROFILE.ABSOLUTE}>Profile</MenuItem>
                        <MenuItem onClick={onSignOutClicked}>Sign out</MenuItem>
                    </MenuList>
                </Menu>
            </Box>
        </Box>
    );
}

export default Navbar;