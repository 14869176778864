import { API_ROUTES } from "src/constants/api-routes";
import {AxiosInstance} from "../index";
import { IAttendanceLogItem, IAttendanceReportItem, IAttendanceSummationItem, ISummationReportPayload } from "../types/attendance-log";
import { IAxiosResponse, IPaginatedResult } from "../types/axios-response";
import { DEFAULT_COUNT } from "src/constants/api-default-values";

export const panelAttendanceLogService = {
    getByRiderAndDate(riderId:string,dateFrom:string,dateTo:string){
        const query = `count=${DEFAULT_COUNT}&FromDate=${encodeURIComponent(dateFrom)}&ToDate=${encodeURIComponent(dateTo)}&RiderIds=${riderId}`;
        return AxiosInstance.get<IAxiosResponse<IPaginatedResult<IAttendanceLogItem>>>(`${API_ROUTES.ATTENDANCE_LOG.TIME_LINE}?${query}`);
    },
    getAllReports(from:string,to:string,riderIds:string,zoneIds:string,groupIds:string,abortSignal:AbortSignal){
        let query = `count=${DEFAULT_COUNT}&FromDate=${encodeURIComponent(from)}&ToDate=${encodeURIComponent(to)}`;
            query += (riderIds) ? `&${riderIds}` : "";
            query += (zoneIds)  ? `&${zoneIds}`  : "";
            query += (groupIds) ? `&${groupIds}` : "";
            
        return AxiosInstance.get<IAxiosResponse<IPaginatedResult<IAttendanceReportItem>>>(`${API_ROUTES.ATTENDANCE_LOG.REPORT}?${query}`,{signal:abortSignal});
    },
    getSummationReports(offset:number,count:number,from:string,to:string,riderIds:string,zoneIds:string,groupIds:string,abortSignal:AbortSignal){
        let query = `count=${count}&offset=${offset}&FromDate=${encodeURIComponent(from)}&ToDate=${encodeURIComponent(to)}`;
            query += (riderIds) ? `&${riderIds}` : "";
            query += (zoneIds)  ? `&${zoneIds}`  : "";
            query += (groupIds) ? `&${groupIds}` : "";

        return AxiosInstance.get<IAxiosResponse<ISummationReportPayload>>(`${API_ROUTES.ATTENDANCE_LOG.SUMMATION}?${query}`,{signal:abortSignal});
    },
    getSummationAggregateReports(offset:number,count:number,from:string,to:string,riderId:string,zoneId:string,groupId:string,abortSignal:AbortSignal){
        let query = `count=${count}&offset=${offset}&FromDate=${encodeURIComponent(from)}&ToDate=${encodeURIComponent(to)}`;
            query += (riderId) ? `&RiderIds=${riderId}` : "";
            query += (zoneId)  ? `&ZoneIds=${zoneId}`   : "";
            query += (groupId) ? `&GroupIds=${groupId}` : "";

        return AxiosInstance.get<IAxiosResponse<IPaginatedResult<IAttendanceReportItem>>>(`${API_ROUTES.ATTENDANCE_LOG.AGGREGATE}?${query}`,{signal:abortSignal});
    }
};