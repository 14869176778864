import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Logo from "src/components/app/Logo";
import { useTitle } from "src/hooks/useTitle";
import { useTranslation } from "react-i18next";
const Home: React.FC = () => {
    const {t} = useTranslation();
    useTitle(t("pageTitles.main"));
    return (
        <Box minWidth="100%" p="10" minHeight="100vh" bg="gray.50">
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Box pb="5" className="logo-box">
                    <Logo />
                </Box>
                <Box pb="5">
                    <Heading textAlign="center">{t("messages.welcome")}</Heading>
                </Box>
                <Box>
                    <Link to="auth/login">{t("titles.login")}</Link>
                </Box>
            </Box>
        </Box>
    );
}

export default Home;