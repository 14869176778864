import { FormControl, Switch } from "@chakra-ui/react";
import React, { useState } from "react";
import { IUserManagementItem } from "src/api/types/user-management";
import ChangeStateConfirmation from "../ChangeStateConfirmation";

interface IUserStateSwitchProps {
    user: IUserManagementItem;
    openSwitchConfirmationModal: boolean
    setOpenSwitchConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserStateSwitch:React.FC<IUserStateSwitchProps> = ({ user, openSwitchConfirmationModal, setOpenSwitchConfirmationModal }) => {
    const [isChecked, setIsChecked] = useState<boolean>(user.isEnabled);

    const handleChangeState = (newState: boolean) => {
        console.log("====>", newState);
        setIsChecked(newState);
    }
    
    return <>
        <FormControl>
            <Switch colorScheme={isChecked ? "yellow" : "gray"} onChange={() => setOpenSwitchConfirmationModal(true)} isChecked={isChecked} />
        </FormControl>
        {
            openSwitchConfirmationModal &&
            <ChangeStateConfirmation
                user={{id: user.id, username: user.username, isActive: isChecked}}
                openSwitchConfirmationModal={openSwitchConfirmationModal}
                setOpenSwitchConfirmationModal={setOpenSwitchConfirmationModal}
                handleChangeState={handleChangeState}/>
        }
    </>
};

export default UserStateSwitch;