import { IPaginatedResult } from "./axios-response";
import { RiderType } from "./rider-type";
import { TVehicleTypes } from "./vehicle";

export enum WebSocketEvents {
  OrderStarted = "OrderStarted",
  RiderAccepted = "RiderAccepted",
  OrderStatusChanged = "OrderStatusChanged",
  IdleRider = "IdleRider",
  UnreachableRider = "UnreachableRider",
  RiderWorkingStatus = "RiderWorkingStatus",
  OrderDeliveredCount = "OrderDeliveredCount",
}

export interface IAcceptedRiders {
  id: string;
  orderId: string;
  firstName: string;
  lastName: string;
  avatar: string;
  employeeId: string;
  civilIdNo: string;
  driverLicenseNo: string;
  contactNo: string;
  riderType: RiderType;
  isRejected?: boolean;
}

export interface IEligibleRiders {
  distance: number;
  employeeId: string;
  firstName: string;
  id: string;
  lastName: string;
  contactNo: string;
  location: {
    latitude: number;
    longitude: number;
  };
  riderId: string;
  status: string;
  isRejected: boolean;
}

export enum IOrderStatusEnum {
  Pending = "Pending",
  RiderAccepted = "RiderAccepted",
  RiderArrived = "RiderArrived",
  RiderCanceled = "RiderCanceled",
  ZoneCanceled = "ZoneCanceled",
  SystemCanceled = "SystemCanceled",
  PickedUp = "PickedUp",
  Delivered = "Delivered",
  Finished = "Finished",
}

export type TOrderStatus =
  | IOrderStatusEnum.Pending
  | IOrderStatusEnum.RiderAccepted
  | IOrderStatusEnum.RiderArrived
  | IOrderStatusEnum.RiderCanceled
  | IOrderStatusEnum.ZoneCanceled
  | IOrderStatusEnum.SystemCanceled
  | IOrderStatusEnum.PickedUp
  | IOrderStatusEnum.Delivered
  | IOrderStatusEnum.Finished;

interface IOrderAddress {
  originLocation: {
    latitude: number;
    longitude: number;
  };
  destinationLocation: {
    latitude: number;
    longitude: number;
  };
  originLocationName: string;
  destinationLocationName: string;
}

export enum OrderFlagType {
  ADDED = "added",
  UPDATED = "updated",
}

export enum OrderPaymentType {
  Paid = "Paid",
  Cash = "Cash",
}

export type TOrderPaymentType = OrderPaymentType.Paid | OrderPaymentType.Cash;

export interface IOrderHistories {
  id: string;
  date: string;
  status: TOrderStatus;
  location: {
    latitude: number;
    longitude: number;
  };
  duration: number;
  comment: string;
  statusBy: string;
}

export enum IOrderType {
  None = "None",
  ByZone = "ByZone",
  ByRider = "ByRider",
  ByAdmin = "ByAdmin",
  ByThirdParty = "ByThirdParty",
}

export interface IOngoingOrders {
  acceptedRiders: IAcceptedRiders[];
  availableActions?: TOrderStatus[];
  address: IOrderAddress;
  applicant: string;
  date: string;
  description?: string;
  group: {
    id: string;
    nameEn: string;
    nameAr: string;
  };
  histories: IOrderHistories[];
  id: string;
  reference: string;
  status: TOrderStatus;
  code: string;
  cost: number;
  onTheWayDuration: number;
  vehicleType: TVehicleTypes;
  paymentType: TOrderPaymentType;
  customerPhoneNumber: string;
  customerAddress: string;
  otpCode: string;
  hasOtp: boolean;
  flag?: OrderFlagType.ADDED | OrderFlagType.UPDATED | "";
  orderType:
    | IOrderType.ByZone
    | IOrderType.ByRider
    | IOrderType.ByAdmin
    | IOrderType.None
    | IOrderType.ByThirdParty;
}

export interface IOngoingOrdersPayload
  extends IPaginatedResult<IOngoingOrders> {
  pendingCount: number;
}

export interface IEligibleRidersItems {
  items: IEligibleRiders[];
}

export interface IOrderAccepted
  extends Pick<
    IOngoingOrders,
    "id" | "acceptedRiders" | "status" | "availableActions" | "histories"
  > {}

export interface IOrderChanged
  extends Pick<
    IOngoingOrders,
    "id" | "status" | "availableActions" | "histories" | "onTheWayDuration"
  > {}

export interface IOrderHistoriesPayload
  extends IPaginatedResult<IOngoingOrders> {}

export interface IOrderFilters {
  groupIds: string[];
  riderIds: string;
  orderType: IOrderType;
  registeredDateRecently: boolean;
}

export interface IMinimalOrderInfoForAcceptedRiders
  extends Pick<
    IOngoingOrders,
    "id" | "reference" | "code" | "acceptedRiders"
  > {}
export interface IMinimalOrderInfoForEligibleRiders
  extends Pick<IOngoingOrders, "id" | "reference" | "code"> {}

export interface IPendingOrder {
  id: string;
  date: string;
  applicant: string;
  reference: string;
  code: string;
  cost: number;
  vehicleType: TVehicleTypes;
  paymentType: TOrderPaymentType;
  groupName: string;
  hasOtp: boolean;
  otpCode: string;
}

export interface IPendingOrdersPayload {
  items: IEligibleRiders[];
}

export interface IOrderInfoPayload
  extends Pick<
    IOngoingOrders,
    | "id"
    | "date"
    | "reference"
    | "applicant"
    | "code"
    | "cost"
    | "vehicleType"
    | "paymentType"
    | "orderType"
  > {
  modifiedAt: string;
  zoneId: string;
  modifiedByAdmin: string | null;
  imageUrl: string | null;
}

export interface IUpdateOrderData
  extends Pick<IOngoingOrders, "cost" | "paymentType"> {}
export interface IAdminOrderFormData
  extends Pick<IPendingOrder, "code" | "cost" | "paymentType" | "vehicleType"> {
  riderId: string;
  groupId: string;
  zoneId: string;
  registeredDate: string;
  imageUrl: string;
}

export interface IAdminOrderInfo extends IOrderInfoPayload {
  registeredDate: string;
  groupId: string;
  riderId: string;
}
