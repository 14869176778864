import React from "react";

interface ILogoProps {
  className?: string;
}

const Logo: React.FC<ILogoProps> = ({ className }) => {
  return (
    <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_164_317)">
        <path d="M83.4636 307.677H73.906L72.3969 315.996H79.9424C82.4576 315.996 85.4757 313.475 85.7273 311.963C86.2303 309.694 85.4757 307.677 83.4636 307.677ZM78.6848 325.323H70.6363L69.1273 334.398H78.9363C81.2 334.398 82.9606 332.129 83.4636 329.86C84.2182 327.34 82.4576 325.323 78.6848 325.323ZM90.2545 342.213C86.9848 344.229 83.2121 344.985 79.6909 344.985H51.7727L60.3242 296.333H89.2485C96.0394 296.585 101.321 300.619 100.818 307.677C100.315 312.215 97.5485 316.248 93.5242 319.021C98.0515 321.542 99.5606 326.583 98.5545 331.373C97.8 335.658 94.5303 339.944 90.2545 342.213Z" fill="#FAA51A" />
        <path d="M105.849 344.985L114.652 296.333H153.888L151.624 307.929H127.73L126.473 314.735H146.845L144.833 325.827H124.712L123.455 333.39H148.103L146.091 344.985H105.849Z" fill="#FAA51A" />
        <path d="M155.9 344.985L164.451 296.333H203.688L201.676 307.929H177.53L176.524 314.735H196.645L194.885 325.827H174.512L173.254 333.39H198.154L195.891 344.985H155.9Z" fill="#FAA51A" />
        <path d="M238.397 310.954C237.642 309.442 236.133 308.685 234.121 308.685H226.073L224.312 318.517H233.367C235.127 318.517 236.636 317.256 237.642 315.744C238.648 314.231 239.151 312.215 238.397 310.954ZM240.661 328.852C234.624 330.869 227.833 330.869 222.3 330.113L219.785 344.985H204.442L212.994 296.333H240.661C247.2 296.333 251.727 300.115 253.488 305.408C254.745 309.442 253.739 315.24 251.476 319.525C249.212 323.81 245.188 327.592 240.661 328.852Z" fill="#FAA51A" />
        <path d="M292.473 307.677H282.915L281.406 315.996H288.951C291.467 315.996 294.485 313.475 294.736 311.963C295.239 309.694 294.485 307.677 292.473 307.677ZM287.694 325.323H279.645L278.136 334.398H287.945C290.209 334.398 291.97 332.129 292.473 329.86C293.227 327.34 291.467 325.323 287.694 325.323ZM299.264 342.213C295.994 344.229 292.221 344.985 288.7 344.985H260.782L269.333 296.333H298.258C305.048 296.585 310.33 300.619 309.827 307.677C309.324 312.215 306.558 316.248 302.533 319.021C307.061 321.542 308.57 326.583 307.564 331.373C306.809 335.658 303.539 339.944 299.264 342.213Z" fill="#D51F27" />
        <path d="M314.857 344.985L323.66 296.333H362.897L360.633 307.929H336.739L335.482 314.735H355.854L353.842 325.827H333.721L332.463 333.39H357.112L355.1 344.985H314.857Z" fill="#D51F27" />
        <path d="M364.909 344.985L373.46 296.333H412.697L410.685 307.929H386.539L385.533 314.735H405.654L403.642 325.827H383.521L382.263 333.39H406.912L404.9 344.985H364.909Z" fill="#D51F27" />
        <path d="M447.406 310.954C446.652 309.442 445.143 308.685 443.13 308.685H435.082L433.321 318.517H442.376C444.137 318.517 445.646 317.256 446.652 315.744C447.658 314.231 448.161 312.215 447.406 310.954ZM449.67 328.852C443.633 330.869 436.843 330.869 431.309 330.113L428.794 344.985H413.452L422.003 296.333H449.67C456.209 296.333 460.737 300.115 462.497 305.408C463.755 309.442 462.749 315.24 460.485 319.525C458.221 323.81 454.197 327.592 449.67 328.852Z" fill="#D51F27" />
        <path fillRule="evenodd" clipRule="evenodd" d="M333.218 168.779C334.727 149.621 319.888 138.781 301.779 138.025H230.348H185.579C178.285 138.025 178.033 149.117 185.076 149.621C185.327 149.621 185.327 149.621 185.579 149.621H230.348C237.894 149.621 237.894 160.965 230.348 161.217H209.221C201.676 161.217 201.676 172.813 209.221 172.813H216.767H231.606H261.033H283.418C285.933 172.813 288.197 172.813 289.957 174.577C291.467 176.09 292.473 178.358 292.724 180.627C292.976 183.652 291.467 186.677 289.454 189.198C286.688 192.223 282.16 195.248 277.885 195.248H238.648C231.606 195.248 231.103 205.079 236.888 206.592H223.809C216.264 206.592 216.264 218.188 223.809 218.188H268.579C269.082 218.188 269.333 218.188 269.836 218.188H278.891C281.406 218.188 284.676 218.44 285.682 221.213C286.939 224.238 284.927 228.019 282.915 230.288C277.885 236.085 271.094 236.842 264.051 236.842H249.967H216.012H204.694H196.394C188.848 236.842 188.848 248.438 196.394 248.438H237.894C244.182 249.698 243.679 259.781 236.636 259.781H201.676H171.494C163.948 259.781 163.948 271.377 171.494 271.377H199.915H216.515H276.124C285.682 271.377 295.742 269.108 304.797 263.815C316.367 257.765 324.918 245.917 327.182 234.573C329.948 220.96 325.421 207.348 313.348 200.542C324.667 192.475 331.96 180.879 333.218 168.779Z" fill="#D51F27" />
        <path d="M398.109 355.069H409.679L409.176 358.094H401.127L400.121 364.144H407.667L407.164 367.169H399.618L397.858 377H394.336L398.109 355.069Z" fill="#333333" />
        <path d="M409.679 365.908C410.936 358.094 413.703 354.817 418.733 354.817C423.764 354.817 425.273 358.346 423.764 365.908C422.506 373.723 419.739 377 414.709 377C409.93 377 408.421 373.471 409.679 365.908ZM420.494 365.656C421.752 358.346 420.242 357.085 418.23 357.085C416.218 357.085 414.206 358.346 412.949 365.656C411.691 373.219 412.949 374.479 415.212 374.479C417.476 374.479 419.236 373.219 420.494 365.656Z" fill="#333333" />
        <path d="M426.53 365.908C427.788 358.094 430.555 354.817 435.585 354.817C440.615 354.817 442.124 358.346 440.615 365.908C439.358 373.723 436.591 377 431.561 377C426.782 377 425.273 373.471 426.53 365.908ZM437.346 365.656C438.603 358.346 437.094 357.085 435.082 357.085C433.07 357.085 431.058 358.346 429.8 365.656C428.543 373.219 429.8 374.479 432.064 374.479C434.327 374.479 436.088 373.219 437.346 365.656Z" fill="#333333" />
        <path d="M445.646 355.069H451.682C458.473 355.069 458.473 359.606 457.467 365.908C456.461 372.21 454.7 376.748 447.909 376.748H441.873L445.646 355.069ZM445.897 373.975H448.664C451.43 373.975 452.939 372.21 454.197 365.656C455.203 359.606 454.7 357.337 451.682 357.337H448.915L445.897 373.975Z" fill="#333333" />
      </g>
      <defs>
        <clipPath id="clip0_164_317">
          <rect width="415" height="242" fill="white" transform="translate(48 135)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
