import { Modal, Text, ModalContent, ModalOverlay, Box, Center } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ordersService } from "src/api/services/orders";
import { IEligibleRiders, IMinimalOrderInfoForEligibleRiders } from "src/api/types/orders";
import { NotFoundResult } from "src/components/app/NotFoundResult";
import SPSpinner from "src/components/app/SPSpinner";
import { DEFAULT_COUNT, DEFAULT_OFFSET } from "src/constants/api-default-values";
import RiderList from "../RiderList";

interface IEligibleRidersModalProps {
    isOpen: boolean;
    handleClose: () => void;
    orderItem: IMinimalOrderInfoForEligibleRiders;
}

const EligibleRiders: React.FC<IEligibleRidersModalProps> = ({ isOpen, handleClose, orderItem }) => {
    const [eligibleRiders, setEligibleRiders] = useState<IEligibleRiders[]>([]);
    const [getEligibleRidersAbortController] = useState<AbortController>(new AbortController());
    const [showNoResultFound, setShowNoResultFound] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const getEligibleRiders = async () => {
        setLoading(true);
        try {
            const result = await ordersService.getEligibleRiders(orderItem.id, DEFAULT_OFFSET, DEFAULT_COUNT, getEligibleRidersAbortController.signal);
            setEligibleRiders(result.data.data.items);
            result.data.data.items.length > 0 ? setShowNoResultFound(false) : setShowNoResultFound(true);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        isOpen && getEligibleRiders();
    }, [isOpen]);

    useEffect(() => {
        return () => {
            getEligibleRidersAbortController.abort();
        }
    }, []);

    const renderEligibleRidersTable = () => {
        if (loading) {
            return <Center mt={2} mb={2} minHeight={200}>
                <SPSpinner size="lg"/>
            </Center>;
        } else {
            if (!showNoResultFound) {
                return <RiderList riders={eligibleRiders} hasRejectedColumn={true} />;
            }

            return <NotFoundResult iconMaxWidth={140} />;
        }
    }

    return <>
        <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
            <ModalOverlay />
            <ModalContent>
                <Box mb={1} display="flex" justifyContent="space-between" alignItems="center" bgColor="rgba(231, 231, 231, 0.16)" p={3} borderBottom="1px solid #E7E7E7" borderRadius="8px 8px 0 0">
                    <Text as="p" fontWeight="500" fontSize="1.3rem">Available Riders</Text>
                    <Box display="flex" alignItems="center">
                        <Text as="span" color="#666666" fontSize="1.1rem">{orderItem.reference}</Text>
                        &nbsp;&nbsp;<Text as="span" color="#ccc" fontWeight="bold">|</Text>&nbsp;&nbsp;
                        <Text as="span" color="#666" fontSize="1.1rem">{orderItem.code}</Text>
                    </Box>
                </Box>
                {renderEligibleRidersTable()}
            </ModalContent>
        </Modal>
    </>
}

export default EligibleRiders;