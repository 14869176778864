import { useEffect, useState } from "react";
import { panelUserManagementServices } from "src/api/services/user-management";
import { IUserManagementItem } from "src/api/types/user-management";
import { useUserManagementData } from "src/pages/dashboard/UserManagement/UserManagementProvider";
import { TCountBadge } from "src/types/user-management";
import { useSearch } from "./useSearch";

export interface IuserListDataProps {
    tabIsActive: boolean;
}

const useUserListData = (props: IuserListDataProps) => {
    const {tabIsActive} = props;
    const { selectedRoleTab, searchKeyword, triggerRefreshUserList, setTriggerRefreshUserList, setCounts } = useUserManagementData();
    const [userlist, setUserList] = useState<IUserManagementItem[]>([]);
    const [getAllAbortController] = useState<AbortController>(new AbortController());
    const [loading, setLoading] = useState<boolean>(false);
    const [pageTotalCount, setPageTotalCount] = useState<number>(0);
    const { searchQuery, updateSearchParameters } = useSearch({
        isStoreOnLocalStorage: true,
        entity: `User`, fields: { size: 10, offset: 0 }
    });

    const getUserList = async(offset:number, count: number) => {
        setLoading(true)
        console.log("Date of Tab:", selectedRoleTab);
        try {
            const result = await panelUserManagementServices.getAll({
                count: count,
                offset: offset,
                searchRole: selectedRoleTab,
                searchKeyword: searchKeyword
            }, getAllAbortController.signal);
            
            const resultData = result.data.data;
            setUserList(resultData.items);
            setCounts({
                Admin: resultData.adminCount,
                GroupAgent: resultData.groupAgentCount,
                ZoneAgent: resultData.zoneAgentCount
            });
            setPageTotalCount(result.data.data.totalCount);
            setTriggerRefreshUserList(false);
        } catch(error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handlePassPaginateValues = (offset: number, size: number) => {
        updateSearchParameters({ offset, size });
        getUserList(offset, size);
    }

    useEffect(() => {
        if(tabIsActive) {
            updateSearchParameters({ offset: 0 });
            getUserList(0, searchQuery.size);
        } else {
            return () => {
                localStorage.removeItem(`User`);
            }
        }
    }, [tabIsActive, searchKeyword]);

    useEffect(() => {
        if(triggerRefreshUserList && tabIsActive) {
            updateSearchParameters({ offset: 0 });
            getUserList(0, searchQuery.size);
        }
    }, [triggerRefreshUserList, tabIsActive]);

    return {
        loading,
        userlist,
        pageTotalCount,
        handlePassPaginateValues,
        searchQuery
    };
}

export default useUserListData;