import React from "react";
import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
} from "@chakra-ui/react";
import { IColumns } from "../../../types/table";
import HorizontalLoading from "../HorizontalLoading";
import { NotFoundResult } from "../NotFoundResult";

interface TableProps<T> {
  dataSource: T[] | undefined;
  columns: IColumns<T>[];
  loading: boolean;
}

const Table = <T extends Record<string, any>>({
  dataSource,
  loading,
  columns,
}: TableProps<T>) => {
  return (
    <Box className="table_container">
      {loading && (
        <Box height="5px" mb="1">
          <HorizontalLoading />
        </Box>
      )}
      {!loading && dataSource && dataSource?.length === 0 ? (
        <NotFoundResult />
      ) : (
        <ChakraTable
          variant="simple"
          colorScheme="blackAlpha"
          size="md"
          boxShadow="md"
          backgroundColor="white"
          borderRadius="md"
          border="1px solid #EEEEEE"
        >
          <Thead pt={2} pb={2} bg="#E6E6E633">
            <Tr>
              {columns.map((col) => (
                <Th key={col.key}>{col.title}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {dataSource?.map((item, rowIndex) => (
              <Tr key={rowIndex}>
                {columns.map((col) => (
                  <Td key={col.key}>
                    {col.render
                      ? col.render(item[col.dataIndex], item)
                      : item[col.dataIndex]}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </ChakraTable>
      )}
    </Box>
  );
};

export default Table;
