import { useCallback, useEffect, useState } from "react";
import { panelDashboardService } from "src/api/services/dashboard";
import { ITopZonesKeyValues } from "src/api/types/dashboard";
import CircleSkeleton from "src/components/app/CircleSkeleton";
import { CHART_DEFAULT_COUNT } from "src/constants/chart";
import DashboardChart from "src/pages/dashboard/Home/components/DashboardChart";
import "./styles.css";

const TopZonesPerRiders: React.FC = () => {
  const [topZones, setTopZones] = useState<ITopZonesKeyValues[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [topZonesPerRidersAbortController] = useState<AbortController>(
    new AbortController()
  );

  const fetchTopZonesPerRiders = useCallback(async () => {
    try {
      const result = await panelDashboardService.getTopZonePerRiders(
        CHART_DEFAULT_COUNT,
        topZonesPerRidersAbortController.signal
      );
      setTopZones(result.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTopZonesPerRiders();
  }, [fetchTopZonesPerRiders]);

  useEffect(() => {
    return () => {
      topZonesPerRidersAbortController.abort();
    };
  }, []);

  return (
    <CircleSkeleton
      loadingSize="l-md"
      isLoaded={loading}
      extraClass={"chart_section_1 box_area"}
    >
      <DashboardChart
        chartType="TopZonesPerRiders"
        items={topZones}
        title={{
          main: "Zones by Assigned RidersSelect (Top 10)",
          sub: "Number of Assigned RidersSelect",
        }}
      />
    </CircleSkeleton>
  );
};

export default TopZonesPerRiders;
