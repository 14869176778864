export const CHART_BG_COLORS = [
    '#ffbe0b',
    '#495057',
    '#ef233c',
    '#80ed99',
    '#e76f51',
    '#64dfdf',
    '#ff7b00',
    '#a663cc',
    '#f72585',
    '#023e8a',
    '#ffd6ff',
    '#2d6a4f',
  ];

  export const CHART_DEFAULT_COUNT = 10;