import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAxiosResponse } from "../types/axios-response";
import { IBaseVehicleItem , IVehicleAssignToRider, IVehicleListItem , IVehicleListPayload } from "../types/vehicle";

export const panelVehiclesServices = {
    getAll( offset:number,count:number,abortSignal:AbortSignal,searchQuery:string = "") {
        let keyword = (searchQuery) ? `&keyword=${searchQuery}` : "";
        return AxiosInstance.get<IAxiosResponse<IVehicleListPayload>>(`${API_ROUTES.VEHICLES.ROOT}?count=${count}&offset=${offset}${keyword}`,{signal:abortSignal});
    },
    get(id:string){
        return AxiosInstance.get<IAxiosResponse<IVehicleListItem>>(API_ROUTES.VEHICLES.GET_DETAILS(id));
    },
    create(data:IBaseVehicleItem) {
        return AxiosInstance.post<IAxiosResponse<{}>>(API_ROUTES.VEHICLES.ROOT , data);
    },
    update(id:string , data:IBaseVehicleItem) {
        return AxiosInstance.put<IAxiosResponse<{}>>(API_ROUTES.VEHICLES.GET_DETAILS(id) , data);
    },
    assignVehicle(id:string,data:IVehicleAssignToRider) {
        return AxiosInstance.post<IAxiosResponse<{}>>(API_ROUTES.VEHICLES.ASSIGN_VEHICLE(id) , data);
    },
    unassignVehicle(riderId:string) {
        return AxiosInstance.delete<IAxiosResponse<{}>>(API_ROUTES.VEHICLES.UNASSIGN_VEHICLE(riderId));
    }
};