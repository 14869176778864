import React from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

interface IConfirmModalProps {
  item: {
    id: string;
    name: string;
    isActive: boolean;
    isOTP?: boolean;
  };
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel: () => void;
  handleStatusChange: () => void;
}

const ConfirmModal: React.FC<IConfirmModalProps> = (props) => {
  const { item, isOpen, setIsOpen, onCancel, handleStatusChange } = props;
  const { name, isActive } = item;

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" mb="-3" mt="3" fontSize="1em">
          <Text as="h3" fontSize="1.1rem" fontWeight="500">
            {`Are you sure that you want to ${isActive ? "disable" : "enable"}
            ${item?.isOTP ? "OTP" : "third party"}?`}
          </Text>
          <Box
            fontWeight="medium"
            fontSize="1rem"
            display="flex"
            justifyContent="center"
            gap={2}
            mt={1}
          >
            <Text>Company Name:</Text>
            <Text color="#aaa">{name}</Text>
          </Box>
        </ModalHeader>
        <ModalBody>
          <Box display="flex" mt="4" mb="4" gap="4">
            <Button
              flex="1"
              variant="outline"
              ml="2"
              onClick={() => onCancel()}
            >
              No
            </Button>
            <Button
              flex="1"
              variant="yellow_100"
              onClick={() => handleStatusChange()}
            >
              Yes
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
