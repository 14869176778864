import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import FormBox from "../components/FormBox";
import {
    Box, Button, FormControl, FormLabel, Input, FormErrorMessage, useToast, Alert, AlertIcon, AlertDescription,
    InputGroup, InputRightElement
} from "@chakra-ui/react";
import { IResetPasswordForm } from "./types";
import { resetPasswordValidationSchema } from "src/validation-schemas/reset-password";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { mdiArrowLeft, mdiEye, mdiEyeOff } from '@mdi/js';
import Icon from "@mdi/react";


const ResetPassword: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.resetPassword"));
    const [loading] = useState<boolean>(false);
    const [passwordChanged] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const [showPasswords,setShowPasswords] = useState<Record<"password" | "confirm",boolean>>({password:false,confirm:false})
    const toast = useToast();
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        formState: { errors }
    } = useForm<IResetPasswordForm>({
        resolver: yupResolver(resetPasswordValidationSchema(t)),
        mode: "all",
        defaultValues: {
            newPassword: '',
            confirmPassword: ''
        }
    });

    useEffect(() => {
        const qToken = searchParams.get("token");
        if (!qToken) {
            toast({
                status: "error",
                description: t("messages.resetPasswordTokenNotProvided")
            });
            navigate(ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE);
        } else {
            // setToken(qToken);
        }
    }, [searchParams, navigate, toast,t]);

    const onFormSubmit = (data: IResetPasswordForm) => {
        
    }

    const togglePassword = (sender:"password" | "confirm")=>{
        setShowPasswords({
            ...showPasswords,
            [sender]:!showPasswords[sender]
        })
    }


    return (
        <FormBox title={t("titles.resetPassword")}>
            {
                !passwordChanged && (
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                        <FormControl mb="6" isInvalid={!!errors.newPassword}>
                            <FormLabel htmlFor="newPassword">{t("fields.newPassword")}</FormLabel>
                            <InputGroup>
                                <Input id="newPassword" type={showPasswords.password?"text":"password"} size="lg" {...register("newPassword")} />
                                <InputRightElement mt="1" cursor="pointer" onClick={()=>{togglePassword("password")}}>
                                    <Icon path={showPasswords.password ? mdiEyeOff : mdiEye} size="28px" />
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>
                                {!!errors.newPassword && errors.newPassword.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl mb="6" isInvalid={!!errors.confirmPassword}>
                            <FormLabel htmlFor="confirmPassword">{t("fields.confirmPassword")}</FormLabel>
                            <InputGroup>
                                <Input id="confirmPassword" type={showPasswords.confirm ? "text":"password"} size="lg" {...register("confirmPassword")} />
                                <InputRightElement mt="1" cursor="pointer" onClick={()=>{togglePassword("confirm")}}>
                                    <Icon path={showPasswords.confirm ? mdiEyeOff : mdiEye} size="28px" />
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>
                                {!!errors.confirmPassword && errors.confirmPassword.message}
                            </FormErrorMessage>
                        </FormControl>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb="6">
                            <Button colorScheme="brand" isFullWidth size="lg" type="submit"
                                isLoading={loading}>{t("actions.resetPassword")}</Button>
                        </Box>
                    </form>
                )
            }
            {
                passwordChanged && (
                    <Box>
                        <Alert status="success">
                            <AlertIcon />
                            <AlertDescription>{t("content.yourPasswordChangedSuccessfuly")}</AlertDescription>
                        </Alert>
                        <Box display="flex" justifyContent="center" alignItems="center" as={Link}
                            mt="6"
                            to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE} color="gray.600" mb="4">
                            <Icon path={mdiArrowLeft} size="24px" />
                            {t("actions.backToLogin")}
                        </Box>
                    </Box>
                )
            }
        </FormBox>
    );
}

export default ResetPassword;