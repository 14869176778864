import * as yup from "yup";

export const vehicleSchema = (t:any) => {
  const validation = {
    plateNumber_left: yup.string()
                      .required("Plate Number is required")
                      .max(2 , t("validationMessages.maxLengthValidation", { field: "left part",validLength: 2}))
                      .min(1 , t("validationMessages.minLengthValidation", { field: "left part",validLength: 1})),

    plateNumber_right: yup.string()
                      .required("Plate Number is required")
                      .max(5 , t("validationMessages.maxLengthValidation", { field: "right part",validLength: 5}))
                      .min(4 , t("validationMessages.minLengthValidation", { field: "right part",validLength: 4})),

    chassis: yup.string()
                      .required("Chassis Number is required")
                      .max(17 , t("validationMessages.maxLengthValidation", { field: "Chassis Number",validLength: 17}))
                      .min(17 , t("validationMessages.minLengthValidation", { field: "Chassis Number",validLength: 17}))
  };

  return yup.object().shape(validation);
}