import { Box, Text } from "@chakra-ui/react";
import { mdiCalendarMonthOutline, mdiTimerOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { IAttendanceLogItem } from "src/api/types/attendance-log";
import BulletType, { classColors } from "src/components/app/BulletType";
import dateHelper from "src/utils/date-helper";

interface ITimeLineItemProps {
    data: IAttendanceLogItem;
}

export const TimeLineItem: React.FC<ITimeLineItemProps> = ({ data }) => {

    const showType = () => {
        let itemType;
        switch (data.type) {
            case "ManualCheckout":
            case "SystematicTooLateCheckIn":
            case "SystematicTooLateExit":
                itemType = "Checkout";
                break;
            default:
                itemType = data.type;
                break;
        }

        return itemType;
    }

    const isInside = () => data.insideDuration > 0 ? true : false;

    const setTypeClassName = (): classColors => {
        let t_l_type_class = "";

        switch (data.type) {
            case "Exit":
                t_l_type_class = "yellow";
                break;
            case "CheckIn":
                t_l_type_class = "green";
                break;
            case "Entrance":
                t_l_type_class = "blue";
                break;
            case "ManualCheckout":
            case "SystematicTooLateCheckIn":
            case "SystematicTooLateExit":
                t_l_type_class = "red";
                break;
            case "Available":
                t_l_type_class = "lightgreen";
                break;
            case "Unavailable":
                t_l_type_class = "gray";
                break;
            case "Busy":
                t_l_type_class = "crimson";
                break;
            default:
                t_l_type_class = "green";
                break;
        }

        return t_l_type_class as classColors;
    }

    const showCheckoutType = () => (data.type === "SystematicTooLateCheckIn" || data.type === "SystematicTooLateExit") ? "Automatic" : "";
    const returnRegisteredDateTime = () => {
        const dateTime = dateHelper.formatDate(data.date).split(" ");
        return [`${dateTime[0]} ${dateTime[1]}`, dateTime[2]];
    }

    const calcDuration = () => {
        const attendanceItemType = data.type;
        if (attendanceItemType === "Available" || attendanceItemType === "Unavailable" || attendanceItemType === "Busy") {
            return <Box style={{ height: "22px" }}></Box>;
        }

        return <Box display="flex" alignItems="center" fontSize="0.85rem">
            <Icon size="22px" path={mdiTimerOutline} color="#a2a2a2" />
            <Text ml={1} color="#333">{isInside() ? dateHelper.getDuration(data.insideDuration, false) : dateHelper.getDuration(data.outsideDuration, false)}</Text>
        </Box>
    }

    return <Box className={`timeline_item_container ${isInside() ? "inside" : "outside"}`}>
        <Box className={`timeline_item ${setTypeClassName()}`}>
            {isInside() ? <>
                <Box className="details">
                    <Box className="time_inmobile">{returnRegisteredDateTime()[1]}</Box>
                    <Box className="inner_details">
                        <Box className="status">
                            <Box className="log_type">
                                <BulletType classColor={setTypeClassName()} style={{ marginRight: "5px" }} />
                                {showType()}
                            </Box>
                            <Box color="#B3B3B3" fontSize="12px">{showCheckoutType()}</Box>
                        </Box>
                        <Box display="flex" alignItems="center" fontSize="0.85rem">
                            <Icon size="22px" path={mdiCalendarMonthOutline} color="#a2a2a2" />
                            <Text ml={1} color="#333">{returnRegisteredDateTime()[0]}</Text>
                        </Box>
                        {calcDuration()}
                    </Box>
                </Box>
                <Box className="time">{returnRegisteredDateTime()[1]}</Box>
            </> : <>
                <Box className="time">{returnRegisteredDateTime()[1]}</Box>
                <Box className="details">
                    <Box className="time_inmobile">{returnRegisteredDateTime()[1]}</Box>
                    <Box className="inner_details">
                        <Box className="status">
                            <Box className="log_type">
                                <BulletType classColor={setTypeClassName()} style={{ marginRight: "5px" }} />
                                {showType()}
                            </Box>
                            <Box color="#B3B3B3" fontSize="12px">{showCheckoutType()}</Box>
                        </Box>
                        <Box display="flex" alignItems="center" fontSize="0.85rem">
                            <Icon size="22px" path={mdiCalendarMonthOutline} color="#a2a2a2" />
                            <Text ml={1}>{returnRegisteredDateTime()[0]}</Text>
                        </Box>
                        {calcDuration()}
                    </Box>
                </Box>
            </>}
        </Box>
    </Box>;
}

