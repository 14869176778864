import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Box, Button, Input, Text } from "@chakra-ui/react";
import { IRiderListItem } from "src/api/types/riders";
import { IZoneListItem } from "src/api/types/zones";
import { useOutsideAlerter } from "src/hooks/useOutsideAlerter";
import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import "./styles.css";

type entityTypes = "rider" | "zone" | "group";
interface ISearchableListDropDownProps {
  entity: entityTypes;
  items: {
    riders?: IRiderListItem[];
    zones?: IZoneListItem[];
  };
  pickedItem: {
    id: string;
    name: string;
  };
  onSelectItem: (item: string) => void;
  disabled: boolean;
  minWidth?: string;
}

const SearchableListDropDown: React.FC<ISearchableListDropDownProps> = ({ entity, items, onSelectItem, pickedItem, disabled, minWidth }) => {
  const [riders, setRiders] = useState<IRiderListItem[]>(items.riders ? items.riders : []);
  const [filteredRiders, setFilteredRiders] = useState<IRiderListItem[]>(items.riders ? items.riders : []);

  const [zones, setZones] = useState<IZoneListItem[]>(items.zones ? items.zones : []);
  const [filteredZones, setFilteredZones] = useState<IZoneListItem[]>(items.zones ? items.zones : []);

  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false);
  const [displayNameItem, setDisplayNameItem] = useState<string>(`select a ${entity}`);
  const searchInput = useRef<any>(null);

  const boxContainerRef = useRef<any>();
  const listRef = useRef<any>();
  const outsideClicked = useOutsideAlerter(boxContainerRef);

  useEffect(() => {
    outsideClicked && isOpenDropDown ? setIsOpenDropDown(true) : setIsOpenDropDown(false);
  }, [outsideClicked, isOpenDropDown]);

  useEffect(() => {
    setRiders(items.riders!);
    setFilteredRiders(items.riders!);
    if (pickedItem.id) {
      setDisplayNameItem(pickedItem.name);
    }

  }, [items.riders]);

  useEffect(() => {
    setZones(items.zones!);
    setFilteredZones(items.zones!);
    if (pickedItem.id) {
      setDisplayNameItem(pickedItem.name);
    }

  }, [items.zones]);

  useEffect(() => {
    if (isOpenDropDown) {
      setRiders(items.riders!);
      setFilteredRiders(items.riders!);

      setZones(items.zones!);
      setFilteredZones(items.zones!);
    }
  }, [isOpenDropDown]);

  const handleSelectAnItem = (id: string, displayName: string) => {
    listRef.current.scrollTop = 0;
    setIsOpenDropDown(false);
    setDisplayNameItem(displayName);
    searchInput.current.value = "";
    onSelectItem(id);
  }

  const filterItems = (e: ChangeEvent<HTMLInputElement>) => {
    let searchedVal = e.target.value.toLowerCase();
    if (entity === "rider") {
      let filteredList = riders.filter(rider => {
        return `${rider.firstName.trim().toLowerCase()} ${rider.lastName.trim().toLowerCase()}`.includes(searchedVal) || rider.employeeId.includes(searchedVal);
      });

      setFilteredRiders(filteredList);
    }

    if (entity === "zone") {
      let filteredList = zones.filter(zone => {
        return `${zone.nameEn.trim().toLowerCase()}`.includes(searchedVal);
      });

      setFilteredZones(filteredList);
    }
  }

  const renderList = (entityName: entityTypes) => {
    if (entityName === "rider") {
      return filteredRiders.map((rider, ind) => {
        return <Text
          key={ind}
          sx={!rider.isActive ? { color: "#a30000" } : {}}
          className="searchable_list_option"
          onClick={() => handleSelectAnItem(rider.id, `${rider.firstName} ${rider.lastName} (${rider.employeeId})`)}>
          {`${rider.firstName} ${rider.lastName} (${rider.employeeId})`}
        </Text>;
      });
    }

    if (entityName === "zone") {
      return filteredZones.map((zone, ind) => {
        return <Text
          key={ind}
          className="searchable_list_option"
          onClick={() => handleSelectAnItem(zone.id, `${zone.nameEn}`)}>
          {`${zone.nameEn}`}
        </Text>;
      });
    }
  }

  return <Box className="searchable_list" ref={boxContainerRef} minWidth={minWidth} >
    <Button onClick={() => setIsOpenDropDown(!isOpenDropDown)} disabled={disabled} paddingRight="40px">
      <Text as="span" pos="absolute" top="8px" right="10px"><Icon path={mdiChevronDown} size="25px" /></Text>
      <Text as="span" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{displayNameItem}</Text>
    </Button>
    <Box className={isOpenDropDown ? "searchable_list_dropdown open" : "searchable_list_dropdown"}>
      <Input type="text" placeholder={`search a ${entity}`} onChange={filterItems} ref={searchInput} />
      <Box ref={listRef} mt="1" className="options_container">
        {renderList(entity)}
      </Box>
    </Box>
  </Box>
}

export default SearchableListDropDown;