import { Box, Button, IconButton, Td, Tooltip, Tr } from "@chakra-ui/react";
import { mdiAccountCheckOutline, mdiAccountMultipleOutline, mdiBicycle, mdiCar, mdiCurrencyUsd, mdiPencil, mdiWalletOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState, memo } from "react";
import { IOngoingOrders, IOrderStatusEnum, IOrderType, OrderPaymentType, TOrderStatus } from "src/api/types/orders";
import dateHelper from "src/utils/date-helper";
import { VehicleType } from "src/api/types/vehicle";
import OnTheWayTimer from "../OnTheWayTimer";
import { useNavigate } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import "./styles.css";

interface IOngoingOrderItemProps {
    setIsOpenAcceptedRiderModal: (isOpen: boolean) => void;
    setIsOpenEligibleRiderModal: (isOpen: boolean) => void;
    orderItem: IOngoingOrders;
    setSelectedOrder: (orderItem: IOngoingOrders) => void;
}

const OngoingOrderItem: React.FC<IOngoingOrderItemProps> = ({ setIsOpenAcceptedRiderModal, setIsOpenEligibleRiderModal, setSelectedOrder, orderItem }) => {
    const [flag, setFlag] = useState<string>("");
    const navigate = useNavigate();
    let timeoutId: any;

    const handleFlagColor = () => {
        setFlag(orderItem?.flag || "");
        timeoutId = setTimeout(() => {
            delete orderItem.flag;
            setFlag("");
        }, 500);
    }

    useEffect(() => {
        handleFlagColor();
        return () => {
            clearTimeout(timeoutId);
        }
    }, []);

    const getStateFromOrderHistory = (colStatusType: TOrderStatus, completeFormat: boolean = false): {
        toastForm: string;
        valueForm: string
    } => {
        let item = orderItem.histories.filter(orderHistory => orderHistory.status === colStatusType);

        if (item.length > 0) {
            return completeFormat ? {
                toastForm: dateHelper.formatDate(item[0].date, "DD/MM/YYYY HH:mm"),
                valueForm: dateHelper.formatDate(item[0].date, "DD/MM/YYYY HH:mm")
            } : {
                toastForm: dateHelper.formatDate(item[0].date, "DD/MM/YYYY HH:mm"),
                valueForm: dateHelper.formatDate(item[0].date, "HH:mm")
            }
        } else {
            return {
                toastForm: "",
                valueForm: "-"
            }
        }
    }

    const showOnTheWayBriefly = (duration: string) => {
        return duration.replace(/hours|hour/, "h").replace(/minutes|minute/, "m").replace(/seconds|second/, "s");
    }

    const renderStatusCells = (colStatusType: TOrderStatus) => {
        let cell: JSX.Element = <Td> - </Td>;
        let cellClass = "";

        if (colStatusType === IOrderStatusEnum.Pending) {
            if (IOrderStatusEnum.Pending === orderItem.status) {
                cellClass = "bold_state";
                cell = <>
                    <Tooltip label={getStateFromOrderHistory(colStatusType, true).toastForm}>
                        <Td className={cellClass}>{getStateFromOrderHistory(colStatusType, true).valueForm}</Td>
                    </Tooltip>
                    <Td> - </Td>
                </>;
            } else {
                cellClass = "";
                cell = <Tooltip label={getStateFromOrderHistory(colStatusType, true).toastForm}>
                    <Td className={cellClass}>{getStateFromOrderHistory(colStatusType, true).valueForm}</Td>
                </Tooltip>;
            }
        } else if (colStatusType === IOrderStatusEnum.RiderAccepted) {
            if (IOrderStatusEnum.RiderAccepted === orderItem.status) {
                cellClass = "bold_state";
                cell = <>
                    <Tooltip label={getStateFromOrderHistory(colStatusType).toastForm}>
                        <Td className={cellClass}>{getStateFromOrderHistory(colStatusType).valueForm}</Td>
                    </Tooltip>
                    <Td> - </Td>
                </>;
            } else {
                cellClass = "";
                cell = <Tooltip label={getStateFromOrderHistory(colStatusType).toastForm}>
                    <Td className={cellClass}>{getStateFromOrderHistory(colStatusType).valueForm}</Td>
                </Tooltip>;
            }
        } else if (colStatusType === IOrderStatusEnum.PickedUp) {
            if (IOrderStatusEnum.PickedUp === orderItem.status) {
                cellClass = "bold_state";
                cell = <>
                    <Tooltip label={getStateFromOrderHistory(colStatusType).toastForm}>
                        <Td>{getStateFromOrderHistory(colStatusType).valueForm}</Td>
                    </Tooltip>
                    <Td className={cellClass}>
                        <OnTheWayTimer
                            orderStatus={orderItem.status}
                            orderOnTheWayDuration={orderItem.onTheWayDuration} />
                    </Td>
                </>;
            } else {
                cellClass = "";
                cell = <>
                    <Tooltip label={getStateFromOrderHistory(colStatusType).toastForm}>
                        <Td className={cellClass}>{getStateFromOrderHistory(colStatusType).valueForm}</Td>
                    </Tooltip>
                </>;
            }
        } else if (colStatusType === IOrderStatusEnum.Delivered) {
            if (IOrderStatusEnum.Delivered === orderItem.status) {
                cellClass = "";
                cell = <>
                    <Tooltip label={dateHelper.getDuration(orderItem.onTheWayDuration)}>
                        <Td>{showOnTheWayBriefly(dateHelper.getDuration(orderItem.onTheWayDuration))}</Td>
                    </Tooltip>
                    <Tooltip label={getStateFromOrderHistory(colStatusType).toastForm}>
                        <Td className={cellClass}>{getStateFromOrderHistory(colStatusType).valueForm}</Td>
                    </Tooltip>
                </>;
            } else {
                cellClass = "";
                cell = <Tooltip label={getStateFromOrderHistory(colStatusType).toastForm}>
                    <Td className={cellClass}>{getStateFromOrderHistory(colStatusType).valueForm}</Td>
                </Tooltip>;
            }
        } else if (colStatusType === IOrderStatusEnum.ZoneCanceled) {
            if (IOrderStatusEnum.ZoneCanceled === orderItem.status) {
                cell = <>
                    <Td> - </Td>
                    <Td> - </Td>
                </>;
            } else {
                cell = <Td> - </Td>;
            }
        }

        return cell;
    }

    const renderActionButttons = () => {
        let disableAcceptedRiderBtn: boolean = false;

        if ([IOrderStatusEnum.Pending, IOrderStatusEnum.ZoneCanceled].includes(orderItem.status)) {
            disableAcceptedRiderBtn = true;
        }

        return <Box display="flex" alignItems="center">
            {/* Eligible Rider Button */}
            <Tooltip label="Available Riders" sx={{ borderRadius: "8px" }}>
                <Button variant="link" onClick={() => {
                    setIsOpenEligibleRiderModal(true);
                    setSelectedOrder(orderItem);
                }}>
                    <Icon path={mdiAccountMultipleOutline} size="25px" />
                </Button>
            </Tooltip>
            {/* Accepted Rider Button*/}
            <Tooltip label="Accepted Rider" sx={{ borderRadius: "8px" }}>
                <Button variant="link" onClick={() => {
                    setIsOpenAcceptedRiderModal(true);
                    setSelectedOrder(orderItem);
                }} disabled={disableAcceptedRiderBtn}>
                    <Icon path={mdiAccountCheckOutline} size="25px" />
                </Button>
            </Tooltip>
            <Tooltip label="Edit Order" sx={{ borderRadius: "8px" }}>
                <Button variant="link" disabled={orderItem.orderType !== IOrderType.ByRider}
                    onClick={() => navigate(ROUTE_CONSTANTS.DASHBOARD.PAGING_ORDERS.UPDATE.BY_DATA(orderItem.id).ABSOLUTE)}>
                    <Icon path={mdiPencil} size="25px" />
                </Button>
            </Tooltip>
        </Box>;
    }

    const handleOrderItemClassName = () => {
        return orderItem.status === IOrderStatusEnum.ZoneCanceled ? "order_item_row canceled" : `${flag} order_item_row`;
    }

    const renderOrderVehicleType = () => {
        return orderItem.vehicleType === VehicleType.Bike
            ? <Icon path={mdiBicycle} size="20px" style={{ minWidth: "20px", marginRight: "4px" }} />
            : <Icon path={mdiCar} size="20px" style={{ minWidth: "20px", marginRight: "4px" }} />;
    }

    const handleOrderValue = () => {
        const icon = orderItem.paymentType === OrderPaymentType.Paid
            ? <Icon path={mdiWalletOutline} size="20px" style={{ marginRight: "4px" }} />
            : <Icon path={mdiCurrencyUsd} size="20px" style={{ marginRight: "4px" }} />;

        return <Tooltip label={orderItem.paymentType}>
            <Box display="flex" alignItems="center">{icon}{orderItem.cost}</Box>
        </Tooltip>
    }

    const renderRowLeftBorderClass = () => {
        switch(orderItem.orderType) {
            case IOrderType.ByRider:
                return "orderId_cell by_rider" ;
            case IOrderType.ByThirdParty:
                return "orderId_cell by_third_party";
            default:
                return "";
        }
    }

    return <Tr key={orderItem.id} className={handleOrderItemClassName()}>
        <Td sx={{ maxWidth: "130px", wordWrap: "break-word" }} 
            className={renderRowLeftBorderClass()}>
            <Box display="flex" alignItems="center">{renderOrderVehicleType()}{orderItem.code}</Box>
        </Td>
        <Td>{orderItem.hasOtp ? orderItem.otpCode : "-"}</Td>
        <Td>{handleOrderValue()}</Td>
        <Td>{orderItem?.group?.nameEn}</Td>
        <Td>{orderItem.applicant}</Td>
        {renderStatusCells(IOrderStatusEnum.Pending)}
        {renderStatusCells(IOrderStatusEnum.RiderAccepted)}
        {renderStatusCells(IOrderStatusEnum.PickedUp)}
        {orderItem.status === IOrderStatusEnum.Delivered ? renderStatusCells(IOrderStatusEnum.Delivered) : renderStatusCells(IOrderStatusEnum.ZoneCanceled)}
        <Td>{renderActionButttons()}</Td>
    </Tr>;
}

export default memo(OngoingOrderItem);