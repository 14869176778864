import React from "react";

interface ILogoMinimalProps{
    width?:number;
    height?:number;
}


const LogoMinimal: React.FC<ILogoMinimalProps> = (props) => {
    const {width=56,height=56} = props;
    return (<svg width={width} height={height} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M406.598 191.127C409.304 156.813 382.698 137.399 350.231 136.045H222.163H141.896C128.818 136.045 128.367 155.91 140.994 156.813C141.445 156.813 141.445 156.813 141.896 156.813H222.163C235.692 156.813 235.692 177.131 222.163 177.582H184.284C170.756 177.582 170.756 198.351 184.284 198.351H197.812H224.418H277.178H317.312C321.821 198.351 325.88 198.351 329.036 201.511C331.742 204.22 333.546 208.284 333.997 212.347C334.448 217.765 331.742 223.183 328.135 227.698C323.174 233.116 315.057 238.534 307.391 238.534H237.044C224.418 238.534 223.516 256.142 233.888 258.851H210.439C196.911 258.851 196.911 279.619 210.439 279.619H290.706C291.608 279.619 292.059 279.619 292.961 279.619H309.195C313.704 279.619 319.567 280.071 321.37 285.037C323.625 290.455 320.018 297.228 316.41 301.291C307.391 311.675 295.216 313.03 282.589 313.03H257.337H196.46H176.167H161.286C147.758 313.03 147.758 333.799 161.286 333.799H235.692C246.965 336.056 246.063 354.116 233.437 354.116H170.756H116.643C103.115 354.116 103.115 374.884 116.643 374.884H167.599H197.362H304.235C321.37 374.884 339.408 370.821 355.642 361.34C376.385 350.504 391.717 329.284 395.776 308.966C400.736 284.586 392.619 260.205 370.974 248.015C391.266 233.567 404.344 212.799 406.598 191.127Z" fill="#D51F27" />
    </svg>)
}

export default LogoMinimal;