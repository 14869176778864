import React, { useEffect, useRef } from "react";
import { Box } from "@chakra-ui/react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import clsx from "clsx";
import { Coords } from "google-map-react";
import { TRiderLocationLogStatus } from "src/api/types/rider-locations";
import { IBaseRider } from "src/api/types/riders";
import dateHelper from "src/utils/date-helper";
import { useExtendedLiveMapDispatch, useExtendedLiveMapState } from "../../ExtendedLiveMapContext";
import { ExtendedLiveMapActionKind } from "../../ExtendedLiveMapStore/ExtendedLiveMap-Actions";
import { STATUS_COLORS } from "../../status-data";
import "./RiderMarker.css";

interface IRiderMarkerProps extends Coords {
    status: TRiderLocationLogStatus;
    rider: IBaseRider;
    zoneName: string;
}

export const RiderMarker: React.FC<IRiderMarkerProps> = ({ status, rider, zoneName }) => {
    const { activeLocationLog, activeZone } = useExtendedLiveMapState();
    const canvasRef = useRef<any>();
    const dispatch = useExtendedLiveMapDispatch();

    const onCloseClicked = () => {
        dispatch({ type: ExtendedLiveMapActionKind.SET_ACTIVE_LOCATIONLOG_BY_RIDERID, data: { selected: false, riderId: rider.id } });
    }

    const onItemClicked = () => {
        dispatch({ type: ExtendedLiveMapActionKind.SET_ACTIVE_LOCATIONLOG_BY_RIDERID, data: { selected: true, riderId: rider.id } });
    }

    const getLatestActivity = () => {
        if (activeLocationLog) {
            const seconds = dateHelper.compareDateWithToday(activeLocationLog.date, "seconds");
            return dateHelper.getDuration(seconds, false);
        }
        return "";
    }

    useEffect(() => {
        renderRiderMarkerCanvas(rider.avatar, rider.firstName, rider.lastName);
    }, []);

    const renderRiderMarkerCanvas = (avatarUrl: string, firstName: string, lastName: string) => {
        let markerName = firstName.substring(0, 1) + lastName.substring(0, 1);
        const canvas = canvasRef.current;
        canvas.setAttribute("style", "z-index: 3; position:relative;");
        const context = canvas.getContext('2d');
        context.textAlign = "center";
        context.font = "500 18px Arial";
        context.fillStyle = "#000";
        context.globalCompositeOperation = 'destination-over';
        if (avatarUrl) {
            let img = new Image();
            img.src = avatarUrl;
            context.drawImage(img, 0, 0, img.width, img.height,
                0, 0, context.canvas.width, context.canvas.height);
        } else {
            context.fillText(markerName, context.canvas.width / 2, context.canvas.height / 2 + 6);
        }
    };

    return <div className={clsx("l-m-rider-marker", STATUS_COLORS[status])} >
        <canvas className="RiderMarkerCanvas" id="myCanvas" ref={canvasRef} width="50" height="50" onClick={onItemClicked}></canvas>
        {
            activeLocationLog?.rider?.id === rider.id && (
                <div className="l-m-rider-details">
                    <span className="close-btn" onClick={onCloseClicked}>
                        <Icon path={mdiClose} size="20px" />
                    </span>
                    <div className="l-m-marker-content">
                        <Box display="flex" alignItems="center" fontSize="1em">
                            <Box bg={STATUS_COLORS[status]} className={clsx("status_bullet", STATUS_COLORS[status])}></Box>
                            <Box fontSize="0.85em" ml="1" className="fullName">{`${rider.firstName} ${rider.lastName}`}</Box>
                        </Box>
                        <Box color="gray.500" fontSize="1em" display="flex" alignItems="flex-start" flexDirection="column">
                            {activeZone && activeZone?.id.trim() === "" && <Box ml="1" fontSize="0.8rem" className="zoneName">Zone: {zoneName}</Box>}
                            <Box ml="1" mt="1" fontSize="0.8rem">{`Updated: ${getLatestActivity()} ago`}</Box>
                        </Box>

                    </div>

                </div>
            )
        }
    </div>
}