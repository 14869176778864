import React from "react";
import NotFoundResultIllustration from "src/assets/img/notFoundResult.svg";
import { Box } from "@chakra-ui/react";
import "./styles.css";

interface INotFoundResultProps {
    iconMaxWidth?: number;
}
export const NotFoundResult: React.FC<INotFoundResultProps> = ({ iconMaxWidth = 180 }) => {
    return <Box className="notFoundResult" mt="20" mb="10">
        <img src={NotFoundResultIllustration} alt="Not Found result" style={{ maxWidth: `${iconMaxWidth}px` }} />
    </Box>
}