import useSignalR from "./useSignalR";
import {
  IOngoingOrders,
  IOrderAccepted,
  IOrderChanged,
  OrderFlagType,
  WebSocketEvents,
} from "../api/types/orders";
import { useEffect, useState } from "react";
import { HubConnectionState } from "@microsoft/signalr";

export function useOngoingOrders() {
  const [newOrder, setNewOrder] = useState<any>({});
  const [acceptedOrder, setAcceptedOrder] = useState<any>({});
  const [changedOrder, setChangedOrder] = useState<any>({});
  const [allowToCallAPI, setAlreadyFetchedAPI] = useState<boolean>(false);

  const { connection, connectionStatus } = useSignalR({
    urlPath: "hub/notificationhub",
  });

  const handleOrdreStartedEvent = () => {
    connection?.on(WebSocketEvents.OrderStarted, (order: string) => {
      const orderJSON: IOngoingOrders = JSON.parse(order);
      orderJSON.flag = OrderFlagType.ADDED;

      setNewOrder(orderJSON);
    });
  };

  const handleRiderAcceptedEvent = () => {
    connection?.on(WebSocketEvents.RiderAccepted, (order: string) => {
      const acceptedOrder: IOrderAccepted = JSON.parse(order);

      setAcceptedOrder(acceptedOrder);
    });
  };

  const handleOrderStatusChanged = () => {
    connection?.on(WebSocketEvents.OrderStatusChanged, (order: string) => {
      const changedOrder: IOrderChanged = JSON.parse(order);
      setChangedOrder(changedOrder);
    });
  };

  useEffect(() => {
    if (connectionStatus === HubConnectionState.Connected) {
      setAlreadyFetchedAPI(true);
      return () => {
        setAlreadyFetchedAPI(false);
      };
    }
  }, [connectionStatus]);

  useEffect(() => {
    if (connection) {
      handleOrdreStartedEvent();
      handleRiderAcceptedEvent();
      handleOrderStatusChanged();
    }
  }, [connection]);

  return { newOrder, acceptedOrder, changedOrder, allowToCallAPI };
}
