import React, {useEffect, useRef, useState } from "react";
import useSupercluster from "use-supercluster";
import GoogleMap from "google-map-react";
import { useExtendedLiveMapState } from "../../ExtendedLiveMapContext";
import { RiderMarker } from "./RiderMarker";
import { ZoneMarker } from "./ZoneMarker";
import { DEFAULT_CENTER,  MAP_API_KEY , DEFAULT_ZOOM } from "src/constants/map-data";
import { ILocationLog } from "src/api/types/rider-locations";

const AnyReactComponent = ({text}: any) => <div>{text}</div>;

const LiveMap: React.FC = () => {
    const maxZoomClustering = 16;
    const { locationLogs, mapCenter,activeZone, activeLocationLog } = useExtendedLiveMapState();
    
    const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);
    const [riders,setRiders] = useState<ILocationLog[]>(locationLogs);
    const [circle,setCircle] = useState<any>();
    const [bounds, setBounds] = useState<any>(null);
    const [newBounds, setNewBounds] = useState<any>(null);
    const mapRef = useRef<any>(null);

    useEffect(()=>{
        const filteredItems = locationLogs.filter(lg=>lg.status!== "None");
        setRiders(filteredItems);
    },[locationLogs]);

    useEffect(() => {
        (activeLocationLog?.rider?.id && isAllZoneSelected() && zoom < maxZoomClustering) && setZoom(15);
    },[activeLocationLog]);

    const onMapLoaded = (data: { map: any, maps: any }) => {
        setCircle(new data.maps.Circle({
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            map:data.map,
            // center: {lat:activeZone?.location?.latitude,lng:activeZone?.location?.longitude},
            radius: activeZone?.validRadius
        }))
    }

    useEffect(()=>{
        setZoom(DEFAULT_ZOOM);
        if(activeZone && circle){
            circle.setRadius(activeZone.validRadius);
            circle.setCenter({lat:activeZone?.location?.latitude,lng:activeZone?.location?.longitude})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeZone]);

    const makePoints = (locationsList:ILocationLog[]) => {
        return locationsList.filter(l => l.location !== null).map(crime => ({
            type: "Feature",
            properties: { cluster: false, crimeId: crime.rider.id, category: crime.rider?.zone?.id || ""},
            geometry: {
              type: "Point",
              coordinates: [
                parseFloat(crime.location.longitude.toString()),
                parseFloat(crime.location.latitude.toString())
              ]
            }
        }));
    }

    const { clusters } = useSupercluster({
        points:makePoints(locationLogs),
        bounds,
        zoom,
        options: { radius: 50, maxZoom: maxZoomClustering }
    });

    const handleRenderMarkers = () => {
        if(!isAllZoneSelected()) {
            return renderRiderMarkers();
        }

        if (zoom < maxZoomClustering - 1) {
            return clusters.length > 0 && 
            clusters.map((cluster,ind) => {
                const [longitude, latitude] = cluster.geometry.coordinates;
                const {
                    cluster: isCluster,
                    point_count: pointCount
                } = cluster.properties;
                
                if (isCluster) {
                    return (
                        <AnyReactComponent
                          key={ind}
                          lat={latitude}
                          lng={longitude}
                          text={
                            <div key={ind}
                                 style={{
                                    width:"30px",
                                    height:"30px",
                                    borderRadius:"50%",
                                    background:"#333",
                                    color:"#fff",
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center"}}>
                                {pointCount}
                            </div>}>
                        </AnyReactComponent>
                    );
                }
            });
        }

        return renderRiderMarkers();
    }


    const renderCurrentRidersInClosingBounds = () => {
        if(newBounds) {
            if(riders.length > 0) {
                return riders.filter((marker:any) => {
                    if (
                        (marker.location.latitude > newBounds.se.lat && newBounds.sw.lat) &&
                        (marker.location.latitude < newBounds.ne.lat && newBounds.nw.lat) &&
                        (marker.location.longitude > newBounds.nw.lng && newBounds.sw.lng) &&
                        (marker.location.longitude < newBounds.ne.lng && newBounds.se.lng)
                    ) {
                        return marker;
                    }
                });
            }
            return riders;
        }

        return riders;
    }

    const isAllZoneSelected = () => {
        return activeZone.id && activeZone?.id.trim() === "";
    }

    const renderRiderMarkers = () => {
        return renderCurrentRidersInClosingBounds().map((l => (
            <RiderMarker lat={l.location.latitude} lng={l.location.longitude} status={l.status} rider={l.rider} zoneName={l.zoneName} key={l.rider.id} />
        )))
    }

    return <GoogleMap ref={mapRef}
        bootstrapURLKeys={{ key: MAP_API_KEY }}
        defaultCenter={{ lat: DEFAULT_CENTER.lat, lng: DEFAULT_CENTER.lng }}
        center={mapCenter}
        yesIWantToUseGoogleMapApiInternals
        defaultZoom={DEFAULT_ZOOM}
        onGoogleApiLoaded={onMapLoaded}
        options={() => ({mapTypeControl:true})}
        zoom={zoom}
        onChange={({ zoom, bounds }) => {
            setZoom(zoom);
            setNewBounds(bounds);
            setBounds([
                bounds.nw.lng,
                bounds.se.lat,
                bounds.se.lng,
                bounds.nw.lat
            ])
        }}>
        {
            (activeZone && activeZone.location && activeZone?.id?.trim() !== "") && <ZoneMarker lat={activeZone?.location?.latitude} lng={activeZone?.location?.longitude} />
        }
        {
            handleRenderMarkers()
        }
    </GoogleMap>
}

export default LiveMap;