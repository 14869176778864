import { ButtonProps } from "@chakra-ui/react";

export const ButtonStyles = {
    baseStyle:{
        fontWeight:500
    },
    sizes:{},
    variants:{
        primary:(props:ButtonProps)=>({
            bg:"primary"
            
        }),
        secondary:(props:ButtonProps)=>({
            bg:"secondary",
            
        }),
        orange: () =>({
            bg: "#F39905",
            color: "#fff",
            _hover: () => ({
                bg: "#d78603"
            })
        }),
        yellow_100: () =>({
            bg: "#FEE4BA",
            color: "#000",
            _hover: () => ({
                bg: "#f1c47c"
            })
        })
    },
    defaultProps:{}
}