export enum RiderType {
    None = "None" ,
    Maintenance = "Maintenance",
    Rider = "Rider"
}

export enum RiderCategory {
    None = "None" ,
    Dedicated = "Dedicated",
    SelfPickup = "SelfPickup",
    DoD = "DoD"
}

export enum RiderStatusType {
    None = "None",
    CheckIn = "CheckIn",
    ManualCheckout = "ManualCheckout",
    SystematicTooLateCheckIn = "SystematicTooLateCheckIn",
    SystematicTooLateExit = "SystematicTooLateExit"
}