import { Box, Center, FormControl, Input, Text } from "@chakra-ui/react";
import { panelAttendanceLogService } from "src/api/services/attendance-log";
import { IAttendanceLogItem } from "src/api/types/attendance-log";
import dateHelper from "src/utils/date-helper";
import TimeLine from "./components/TimeLine";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import HorizontalLoading from "src/components/app/HorizontalLoading";
import { NotFoundResult } from "src/components/app/NotFoundResult";
import Icon from "@mdi/react";
import { mdiTimerOffOutline, mdiTimerOutline } from "@mdi/js";
import "./styles.css";
import SPSpinner from "src/components/app/SPSpinner";
import { ControlledSelectBox } from "../../../components/app/SelectBox";
import { useForm } from "react-hook-form";
import useQueryParam from "../../../hooks/useSearchQuery";
import dayjs from "dayjs";
import { useRidersFilter } from "src/hooks/useRidersFilter";
import { IFetchDataResponse } from "src/types/general";
import { useAsync } from "src/hooks/useAsync";
import { useEffect } from "react";

interface IFetchAttendanceLogsFilters {
  date: string;
  riderId: string;
}

const AttendanceTimeline: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.attendanceTimeline"));

  const { control, watch, setValue } = useForm();
  const { queryParams, updateQueryParams: setQueryParams } = useQueryParam({
    initialFields: {},
  });

  const {
    riders,
    isLoading: isLoadingRiders,
    handleScroll: handleScrollRidersFilter,
    handleSearch: handleSearchRiders,
  } = useRidersFilter(queryParams["riderId"]);

  const {
    run: getAttendanceLog,
    isLoading: loadingLogs,
    data: attendanceData,
  } = useAsync<
    IFetchDataResponse<IAttendanceLogItem>,
    IFetchAttendanceLogsFilters
  >(
    (options) => {
      const { date, riderId } = queryParams;
      return new Promise(async (resolve, reject) => {
        try {
          const dates = dateHelper.getStartEndByDate(date);
          const result = await panelAttendanceLogService.getByRiderAndDate(
            riderId ? riderId : "",
            dates.start,
            dates.end
          );
          resolve(result.data.data as IFetchDataResponse<IAttendanceLogItem>);
        } catch (e) {
          reject([]);
        }
      });
    },
    {
      defaultValue: undefined,
      immediate: false,
    }
  );

  const attendanceLogs = attendanceData?.items || [];

  useEffect(() => {
    const { date, riderId } = queryParams;
    if (!!riderId || !!date) {
      getAttendanceLog({ date, riderId });
      setValue("riderIds", queryParams.riderId);
    }
  }, [queryParams.riderId, queryParams.date]);

  const onDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams({ date: event.target.value });
  };

  const getTotalTime = () => {
    if (attendanceLogs.length === 0) {
      return {
        totalInsideDurations: " ---",
        totalOutsideDurations: " ---",
      };
    }

    let totalInsideDurations = attendanceLogs.reduce((total, log) => {
      return total + log.insideDuration;
    }, 0);

    let totalOutsideDurations = attendanceLogs.reduce((total, log) => {
      return total + log.outsideDuration;
    }, 0);

    return {
      totalInsideDurations: dateHelper.getDuration(totalInsideDurations),
      totalOutsideDurations: dateHelper.getDuration(totalOutsideDurations),
    };
  };

  const renderTimeLineItems = () => {
    if (attendanceLogs.length) {
      return <TimeLine items={attendanceLogs} loading={loadingLogs} />;
    }

    return loadingLogs ? (
      <Center height="100%">
        <SPSpinner size="xl" />
      </Center>
    ) : (
      <NotFoundResult />
    );
  };

  const handleRiderOnChange = (selectedRiderId: string) => {
    setQueryParams({
      date: queryParams.date ? queryParams.date : dayjs().format("YYYY-MM-DD"),
      riderId: selectedRiderId,
    });
  };

  useEffect(() => {
    handleRiderOnChange(watch("riderIds"));
  }, [watch("riderIds")]);
  return (
    <Box className="attendance_timeline_container">
      <Box className="timeline_header">
        <Text as="h3" fontSize="3xl">
          {t("titles.attendanceTimeline")}
        </Text>
        <Box className="timeline_filters">
          <Box className="searchable_list_filter">
            <FormControl>
              {isLoadingRiders && <HorizontalLoading />}
              <ControlledSelectBox
                control={control}
                name={"riderIds"}
                placeholder={"Select a Rider"}
                options={[
                  {
                    id: undefined,
                    text: "All",
                    lastName: "",
                  },
                  ...riders.items,
                ].map((rider) => ({
                  value: rider.id,
                  label: rider.text,
                }))}
                styles={{
                  input: (base) => ({
                    ...base,
                    minWidth: 250,
                    marginTop: 0,
                  }),
                  container: (base) => ({
                    ...base,
                    marginTop: 0,
                    maxWidth: 270,
                  }),
                }}
                isLoading={isLoadingRiders}
                onMenuScrollToBottom={handleScrollRidersFilter}
                onInputChange={handleSearchRiders}
              />
            </FormControl>
          </Box>
          <Box className="date_filter">
            <FormControl>
              <Input
                type="date"
                id="date"
                value={
                  queryParams.date
                    ? queryParams.date
                    : dayjs().format("YYYY-MM-DD")
                }
                onChange={onDateChanged}
                width="auto"
                bg="#fff"
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box className="total_time_box">
        <Box className="total_time_item">
          <Box className="total_time_desk">
            <Icon size="30px" path={mdiTimerOutline} color="#65A30D" />
            <Box ml={2}>
              <Text color="#666666">Total time inside zone</Text>
              <Text color="#333333" fontWeight="500">
                {getTotalTime().totalInsideDurations || "---"}
              </Text>
            </Box>
          </Box>
          <Box className="total_time_mobile">
            <Box display="flex">
              <Icon size="32px" path={mdiTimerOutline} color="#65A30D" />
              <Text color="#666666" ml="2">
                Total time <br />
                inside zone
              </Text>
            </Box>
            <Text color="#333333" fontWeight="500">
              {dateHelper.getShortFormatDuration(
                getTotalTime().totalInsideDurations
              ) || "---"}
            </Text>
          </Box>
        </Box>
        <Box className="total_time_item">
          <Box className="total_time_desk">
            <Icon size="30px" path={mdiTimerOffOutline} color="#F33B12" />
            <Box ml={2}>
              <Text color="#666666">Total time outside zone</Text>
              <Text color="#333333" fontWeight="500">
                {getTotalTime().totalOutsideDurations || "---"}
              </Text>
            </Box>
          </Box>
          <Box className="total_time_mobile">
            <Box display="flex">
              <Icon size="32px" path={mdiTimerOffOutline} color="#F33B12" />
              <Text color="#666666" ml="2">
                Total time <br />
                outside zone
              </Text>
            </Box>
            <Text color="#333333" fontWeight="500">
              {dateHelper.getShortFormatDuration(
                getTotalTime().totalOutsideDurations
              ) || "---"}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box className="timeline_container_box">
        <Box className="timeline_container">{renderTimeLineItems()}</Box>
      </Box>
    </Box>
  );
};

export default AttendanceTimeline;
