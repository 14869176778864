import { Box, Button, FormControl, FormErrorMessage, FormLabel, IconButton, Input, InputGroup, InputRightElement, Modal, ModalContent, ModalOverlay, Select } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { mdiEyeOffOutline, mdiEyeOutline, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { panelUserManagementServices } from "src/api/services/user-management";
import { ICreateUserFormData, USER_ROLE } from "src/types/user-management";
import FormHelper from "src/utils/form-helper";
import { toast } from "src/utils/toast";
import { userValidation } from "src/validation-schemas/create-update-user";
import { useUserManagementData } from "../../UserManagementProvider";
import RoleInputDependency from "../RoleInputDependency";

const CreateUser:React.FC = () => {
    const { t } = useTranslation();
    const { selectedRoleTab, setSelectedRoleTab, setTriggerRefreshUserList } = useUserManagementData();
    const [ abortController ] = useState<AbortController>(new AbortController());
    const [ checkUniquenessAbortController ] = useState<AbortController>(new AbortController());
    const [ uniquenessOfUsername, setUniquenessOfUsername ] = useState<{
        isUnique: boolean;
        error: string;
    }>({
        isUnique: false, 
        error: ""
    });
    const [ showPassword, setShowPassword ] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const methods = useForm<ICreateUserFormData>({
        resolver: yupResolver(userValidation(t, "create")),
        mode: "all",
        defaultValues: {
            firstName: "",
            lastName: "",
            username: "",
            rawPassword: "",
            confirmPassword: "",
            role: USER_ROLE.Admin,
            roleDependency: ""
        }
    });
    const formHelperInstance = new FormHelper();

    const { register, handleSubmit, watch, setValue, reset, formState: {isValid, errors}} = methods;
    let roleWatch = watch("role");
    
    useEffect(() => {
        setValue("role", selectedRoleTab);
    }, [selectedRoleTab]);

    useEffect(() => {
        setValue("roleDependency", "");
    },[roleWatch]);

    const renderRoleInputDependency = () => {
        if(roleWatch === USER_ROLE.Admin) {
            return <></>;
        }
        return <RoleInputDependency userRoleType={roleWatch} methods={methods}/>
    }

    const onUsernameBlur = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let username = e.target.value;
        try {
            const result = await panelUserManagementServices.checkUsernameUniqueness({username}, checkUniquenessAbortController.signal);
            let uniqueness: {
                isUnique: boolean;
                error: string;
            } = result.data.data ? {isUnique: false, error: "The username must be unique"} : {isUnique: true, error: ""}
            setUniquenessOfUsername(uniqueness);
        } catch(error) {
            console.log(error);
        }
    }

    const onSubmit = async (data: ICreateUserFormData) => {
        console.log(errors);
        const {confirmPassword, roleDependency, ...userFormData} = data;
        userFormData.groupId = null;
        userFormData.zoneId = null;

        if(userFormData.role === USER_ROLE.GroupAgent) {
            userFormData.groupId = data.roleDependency;
            userFormData.zoneId = null;
        } else if(userFormData.role === USER_ROLE.ZoneAgent){
            userFormData.groupId = null;
            userFormData.zoneId = data.roleDependency;
        }
        
        console.log(userFormData);
        try {
            setLoading(true);
            await panelUserManagementServices.createUser(userFormData, abortController.signal);
            toast.success(t("messages.userCreatedSuccessfully"));
            reset();
            setIsOpen(false);
            setSelectedRoleTab(data.role);
            setTriggerRefreshUserList(true);
        } catch(error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const closeModal = () => {
        setIsOpen(false);
        reset();
    }

    return <>
        <Button rightIcon={<Icon path={mdiPlus} size="18px"/>} variant="orange" onClick={() => setIsOpen(true)}>Create User</Button>
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModal} size="2xl">
            <ModalOverlay />
            <ModalContent p={9}>
                <form noValidate style={{display:"flex", flexDirection: "column", gap: "12px"}} onSubmit={handleSubmit(onSubmit)}>
                    <p style={{fontWeight: "600", fontSize: "20px"}}>Create New User</p>
                    <Box display="flex" alignItems="start" gap={2}>
                        <FormControl isInvalid={!!errors.firstName}>
                            <FormLabel>First Name</FormLabel>
                            <Input type="text" size="lg" autoComplete="off" maxLength={95} {...register("firstName")}/>
                            <FormErrorMessage>{!!errors.firstName && errors.firstName.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.lastName}>
                            <FormLabel>Last Name</FormLabel>
                            <Input type="text" size="lg" autoComplete="off" maxLength={95} {...register("lastName")}/>
                            <FormErrorMessage>{!!errors.lastName && errors.lastName.message}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <FormControl isInvalid={!!errors.username || (!uniquenessOfUsername.isUnique && uniquenessOfUsername.error.length > 0)}>
                        <FormLabel>Username/Email</FormLabel>
                        <Input type="text" size="lg" autoComplete="off" maxLength={50} {...register("username")} onBlur={onUsernameBlur}
                            onInput={(e: ChangeEvent<HTMLInputElement>) => formHelperInstance.acceptSpecialPattern(e, "^[a-zA-Z0-9@._]+$")}/>
                        <FormErrorMessage>{!!errors.username ? errors.username.message : uniquenessOfUsername.error}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.rawPassword}>
                        <FormLabel>Password</FormLabel>
                        <InputGroup size="lg">
                            <Input type={showPassword ? "text" : "password"} size="lg" maxLength={8} autoComplete="off" {...register("rawPassword")}/>
                            <InputRightElement>
                                <IconButton 
                                    aria-label="show-hide-password"
                                    variant="ghost" 
                                    onClick={() => setShowPassword(!showPassword)} 
                                    icon={<Icon size="22px" path={showPassword ? mdiEyeOutline : mdiEyeOffOutline}/>}/>
                            </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{!!errors.rawPassword && errors.rawPassword.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.confirmPassword}>
                        <FormLabel>Confirm Password</FormLabel>
                        <InputGroup size="lg">
                            <Input type={showPassword ? "text" : "password"} size="lg" maxLength={8} autoComplete="off" {...register("confirmPassword")}/>
                            <InputRightElement>
                                <IconButton 
                                    aria-label="show-hide-password"
                                    variant="ghost" 
                                    onClick={() => setShowPassword(!showPassword)} 
                                    icon={<Icon size="22px" path={showPassword ? mdiEyeOutline : mdiEyeOffOutline}/>}/>
                            </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{!!errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Role</FormLabel>
                        <Select size="lg" {...register("role")}>
                            <option value={USER_ROLE.Admin}>Admin</option>
                            <option value={USER_ROLE.GroupAgent}>Group Manager</option>
                            <option value={USER_ROLE.ZoneAgent}>Zone Agent</option>
                        </Select>
                    </FormControl>
                    {renderRoleInputDependency()}
                    <Box display="flex" alignItems="center" gap="12px" mt={3}>
                        <Button size="lg" flex="1 1 0px" variant="outline" disabled={loading} onClick={closeModal}>{t("actions.cancel")}</Button>
                        <Button size="lg" flex="1 1 0px" variant="yellow_100" type="submit" isLoading={loading} disabled={!isValid || !uniquenessOfUsername.isUnique}>{t("actions.save")}</Button>
                    </Box>
                </form>
            </ModalContent>
        </Modal>
    </>;
}

export default CreateUser;