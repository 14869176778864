import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAxiosResponse, IPaginatedResult } from "../types/axios-response";

import { IMessageListItem, INewMessagePayload } from "../types/messages";

export const messagesService = {
  getAll(
    offset: number,
    count: number,
    abortSignal: AbortSignal,
    searchQuery: string = ""
  ) {
    let keyword = searchQuery ? `&keyword=${searchQuery}` : "";
    return AxiosInstance.get<
      IAxiosResponse<IPaginatedResult<IMessageListItem>>
    >(
      `${API_ROUTES.MESSAGES.GET_ALL_MESSAGES}?count=${count}&offset=${offset}${keyword}`,
      { signal: abortSignal }
    );
  },
  create(data: INewMessagePayload, abortSignal: AbortSignal) {
    return AxiosInstance.post<IAxiosResponse<{}>>(
      API_ROUTES.MESSAGES.CREATE_NEW_MESSAGE,
      data,
      { signal: abortSignal }
    );
  },
};
