import { Box, Button, Checkbox, IconButton, Modal, ModalBody, ModalContent, ModalOverlay, Text } from "@chakra-ui/react";
import { useState } from "react";
import { panelThirdPartiesService } from "src/api/services/third-party";
import { useClipboard } from '@chakra-ui/react'
import { mdiCheckCircle, mdiContentCopy } from "@mdi/js";
import Icon from "@mdi/react";

interface IAPIKeyConfirmationProps {
    item: {
        id: string;
        name: string;
    }
    openAPIKeyConfirmationModal: boolean;
    setOpenAPIKeyConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleGenerateNewKey: () => void;
}

const APIKeyConfirmation: React.FC<IAPIKeyConfirmationProps> = (props) => {
    let { item, openAPIKeyConfirmationModal, setOpenAPIKeyConfirmationModal, handleGenerateNewKey } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [disableConfirm, setDisableConfirm] = useState<boolean>(true);
    const [abortController] = useState<AbortController>(new AbortController());
    const [generatedToken, setGeneratedToken] = useState<string>("");
    const { onCopy, value, hasCopied } = useClipboard(generatedToken);

    const generateAPIKey = async () => {
        setLoading(true);
        try {
            const result = await panelThirdPartiesService.generateAPIKey(item.id, abortController.signal);
            setGeneratedToken(result.data.data);
            handleGenerateNewKey();
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const renderConfirmationStep = () => {
        return <>
            <Box p={5}>
                <Text as="h3" fontSize="1.2rem" mb={2} fontWeight="500">Do you want to generate new API Key for {item.name}?</Text>
                <Text color="#a4a4a4" mb={3} fontWeight="normal">If you generate new API key your last API key will be reset!</Text>
                <Checkbox colorScheme='orange' onChange={() => setDisableConfirm(!disableConfirm)}>I confirm and I sure about it.</Checkbox>
            </Box>
            <Box display="flex" mt="2" gap="4">
                <Button flex="1" variant="outline" ml="2" onClick={() => setOpenAPIKeyConfirmationModal(false)} isDisabled={loading}>Cancel</Button>
                <Button flex="1" variant="yellow_100" onClick={generateAPIKey} isLoading={loading} disabled={disableConfirm}>Confirm</Button>
            </Box>
        </>
    }

    const renderShowingToken = () => {
        return <>
            <Box p={5} pb={0}>
                <Text as="h3" fontSize="1.2rem" mb={2} fontWeight="500">API Key has actived</Text>
                <Text color="#888" mb={3} fontWeight="normal">Please copy the token and give it to <strong>{item.name}</strong> company</Text>
            </Box>
            <Box pos="relative" minH="90px" border="1px solid #ddd" bg="#FAFAFA" borderRadius="12px" p={3}>
                <Box display="flex" alignItems="center" justifyContent="space-between"
                    bg="#d5d5d5" px={3} py={1} borderRadius="12px" marginBottom="12px" cursor="pointer"
                    onClick={onCopy}>
                    <Text ml={3}>{hasCopied ? "Copied" : "Copy"}</Text>
                    <IconButton aria-label="copy" size="md" variant="unstyled"
                        icon={<Icon size="25px" path={hasCopied ? mdiCheckCircle : mdiContentCopy} color={hasCopied ? "#209915" : "#323232"} />}/>
                </Box>
                {value}
            </Box>
            <Box mt={3} textAlign="center">
                <Button variant="yellow_100" onClick={() => setOpenAPIKeyConfirmationModal(false)} pr={8} pl={8}>Got It!</Button>
            </Box>
        </>
    }

    return <Modal closeOnOverlayClick={false} isOpen={openAPIKeyConfirmationModal} onClose={() => setOpenAPIKeyConfirmationModal(false)}>
        <ModalOverlay />
        <ModalContent p={1}>
            <ModalBody mt={2} mb={4}>
                {generatedToken === "" ? renderConfirmationStep() : renderShowingToken()}
            </ModalBody>
        </ModalContent>
    </Modal>;
}

export default APIKeyConfirmation;