import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import React, { FormEvent, useEffect, useState } from "react";

interface ISearchInput {
  value: string;
  searchPlaceholder: string;
  onSearchInput: (keyword: string) => void;
}
export const SearchInput: React.FC<ISearchInput> = ({
  value,
  searchPlaceholder,
  onSearchInput,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>(value);

  useEffect(() => {
    if (!!value && !searchQuery) {
      setSearchQuery(value);
    }
  }, [value]);

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    onSearchInput(searchQuery);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      as="form"
      noValidate
      onSubmit={handleSearch}
    >
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          color="gray"
          children={
            <Icon path={mdiMagnify} size="24px" style={{ marginTop: "12px" }} />
          }
        />
        <Input
          type="search"
          placeholder={searchPlaceholder}
          backgroundColor="white"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          size="lg"
        />
      </InputGroup>
      <Button size="lg" ml="3" colorScheme="primary" type="submit">
        Search
      </Button>
    </Box>
  );
};
