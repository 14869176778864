import React from "react";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { mdiAlert } from "@mdi/js";
import Icon from "@mdi/react";

interface Props {
  data: {
    onAccept: () => void;
    onReject: () => void;
    title?: string;
    iconType?: "confirm" | "attention";
    shouldAcceptOrReject?: boolean;
  };
  closeModal: () => void;
}

const ConfirmModal: React.FC<Props> = (props) => {
  const iconType = props.data.iconType || "confirm";
  const shouldAcceptOrReject = props.data.shouldAcceptOrReject || false;
  return (
    <Modal isOpen={true} onClose={props.closeModal} size="md" isCentered>
      <ModalOverlay />
      <ModalContent p="4">
        <Box>
          <Text as="h3" textAlign={iconType === "confirm" ? "center" : "start"}>
            {iconType === "attention" && (
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Icon
                  path={mdiAlert}
                  size={"24px"}
                  style={{ color: "var(--chakra-colors-red-600)" }}
                />
                <Text
                  color={"red.600"}
                  as="span"
                  fontWeight="700"
                  fontSize="1rem"
                  ml={"2"}
                >
                  Attention
                </Text>
              </Box>
            )}

            <Text as="p" fontWeight="400" mt={"2"} fontSize=".9rem">
              {props.data.title}
            </Text>
          </Text>
          <Flex w={"100%"} mt={"4"} justify={"center"}>
            {shouldAcceptOrReject && (
              <>
                <Button
                  colorScheme={iconType === "attention" ? "red" : "yellow"}
                  size="lg"
                  w={"100%"}
                  onClick={props.data.onAccept}
                >
                  Yes
                </Button>
                <Button
                  colorScheme="gray"
                  size="lg"
                  w={"100%"}
                  ml={"2"}
                  onClick={props.data.onReject}
                >
                  No
                </Button>
              </>
            )}
            {!shouldAcceptOrReject && (
              <Box w={"100%"} display={"flex"} justifyContent={"end"}>
                <Button
                  colorScheme="red"
                  size="lg"
                  ml={"2"}
                  onClick={props.data.onReject}
                >
                  Ok , got it
                </Button>
              </Box>
            )}
          </Flex>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
