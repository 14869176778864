import { string, object } from "yup";
const nameValidation = (field: string,t:any) => {
  return string()
    .required(
      t("validationMessages.fieldIsRequired", {
        field
      })
    )
    .max(256,
      t("validationMessages.maximumCharacter", {
        field
      }))
    .min(3,
      t("validationMessages.minumuemCharacter", {
        field
      })
    )
}
export const newGroupValidationSchema = (t: any) =>
  object().shape({
    nameEn: nameValidation(t("fields.nameEn"),t),
    nameAr: nameValidation(t("fields.nameEn"),t),
  });
