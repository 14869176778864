import { AxiosResponse } from "axios";
import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "..";
import { IWorkingStatus } from "../types/riders";
import { IPaginatedResult } from "../types/axios-response";

export const workingStatusService = {
  getAll() {
    return AxiosInstance.get<AxiosResponse<IPaginatedResult<IWorkingStatus>>>(
      API_ROUTES.WORKING_STATUS.ROOT
    );
  },
  get() {
    return AxiosInstance.get<AxiosResponse<IWorkingStatus>>(
      API_ROUTES.WORKING_STATUS.ROOT
    );
  },
  update(riderId: string, workingStatusId: number) {
    return AxiosInstance.path<AxiosResponse<{}>>(
      API_ROUTES.WORKING_STATUS.EDIT(riderId, workingStatusId)
    );
  },
};
