import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import { useNavigate, useParams } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { IGroupListItem, INewGroupData } from "src/api/types/groups";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { newGroupValidationSchema } from "src/validation-schemas/group";
import ContentView, {
  ContentViewBody,
  ContentViewHeader,
} from "src/components/app/ContentView";
import FormRow from "src/components/app/FormRow";
import { panelGroupsService } from "src/api/services/groups";
import { toast } from "src/utils/toast";
import { BrowserBack } from "src/components/app/BrowserBack";
import { useAsync } from "src/hooks/useAsync";
import { useAbortController } from "src/hooks/useAbortController";

const CreateGroup: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getAbortSignal } = useAbortController();
  const [editable, setEditable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams<{ id: string }>();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<INewGroupData>({
    resolver: yupResolver(newGroupValidationSchema(t)),
    mode: "all",
    defaultValues: {
      nameEn: "",
      nameAr: "",
    },
  });

  const { run: getGroupDetails, isLoading } = useAsync<IGroupListItem, any>(
    () => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await panelGroupsService.get(
            params.id!,
            getAbortSignal("getGroupDetails").signal
          );
          const { ...rest } = response.data.data;
          reset(rest);
          resolve(response.data.data as IGroupListItem);
        } catch (e) {
          reject([]);
        }
      });
    }
  );

  useEffect(() => {
    if (params.id) {
      setEditable(true);
      getGroupDetails({});
    }
  }, [params]);

  const onSubmit = async (data: INewGroupData) => {
    setLoading(true);
    try {
      if (editable) {
        await panelGroupsService.update(
          params.id!,
          data,
          getAbortSignal("updateGroupData").signal
        );
      } else {
        await panelGroupsService.create(
          data,
          getAbortSignal("createGroupData").signal
        );
      }
      toast.success(
        t(
          editable
            ? "messages.groupUpdatedSuccessfully"
            : "messages.groupCreatedSuccessfully"
        )
      );
      editable
        ? navigate(-1)
        : navigate(ROUTE_CONSTANTS.DASHBOARD.GROUPS.ROOT.ABSOLUTE);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useTitle(t(params.id ? "pageTitles.editGroup" : "pageTitles.newGroup"));

  return (
    <ContentView>
      <ContentViewHeader>
        <Box display="flex" alignItems="center">
          <Text as="h3" fontSize="3xl">
            {t(editable ? "titles.editGroup" : "titles.newGroup")}
          </Text>
          <BrowserBack />
        </Box>
        <Box
          mb="4"
          mt="4"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        ></Box>
      </ContentViewHeader>

      <ContentViewBody>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormRow
            bidirectional
            leftContent={
              <FormControl isInvalid={!!errors.nameEn} isRequired>
                <FormLabel htmlFor="nameEn">{t("fields.nameEn")}</FormLabel>
                <Input
                  id="nameEn"
                  type="text"
                  size="lg"
                  {...register("nameEn")}
                />
                <FormErrorMessage>
                  {!!errors.nameEn && errors.nameEn.message}
                </FormErrorMessage>
              </FormControl>
            }
            rightContent={
              <FormControl isInvalid={!!errors.nameAr} isRequired>
                <FormLabel htmlFor="nameAr">{t("fields.nameAr")}</FormLabel>
                <Input
                  id="nameAr"
                  type="text"
                  size="lg"
                  {...register("nameAr")}
                />
                <FormErrorMessage>
                  {!!errors.nameAr && errors.nameAr.message}
                </FormErrorMessage>
              </FormControl>
            }
          />

          <Box textAlign="right">
            <Button
              onClick={() =>
                editable
                  ? navigate(-1)
                  : navigate(ROUTE_CONSTANTS.DASHBOARD.GROUPS.ROOT.ABSOLUTE)
              }
              size="lg"
              colorScheme="orange"
              mr="4"
            >
              {t("actions.cancel")}
            </Button>
            <Button
              colorScheme="primary"
              size="lg"
              type="submit"
              isLoading={loading || isLoading}
            >
              {t("actions.save")}
            </Button>
          </Box>
        </form>
      </ContentViewBody>
    </ContentView>
  );
};

export default CreateGroup;
