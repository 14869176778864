import { Box, Modal, ModalContent, ModalOverlay, Text } from "@chakra-ui/react";
import React, { ChangeEvent, useState } from "react";
import { ApiConfig } from "src/api/config";
import { IAxiosResponse } from "src/api/types/axios-response";
import { getAuthorization } from "src/utils/get-authorization";
import { ShowSuccessState } from "./components/ShowSuccessState";
import { BulkFileUploader } from "./components/BulkFileUploader";
import { FileUploaderActions } from "./components/FileUploaderActions";
import { calcAndShowFileSize } from "src/utils/file-size-helper";
import "./styles.css";

interface IBulkUpload {
  data: {
    title: string;
    maxValidSize: string;
    bulkUploadApiUrl?: string;
  };
  closeModal: () => {};
}

export const BulkUpload: React.FC<IBulkUpload> = ({ data, closeModal }) => {
  const { title, maxValidSize, bulkUploadApiUrl } = data;
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
  const [wasSelectedFile, setWasSelectedFile] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<null | File>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [exceptionMessage, setExceptionMessage] = useState<string[]>([]);
  const [doneImportItems, setDoneImportItems] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const helpMessages = [
    "Import file must be .csv format",
    "CSV file must follow a specific structure as shown on the template",
    "Import file should not exceed " +
      maxValidSize?.split("-")[0] +
      maxValidSize?.split("-")[1] +
      " in size",
  ];

  const handleCloseModal = (isAfterUpload = false) => {
    setSelectedFile(null);
    setWasSelectedFile(false);
    closeModal();
    setDisabledSubmit(true);
    setIsUploading(false);
    setExceptionMessage([]);

    if (isAfterUpload) {
      setDoneImportItems(false);
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
  };

  const handleChooseFile = (e: ChangeEvent<HTMLInputElement>) => {
    setExceptionMessage([]);
    if (!e.target.files || e.target.files.length === 0) {
      // file not selected
      setExceptionMessage(["File not selected yet!"]);
      return;
    } else {
      let choosenFile = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(choosenFile);

      let validateFileResult = validateFile(choosenFile);
      if (!validateFileResult[0]) {
        setExceptionMessage([validateFileResult[1]]);
        return;
      }
      setWasSelectedFile(true);
      setSelectedFile(choosenFile);
      setDisabledSubmit(false);
    }
  };

  const validateFile = (choosenFile: File): [boolean, string] => {
    var validExt = ["csv"];
    let fileName = choosenFile.name;
    let fileExt = fileName.split(".").pop();
    if (validExt.indexOf(fileExt?.toLocaleLowerCase()!) === -1) {
      return [false, "File extension is Invalid!"];
    }

    if (choosenFile.size >= calcAndShowFileSize(maxValidSize).fileSize) {
      return [
        false,
        "File size is greater than " +
          calcAndShowFileSize(maxValidSize).viewForm,
      ];
    }

    return [true, ""];
  };

  const removeSelectedFile = () => {
    setSelectedFile(null);
    setWasSelectedFile(false);
    setDisabledSubmit(true);
  };

  const onUploadFile = () => {
    setIsUploading(true);
    setExceptionMessage([]);

    const formData = new FormData();
    formData.append("File", selectedFile as Blob);
    // if admin select avatar
    fetch(ApiConfig.baseURL + bulkUploadApiUrl, {
      method: "POST",
      headers: {
        Authorization: getAuthorization()!,
        accept: "*/*",
      },
      body: formData,
    })
      .then((res) => res.text())
      .then((text) => (text.length ? JSON.parse(text) : {}))
      .then((data: IAxiosResponse<{}>) => {
        if (data.meta.messageType === "Success") {
          setDoneImportItems(true);
          setSuccessMessage(data.meta.message);
        } else {
          setExceptionMessage(data.meta.message.split(","));
          setDisabledSubmit(true);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsUploading(false);
      });
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={true}
      onClose={handleCloseModal}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <Box mb="3" mt="3">
          <Text as="h3" textAlign="center">
            <Text as="span" fontWeight="500" fontSize="1.2rem">
              {title} Bulk Upload
            </Text>
          </Text>
        </Box>
        {doneImportItems ? (
          <ShowSuccessState
            selectedFile={selectedFile}
            handleClose={handleCloseModal}
            message={successMessage}
          />
        ) : (
          <>
            <BulkFileUploader
              exceptionMessage={exceptionMessage}
              handleChooseFile={handleChooseFile}
              isUploading={isUploading}
              removeSelectedFile={removeSelectedFile}
              selectedFile={selectedFile}
              wasSelectedFile={wasSelectedFile}
              helpMessages={helpMessages}
              maxValidSize={maxValidSize}
            />

            <FileUploaderActions
              disabledSubmit={disabledSubmit}
              handleCloseModal={handleCloseModal}
              isUploading={isUploading}
              handleUploadFile={onUploadFile}
            />
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
