export enum OrdersDataStoreActionKind {
    SET_ORDERS = "SET_ORDERS",
    ADD_NEW_ORDER = "ADD_NEW_ORDER",
    ACCEPT_ORDER = "ACCEPT_ORDER",
    UPDATE_ORDER = "UPDATE_ORDER",
    SET_PENDING_ORDERS = "SET_PENDING_ORDERS"
}

export interface OrdersDataStoreAction<T> {
    type: OrdersDataStoreActionKind;
    data?: T
}