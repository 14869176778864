import { useEffect, useRef } from "react";

export function useAbortController() {
  const abortSignalsMap = useRef<Record<string, AbortController>>({});
  function stopRequest(requestName: string) {
    abortSignalsMap.current[requestName].abort();
  }

  function getAbortSignal(requestName: string) {
    if (!abortSignalsMap.current[requestName]) {
      abortSignalsMap.current[requestName] = new AbortController();
    }
    return abortSignalsMap.current[requestName];
  }

  function stopAllRequests() {
    Object.values(abortSignalsMap.current).forEach((controller) =>
      controller.abort()
    );
  }

  useEffect(() => {
    return () => {
      stopAllRequests();
    };
  }, []);

  return { stopRequest, stopAllRequests, getAbortSignal };
}
