import { useCallback, useEffect, useState } from "react";
import { panelDashboardService } from "src/api/services/dashboard";
import { ITopZonesKeyValues } from "src/api/types/dashboard";
import CircleSkeleton from "src/components/app/CircleSkeleton";
import { CHART_DEFAULT_COUNT } from "src/constants/chart";
import DashboardChart from "src/pages/dashboard/Home/components/DashboardChart";
import dateHelper from "src/utils/date-helper";
import "./styles.css";

const TopZonesPerTime: React.FC = () => {
  const [topZones, setTopZones] = useState<ITopZonesKeyValues[]>([]);
  const [totalCheckInTime,setTotalCheckInTime] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const [topZonesPerTimeAbortController] = useState<AbortController>(new AbortController());

  const fetchTopZonesPerTime = useCallback(async () => {
    try {
      const fromDate = dateHelper.getStartEndByDate(dateHelper.getYesterdayDate()).start;
      const toDate = dateHelper.getStartEndByDate(dateHelper.getToday()).start;
      const result = await panelDashboardService.getTopZonePerCheckinTimes(CHART_DEFAULT_COUNT,fromDate,toDate,topZonesPerTimeAbortController.signal);
      setTopZones(result.data.data.topZonesPerCheckInTime);
      setTotalCheckInTime(result.data.data.totalCheckInTime);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTopZonesPerTime();
  }, [fetchTopZonesPerTime]);

  useEffect(() => {
    return () => {
      topZonesPerTimeAbortController.abort();
    }
  },[]);


  return <CircleSkeleton loadingSize="l-md" isLoaded={loading} extraClass={"chart_section_2 box_area"}>
    <DashboardChart
      chartType="TopZonesPerTime"
      items={topZones}
      title={{
        main: "Zones by Attendance Time (Top 10)",
        sub: `Total Work Hours in ${dateHelper.formatDate(dateHelper.getYesterdayDate() , "YYYY-MM-DD")} : ${+Math.round(totalCheckInTime! / 3600)} hours`
      }} />
  </CircleSkeleton>
}

export default TopZonesPerTime;