import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  Textarea,
  ModalContent,
  ModalOverlay,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel
} from "@chakra-ui/react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import "./addNewMessageModal.css";

import { INewMessageData } from "src/api/types/messages";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { newMessageValidationSchema } from "src/validation-schemas/message";
import { useTranslation } from "react-i18next";
import { messagesService } from "src/api/services/messages";
import { useAbortController } from "src/hooks/useAbortController";
import { toast } from "src/utils/toast";
import ZonesSelectContainer from "./ZonesSelect/Container";
import RidersSelectContainer from "./RidersSelect/Container";

interface IAddNewMessageModalProps {
  data: { onCreateNewMessageSuccess: () => void };
  closeModal: (clearModals: boolean) => void;
}

const AddNewMessageModal: React.FC<IAddNewMessageModalProps> = ({
  data: dataFromProps,
  closeModal,
}) => {
  const { getAbortSignal } = useAbortController();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedZones, setSelectedZones] = useState<string[]>([]);
  const [zonesAllSelected, setZonesAllSelected] = useState(false);
  const [ridersAllSelected, setRidersAllSelected] = useState(false);

  const [selectedRiders, setSelectedRiders] = useState<string[]>([]);

  const {
    register,
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
      getValues
  } = useForm<INewMessageData>({
    resolver: yupResolver(newMessageValidationSchema(t)),
    mode: "onChange",
    defaultValues: {
      title: "",
      body: "",
    },
  });

  const onSubmit = async (data: INewMessageData) => {
    setLoading(true);
    const values = getValues();
    try {
      const payload = {
        Title: values?.title,
        Body: values?.body,
        // @ts-ignore
        zoneIds: zonesAllSelected ? [] : selectedZones.map(item => item.id),
        // @ts-ignore
        riderIds: ridersAllSelected ? [] : selectedRiders.map(item => item.id),
      };
      await messagesService.create(
        payload,
        getAbortSignal("createMessage").signal
      );
      toast.success(t("messages.messageSentSuccessfully"));
      dataFromProps.onCreateNewMessageSuccess();
      closeModal(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => setStep(2);
  const handleBack = () => setStep(1);

  const test = () => {
    console.log("test");
  };
  return (
    <Modal isOpen={true} onClose={() => closeModal(true)} size="lg">
      <ModalOverlay />
      <ModalContent p="8" borderRadius={"3xl"}>
        <Box
          mb="4"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text as="h3" fontWeight="500" fontSize="1rem">
            {step === 1
              ? t("titles.newNotification")
              : "Select Zones or Riders"}
          </Text>
          <Text
            as="span"
            color="gray"
            cursor="pointer"
            onClick={() => closeModal(true)}
            className="closeModal"
          >
            <Icon path={mdiClose} size="22px" />
          </Text>
        </Box>
        {step === 1 && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleNext();
            }}
            noValidate
          >
            <Box textAlign="right" margin={"20px 0 20px 0"}>
              <FormControl isInvalid={!!errors.title} isRequired>
                <FormLabel htmlFor="title">{t("fields.title")}</FormLabel>
                <Input
                  id="title"
                  type="text"
                  size="lg"
                  {...register("title")}
                />
                <FormErrorMessage>
                  {!!errors.title && errors.title.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box textAlign="right">
              <FormControl isInvalid={!!errors.body} isRequired>
                <FormLabel htmlFor="body">{t("fields.message")}</FormLabel>
                <Textarea id="body" rows={10} {...register("body")} />
                <FormErrorMessage>
                  {!!errors.body && errors.body.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box display={"flex"} margin={"30px 0 20px 0"}>
              <Button
                onClick={() => closeModal(true)}
                size="lg"
                border={"1px solid lightGray"}
                bgColor={"white"}
                color={"black"}
                mr="4"
                isFullWidth={true}
              >
                {t("actions.cancel")}
              </Button>
              <Button
                bgColor={isValid ? "#FEE4BA" : "white"}
                color={"black"}
                border={isValid ? "" : "1px solid lightGray"}
                size="lg"
                type="button"
                onClick={handleNext}
                isFullWidth={true}
                disabled={loading || !isValid || isSubmitting}
              >
                Next
              </Button>
            </Box>
          </form>
        )}
        {step === 2 && (
          <Box p="0">
            <Tabs colorScheme={'orange'}>
              <TabList mb="1em">
                <Tab>Zones</Tab>
                <Tab>Riders</Tab>
              </TabList>
              <TabPanels>
                <TabPanel p="0">
                  <ZonesSelectContainer
                      allSelectedCount={selectedZones.length}
                      onZoneSelected={(zones) => setSelectedZones(zones)}
                                        onAllSelected={(value) => setZonesAllSelected(value)} />
                </TabPanel>
                <TabPanel p="0">
                  <RidersSelectContainer onRiderSelected={(riders) => {
                    setSelectedRiders(riders)
                  }} onAllSelected={(value) => setRidersAllSelected(value)}
                                         allSelectedCount={selectedRiders.length}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              margin={"30px 0 20px 0"}
            >
              <Button
                onClick={handleBack}
                size="lg"
                border={"1px solid lightGray"}
                bgColor={"white"}
                color={"black"}
                mr="4"
                isFullWidth={true}
              >
                Back
              </Button>
              <Button
                bgColor={"#FEE4BA"}
                color={"black"}
                border={"1px solid #FEE4BA"}
                size="lg"
                type="submit"
                onClick={() => onSubmit({ title: "", body: "" })} // Hook up actual data
                isFullWidth={true}
                disabled={loading}
              >
                Send
              </Button>
            </Box>
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
};

export default AddNewMessageModal;
