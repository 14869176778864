import React from "react";
import {
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useAsync } from "../../../hooks/useAsync";
import { panelTargetGroupsService } from "../../../api/services/target-groups";
import { modalActions } from "../../../global-context/modals";
import { IRiderListItem } from "../../../api/types/riders";
import HorizontalLoading from "../HorizontalLoading";
import { Title } from "chart.js";

interface Props {
  data: {
    targetGroupId?: string;
    targetGroupName?: string;
  };
}

const TargetGroupRiders: React.FC<Props> = (props) => {
  const targetGroupRiders = useAsync<IRiderListItem[], unknown>(
    () => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await panelTargetGroupsService.getTargetGroupRiders(
            props.data.targetGroupId as any
          );
          resolve(response.data.data.items as any);
        } catch (e) {
          reject([]);
        }
      });
    },
    {
      immediate: true,
    }
  );

  console.log("targetGroupRiders : ", targetGroupRiders.data);
  return (
    <Modal
      isOpen={true}
      onClose={() => modalActions.clearModals()}
      size="2xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent p="4" maxHeight={"80vh"} overflowY={"auto"}>
        <Text as={"p"} fontWeight={500} fontSize={20} mb={"4"}>
          {props.data.targetGroupName} Riders
        </Text>
        <Box>
          {targetGroupRiders.isLoading ? (
            <HorizontalLoading />
          ) : (
            <Table
              variant="simple"
              colorScheme="blackAlpha"
              size="md"
              boxShadow="md"
              backgroundColor="white"
              borderRadius="md"
            >
              <Thead>
                <Tr>
                  <Th>{"Employee Id"}</Th>
                  <Th>{"First Name"}</Th>
                  <Th>{"Last Name"}</Th>
                  <Th>{"ZonesSelect"}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {targetGroupRiders?.data?.map((targetGroupItem, index) => (
                  <Tr key={index}>
                    <Td>{targetGroupItem.employeeId}</Td>
                    <Td>{targetGroupItem.firstName}</Td>
                    <Td>{targetGroupItem.lastName}</Td>
                    <Td>{targetGroupItem.zone?.nameEn || "----"}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default TargetGroupRiders;
