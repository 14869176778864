import { API_ROUTES } from "src/constants/api-routes";
import {AxiosInstance} from "../index";
import { IAxiosResponse } from "../types/axios-response";
import { ICompleteRiderLocations } from "../types/rider-locations";
import { DEFAULT_COUNT } from "src/constants/api-default-values";

export const panelRiderLocationService = {
    getAllByZoneId(zoneId:string,abortSignal:AbortSignal){
        let zoneIds = zoneId.trim() !== "" ? `&ZoneIds=${zoneId}` : ``;
        return AxiosInstance.get<IAxiosResponse<ICompleteRiderLocations>>(`${API_ROUTES.RIDER_LOCATIONS.ROOT}?count=${DEFAULT_COUNT}${zoneIds}`,{signal:abortSignal});
    }
};