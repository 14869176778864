import { useAsync } from "../../../hooks/useAsync";
import { panelTargetGroupsService } from "../../../api/services/target-groups";
import { toast } from "../../../utils/toast";
import { useTranslation } from "react-i18next";
import { useToggle } from "../../../hooks/useToggle";
import { modalActions } from "../../../global-context/modals";
import { MODAL_TYPES } from "../../../types/modals";

export function useChangeTargetGroupActivity(targetGroupId: string) {
  const { t } = useTranslation();
  const { toggle, active, setIsActive } = useToggle(false);

  const toggleActivityAsync = useAsync<unknown, boolean>((payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        let result;
        result = await panelTargetGroupsService.toggle(
          String(targetGroupId),
          payload ? "enable" : "disable"
        );
        toggle();
        toast.success(t("messages.targetGroupUpdatedSuccessfully"));
        resolve(result);
      } catch (e) {
        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
          title:
            "You can not disable this target group because some riders are linked to it , You can edit their target group and try again",
          iconType: "attention",
          onAccept: () => {},
          onReject: () => modalActions.clearModals(),
        });
        reject(e);
      }
    });
  });

  function changeActivity(value: boolean) {
    modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
      title: `Are you sure you want to ${
        value ? "Enable" : "Disable"
      } target group?`,
      shouldAcceptOrReject: true,
      iconType: "confirm",
      onAccept: () => {
        toggleActivityAsync.run(value);
        modalActions.clearModals();
      },
      onReject: () => {
        modalActions.clearModals();
      },
    });
  }

  return {
    ...toggleActivityAsync,
    run: changeActivity,
    active,
    setIsActive,
  };
}
