import {
  Badge,
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { USER_ROLE_TYPES } from "src/types/user-management";
import UserListTable from "../components/UserListTable";
import { roleData } from "../roleData";
import { useUserManagementData } from "../UserManagementProvider";

const UserList: React.FC = () => {
  const { selectedRoleTab, setSelectedRoleTab, searchKeyword, counts } =
    useUserManagementData();
  const selectedTabStyles = {
    color: "#FAA51A",
    borderBottom: "2px solid #FAA51A",
    fontWeight: 600,
  };
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const handleOnChangeTabs = (index: number) => {
    setActiveTabIndex(index);
    setSelectedRoleTab(roleData.find((role) => role.tabIndex === index)!.role);
  };

  const renderBadge = (role: USER_ROLE_TYPES, tabIndex: number) => {
    if (searchKeyword.trim().length > 0) {
      const r = roleData.find((roleItem) => roleItem.role === role)?.role!;
      let activeTabBadge =
        tabIndex === activeTabIndex
          ? {
              backgroundColor: "#FAA51A",
              color: "#fff",
            }
          : { backgroundColor: "#444", color: "#fff" };
      return (
        <Badge
          sx={{
            padding: "1px 6px",
            borderRadius: "50%",
            textAlign: "center",
            ...activeTabBadge,
          }}
        >
          {counts[r]}
        </Badge>
      );
    }

    return null;
  };

  useEffect(() => {
    setActiveTabIndex(
      roleData.find((roleItem) => roleItem.role === selectedRoleTab)!.tabIndex
    );
  }, [selectedRoleTab]);

  return (
    <Box
      background="#fff"
      padding="12px"
      borderRadius="8px"
      border="1px solid #ddd"
    >
      <Tabs
        onChange={(index) => handleOnChangeTabs(index)}
        index={activeTabIndex}
      >
        <TabList>
          {roleData.map((roleItem, index) => (
            <Tab
              key={index}
              sx={{ minWidth: "150px" }}
              _selected={selectedTabStyles}
            >
              {roleItem.title}
              &nbsp;{renderBadge(roleItem.role, index)}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {/* Admin List */}
          <TabPanel>
            <UserListTable tabIsActive={activeTabIndex === 0} />
          </TabPanel>
          {/* Group Manager List */}
          <TabPanel>
            <UserListTable tabIsActive={activeTabIndex === 1} />
          </TabPanel>
          {/* ZonesSelect Agent List */}
          <TabPanel>
            <UserListTable tabIsActive={activeTabIndex === 2} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default UserList;
