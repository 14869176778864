import {Box, Progress} from "@chakra-ui/react";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { RiderItem } from "./RiderItem";
import { IManagingZoneRiderItem } from "../../ManageZoneRidersModal/types";
import "./RiderSelectorList.css";

interface IZoneRidersProps {
  items: any[];
  onChange: (data: any[]) => void;
    onSelected: (data: any[]) => void;
  searchQuery?: string;
  disabled?: boolean;
  hasMore?: boolean;
  loading?: boolean;
  onScrollToEnd?: () => void;
  totalCount: number;
  onAllSelected?: (value : boolean) => void;
  allSelectedCount: number
}

const RidersSelect: React.FC<IZoneRidersProps> = (props) => {
  const {
    items,
    totalCount,
    onChange,
      onSelected,
    disabled = false,
      hasMore,
      loading,
      onScrollToEnd,
      onAllSelected,
      allSelectedCount
  } = props;

  const [selectedRiders, setSelectedRiders] = useState<
    IManagingZoneRiderItem[]
  >([]);
  const [isSelectAllActive, setIsSelectAllActive] = useState(false);

  const onZoneChanged = useCallback(
      (sender: IManagingZoneRiderItem, selected: boolean) => {
      if (disabled || isSelectAllActive) return;

      const tempItems = [...items];
      const itemIndex = tempItems.findIndex((ti) => ti.id === sender.id);
      if (itemIndex === -1) return;

      tempItems[itemIndex].selected = selected;

      if (selected) {
        setSelectedRiders((prevSelected) => [
          ...prevSelected,
          tempItems[itemIndex],
        ]);
      } else {
        setSelectedRiders((prev) =>
          prev.filter((sr) => sr.id !== tempItems[itemIndex].id)
        );
      }

    },
    [disabled, isSelectAllActive, items, onChange]
  );

    const selectAll = useCallback(() => {
        if (disabled) return;

        const tempItems = [...items];
        if (selectedRiders.length === tempItems.length) {
            const deselectedItems = tempItems.map((ti) => ({
                ...ti,
                selected: false,
            }));
            setSelectedRiders([]);
            onChange(deselectedItems);
            setIsSelectAllActive(false); // Enable scrolling and selection
        } else {
            const tempSelectedItems = tempItems.map((ti) => ({
                ...ti,
                selected: true,
            }));
            setSelectedRiders(tempSelectedItems);
            onChange(tempSelectedItems);
            setIsSelectAllActive(true); // Disable scrolling and selection
        }
    }, [disabled, items, onChange, selectedRiders.length]);
  // Memoize filtered items
  const filteredItems = React.useMemo(
    () =>
      items.map(item => ({
          ...item,
          selected: selectedRiders.find(selected => selected.id === item.id)
      })),
    [items, selectedRiders]
  );

    const observer = useRef<IntersectionObserver>();
    const lastZoneElementRef = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    onScrollToEnd && onScrollToEnd();
                }
            });

            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    useEffect(() => {
        onAllSelected && onAllSelected(isSelectAllActive);
    }, [isSelectAllActive]);

    useEffect(() => {
        onSelected && onSelected(selectedRiders)
    }, [selectedRiders]);
    return (
        <Box position="relative">
            {loading && (
                <Progress
                    size="xs"
                    isIndeterminate
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    zIndex="1"
                    colorScheme={'orange'}
                />
            )}
            <div
                className="riders-container"
                style={{
                    overflowY: isSelectAllActive ? "hidden" : "auto",
                    maxHeight: "500px",
                    position: "relative",
                    opacity: isSelectAllActive ? 0.7 : 1,
                    pointerEvents: isSelectAllActive ? "none" : "auto",
                }}
            >
                {filteredItems.map((item, index) => (
                    <div
                        key={index}
                        ref={index === filteredItems.length - 1 ? lastZoneElementRef : null}
                    >
                        <RiderItem
                            data={item}
                            onChange={onZoneChanged}
                            disabled={isSelectAllActive}
                        />
                    </div>
                ))}
            </div>
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt="3"
        >
            <Box color="#808080">
                {(!!allSelectedCount && !isSelectAllActive) && <>
                    {`${allSelectedCount} Rider(s) Selected`}
                </>}
                {(!allSelectedCount || isSelectAllActive) && <>   {`total of ${totalCount} Riders`}</>}
            </Box>
            {items.length > 0 && (
                <Box
                    color="#06A7F7"
                    cursor="pointer"
                    userSelect="none"
                    onClick={selectAll}
          >
            {selectedRiders.length < items.length
              ? "Select All"
              : "Deselect All"}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(RidersSelect);
