import React from "react";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { IRiders } from "./Modal";

interface IRiderList {
  riders: any[];
  lastRiderRef: (node: HTMLTableRowElement | null) => void;
}

const RiderList: React.FC<IRiderList> = ({ riders, lastRiderRef }) => {
  return (
    <Box p={4} maxHeight="400px" overflowY="auto">
      <Box className="table_container">
        <Table
          variant="simple"
          colorScheme="blackAlpha"
          size="md"
          backgroundColor="white"
          borderRadius="md"
          rounded="md"
          border="1px solid #E7E7E7"
        >
          <Thead bgColor="#E6E6E6" borderRadius="8px">
            <Tr>
              <Th>Employee ID</Th>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Zone</Th>
            </Tr>
          </Thead>
          <Tbody>
            {riders.map((rider, ind) => {
              if (riders.length === ind + 1) {
                return (
                  <Tr key={ind} ref={lastRiderRef}>
                    <Td>{rider.employeeId}</Td>
                    <Td>{rider.firstName}</Td>
                    <Td>{rider.lastName}</Td>
                    <Td>{rider.zoneNameEr}</Td>
                  </Tr>
                );
              } else {
                return (
                  <Tr key={ind}>
                    <Td>{rider.employeeId}</Td>
                    <Td>{rider.firstName}</Td>
                    <Td>{rider.lastName}</Td>
                    <Td>{rider.zoneNameEr}</Td>
                  </Tr>
                );
              }
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default RiderList;
