import { Select, SelectProps } from "@chakra-ui/react";
import {
  ControllerRenderProps,
  FieldValues,
  UseFormReturn,
} from "react-hook-form";
import { useAsync } from "src/hooks/useAsync";
import { FormItem } from "src/types/form";

type FormFieldValues<TFieldValues extends FieldValues = Record<string, any>> =
  TFieldValues;

interface Props extends SelectProps {
  formItem: FormItem;
  field: ControllerRenderProps;
  useFormReturn: UseFormReturn<FormFieldValues>;
}
type IOption = { title: string; value: string };
export const FormGeneratorSelectBox = (props: Props) => {
  const { formItem, field, useFormReturn } = props;
  const { data } = useAsync(
    () =>
      new Promise(async (resolve) => {
        try {
          if (formItem.fetcher) {
            const data = await formItem.fetcher();
            const options = data;
            if (formItem?.hasNoneOption) {
              options?.unshift({ title: "None", value: "" });
            }
            resolve(options as IOption[]);
          } else if (formItem?.options) {
            resolve(formItem?.options);
          }
        } catch (e) {
          resolve(formItem?.options);
        }
      }),
    { immediate: true }
  );

  return (
    <Select
      {...field}
      {...formItem}
      isInvalid={!!useFormReturn.formState.errors[formItem.name]}
    >
      {((data as IOption[]) || [])?.map(({ title, value }: IOption) => (
        <option value={value} key={value}>
          {title}
        </option>
      ))}
    </Select>
  );
};
