import { IPaginatedResult } from "./axios-response";

export enum NotificationTypeEnum {
    SystematicTooLateCheckIn = "SystematicTooLateCheckIn",
    SystematicTooLateExit = "SystematicTooLateExit",
    IdleRider = "IdleRider"
};

export interface INotificationListItem {
    id: number;
    message: string;
    status: "Pending" | "Seen";
    riderId: string;
    createdAt: string;
    checkoutPeriodSeconds: number;
    type: NotificationTypeEnum.SystematicTooLateCheckIn | NotificationTypeEnum.SystematicTooLateExit | NotificationTypeEnum.IdleRider;
}

export interface INotificationsListPayload extends IPaginatedResult<INotificationListItem> {}