import { extendTheme } from "@chakra-ui/react";
import {ButtonStyles as Button} from "./components/ButtonStyles";
const theme = extendTheme(
    {
        fonts:{
            body:"'Roboto', sans-serif"
        },
        colors: {
            primary:{
                500:"#D51F27",
                600:"#C93A00"
            },
            secondary:{
                500:"#F39905",
                600:"#d6890b"
            },
            primaryText:"#333333",
            secondaryText:"#777777"
        },
        shadows:{
            outline:"0 0 0 3px rgb(195 195 195 / 60%)"
        },
        components:{
            Button
        }
    }
);

type Theme = typeof theme;

export type { Theme };
export { theme };
