import React, { FC, ReactNode } from "react";
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  SubmitHandler,
  UseFormReturn,
} from "react-hook-form";
import {
  Box,
  Checkbox,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
} from "@chakra-ui/react";
import {
  FormItem as FormItemType,
  FormItemObject,
  FORM_ITEM_TYPE,
  BidirectionalFormRow,
} from "src/types/form";
import ReactDatePicker from "react-datepicker";
import FormRow from "src/components/app/FormRow";
import { FormGeneratorSelectBox } from "./FormGeneratorSelectBox";

type TFormItemProps<TFieldValues extends FieldValues = Record<string, any>> = {
  useFormReturn: UseFormReturn<TFieldValues>;
  formItem: FormItemType;
};

export const FormItem = ({ useFormReturn, formItem }: TFormItemProps) => {
  const renderFormItem = (
    field: ControllerRenderProps,
    formItem: FormItemType
  ) => {
    switch (formItem.type) {
      case FORM_ITEM_TYPE.Select: {
        return (
          <FormGeneratorSelectBox
            formItem={formItem}
            field={field}
            useFormReturn={useFormReturn}
          />
        );
      }
      case FORM_ITEM_TYPE.check_box: {
        return (
          <Checkbox
            isDisabled={formItem?.disabled}
            {...field}
            isChecked={field.value}
          >
            {formItem.CheckboxText}
          </Checkbox>
        );
      }
      case FORM_ITEM_TYPE.Password: {
        return (
          <Input
            {...field}
            {...formItem}
            type="password"
            isInvalid={!!useFormReturn.formState.errors[formItem.name]}
          />
        );
      }
      case FORM_ITEM_TYPE.text_area: {
        return (
          <Input
            as="textarea"
            {...field}
            {...formItem}
            isInvalid={!!useFormReturn.formState.errors[formItem.name]}
          />
        );
      }
      case FORM_ITEM_TYPE.Day_select: {
        return <ReactDatePicker {...field} {...formItem} />;
      }
      case FORM_ITEM_TYPE.Number:
      case FORM_ITEM_TYPE.Email:
      case FORM_ITEM_TYPE.Text:
      default:
        return (
          <Input
            {...field}
            {...formItem}
            onBlur={formItem.validate}
            isInvalid={!!useFormReturn.formState.errors[formItem.name]}
          />
        );
    }
  };

  return (
    <FormControl
      isInvalid={!!useFormReturn.formState.errors[formItem.name]}
      isRequired={formItem.required}
      isDisabled={formItem.disabled}
    >
      <FormLabel htmlFor={formItem.name}>{formItem.label}</FormLabel>
      {formItem.loading ? (
        <Spinner />
      ) : (
        <Controller
          name={formItem.name}
          control={useFormReturn.control}
          render={({ field }) => renderFormItem(field, formItem)}
        />
      )}
      <FormErrorMessage>
        {useFormReturn.formState.errors[formItem.name]?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
