import React, { useEffect, useState } from "react";
import GoogleMap from "google-map-react";

import { ILatLng } from "src/types/map";
import { DEFAULT_CENTER, DEFAULT_ZOOM, MAP_API_KEY } from "src/constants/map-data";

interface IMapChooseLocationProps {
    width?: number | string;
    height?: number | string;
    zoom?: number;
    center?: ILatLng;
    marker?: ILatLng;
    onChange?: (data: ILatLng) => void;
    zoneRadius?: number;
    fullscreenControl?: boolean;
    editable?: boolean;
}

export const MapChooseLocation: React.FC<IMapChooseLocationProps> = (props) => {
    const { width = "100%", height = "300px", zoom = DEFAULT_ZOOM, center = DEFAULT_CENTER, onChange, zoneRadius, marker = DEFAULT_CENTER, fullscreenControl = false, editable = true } = props;
    const [markerPosition, setMarkerPosition] = useState<ILatLng>(marker);
    const [defaultCenter] = useState<ILatLng>(center);
    const [dynamicCenter, setDynamicCenter] = useState<ILatLng>(marker);
    const [circle, setCircle] = useState<any>();
    const [nativeMarker, setNativeMarker] = useState<any>()

    useEffect(() => {
        setMarkerPosition({ lat: marker.lat, lng: marker.lng });
    }, [marker])

    const onMapLoaded = (data: { map: any, maps: any }) => {
        setCircle(new data.maps.Circle({
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            map: data.map,
            center: markerPosition,
            radius: zoneRadius,
            clickable:false
            
        }));
        const nMarker = new data.maps.Marker({
            position: markerPosition,
            map: data.map
        });
        setNativeMarker(nMarker);
        data.maps.event.addListener(data.map, "click", (e: any) => {
            if (!editable) {
                return;
            }
            const value = { lat: e.latLng.lat(), lng: e.latLng.lng() };
            nMarker.setPosition(value)
            setMarkerPosition(value);
            onChange && onChange(value)
        });
    }

    useEffect(() => {
        if (center) {
            setDynamicCenter(center);
            setMarkerPosition(center);
            if (nativeMarker) {
                nativeMarker.setPosition(center);
            }
            onChange && onChange(center)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [center]);

    useEffect(() => {
        if (zoneRadius && circle) {
            circle.setRadius(zoneRadius > 0 ? zoneRadius : 0);
            circle.setCenter(markerPosition)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoneRadius, markerPosition])

    return <div style={{ width, height, marginBottom: 16 }}>
        <GoogleMap
            bootstrapURLKeys={{ key: MAP_API_KEY }}
            defaultCenter={{ lat: defaultCenter.lat, lng: defaultCenter.lng }}
            center={dynamicCenter}
            onGoogleApiLoaded={onMapLoaded}
            options={{
                fullscreenControl
            }}
            defaultZoom={zoom}>
            {/* <DefaultMarker lat={markerPosition.lat} lng={markerPosition.lng} /> */}
        </GoogleMap>
    </div>
}