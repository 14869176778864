import { Box } from "@chakra-ui/react";
import React from "react";
import { Outlet , Link} from "react-router-dom";
import Logo from "src/components/app/Logo";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import "./GeneralLayout.css";
import Banner1 from "src/assets/img/banner_1.jpg";
import Banner2 from "src/assets/img/banner_2.jpg";
import Banner3 from "src/assets/img/banner_3.jpg";

const images = [Banner1,Banner2,Banner3]

const GeneralLayout: React.FC = () => {
    return (
        <Box className="general-layout">
            <Box className="left-box">
                <Outlet />
            </Box>
            <Box className="right-box" style={{backgroundImage:`url(${images[Math.floor(Math.random()*2)]})`}}>
                <div className="mask"/>
                <div className="box-content">
                <Link to={ROUTE_CONSTANTS.ROOT.ABSOLUTE}>
                    <Logo />
                </Link>
                </div>
            </Box>
        </Box>
    );
}

export default GeneralLayout;