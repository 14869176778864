import { Box } from "@chakra-ui/react";
import React from "react";
import "./ContentView.css";

export const ContentViewHeader:React.FC = ({children})=>{
    return <Box className="view-header">{children}</Box>
}

export const ContentViewBody:React.FC = ({children})=>{
    return <Box className="view-body">{children}</Box>
}

const ContentView: React.FC = ({ children }) => {
    return (<Box className="content-view">
        {
            children
        }
    </Box>);
}

export default ContentView;