import {
  ILiveMapStatistics,
  ILocationLog,
} from "src/api/types/rider-locations";
import { IZoneListItem } from "src/api/types/zones";
import { DEFAULT_CENTER } from "src/constants/map-data";
import { ILatLng } from "src/types/map";
import {
  ExtendedLiveMapAction,
  ExtendedLiveMapActionKind,
} from "./ExtendedLiveMap-Actions";

export interface IExtendedLiveMapInitialState {
  zones: IZoneListItem[];
  activeZone: IZoneListItem;
  locationLogs: ILocationLog[];
  activeLocationLog: ILocationLog;
  loadingRiders: boolean;
  mapCenter: ILatLng;
  activeZoneStatistics: ILiveMapStatistics;
}

export const ExtendedLiveMapInitialState: IExtendedLiveMapInitialState = {
  zones: [],
  activeZone: {} as IZoneListItem,
  locationLogs: [],
  activeLocationLog: {} as ILocationLog,
  loadingRiders: false,
  mapCenter: DEFAULT_CENTER,
  activeZoneStatistics: {
    totalRiders: 0,
    idleRidersCount: 0,
    unreachableRidersCount: 0,
    busyRidersCount: 0,
    activeRidersCount: 0,
  } as ILiveMapStatistics,
};

export const extendedLiveMapReducer = (
  state = ExtendedLiveMapInitialState,
  action: ExtendedLiveMapAction<any>
) => {
  const { type, data } = action;

  switch (type) {
    case ExtendedLiveMapActionKind.SET_ZONES:
      return { ...state, zones: data.zones };
    case ExtendedLiveMapActionKind.SET_ACTIVE_ZONE:
      if (data.allZoneSelected) {
        return {
          ...state,
          activeZone: data.activeZone,
          locationLogs: [],
          loadingRiders: data.loadingRiders,
          activeLocationLog: {} as ILocationLog,
        };
      }
      return {
        ...state,
        activeZone: data.activeZone,
        locationLogs: [],
        loadingRiders: data.loadingRiders,
      };
    case ExtendedLiveMapActionKind.SET_LOCATION_LOGS:
      return {
        ...state,
        locationLogs: data.locationLogs,
        activeZoneStatistics: data.activeZoneStatistics,
      };
    case ExtendedLiveMapActionKind.SET_ACTIVE_LOCATION_LOG:
      if (data.hasLocation) {
        return {
          ...state,
          activeLocationLog: data.activeLocationLog,
          mapCenter: data.mapCenter,
        };
      }
      return { ...state, activeLocationLog: data.activeLocationLog };
    case ExtendedLiveMapActionKind.SET_LOADING_RIDERS:
      return { ...state, loadingRiders: data.loadingRiders };
    case ExtendedLiveMapActionKind.SET_MAP_CENTER:
      return { ...state, mapCenter: data.mapCenter };
    case ExtendedLiveMapActionKind.SET_ACTIVE_LOCATIONLOG_BY_RIDERID:
      if (data.selected) {
        const targetIndex = state.locationLogs.findIndex(
          (ll) => ll.rider.id === data.riderId
        );
        if (targetIndex > -1) {
          return {
            ...state,
            activeLocationLog: state.locationLogs[targetIndex],
          };
        }
        return { ...state, activeLocationLog: undefined };
      } else {
        return { ...state, activeLocationLog: undefined };
      }
    default:
      return state;
  }
};
