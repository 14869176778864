import { Box } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import "./styles.css";

interface ICircleSkeleton {
    children: ReactNode;
    loadingSize: "l-sm" | "l-md" | "l-lg";
    isLoaded: boolean;
    extraClass?: string;
}

const CircleSkeleton: React.FC<ICircleSkeleton> = ({ children, loadingSize, isLoaded, extraClass }) => {
    let isHideLoading = isLoaded ? "" : "hide";
    return <Box className={`circle_skeleton ${extraClass}`}>
        <Box className={`loading ${loadingSize} ${isHideLoading}`}></Box>
        {children}
    </Box>

}

export default CircleSkeleton;