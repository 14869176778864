import { Box, Modal, ModalContent, ModalOverlay, Text } from "@chakra-ui/react";
import { useState } from "react";
import { IMinimalOrderInfoForAcceptedRiders } from "src/api/types/orders";
import { NotFoundResult } from "src/components/app/NotFoundResult";
import RiderList from "../RiderList";

interface IAcceptedRiderModalProps {
    isOpen: boolean;
    handleClose: () => void;
    orderItem: IMinimalOrderInfoForAcceptedRiders;
}

const AcceptedRiders: React.FC<IAcceptedRiderModalProps> = ({ isOpen, handleClose, orderItem }) => {
    const [showNoResultFound] = useState<boolean>(false);

    const renderAcceptedRidersTable = () => {
        if (!showNoResultFound) {
            return <RiderList riders={orderItem?.acceptedRiders} />;
        }

        return <NotFoundResult iconMaxWidth={140} />;
    }

    return <>
        <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
            <ModalOverlay />
            <ModalContent>
                <Box mb={1} display="flex" justifyContent="space-between" alignItems="center" bgColor="rgba(231, 231, 231, 0.16)" p={3} borderBottom="1px solid #E7E7E7" borderRadius="8px 8px 0 0">
                    <Text as="p" fontWeight="500" fontSize="1.3rem">Accepted Rider</Text>
                    <Box display="flex" alignItems="center">
                        <Text as="span" color="#666666" fontSize="1.1rem">{orderItem.reference}</Text>
                        &nbsp;&nbsp;<Text as="span" color="#ccc" fontWeight="bold">|</Text>&nbsp;&nbsp;
                        <Text as="span" color="#666" fontSize="1.1rem">{orderItem.code}</Text>
                    </Box>
                </Box>
                {renderAcceptedRidersTable()}
            </ModalContent>
        </Modal>
    </>
}

export default AcceptedRiders;