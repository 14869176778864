import {
  Box,
  Center,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { panelThirdPartiesService } from "src/api/services/third-party";
import { NotFoundResult } from "src/components/app/NotFoundResult";
import { Pagination } from "src/components/app/Pagination";
import { SearchInput } from "src/components/app/SearchInput";
import SPSpinner from "src/components/app/SPSpinner";
import { useSearch } from "src/hooks/useSearch";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { IThirdPartyItem } from "src/types/third-party";
import ThirdPartyRow from "../components/ThirdPartyRow";
import { useAsync } from "src/hooks/useAsync";
import { IFetchDataResponse } from "src/types/general";
import { useAbortController } from "src/hooks/useAbortController";

interface IFetchRidersFilters {
  count: number;
  offset: number;
  query?: string;
}

const ThirdPartiesList: React.FC = () => {
  const { t } = useTranslation();

  const [showNoResultFound, setShowNoResultFound] = useState<boolean>(false);
  const { searchQuery, updateSearchParameters } = useSearch({
    isStoreOnLocalStorage: false,
    entity: "Third Parties",
    fields: { query: "", size: 10, offset: 0 },
  });
  const { getAbortSignal } = useAbortController();

  const {
    run: fetchThirdParties,
    isLoading: loading,
    data: thirdPartyData,
  } = useAsync<IFetchDataResponse<IThirdPartyItem>, IFetchRidersFilters>(
    (options) => {
      const { count, offset, query = searchQuery.query } = options;
      return new Promise(async (resolve, reject) => {
        try {
          const response = await panelThirdPartiesService.getAll(
            {
              count: count,
              offset: offset,
              searchQuery: query,
            },
            getAbortSignal("fetchThirdParties").signal
          );
          if (response.data.data.items.length === 0) {
            setShowNoResultFound(true);
          }
          resolve(response.data.data as IFetchDataResponse<IThirdPartyItem>);
        } catch (e) {
          reject([]);
        }
      });
    },
    {
      defaultValue: undefined,
    }
  );
  const { items: thirdPartyList } = thirdPartyData || {};

  const search = (searched: string) => {
    updateSearchParameters({
      query: searched.toLocaleLowerCase().trim(),
      offset: 0,
    });
    fetchThirdParties({
      offset: 0,
      count: searchQuery.size,
      query: searched.toLocaleLowerCase().trim(),
    });
  };

  const handlePassPaginateValues = (offset: number, size: number) => {
    updateSearchParameters({ offset: offset, size: size });
    fetchThirdParties({ offset: offset, count: size });
  };

  useEffect(() => {
    fetchThirdParties({
      offset: searchQuery.offset,
      count: searchQuery.size,
      query: searchQuery.query,
    });
  }, []);

  return (
    <>
      <Box display="flex" alignItems="center">
        <Text as="h3" fontSize="3xl">
          {t("titles.thirdParties")}
        </Text>
        <IconButton
          aria-label="New Group"
          as={Link}
          to={ROUTE_CONSTANTS.DASHBOARD.THIRD_PARTIES.CREATE.ABSOLUTE}
          ml="4"
          icon={<Icon path={mdiPlus} size="28px" />}
          colorScheme="primary"
          variant="outline"
          size="sm"
        />
      </Box>
      <Box
        mb="4"
        mt="4"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {!loading && (
          <SearchInput
            searchPlaceholder={t("actions.search")}
            value={searchQuery.query}
            onSearchInput={search}
          />
        )}
      </Box>
      {loading ? (
        <Center minHeight="200px">
          <SPSpinner size="lg" />
        </Center>
      ) : (
        <TableContainer background="#fff" borderRadius="8px" padding={4}>
          {!showNoResultFound ? (
            <Table size="md" border="1px solid #EEEEEE">
              <Thead pt={2} pb={2} bg="#E6E6E633">
                <Tr>
                  <Th>{t("fields.nameEn")}</Th>
                  <Th>{t("fields.domain")}</Th>
                  <Th>{t("fields.contactNo")}</Th>
                  <Th>{t("fields.createdAt")}</Th>
                  <Th>{t("fields.enableDisable")}</Th>
                  <Th>Updated API key date</Th>
                  <Th>{t("fields.OTP")}</Th>
                  <Th>{t("fields.action")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {thirdPartyList?.map((item, index) => (
                  <ThirdPartyRow key={index} item={item} />
                ))}
              </Tbody>
            </Table>
          ) : (
            <NotFoundResult />
          )}
          <Pagination
            entityTitle="Third Parties"
            pageTotalCount={thirdPartyData?.totalCount || 0}
            perPageItems={searchQuery.size}
            passPaginateValues={handlePassPaginateValues}
          />
        </TableContainer>
      )}
    </>
  );
};

export default ThirdPartiesList;
