import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { IGroupListItem, IGroupZonesList } from "src/api/types/groups";
import { panelGroupsService } from "src/api/services/groups";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import {
  Box,
  Center,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import "./groupZonesModal.css";
import SPSpinner from "../SPSpinner";
import { useAsync } from "src/hooks/useAsync";
import { useAbortController } from "src/hooks/useAbortController";
import { useNavigate } from "react-router-dom";

interface IGroupZonesModalProps {
  data: IGroupListItem;
  closeModal: (clearModals: boolean) => void;
}

const GroupZonesModal: React.FC<IGroupZonesModalProps> = ({
  data,
  closeModal,
}) => {
  const { getAbortSignal } = useAbortController();
  const navigate = useNavigate();

  const {
    run: fetchZonesGroup,
    isLoading,
    data: groupsData,
  } = useAsync<IGroupZonesList[], any>(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await panelGroupsService.getZonesGroup(
          data.id,
          getAbortSignal("fetchZonesGroup").signal
        );
        resolve(response.data.data.items as IGroupZonesList[]);
      } catch (e) {
        reject([]);
      }
    });
  });

  useEffect(() => {
    fetchZonesGroup({});
  }, []);

  const renderZonesList = () => {
    if (isLoading) {
      return (
        <Center>
          <SPSpinner size="xl" />
        </Center>
      );
    }

    return groupsData?.length ? (
      <Box className="zones_container">
        {groupsData?.map((zone: IGroupZonesList) => {
          return (
            <Text
              key={zone.id}
              color="blue.400"
              className="zone_item"
              onClick={() => {
                closeModal(true);
                navigate(
                  ROUTE_CONSTANTS.DASHBOARD.ZONES.DETAILS.BY_DATA(zone.id)
                    .ABSOLUTE
                );
              }}
            >
              {zone.nameEn}
            </Text>
          );
        })}
      </Box>
    ) : (
      <Text>No zones found for this group!</Text>
    );
  };

  return (
    <Modal isOpen={true} onClose={() => closeModal(true)} size="2xl">
      <ModalOverlay />
      <ModalContent p="4">
        <Box mb="4">
          <Text as="h3" textAlign="center">
            <Text as="span" fontWeight="500" fontSize="1rem">
              {data.nameEn} zones {!isLoading && `(${groupsData?.length})`}
            </Text>
          </Text>
          <Text
            as="span"
            color="gray"
            cursor="pointer"
            onClick={() => closeModal(true)}
            className="closeModal"
          >
            <Icon path={mdiClose} size="22px" />
          </Text>
        </Box>
        {renderZonesList()}
      </ModalContent>
    </Modal>
  );
};

export default GroupZonesModal;
