import { useState } from "react";

export function useToggle(initial: boolean) {
  const [active, setIsActive] = useState(initial);

  function toggle() {
    setIsActive((prev) => !prev);
  }

  return { active, toggle, setIsActive };
}
