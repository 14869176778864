import {string, object, number} from "yup";

const stringValidation = (field: string, t: any) => {
    return string()
        .required(
            t("validationMessages.fieldIsRequired", {
                field,
            })
        )
        .max(
            128,
            t("validationMessages.maxLengthValidation", {
                field,
                validLength: 128
            })
        )
        .min(
            1,
            t("validationMessages.minumuemCharacter", {
                field,
            })
        );
};

const numberValidation = (field: string, t: any) => {
    return number()
        .positive(`${field} must be Positive`)
        .required(t("validationMessages.fieldIsRequired", {field}))
        .typeError(t("validationMessages.beNumber", {field}));
};
export const newTargetGroupValidationSchema = (t: any) =>
    object().shape({
        name: stringValidation(t("fields.name"), t),
        veryGood: numberValidation(t("fields.veryGood"), t),
        good: numberValidation(t("fields.good"), t),
        dailyTarget: numberValidation(t("fields.dailyTarget"), t),
        bad: numberValidation(t("fields.bad"), t),
        veryBad: numberValidation(t("fields.veryBad"), t),
        veryGoodFills: numberValidation(t("fields.veryGoodFills"), t),
        goodFills: numberValidation(t("fields.goodFills"), t),
        dailyTargetFills: numberValidation(t("fields.dailyTargetFills"), t),
    });
