import { Box, Button, ModalFooter } from "@chakra-ui/react";
import React from "react";
import HorizontalLoading from "src/components/app/HorizontalLoading";

interface IFileUploaderActionsProps {
    isUploading:boolean;
    disabledSubmit:boolean;
    handleUploadFile:()=>void;
    handleCloseModal:()=>void;
}

export const FileUploaderActions:React.FC<IFileUploaderActionsProps> = ({isUploading , disabledSubmit , handleUploadFile , handleCloseModal}) => {
    return <ModalFooter pt="1">
    {!isUploading &&
        <Box>
            <Button colorScheme='blue' disabled={disabledSubmit} mr={3} onClick={handleUploadFile}>Upload</Button>
            <Button onClick={() => handleCloseModal()}>Cancel</Button>
        </Box>
    }
    {isUploading && <HorizontalLoading />}
</ModalFooter>
}