import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import Icon from "@mdi/react";
import {
  Box,
  Button,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  mdiBicycle,
  mdiCar,
  mdiPencil,
  mdiPlus,
  mdiTrayArrowDown,
} from "@mdi/js";
import { useTranslation } from "react-i18next";
import { exportFile } from "src/utils/export-file";
import { ApiConfig } from "src/api/config";
import { API_ROUTES } from "src/constants/api-routes";
import { DEFAULT_COUNT } from "src/constants/api-default-values";
import dateHelper from "src/utils/date-helper";
import HorizontalLoading from "src/components/app/HorizontalLoading";
import { panelVehiclesServices } from "src/api/services/vehicle";
import { IVehicleListItem, VehicleType } from "src/api/types/vehicle";
import { Pagination } from "src/components/app/Pagination";
import { NotFoundResult } from "src/components/app/NotFoundResult";
import { useTitle } from "src/hooks/useTitle";
import { SearchInput } from "src/components/app/SearchInput";
import { useSearch } from "src/hooks/useSearch";
import { modalActions } from "src/global-context/modals";
import { MODAL_TYPES } from "src/types/modals";

const Vehicles: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.vehicles"));

  const [fetchVehicleController] = useState<AbortController>(
    new AbortController()
  );
  const [loading, setLoading] = useState<boolean>(false);
  const exportButton = useRef<any>();
  const [vehicles, setVehicles] = useState<IVehicleListItem[]>([]);
  const [pageTotalCount, setPageTotalCount] = useState<number>(0);
  const [showNoResultFound, setShowNoResultFound] = useState<boolean>(false);

  const { searchQuery, updateSearchParameters } = useSearch({
    isStoreOnLocalStorage: false,
    entity: "Vehicle",
    fields: { query: "", size: 10, offset: 0 },
  });

  const getAllVehicles = async (
    offset: number,
    size: number,
    query: string = searchQuery.query
  ) => {
    try {
      setLoading(true);
      const result = await panelVehiclesServices.getAll(
        offset,
        size,
        fetchVehicleController.signal,
        query
      );
      setVehicles(result.data.data.items);
      setPageTotalCount(result.data.data.totalCount);
      result.data.data.totalCount > 0
        ? setShowNoResultFound(false)
        : setShowNoResultFound(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllVehicles(searchQuery.offset, searchQuery.size, searchQuery.query);
  }, []);

  const search = (searched: string) => {
    updateSearchParameters({
      query: searched.toLocaleLowerCase().trim(),
      offset: 0,
    });
    getAllVehicles(0, searchQuery.size, searched.toLocaleLowerCase().trim());
  };

  const handlePassPaginateValues = (offset: number, size: number) => {
    updateSearchParameters({ offset: offset, size: size });
    getAllVehicles(offset, size);
  };

  const exportCSV = () => {
    exportFile(
      ApiConfig.baseURL + API_ROUTES.VEHICLES.EXPORT,
      {
        count: "" + DEFAULT_COUNT,
        keyword: "" + searchQuery.query,
      },
      exportButton,
      `Vehicles-${dateHelper.getToday()}`
    );
  };

  const showVehicleType = (type: string) => {
    if (type === VehicleType.Bike) {
      return (
        <Text display="flex" alignItems="flex-end">
          <Icon color="#999" path={mdiBicycle} size="27px" />
          <Text as="span" ml="2">
            {type}
          </Text>
        </Text>
      );
    }

    return (
      <Text display="flex" alignItems="flex-end">
        <Icon color="#999" path={mdiCar} size="25px" />
        <Text as="span" ml="2">
          {type}
        </Text>
      </Text>
    );
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Text as="h3" fontSize="3xl">
          {t("titles.vehicles")}
        </Text>
        <IconButton
          aria-label="New Vehicle"
          as={Link}
          to={ROUTE_CONSTANTS.DASHBOARD.VEHICLES.CREATE.ABSOLUTE}
          ml="4"
          icon={<Icon path={mdiPlus} size="28px" />}
          colorScheme="primary"
          variant="outline"
          size="sm"
        />
      </Box>
      <Box
        mb="4"
        mt="4"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SearchInput
          searchPlaceholder={t("actions.search")}
          value={searchQuery.query}
          onSearchInput={search}
        />
        <Box>
          <Button
            colorScheme="blackAlpha"
            ml="2"
            pr="20px"
            pl="20px"
            onClick={() =>
              modalActions.addModal(MODAL_TYPES.BULK_UPLOAD_MODAL, {
                bulkUploadApiUrl: API_ROUTES.RIDERS.BULK_INSERT,
                title: "Vehicle",
                maxValidSize: "50-KB",
              })
            }
          >
            Bulk Upload
          </Button>
          <Button
            bg="#F39905"
            color="#333"
            ml="2"
            pr="20px"
            pl="20px"
            ref={exportButton}
            onClick={() => exportCSV()}
          >
            <Icon path={mdiTrayArrowDown} size="25px" color="#333" />
            &nbsp;Export
          </Button>
        </Box>
      </Box>
      {/* Horizontal Loading */}
      <Box height="5px" mb="1">
        {loading && <HorizontalLoading />}
      </Box>
      {/* Vehicles List */}
      <Box className="table_container">
        {!showNoResultFound ? (
          <Table
            variant="simple"
            colorScheme="blackAlpha"
            size="md"
            boxShadow="md"
            backgroundColor="white"
            borderRadius="md"
          >
            <Thead>
              <Tr>
                <Th>Vehicle Type</Th>
                <Th>Plate No</Th>
                <Th>Chassis No</Th>
                <Th>Rider</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {vehicles.map((vehicle) => {
                return (
                  <Tr key={vehicle.id}>
                    <Td>{showVehicleType(vehicle.vehicleType)}</Td>
                    <Td>{vehicle.plateNumber}</Td>
                    <Td>{vehicle.chassis}</Td>
                    <Td>
                      {vehicle.rider
                        ? `${vehicle.rider?.firstName} ${vehicle.rider?.lastName}`
                        : "-"}
                    </Td>
                    <Td>
                      <IconButton
                        aria-label="Edit Vehicle"
                        icon={<Icon path={mdiPencil} size="28px" />}
                        color="gray.100"
                        bg="blue.300"
                        as={Link}
                        to={
                          ROUTE_CONSTANTS.DASHBOARD.VEHICLES.UPDATE.BY_DATA(
                            vehicle.id
                          ).ABSOLUTE
                        }
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        ) : (
          <NotFoundResult />
        )}
      </Box>

      <Pagination
        entityTitle="Vehicle"
        pageTotalCount={pageTotalCount}
        perPageItems={searchQuery.size}
        passPaginateValues={handlePassPaginateValues}
      />
    </Box>
  );
};

export default Vehicles;
