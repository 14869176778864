import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAxiosResponse } from "../types/axios-response";
import { IRidersListPayload, RiderSorts } from "../types/riders";
import { DEFAULT_COUNT } from "src/constants/api-default-values";
import { number } from "yup";

export const panelZoneRidersService = {
  getRiders(zoneId: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<IRidersListPayload>>(
      `${API_ROUTES.ZONES.RIDERS(zoneId)}?count=${DEFAULT_COUNT}`,
      { signal: abortSignal }
    );
  },
  deleteRiders(zoneId: string, riderIds: string[]) {
    return AxiosInstance.deleteByBody<IAxiosResponse<{}>>(
      `${API_ROUTES.ZONES.RIDERS(zoneId)}`,
      {
        riderIds,
      }
    );
  },
  addRiders(zoneId: string, riderIds: string[]) {
    return AxiosInstance.post<IAxiosResponse<{}>>(
      `${API_ROUTES.ZONES.RIDERS(zoneId)}`,
      {
        riderIds,
      }
    );
  },
  getNotOwnedRiders(
    zoneId: string,
    onlyActive: boolean,
    sort: RiderSorts,
    abortSignal: AbortSignal,
    offset: number = 0,
    keyword?: string
  ) {
    let url = `${
      API_ROUTES.RIDERS.ROOT
    }?count=${50}&onlyActive=${onlyActive}&ExceptZoneId=${zoneId}&sort=${sort}&offset=${offset}`;
    if (keyword) url += `&keyword=${keyword}`;
    return AxiosInstance.get<IAxiosResponse<IRidersListPayload>>(url, {
      signal: abortSignal,
    });
  },
};
