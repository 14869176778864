import { Input } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useUserManagementData } from "../UserManagementProvider";

const SearchUser:React.FC = () => {
    const { setSearchKeyword } = useUserManagementData();
    const [keyword, setKeyword] = useState<string>("");

    useEffect(() => {
        let keywordTimeout: ReturnType<typeof setTimeout>;
        keywordTimeout = setTimeout(() => {
            setSearchKeyword(keyword);
        }, 500); 

        return () => clearTimeout(keywordTimeout);
    }, [keyword]);

    return <Input placeholder="Search" size="md" borderRadius="8px" bg="#fff" maxWidth="360px" 
            onChange={(e) => setKeyword(e.target.value)}/>;
}

export default SearchUser;