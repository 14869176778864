import { mdiChevronRight, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import { Riders } from "./components/Riders";
import Zones from "./components/Zones";
import { ExtendedLiveMapProvider } from "./ExtendedLiveMapContext";
import Map from "./components/Map";
import "./ExtendedLiveMap.css";
import MapStatistics from "./components/MapStatistics";

export const ExtendedLiveMap:React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.liveMap"));
    const [showRiders, setShowRiders] = useState<boolean>(true);

    const toggleOpenRiders = (value: boolean) => {
        setShowRiders(value);
    }
    
    return <ExtendedLiveMapProvider>
        <div className={clsx("live-map-container", showRiders && "open")}>
            {
                !showRiders && <span className="riders-handler" onClick={() => { toggleOpenRiders(true) }}>
                    <Icon path={mdiChevronRight} size="24px" />
                    Riders
                </span>
            }
            <div className="l-m-left-panel">
                <span className="close-riders-handler" onClick={() => { toggleOpenRiders(false) }}>
                    <Icon path={mdiClose} size="24px" />
                </span>
                <Zones/>
                <Riders/>
            </div>
            <div className="l-m-map-box">
                <MapStatistics />
                <Map />
            </div>
        </div>
    </ExtendedLiveMapProvider>
}