import React, { ChangeEvent } from "react";
import { Box, Input, ModalBody , Text } from "@chakra-ui/react";
import { mdiCloudUpload } from "@mdi/js";
import Icon from "@mdi/react";
import { ChoosenFileBox } from "../ChoosenFileBox";
import { ShowFileExceptions } from "../ShowFileExceptions";
import { calcAndShowFileSize } from "src/utils/file-size-helper";

interface IBulkFileUploaderProps {
    wasSelectedFile:boolean;
    handleChooseFile:(e:ChangeEvent<HTMLInputElement>)=>void;
    isUploading:boolean;
    removeSelectedFile:()=>void;
    selectedFile:File | null;
    exceptionMessage:string[];
    helpMessages:string[];
    maxValidSize:string;
}

export const BulkFileUploader:React.FC<IBulkFileUploaderProps> = (props) => {
    const {wasSelectedFile , handleChooseFile , isUploading , removeSelectedFile , selectedFile , exceptionMessage , helpMessages , maxValidSize} = props;

    const renderHelps = () => {
        let liItems:any[] = [];
        helpMessages.forEach((msg , ind) => {
            liItems.push(<li key={ind}>{msg}</li>);
        });

        return <>
            <Text fontSize="0.875rem" fontWeight="500">Note:</Text>
            <ul style={{marginLeft:"16px"}}>{liItems}</ul>
        </>
    }

    return <ModalBody className="bulkFileUploaderContainer">
    {!wasSelectedFile &&
        <>
            <label className="bulkFileUploader">
            <Input type="file" accept=".csv" onChange={handleChooseFile} />
            <Box className="uploader" color="#ccc">
                <Icon size="60px" path={mdiCloudUpload} />
                <Box mt="2" fontSize="1.2rem" textAlign="center">Browse CSV file to upload<Text fontSize="0.8em">(MAX {calcAndShowFileSize(maxValidSize).viewForm})</Text></Box>
            </Box>
            </label>
            {helpMessages.length > 0 && <Box className="helps" mt="2" fontSize="0.775rem" color="#555">{renderHelps()}</Box>}
        </>
    }
    {wasSelectedFile && <ChoosenFileBox fileName={selectedFile?.name!} isUploading={isUploading} removeSelectedFile={removeSelectedFile}/>}
    {exceptionMessage.length > 0 && <ShowFileExceptions exceptionMessage={exceptionMessage}/>}
</ModalBody>
}