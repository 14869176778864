import React from "react";
import {Coords} from "google-map-react";
import { ZoneObjectIcon } from "./ZoneObjectIcon";
import "./ZoneMarker.css"

interface IDefaultMarkerProps extends Coords{
}

export const ZoneMarker: React.FC<IDefaultMarkerProps> = () => {
    return <div className="l-m-zone-marker">
        <ZoneObjectIcon />
    </div>
}