import { object, ref, string } from "yup"
export const userResetPasswordValidation = (t: any) => 
    object().shape({
        rawPassword: string().trim()
            .required(t("validationMessages.fieldIsRequired",{field:t("fields.password")}))
            .test("len", t("validationMessages.minAndMaxLengthValidation", {field: t("fields.password"), min:6, max:8}), 
                val => val!.length >= 6 && val!.length <= 8),
        confirmPassword: string().trim()
            .required(t("validationMessages.fieldIsRequired",{field:t("fields.confirmPassword")}))
            .oneOf([ref('rawPassword'), null], t("validationMessages.passwordsNotMatch"))
    });

