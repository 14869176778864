import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, Select, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { IBaseVehicleItem, IVehicleListItem, IVehicleOfCreateForm, VehicleType } from "src/api/types/vehicle";
import ContentView, { ContentViewBody, ContentViewHeader } from "src/components/app/ContentView";
import FormRow from "src/components/app/FormRow";
import { useTitle } from "src/hooks/useTitle";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { vehicleSchema } from "src/validation-schemas/vehicle";
import FormHelper from "src/utils/form-helper";
import { panelVehiclesServices } from "src/api/services/vehicle";
import { toast } from "src/utils/toast";
import { BrowserBack } from "src/components/app/BrowserBack";
import SPSpinner from "src/components/app/SPSpinner";


const CreateVehicle: React.FC = () => {
  const formHelperInstance = new FormHelper();
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  useTitle((params.id) ? t("pageTitles.editVehicle") : t("pageTitles.newVehicle"));
  const navigate = useNavigate();

  const [editable, setEditable] = useState<boolean>(false);
  const [, setExistingData] = useState<IVehicleListItem>();
  const [loading, setLoading] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [customErrors, setCustomErrors] = useState<Record<string, string>>({
    plateNumber_left: "",
    plateNumber_right: "",
    chassis: "",
  });

  const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({
    resolver: yupResolver(vehicleSchema(t)),
    mode: "all",
    defaultValues: {
      plateNumber_left: "",
      plateNumber_right: "",
      chassis: "",
      vehicleType: VehicleType.Car
    }
  });

  const getVehicleDetails = useCallback(async () => {
    try {
      setLoading(true);
      const result = await panelVehiclesServices.get(params.id!);
      const { vehicleType, plateNumber, chassis } = result.data.data;
      setExistingData(result.data.data);
      reset({
        vehicleType,
        plateNumber_left: plateNumber.split("/")[0],
        plateNumber_right: plateNumber.split("/")[1],
        chassis
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (params.id) {
      setEditable(true);
      getVehicleDetails();
    }
  }, [getVehicleDetails, params]);

  const renderVehicleTypesSelect = () => {
    let opt: any[] = [];
    Object.keys(VehicleType).forEach((key, index) => {
      opt.push(<option value={key} key={index}>{key}</option>);
    })

    return <Select {...register("vehicleType")}>{opt}</Select>
  }

  const onSubmit = async (data: IVehicleOfCreateForm) => {
    setDisableSubmit(true);
    if (customErrors.plateNumber_left || customErrors.plateNumber_right || customErrors.chassis) {
      return;
    }

    let suitableData: IBaseVehicleItem = {
      vehicleType: data.vehicleType,
      plateNumber: `${data.plateNumber_left}/${data.plateNumber_right}`,
      chassis: data.chassis
    };

    try {
      if (editable) {
        await panelVehiclesServices.update(params.id!, suitableData);
      } else {
        await panelVehiclesServices.create(suitableData);
      }
      toast.success(t(editable ? "messages.vehicleUpdatedSuccessfully" : "messages.vehicleCreatedSuccessfully"));
      editable ? navigate(-1) : navigate(ROUTE_CONSTANTS.DASHBOARD.VEHICLES.ROOT.ABSOLUTE);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setDisableSubmit(false);
    }
  }

  const onPlateNumberBlur = () => {
    let left_plateNumber_value = getValues("plateNumber_left");
    let right_plateNumber_value = getValues("plateNumber_right");

    if (left_plateNumber_value.length > 2 || right_plateNumber_value.length > 5) {
      setCustomErrors({ ...customErrors, plateNumber_left: "Plate Number pattern is not valid" });
    } else {
      setCustomErrors({ ...customErrors, plateNumber_left: "" });
    }
  }

  const showPlateNumberCustomErrors = () => {
    if (customErrors.plateNumber_left || customErrors.plateNumber_right) {
      return customErrors.plateNumber_left || customErrors.plateNumber_right;
    } else {
      return !!errors.plateNumber_left ? errors.plateNumber_left?.message : !!errors.plateNumber_right && errors.plateNumber_right?.message;
    }
  }

  return (
    <ContentView>
      <ContentViewHeader>
        <Box display="flex" alignItems="center">
          <Text as="h3" fontSize="3xl">
            {t(editable ? "titles.editVehicle" : "titles.newVehicle")}
          </Text>
          <BrowserBack />
        </Box>
      </ContentViewHeader>
      <ContentViewBody>
        {
          loading
            ? <Box display="flex" justifyContent="center">
              <SPSpinner size="xl" />
            </Box>
            : <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <FormRow
                bidirectional
                rightContent={null}
                leftContent={
                  <FormControl isRequired>
                    <FormLabel htmlFor="riderType">{t("fields.riderType")}</FormLabel>
                    {renderVehicleTypesSelect()}
                  </FormControl>
                } />
              <FormRow
                bidirectional
                rightContent={null}
                leftContent={
                  <FormControl isRequired>
                    <FormLabel htmlFor="plateNumber">{t("fields.plateNumber")}</FormLabel>
                    <FormControl display="flex" alignItems="flex-end">
                      <FormControl isInvalid={!!errors.plateNumber_left} isRequired flex="0 0 70px">
                        <InputGroup display="flex" alignItems="center">
                          <Input id="plateNumber_left"
                            type="number" size="lg"
                            {...register("plateNumber_left")}
                            onInput={(e: ChangeEvent<HTMLInputElement>) => formHelperInstance.limitInputLength(e, 2).preventSpace(e)}
                            onBlur={onPlateNumberBlur} />
                        </InputGroup>
                      </FormControl>
                      <Text as="span" mr={2} ml={2} mb={3}> / </Text>
                      <FormControl isInvalid={!!errors.plateNumber_right} isRequired>
                        <InputGroup display="flex" alignItems="center">
                          <Input id="plateNumber_right"
                            type="number" size="lg"
                            {...register("plateNumber_right")}
                            onInput={(e: ChangeEvent<HTMLInputElement>) => formHelperInstance.limitInputLength(e, 5).preventSpace(e)}
                            onBlur={onPlateNumberBlur} />
                        </InputGroup>
                      </FormControl>
                    </FormControl>
                    <Box color="#E53E3E" mt={1} fontSize="0.875rem">
                      {showPlateNumberCustomErrors()}
                    </Box>
                  </FormControl>
                }
              />
              <FormRow
                bidirectional
                rightContent={null}
                leftContent={
                  <FormControl isInvalid={!!errors.chassis} isRequired>
                    <FormLabel htmlFor="chassis">{t("fields.chassisNumber")}</FormLabel>
                    <InputGroup display="flex" alignItems="center">
                      <Input id="chassis"
                        type="text" size="lg"
                        autoComplete="off"
                        {...register("chassis")}
                        onInput={(e: ChangeEvent<HTMLInputElement>) => formHelperInstance.limitInputLength(e, 17).preventSpecialCharacters(e)} />
                    </InputGroup>
                    <FormErrorMessage>{!!errors.chassis && errors.chassis?.message}</FormErrorMessage>
                  </FormControl>
                }
              />
              <Box textAlign="right">
                <Button onClick={() => editable ? navigate(-1) : navigate(ROUTE_CONSTANTS.DASHBOARD.VEHICLES.ROOT.ABSOLUTE)}
                  size="lg" colorScheme="orange" mr="4" disabled={disableSubmit}>{t("actions.cancel")}</Button>
                <Button colorScheme="primary" size="lg" type="submit" disabled={disableSubmit}>{t("actions.save")}</Button>
              </Box>
            </form>
        }

      </ContentViewBody>
    </ContentView>
  );
}

export default CreateVehicle;