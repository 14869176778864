import { FormControl, FormLabel, Switch, Text } from "@chakra-ui/react";
import { useState } from "react";
import { IZoneListItem } from "src/api/types/zones";
import RiderSwitchConfirmationModal from "../ZoneSwitchOTPConfirmationModal";

interface IRiderSwitchStateProps {
    zoneDetail: IZoneListItem;
    handleUpdateDetails: () => void;
}

const ZoneSwitchOTPState: React.FC<IRiderSwitchStateProps> = ({ zoneDetail, handleUpdateDetails }) => {
    const { hasOtp } = zoneDetail;
    const [openSwitchConfirmationModal, setOpenSwitchConfirmationModal] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(hasOtp);

    const handleConfirmChangeState = (updatedState: boolean) => {
        setIsChecked(updatedState);
        handleUpdateDetails();
    }

    return <>
        <FormControl display="flex" alignItems="center" mb="6">
            <FormLabel htmlFor="otp-status" mb="0">
                {hasOtp ? <Text color="yellow.500">OTP Enable</Text> : <Text>OTP Disable</Text>}
            </FormLabel>
            <Switch id="otp-status" colorScheme={hasOtp ? "yellow" : "gray"} onChange={() => setOpenSwitchConfirmationModal(true)} isChecked={isChecked} />
        </FormControl>
        {
            openSwitchConfirmationModal &&
            <RiderSwitchConfirmationModal
                zoneDetail={zoneDetail}
                handleConfirmChangeState={handleConfirmChangeState}
                openSwitchConfirmationModal={openSwitchConfirmationModal}
                setOpenSwitchConfirmationModal={setOpenSwitchConfirmationModal} />
        }
    </>
}

export default ZoneSwitchOTPState;