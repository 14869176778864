export enum ExtendedLiveMapActionKind {
    SET_ZONES = "SET_ZONES",
    SET_ACTIVE_ZONE = "SET_ACTIVE_ZONE",
    SET_LOCATION_LOGS = "SET_LOCATION_LOGS",
    SET_ACTIVE_LOCATION_LOG = "SET_ACTIVE_LOCATION_LOG",
    SET_LOADING_RIDERS = "SET_LOADING_RIDERS",
    SET_MAP_CENTER = "SET_MAP_CENTER",
    SET_ACTIVE_LOCATIONLOG_BY_RIDERID = "SET_ACTIVE_LOCATIONLOG_BY_RIDERID",
}

export interface ExtendedLiveMapAction<T> {
    type: ExtendedLiveMapActionKind;
    data?: T;
}