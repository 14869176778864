import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Chart, ArcElement, Tooltip } from "chart.js";
import { Doughnut, } from "react-chartjs-2";
import { CHART_BG_COLORS, CHART_DEFAULT_COUNT } from "src/constants/chart";
import { ITopZonesKeyValues } from "src/api/types/dashboard";
import "./styles.css";

interface IDashboardChartProps {
  chartType: "TopZonesPerRiders" | "TopZonesPerTime";
  items: ITopZonesKeyValues[];
  title: {
    main: string;
    sub: string;
  }
}

const DashboardChart: React.FC<IDashboardChartProps> = ({ chartType, items, title }) => {
  Chart.register(ArcElement, Tooltip);

  const renderChartData = () => {
    const labels = [...items.map(item => item.key)];
    const values = [...items.map(item => item.value)];

    return {
      labels: labels,
      datasets: [{
        label: chartType,
        data: values,
        backgroundColor: CHART_BG_COLORS.slice(0, CHART_DEFAULT_COUNT),
      }]
    };
  }

  const renderDataBox = () => {
    return items.map((tz, ind) => {
      let itemValue: number | string = tz.value;
      if (chartType === "TopZonesPerTime") {
        itemValue = +Math.round((tz.value / 3600)) + "h";
      }
      return <Box key={ind} className="item">
        <Box className="bullet" bg={CHART_BG_COLORS[ind]}></Box>
        <Text fontSize="0.9em" flex="0 1 auto" className="text_overflow">{tz.key}</Text>
        <Text fontSize="0.9em" flex="1" color="#999">({itemValue})</Text>
      </Box>
    });
  }

  return <Box>
    <Box mb={5}>
      <Box className="value">{title.main}</Box>
      <Box className="title">({title.sub})</Box>
    </Box>
    <Box className="chartInfoContainer">
      <Box className="chart">
        <Doughnut
          height="100%"
          width="auto"
          data={renderChartData()}
          options={{
            elements: {
              arc: {
                borderWidth: 1
              }
            },
            responsive: true,
            aspectRatio: 1,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
                position: 'top'
              },
              tooltip: {
                enabled: true,
                backgroundColor: "#fff",
                bodyColor: "#000",
                borderColor: "#ccc",
                borderWidth: 1,
                callbacks: {
                  label: function (context) {
                    let label = context.dataset.label || '';
                    if (label === "TopZonesPerTime") {
                      return `${context.label} (${+Math.round((context.parsed / 3600))}h)`;
                    }

                    return `${context.label} (${context.parsed})`;
                  }
                }
              }
            }
          }} />
      </Box>
      <Box className="info">
        {renderDataBox()}
      </Box>
    </Box>
  </Box>
}

export default DashboardChart;