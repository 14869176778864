import { AxiosResponse } from "axios";
import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "..";
import { IAxiosResponse } from "../types/axios-response";
import {
  IAdminOrderFormData,
  IAdminOrderInfo,
  IEligibleRidersItems,
  IOngoingOrdersPayload,
  IOrderHistoriesPayload,
  IOrderInfoPayload,
  IOrderType,
  IPendingOrdersPayload,
  IUpdateOrderData,
  TOrderStatus,
} from "../types/orders";

export const ordersService = {
  getOngoingOrders(
    {
      offset,
      count,
      fromDate,
      toDate,
      groupIds,
    }: {
      offset: number;
      count: number;
      fromDate: string;
      toDate: string;
      groupIds?: string;
    },
    abortSignal: AbortSignal
  ) {
    let query = `count=${count}&offset=${offset}&FromDate=${encodeURIComponent(
      fromDate
    )}&ToDate=${encodeURIComponent(toDate)}`;
    query += groupIds ? `&${groupIds}` : "";

    return AxiosInstance.get<AxiosResponse<IOngoingOrdersPayload>>(
      `${API_ROUTES.ORDERS.GET_ONGOING_ORDERS}?${query}`,
      { signal: abortSignal }
    );
  },
  getEligibleRiders(
    id: string,
    offset: number,
    count: number,
    abortSignal: AbortSignal
  ) {
    return AxiosInstance.get<AxiosResponse<IEligibleRidersItems>>(
      `${API_ROUTES.ORDERS.GET_ELIGIBLE_RIDERS(
        id
      )}?count=${count}&offset=${offset}`,
      { signal: abortSignal }
    );
  },
  getOrderHistories(
    {
      offset,
      count,
      toDate,
      groupIds,
      orderType,
      registeredDateRecently,
      riderIds,
      workDate,
    }: {
      offset: number;
      count: number;
      toDate: string;
      workDate: string;
      groupIds?: string;
      orderType?: string;
      registeredDateRecently?: string;
      riderIds: string;
    },
    abortSignal: AbortSignal
  ) {
    let query = `count=${count}&offset=${offset}&toDate=${encodeURIComponent(
      toDate
    )}`;
    query += groupIds ? `&${groupIds}` : "";
    query += orderType ? `&${orderType}` : "";
    query += registeredDateRecently ? `&${registeredDateRecently}` : "";
    query += riderIds ? `&${riderIds}` : "";
    query += workDate ? `&WorkDate=${encodeURIComponent(workDate)}` : "";
    return AxiosInstance.get<AxiosResponse<IOrderHistoriesPayload>>(
      `${API_ROUTES.ORDERS.GET_ORDER_HISTORIES}?${query}`,
      { signal: abortSignal }
    );
  },
  getOrdersByStatusCount(statusId: TOrderStatus, abortSignal: AbortSignal) {
    let query = `statusId=${statusId}`;
    return AxiosInstance.get<AxiosResponse<number>>(
      `${API_ROUTES.ORDERS.GET_ORDERS_BY_STATUS_COUNT}?${query}`,
      { signal: abortSignal }
    );
  },
  getOrdersByStatus(
    {
      offset,
      count,
      fromDate,
      toDate,
      statusId,
    }: {
      offset: number;
      count: number;
      fromDate: string;
      toDate: string;
      statusId: TOrderStatus;
    },
    abortSignal: AbortSignal
  ) {
    let query = `count=${count}&offset=${offset}&FromDate=${encodeURIComponent(
      fromDate
    )}&ToDate=${encodeURIComponent(toDate)}&statusId=${statusId}`;
    return AxiosInstance.get<AxiosResponse<IPendingOrdersPayload>>(
      `${API_ROUTES.ORDERS.GET_ORDERS_BY_STATUS}?${query}`,
      { signal: abortSignal }
    );
  },
  getOrderById(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<AxiosResponse<IOrderInfoPayload>>(
      `${API_ROUTES.ORDERS.GET_ORDER_BY_ID(id)}`,
      { signal: abortSignal }
    );
  },
  updateOrder(id: string, data: IUpdateOrderData, abortSignal: AbortSignal) {
    return AxiosInstance.put<AxiosResponse<any>>(
      `${API_ROUTES.ORDERS.UPDATE_ORDER(id)}`,
      data,
      { signal: abortSignal }
    );
  },
  createOrderByAdmin(data: IAdminOrderFormData, abortSignal: AbortSignal) {
    return AxiosInstance.post<AxiosResponse<string>>(
      `${API_ROUTES.ORDERS.CREATE_ORDER_BY_ADMIN}`,
      data,
      { signal: abortSignal }
    );
  },
  getAdminOrder(orderId: string) {
    return AxiosInstance.get<IAxiosResponse<IAdminOrderInfo>>(
      `${API_ROUTES.ORDERS.GET_ADMIN_ORDER(orderId)}`
    );
  },
  updateOrderByAdmin(id: string, data: IAdminOrderFormData) {
    return AxiosInstance.put<IAxiosResponse<string>>(
      `${API_ROUTES.ORDERS.UPDATE_ORDER_BY_ADMIN(id)}`,
      data
    );
  },
};
