import { FormControl, Switch, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { IThirdPartyItem } from "src/types/third-party";
import ConfirmModal from "../ConfirmModal";

interface IEnableDisableButtonProps {
  value: boolean;
  isOTP?: boolean;
  item: IThirdPartyItem;
  handleStatusChange: (status: boolean) => void;
}

const EnableDisableButton: React.FC<IEnableDisableButtonProps> = ({
  isOTP = false,
  item,
  value,
  handleStatusChange,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isChecked, setIsChecked] = useState(value);

  const onStatusChange = () => {
    setShowConfirmModal(false);
    setIsChecked(!isChecked);
    handleStatusChange(isChecked);
  };

  const handleCancel = () => {
    setShowConfirmModal(false);
  };

  return (
    <>
      <FormControl>
        {isOTP ? (
          <Text as="span" fontSize="sm" className="p-3">
            {isChecked ? "Enable" : "Disable"} &nbsp; &nbsp;
          </Text>
        ) : null}
        <Switch
          colorScheme={isChecked ? "yellow" : "gray"}
          onChange={(e) => {
            setShowConfirmModal(true);
          }}
          isChecked={isChecked}
        />
      </FormControl>
      {showConfirmModal && (
        <ConfirmModal
          item={{
            id: item.id,
            name: item.companyNameEn,
            isActive: isChecked,
            isOTP,
          }}
          isOpen={showConfirmModal}
          setIsOpen={setShowConfirmModal}
          onCancel={handleCancel}
          handleStatusChange={onStatusChange}
        />
      )}
    </>
  );
};

export default EnableDisableButton;
