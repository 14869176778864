import { Box, Button, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import React, { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { panelZonesService } from "src/api/services/zones";
import { IZoneListItem } from "src/api/types/zones";
import { toast } from "src/utils/toast";

interface IRiderSwitchConfirmationModalProps {
    zoneDetail: IZoneListItem;
    openSwitchConfirmationModal: boolean;
    setOpenSwitchConfirmationModal: React.Dispatch<SetStateAction<boolean>>;
    handleConfirmChangeState: (newState: boolean) => void;
}

const ZoneSwitchOTPConfirmationModal: React.FC<IRiderSwitchConfirmationModalProps> = (props) => {
    const { zoneDetail, openSwitchConfirmationModal, setOpenSwitchConfirmationModal, handleConfirmChangeState } = props;
    const { hasOtp, id } = zoneDetail;

    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [changeOTPStateAbortController] = useState<AbortController>(new AbortController());

    const handleZoneChangeOTPState = async () => {
        setLoading(true);
        try {
            if (hasOtp) {
                await panelZonesService.disableZoneOTP(id, changeOTPStateAbortController.signal);
                toast.success(t("messages.zoneOTPWasDisabled"));
            } else {
                await panelZonesService.enableZoneOTP(id, changeOTPStateAbortController.signal);
                toast.success(t("messages.zoneOTPWasEnabled"));
            }
            handleConfirmChangeState(!hasOtp);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            setOpenSwitchConfirmationModal(false);
        }
    }

    return <Modal closeOnOverlayClick={false} isOpen={openSwitchConfirmationModal} onClose={() => setOpenSwitchConfirmationModal(false)}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader textAlign="center" as="h3" fontSize="1.1rem" mb="-3" mt="3" fontWeight="500">Are you sure that you want to {hasOtp ? "disable" : "enable"} OTP?</ModalHeader>
            <ModalBody>
                <Box display="flex" mt="4" mb="4" gap="4">
                    <Button flex="1" colorScheme="blackAlpha" ml="2" onClick={() => setOpenSwitchConfirmationModal(false)} isDisabled={loading}>No</Button>
                    <Button flex="1" bg="#FEE4BA" color="#000" onClick={handleZoneChangeOTPState} isLoading={loading}>Yes</Button>
                </Box>
            </ModalBody>
        </ModalContent>
    </Modal>;
}

export default ZoneSwitchOTPConfirmationModal;