import { API_ROUTES } from "src/constants/api-routes";
import { USER_ROLE } from "src/types/user-management";
import { AxiosInstance } from "../index";
import { IAxiosResponse } from "../types/axios-response";
import { ICheckUsernameUniqueness, INewUserData, IUpdateUserData, IUserManagementItem, IUserManagementPayload, IUserResetPassword } from "../types/user-management";

export const panelUserManagementServices = {
    getAll( {offset, count, searchRole, searchKeyword = ""}: {
        offset: number,
        count: number,
        searchRole: USER_ROLE,
        searchKeyword: string
    }, abortSignal:AbortSignal) {
        let keyword = (searchKeyword) ? `&keyword=${searchKeyword}` : "";
        return AxiosInstance.get<IAxiosResponse<IUserManagementPayload>>(`${API_ROUTES.USER_MANAGEMENT.GET_USERS}?role=${searchRole}&count=${count}&offset=${offset}${keyword}`,{signal:abortSignal});
    },
    createUser(data: INewUserData, abortSignal: AbortSignal) {
        return AxiosInstance.post<IAxiosResponse<string>>(`${API_ROUTES.USER_MANAGEMENT.CREATE_USER}`, data, {signal: abortSignal});
    },
    checkUsernameUniqueness(data: ICheckUsernameUniqueness, abortSignal: AbortSignal) {
        return AxiosInstance.post<IAxiosResponse<boolean>>(`${API_ROUTES.USER_MANAGEMENT.CHECK_USERNAME_UNIQUENESS}`, data, {signal: abortSignal});
    },
    getUser(id: string, abortSignal: AbortSignal) {
        return AxiosInstance.get<IAxiosResponse<IUserManagementItem>>(`${API_ROUTES.USER_MANAGEMENT.GET_USER(id)}`, {signal: abortSignal});
    },
    updateUser(id:string, data:IUpdateUserData, abortSignal: AbortSignal ) {
        return AxiosInstance.put<IAxiosResponse<boolean>>(`${API_ROUTES.USER_MANAGEMENT.UPDATE_USER(id)}`, data, {signal: abortSignal})
    },
    toEnableUser(id: string, abortSignal: AbortSignal) {
        return AxiosInstance.put<IAxiosResponse<{}>>(`${API_ROUTES.USER_MANAGEMENT.ENABLE_USER(id)}`, {signal: abortSignal})
    },
    toDisableUser(id: string, abortSignal: AbortSignal) {
        return AxiosInstance.put<IAxiosResponse<{}>>(`${API_ROUTES.USER_MANAGEMENT.DISABLE_USER(id)}`, {signal: abortSignal})
    },
    resetPassword(data: IUserResetPassword, abortSignal: AbortSignal) {
        return AxiosInstance.put<IAxiosResponse<boolean>>(`${API_ROUTES.USER_MANAGEMENT.RESET_PASSWORD}`, data, {signal: abortSignal});
    }
};