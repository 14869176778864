import { Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IuseUserManagementContext, TCountBadge, USER_ROLE, USER_ROLE_TYPES } from "src/types/user-management";
import { contextFactory } from "src/utils/context-factory";

export const [userManagementContext, useUserManagementData] = contextFactory<IuseUserManagementContext>("userManagementContext", "useUserManagementData");

const UserManagementProvider:React.FC = ({children}) => {
    const { t } = useTranslation();

    const [selectedRoleTab, setSelectedRoleTab] = useState<USER_ROLE_TYPES>(USER_ROLE.Admin);
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [triggerRefreshUserList, setTriggerRefreshUserList] = useState<boolean>(false);
    const [counts, setCounts] = useState<TCountBadge>({
        [USER_ROLE.Admin]: 0,
        [USER_ROLE.GroupAgent]: 0,
        [USER_ROLE.ZoneAgent]: 0,
    });

    return <userManagementContext.Provider value={{
            selectedRoleTab,
            setSelectedRoleTab,
            searchKeyword,
            setSearchKeyword,
            triggerRefreshUserList,
            setTriggerRefreshUserList,
            counts,
            setCounts
        }}>
        <>
            <Text as="h3" fontSize="3xl" mb={4}>
                {t("titles.userManagement")}
            </Text>
            {children}
        </>
    </userManagementContext.Provider>;
}
export default UserManagementProvider;