import React, { useEffect, useState } from "react";
import { IOngoingOrders, IOrderStatusEnum, TOrderStatus } from "../../../../../api/types/orders";
import dateHelper from "../../../../../utils/date-helper";
import { Box, Flex } from "@chakra-ui/react";
import Icon from "@mdi/react";
import { mdiTimerOutline } from "@mdi/js";

interface IOnTheWayTimerProps {
    orderStatus: TOrderStatus;
    orderOnTheWayDuration: number;
}

const OnTheWayTimer: React.FC<IOnTheWayTimerProps> = ({ orderStatus, orderOnTheWayDuration }) => {
    let onTheWayTimerInterval: any;
    const [onTheWayTimer, setOnTheWayTimer] = useState<number>(orderOnTheWayDuration);

    const handleOnTheWayTimer = () => {
        onTheWayTimerInterval = setInterval(() => {
            setOnTheWayTimer(preVal => preVal + 60);
        }, 60000);
    }

    useEffect(() => {
        orderStatus === IOrderStatusEnum.PickedUp && handleOnTheWayTimer();
    }, [orderStatus]);

    useEffect(() => {
        return () => {
            clearInterval(onTheWayTimerInterval);
        }
    }, []);

    return <Box display="flex" flexDir="row" alignItems="center">
        <Icon path={mdiTimerOutline} size="20px" />&nbsp;{dateHelper.getDuration(onTheWayTimer, false).substring(0, 5)}
    </Box>
}

export default OnTheWayTimer;