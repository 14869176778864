import imageCompression, { Options } from "browser-image-compression";

export const compressImage = async (file: File, options?: Options) => {
    const comparessionOptions: Options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1500,
        useWebWorker: true,
        fileType: "image/jpeg",
        ...options
    }

    let compressedFile = file;
    if(file.size > 1024 * 1024) {
        // console.log('originalFile instanceof Blob', file instanceof Blob); // true
        console.log(`originalFile size ${+Number((file.size / 1024 / 1024)).toFixed(1)} MB`);
    
        compressedFile = await imageCompression(file as File, comparessionOptions);
    
        // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${+Number((compressedFile.size / 1024 / 1024)).toFixed(1)} MB`); // smaller than maxSizeMB
    } else {
        console.log(`File size is ${+Number((file.size / 1024 / 1024)).toFixed(1)} MB that is smaller that ${comparessionOptions.maxSizeMB} MB.`);
    }

    return compressedFile;
}