import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalContent, ModalOverlay, Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IUserManagementItem } from "src/api/types/user-management";
import { IUpdateUserFormData, USER_ROLE } from "src/types/user-management";
import { useUserManagementData } from "../../UserManagementProvider";
import RoleInputDependency from "../RoleInputDependency";
import { userValidation } from "src/validation-schemas/create-update-user";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { panelUserManagementServices } from "src/api/services/user-management";
import { toast } from "src/utils/toast";

interface IEditUserProps {
    user: IUserManagementItem;
    openEditUserModal: boolean;
    setOpenEditUserModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleUserUpdateInform: () => void;
}

const EditUser:React.FC<IEditUserProps> = ({ user, openEditUserModal, setOpenEditUserModal, handleUserUpdateInform}) => {
    const { t } = useTranslation();
    const roleDependency = user.role === USER_ROLE.Admin ? "" : user.role === USER_ROLE.GroupAgent ? user.groupId : user.zoneId;
    const { selectedRoleTab ,setSelectedRoleTab } = useUserManagementData();
    const [ updateUserAbortController ] = useState<AbortController>(new AbortController());
    const [loading, setLoading] = useState<boolean>(false);
    const methods = useForm<IUpdateUserFormData>({
        resolver: yupResolver(userValidation(t, "update")),
        mode: "all",
        defaultValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            username: user.username,
            roleDependency
        }
    });

    const { register, handleSubmit, watch, setValue, reset, formState: {isValid, errors} } = methods;
    let roleWatch = watch("role");

    useEffect(() => {
        setValue("role", selectedRoleTab);
    }, [selectedRoleTab]);

    useEffect(() => {
        roleWatch !== user.role && setValue("roleDependency", "");
    },[roleWatch, user.role]);
    
    const renderRoleInputDependency = () => {
        if(roleWatch === USER_ROLE.Admin) {
            return <></>;
        }
        return <RoleInputDependency userRoleType={roleWatch} methods={methods}/>
    }

    const onSubmit = async (data: IUpdateUserFormData) => {
        setLoading(true);
        const {roleDependency, ...userFormData} = data;
        userFormData.groupId = null;
        userFormData.zoneId = null;

        if(userFormData.role === USER_ROLE.GroupAgent) {
            userFormData.groupId = data.roleDependency;
            userFormData.zoneId = null;
        } else if(userFormData.role === USER_ROLE.ZoneAgent){
            userFormData.groupId = null;
            userFormData.zoneId = data.roleDependency;
        }
        
        try {
            await panelUserManagementServices.updateUser(user.id, userFormData, updateUserAbortController.signal);
            toast.success(t("messages.userUpdatedSuccessfully"));
            reset();
            setOpenEditUserModal(false);
            setSelectedRoleTab(data.role);
            handleUserUpdateInform();
        } catch(error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const closeModal = () => {
        setOpenEditUserModal(false);
        reset();
    }

    return <Modal closeOnOverlayClick={false} isOpen={openEditUserModal} onClose={closeModal} size="2xl">
        <ModalOverlay />
        <ModalContent p={9}>
            <form noValidate style={{display:"flex", flexDirection: "column", gap: "12px"}} onSubmit={handleSubmit(onSubmit)}>
                <p style={{fontWeight: "600", fontSize: "20px"}}>Edit User</p>
                <FormControl>
                    <FormLabel>Username</FormLabel>
                    <Input type="text" size="lg" disabled value={user.username} bgColor="#E6E6E6"/>
                </FormControl>
                <Box display="flex" alignItems="start" gap={2}>
                    <FormControl flex="1">
                        <FormLabel>First Name</FormLabel>
                        <Input type="text" size="lg" autoComplete="off" maxLength={95} autoFocus {...register("firstName")}/>
                        <FormErrorMessage>{!!errors.firstName && errors.firstName.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl flex="1">
                        <FormLabel>Last Name</FormLabel>
                        <Input type="text" size="lg" autoComplete="off" maxLength={95} {...register("lastName")}/>
                        <FormErrorMessage>{!!errors.lastName && errors.lastName.message}</FormErrorMessage>
                    </FormControl>
                </Box>
                <FormControl>
                    <FormLabel>Role</FormLabel>
                    <Select size="lg" {...register("role")}>
                        <option value={USER_ROLE.Admin}>Admin</option>
                        <option value={USER_ROLE.GroupAgent}>Group Manager</option>
                        <option value={USER_ROLE.ZoneAgent}>Zone Agent</option>
                    </Select>
                </FormControl>
                {renderRoleInputDependency()}
                <Box display="flex" alignItems="center" gap="12px" mt={3}>
                    <Button size="lg" flex="1 1 0px" variant="outline" disabled={loading} onClick={closeModal}>{t("actions.cancel")}</Button>
                    <Button size="lg" flex="1 1 0px" variant="yellow_100" type="submit" isLoading={loading} disabled={!isValid}>{t("actions.save")}</Button>
                </Box>
            </form>
        </ModalContent>
    </Modal>;
}

export default EditUser;