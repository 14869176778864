import React from "react";
import { Link, useParams } from "react-router-dom";
import ContentView, {
  ContentViewBody,
  ContentViewHeader,
} from "src/components/app/ContentView";
import {
  Flex,
  Box,
  Center,
  FormControl,
  FormLabel,
  IconButton,
  Switch,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import { BrowserBack } from "src/components/app/BrowserBack";
import SPSpinner from "src/components/app/SPSpinner";
import { useAsync } from "../../../../hooks/useAsync";
import { panelTargetGroupsService } from "../../../../api/services/target-groups";
import { useAbortController } from "../../../../hooks/useAbortController";
import FormRow from "../../../../components/app/FormRow";
import { ITargetGroupListItem } from "../../../../api/types/target-groups";
import dateHelper from "../../../../utils/date-helper";
import ROUTE_CONSTANTS from "../../../../Routes/route-constants";
import Icon from "@mdi/react";
import { mdiPencil } from "@mdi/js";
import { useToggle } from "../../../../hooks/useToggle";
import { useChangeTargetGroupActivity } from "../useChangeActivity";

const TargetGroupDetail: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.targetGroupDetail"));
  const { getAbortSignal } = useAbortController();

  const params = useParams<{ id: string }>();

  const changeTargetGroupActivity = useChangeTargetGroupActivity(params.id!);

  const getDetailAsync = useAsync<ITargetGroupListItem, unknown>(
    () => {
      return new Promise(async (resolve, reject) => {
        if (!params.id) return reject();
        try {
          const response = await panelTargetGroupsService.get(
            params.id,
            getAbortSignal("getDetail").signal
          );
          response.data.data.createdAt = dateHelper.formatDate(
            response.data.data.createdAt
          );
          changeTargetGroupActivity.setIsActive(
            Boolean(response.data.data.active)
          );
          delete response.data.data.active;
          resolve(response.data.data);
        } catch (e) {
          reject(e);
        }
      });
    },
    { immediate: true }
  );

  return (
    <Box display="flex" flexDirection="row">
      <ContentView>
        {getDetailAsync.isLoading && (
          <Center>
            <SPSpinner size="xl" />
          </Center>
        )}
        <ContentViewHeader>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Text as="h3" fontSize="1.4em" display="inline-block">
                Target Group Details
              </Text>
              <IconButton
                aria-label={"Edit Target-Group"}
                as={Link}
                to={
                  ROUTE_CONSTANTS.DASHBOARD.TARGET_GROUPS.UPDATE.BY_DATA(
                    params.id!
                  ).ABSOLUTE
                }
                ml="4"
                icon={<Icon path={mdiPencil} size="28px" />}
                colorScheme="primary"
                variant="outline"
                size="sm"
              />
              <BrowserBack />
            </Box>
          </Box>
        </ContentViewHeader>

        <ContentViewBody>
          <FormControl mb={"2"}>
            <FormLabel
              style={{
                fontWeight: 500,
                display: "inline",
                color: changeTargetGroupActivity.active
                  ? "var(--chakra-colors-yellow-500)"
                  : "",
              }}
            >
              {changeTargetGroupActivity.active ? "Enable" : "Disable"} Target
              Group
            </FormLabel>
            <Switch
              ml={"2"}
              colorScheme={changeTargetGroupActivity.active ? "yellow" : "gray"}
              isChecked={changeTargetGroupActivity.active}
              onChange={(e) => {
                changeTargetGroupActivity.run(e.target.checked);
              }}
            />
          </FormControl>
          {getDetailAsync.data &&
            Object.entries(getDetailAsync.data).map(
              ([detailKey, detailValue], index, array) => {
                const fieldsContainingExtraFills = [
                  "good",
                  "veryGood",
                  "dailyTarget",
                ];
                const extraFillsFields = [
                  "goodFills",
                  "veryGoodFills",
                  "dailyTargetFills",
                ];

                const fieldShouldHidden = ["riderCount", "createdAt"];
                const isShouldHidden = fieldShouldHidden?.find(
                  (item) => item === detailKey
                );

                const isExtraFills = extraFillsFields.find(
                  (item: string) => item === detailKey
                );

                const isContainExtraFills = fieldsContainingExtraFills.find(
                  (item: string) => item === detailKey
                );
                if (isExtraFills || isShouldHidden) {
                  return null; // Skip this entry
                }

                const nextEntryKey = array[index + 1]?.[0];
                const nextEntryValue = array[index + 1]?.[1];

                if (isContainExtraFills) {
                  return (
                    <Flex key={detailKey} justify="space-between">
                      <Box flex="0 0 65%">
                        <FormRow
                          bidirectional
                          leftContent={
                            <Box>
                              <Text as="span" fontWeight="500">
                                {t(`fields.${detailKey}`)}:{" "}
                              </Text>
                              <Text as="span" ml="2">
                                {detailValue || "---"}
                              </Text>
                            </Box>
                          }
                          rightContent=""
                        />
                      </Box>
                      <Box flex="0 0 35%">
                        <FormRow
                          bidirectional
                          leftContent={
                            <Box>
                              <Text as="span" fontWeight="500">
                                {t(`fields.${nextEntryKey}`)}:{" "}
                              </Text>
                              <Text as="span" ml="2">
                                {nextEntryValue || "---"}
                              </Text>
                            </Box>
                          }
                          rightContent=""
                        />
                      </Box>
                    </Flex>
                  );
                }

                return (
                  <FormRow
                    bidirectional
                    leftContent={
                      <Box>
                        <Text as="span" fontWeight="500">
                          {t(`fields.${detailKey}`)}:{" "}
                        </Text>
                        <Text as="span" ml="2">
                          {detailValue || "---"}
                        </Text>
                      </Box>
                    }
                    rightContent=""
                    key={detailKey}
                  />
                );
              }
            )}
        </ContentViewBody>
      </ContentView>
    </Box>
  );
};

export default TargetGroupDetail;
