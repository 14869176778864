import { USER_ROLE, USER_ROLE_TYPES } from "src/types/user-management";
import { mixed, object, ref, string } from "yup"
export const userValidation = (t: any, operation: "create" | "update") => {
    const baseValidations: any = {
        firstName: string().trim()
                        .required(t("validationMessages.fieldIsRequired", {field:t("fields.firstName")}))
                        .min(1, t("validationMessages.minLengthValidation" , { field: t("fields.firstName") ,validLength: 1})),
        lastName: string().trim()
                        .required(t("validationMessages.fieldIsRequired", {field:t("fields.lastName")}))
                        .min(1, t("validationMessages.minLengthValidation" , { field: t("fields.lastName") ,validLength: 1})),
        role: mixed<USER_ROLE>().required().oneOf(Object.values(USER_ROLE)),
        roleDependency: string().when('role', {
            is: (value: USER_ROLE_TYPES) => value && [USER_ROLE.GroupAgent, USER_ROLE.ZoneAgent].includes(value),
            then: string().required("Please select a valid option"),
            otherwise: string().nullable(),
        })
    }

    if(operation === "create") {
        baseValidations["username"] = string().trim()
            .required(t("validationMessages.fieldIsRequired", {field:`${t("fields.email")}/${t("fields.userName")}`}))
            .transform((originalValue) => originalValue.replace(/\s/g, ''))
            .min(3, t("validationMessages.minLengthValidation" , { field: `${t("fields.email")}/${t("fields.userName")}` ,validLength: 3}));
        
        baseValidations["rawPassword"] = string().trim()
            .required(t("validationMessages.fieldIsRequired",{field:t("fields.password")}))
            .test("len", t("validationMessages.minAndMaxLengthValidation", {field: t("fields.password"), min:6, max:8}), 
                val => val!.length >= 6 && val!.length <= 8);

        baseValidations["confirmPassword"] = string().trim()
            .required(t("validationMessages.fieldIsRequired",{field:t("fields.confirmPassword")}))
            .oneOf([ref('rawPassword'), null], t("validationMessages.passwordsNotMatch"));
    }

    return object().shape(baseValidations);
}
