import { TRiderLocationLogStatus } from "src/api/types/rider-locations";

export const STATUS_COLORS: Record<TRiderLocationLogStatus, string> = {
    CheckInOffline: "item-check-in-offline",
    None: "item-none",
    CheckInOnline: "item-check-in-online",
    Checkout: "item-check-out",
    Available: "item-available",
    Unavailable: "item-unavailable",
    Busy: "item-busy"
}