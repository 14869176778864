import React from "react";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { IAcceptedRiders, IEligibleRiders } from "src/api/types/orders";

interface IRiderList {
    riders: IEligibleRiders[] | IAcceptedRiders[];
    hasRejectedColumn?: boolean;
}

const RiderList: React.FC<IRiderList> = ({ riders, hasRejectedColumn = false }) => {

    const renderIsRejectedColumn = (riderRejectedOrder: boolean) => {
        return riderRejectedOrder ? <Td color="#D51F27">Rejected</Td> : <Td>---</Td>;
    }

    return <Box p={4} maxHeight="400px" overflowY="auto">
        <Box className="table_container">
            <Table
                variant="simple"
                colorScheme="blackAlpha"
                size="md"
                backgroundColor="white"
                borderRadius="md"
                rounded="md"
                border="1px solid #E7E7E7">
                <Thead bgColor="#E6E6E6" borderRadius="8px">
                    <Tr>
                        <Th>Employee ID</Th>
                        <Th>First Name</Th>
                        <Th>Last Name</Th>
                        <Th>Contact No.</Th>
                        {hasRejectedColumn && <Th>Action</Th>}
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        riders.map((rider, ind) => {
                            return <Tr key={ind}>
                                <Td>{rider.employeeId}</Td>
                                <Td>{rider.firstName}</Td>
                                <Td>{rider.lastName}</Td>
                                <Td>{rider.contactNo || "---"}</Td>
                                {hasRejectedColumn && renderIsRejectedColumn(rider.isRejected || false)}
                            </Tr>
                        })
                    }
                </Tbody>
            </Table>
        </Box>
    </Box>
}

export default RiderList;