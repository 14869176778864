export interface IAxiosResponse<T> {
  data: T;
  message?: string;
  errors?: { [key: string]: string[] };
  success?: boolean;
}

export interface ISelectBox {
  title: string;
  value: string;
}
type IGetAllType = {};

export type FetcherFunction<T> = (
  options?: IGetAllType,
  abortSignal?: AbortSignal
) => Promise<{ title: string; value: string }[]>;

export enum FORM_ITEM_TYPE {
  Text = "text",
  Email = "email",
  Number = "number",
  Password = "password",
  Captcha = "captcha",
  Select = "select",
  Array = "array",
  Check_Group = "checkbox_group",
  Time_Range = "time_range",
  Day_select = "day_select",
  check_box = "check_box",
  text_area = "text_area",
  delivery = "delivery",
  map = "map",
  formGeneratorSelectBox = "formGeneratorSelectBox",
}

interface DefaultOptionType {
  id: string;
  title: string;
  value: string;
}
export interface FormItem {
  label: string;
  name: string;
  required?: boolean;
  placeholder?: string;
  type: FORM_ITEM_TYPE;
  loading?: boolean;
  revoke?: (args?: any) => Promise<void>;
  options?: DefaultOptionType[];
  checkboxOptions?: string[];
  subItems?: FormItemObject;
  addonBefore?: string;
  //   span?: ColProps;
  before?: React.ReactNode;
  after?: React.ReactNode;
  button?: React.ReactNode;
  CheckboxText?: string;
  disabled?: boolean;
  bidirectional?: boolean;
  hasNoneOption?: boolean;
  validate?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  //   props?: SelectProps;
  fetcher?: FetcherFunction<any>;
  items?: ISelectBox[];
}

export interface BidirectionalFormRow extends FormItem {
  rightContent?: FormItem | undefined;
}

export type FormItemObject = Record<string, BidirectionalFormRow>;

export enum DATE_TYPE {
  DATE = "1",
  YEAR = "2",
  MONTH = "3",
}
