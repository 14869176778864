import { Box } from "@chakra-ui/react";
import CreateUser from "./components/CreateUser";
import SearchUser from "./SearchUser";
import UserList from "./UserList";
import UserManagementProvider from "./UserManagementProvider";

const UserManagement:React.FC = () => {
    return <UserManagementProvider>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <SearchUser/>
            <CreateUser/>
        </Box>
        <UserList/>
    </UserManagementProvider>;
}

export default UserManagement;