import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import {IFormRowProps} from "./types.d"

const FormRow:React.FC<IFormRowProps> = ({leftContent,rightContent,bidirectional = false})=>{
    return (<SimpleGrid columns={{base:1,sm:1,md:2}} spacing="4" mb="6">
        <Box>{leftContent}</Box>
        <Box dir={bidirectional?"rtl":"ltr"}>{rightContent}</Box>
    </SimpleGrid>);
}

export default FormRow;