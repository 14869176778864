import { IMainContext } from "./types";
import React, { useState, useEffect, useCallback } from "react";
import { contextFactory } from "src/utils/context-factory";
import { getAuthorization } from "src/utils/get-authorization";
import { useNavigate, useLocation } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { Box } from "@chakra-ui/react";
import { HTTP_STATUS_CODES } from "src/constants/http-status-codes";
import { AxiosResponse } from "axios";
import { IAuthenticatedUser } from "src/api/types/auth";
import { panelUserService } from "src/api/services/current-user";
import SPSpinner from "src/components/app/SPSpinner";
import { IStatisticsData } from "../api/types/dashboard";
import ModalCreator from "../components/app/Modal/ModalCreator";

export const [GlobalContext, useGlobal] = contextFactory<IMainContext>(
  "GlobalContext",
  "useGlobal"
);

export const GlobalProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IAuthenticatedUser | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const { pathname } = useLocation();
  const [getProfileController] = useState(new AbortController());
  const [statistics, setStatistics] = useState<IStatisticsData>({});

  const navigate = useNavigate();
  const getUserProfile = useCallback(async () => {
    try {
      if (getAuthorization()) {
        const result = await panelUserService.getProfile(
          getProfileController.signal
        );
        setUser(result.data.data);
      } else if (!user) {
        if (pathname.includes("dashboard")) {
          navigate(ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE);
        }
      }
    } catch (err) {
      console.log(err);
      const error = err as AxiosResponse;
      if (
        error.status &&
        error.status === HTTP_STATUS_CODES.UN_AUTHORIZED &&
        pathname.includes("dashboard")
      ) {
        navigate(ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE);
      }
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  useEffect(() => {
    return () => {
      getProfileController.abort();
    };
  }, [getProfileController]);

  const updateStatistics = (statistics: Partial<IStatisticsData>) => {
    setStatistics((prev) => ({
      ...prev,
      ...statistics,
    }));
  };

  return (
    <GlobalContext.Provider
      value={{ user, setUser, statistics, updateStatistics }}
    >
      {loading ? (
        <Box
          position="absolute"
          width="100vw"
          height="100vh"
          top="0"
          left="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <SPSpinner size="xl" />
        </Box>
      ) : (
        children
      )}
      <ModalCreator />
    </GlobalContext.Provider>
  );
};
