import { IRiderListItem } from "../api/types/riders";

export const trunctate = (text: string, maxLength: number) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

export const sanitizeRiderName = (rider?: Partial<IRiderListItem>) => {
  if (!rider) return "";
  if (!rider.employeeId) return `All`;
  return `${rider.employeeId} - ${rider.firstName} ${rider.lastName}`;
};
