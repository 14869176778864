import React from "react";
import { useExtendedLiveMapState } from "../../ExtendedLiveMapContext";
import { Box } from "@chakra-ui/react";
import "./styles.css";
import SPSpinner from "src/components/app/SPSpinner";

const MapStatistics: React.FC = () => {
  const { activeZoneStatistics, loadingRiders } = useExtendedLiveMapState();

  const renderStatisticsValues = (value: number) => {
    return (
      <Box className="value">
        {loadingRiders ? <SPSpinner size="sm" /> : value}
      </Box>
    );
  };

  return (
    <Box className="map-statistics">
      <Box className="statistics-item type3">
        <Box className="title">IDLE RIDERS</Box>
        <Box className="value">
          {renderStatisticsValues(activeZoneStatistics?.idleRidersCount!)}
        </Box>
      </Box>
      <Box className="statistics-item type4">
        <Box className="title">UNREACHABLE RIDERS</Box>
        <Box className="value">
          {renderStatisticsValues(
            activeZoneStatistics?.unreachableRidersCount!
          )}
        </Box>
      </Box>
      <Box className="statistics-item type1">
        <Box className="title">ACTIVE RIDERS</Box>
        <Box className="value">
          {renderStatisticsValues(activeZoneStatistics?.activeRidersCount!)}
        </Box>
      </Box>
      <Box className="statistics-item type2">
        <Box className="title">BUSY RIDERS</Box>
        <Box className="value">
          {renderStatisticsValues(activeZoneStatistics?.busyRidersCount!)}
        </Box>
      </Box>
      <Box className="statistics-item type5">
        <Box className="title">TOTAL RIDERS</Box>
        <Box className="value">
          {renderStatisticsValues(activeZoneStatistics?.totalRiders!)}
        </Box>
      </Box>
    </Box>
  );
};

export default MapStatistics;
