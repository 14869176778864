import { useCallback, useEffect, useRef, useState } from "react";
import { HubConnectionState } from "@microsoft/signalr";
import { useAbortController } from "./useAbortController";
import { useAsync } from "./useAsync";
import { IRiderListItem, RiderSorts } from "src/api/types/riders";
import { panelRidersService } from "src/api/services/riders";
import { unique } from "src/utils/iterate";
import { debounce } from "lodash";
import { IDropDownItem } from "src/types/dropdownitem";

export function useRidersFilter(selectedRider?: string) {
  const { getAbortSignal } = useAbortController();
  const [riders, setRiders] = useState<{
    totalCount: number;
    items: IDropDownItem[];
  }>({
    totalCount: 0,
    items: [],
  });
  const ridersOffset = useRef<number>(0);
  const {
    run: fetchRiders,
    data,
    isLoading,
  } = useAsync<Partial<IRiderListItem>[], any>(
    (options) => {
      return new Promise(async (resolve, reject) => {
        let {
          offset = ridersOffset.current,
          keyword = undefined,
          riderId = selectedRider,
        } = options || {};
        try {
          const result = await panelRidersService.getAll(
            offset,
            50,
            RiderSorts.ByNameAsc,
            getAbortSignal("fetchRiders").signal,
            keyword,
            undefined,
            riderId
          );
          if (!keyword) ridersOffset.current += 50;
          const ridersListMap: IDropDownItem[] = result.data.data.items.map(
            (item) => {
              return {
                id: item.id,
                text: `${item.firstName} ${item.lastName} (${item.employeeId})`,
                searchableFields: {
                  firstNameEn: item.firstName.trim(),
                  lastNameEn: item.lastName.trim(),
                  fullName: `${item.firstName.trim()} ${item.lastName.trim()}`,
                  employeeId: `${item.employeeId.trim()}`,
                },
                stylesOfEachData: !item.isActive ? { color: "#a30000" } : {},
              };
            }
          );
          setRiders((prevRidersList) => ({
            totalCount: result.data.data.totalCount,
            items: !keyword
              ? [...(prevRidersList?.items || []), ...ridersListMap]
              : ridersListMap,
          }));
          resolve(ridersListMap);
        } catch (error) {
          console.log(error);
        } finally {
          resolve([]);
        }
      });
    },
    { immediate: false }
  );

  useEffect(() => {
    fetchRiders({ riderId: selectedRider });
  }, [selectedRider]);

  const handleSearch = useCallback(
    debounce((keyword) => {
      if (!isLoading) {
        fetchRiders({ keyword, offset: 0 });
      }
    }, 500),
    [fetchRiders]
  );

  const handleScroll = useCallback(
    debounce(() => {
      if (ridersOffset.current < riders?.totalCount && !isLoading)
        fetchRiders({});
    }, 500),
    [fetchRiders]
  );

  return {
    isLoading,
    riders,
    handleScroll,
    handleSearch,
  };
}
