import { DEFAULT_COUNT } from "src/constants/api-default-values";
import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAxiosResponse } from "../types/axios-response";
import { INewRiderData, IRiderListItem, IRidersListPayload, RiderSorts, TUniqueFields } from "../types/riders";

export const panelRidersService = {
    getAll(offset: number, count: number, sort: RiderSorts, abortSignal: AbortSignal, searchQuery: string = "" , status: string = "", riderId ?: IRiderListItem["id"]) {
        let keyword = searchQuery ? `&keyword=${searchQuery}` : "";
        let searchedStatus = status ? `&WorkingStatusId=${status}` : "";
        let queryRiderId = riderId ? `&AttachRiderId=${riderId}` : "";
        return AxiosInstance.get<IAxiosResponse<IRidersListPayload>>(`${API_ROUTES.RIDERS.ROOT}?count=${count}&offset=${offset}&sort=${sort}${keyword}${queryRiderId}${searchedStatus}`, { signal: abortSignal });
    },
    create(data: INewRiderData) {
        return AxiosInstance.post<IAxiosResponse<{}>>(API_ROUTES.RIDERS.ROOT, data);
    },
    get(id: string, abortSignal: AbortSignal) {
        return AxiosInstance.get<IAxiosResponse<IRiderListItem>>(API_ROUTES.RIDERS.GET_DETAILS(id), { signal: abortSignal });
    },
    update(id: string, data: INewRiderData) {
        return AxiosInstance.put<IAxiosResponse<{}>>(API_ROUTES.RIDERS.GET_DETAILS(id), data);
    },
    checkUniqueFieldDublication(value: string, field: TUniqueFields) {
        return AxiosInstance.get<IAxiosResponse<IRidersListPayload>>(`${API_ROUTES.RIDERS.ROOT}?count=${DEFAULT_COUNT}&${field}=${value.trim().toLowerCase()}`);
    },
    changePassword(id: string, rawPassword: string) {
        return AxiosInstance.put<IAxiosResponse<{}>>(API_ROUTES.RIDERS.CHANGE_PASSWORD(id), { "rawPassword": rawPassword });
    },
    toDisabledRider(id: string, abortSignal: AbortSignal) {
        return AxiosInstance.put<IAxiosResponse<{}>>(API_ROUTES.RIDERS.TO_DISABLED_RIDER(id), { signal: abortSignal });
    },
    toEnabledRider(id: string, abortSignal: AbortSignal) {
        return AxiosInstance.put<IAxiosResponse<{}>>(API_ROUTES.RIDERS.TO_ENABLED_RIDER(id), { signal: abortSignal });
    }
};