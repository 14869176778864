import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { mdiImage, mdiTimerOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ApiConfig } from "src/api/config";
import { panelGroupsService } from "src/api/services/groups";
import { panelRidersService } from "src/api/services/riders";
import { IGroupListItem, IGroupZonesList } from "src/api/types/groups";
import { IAdminOrderFormData, OrderPaymentType } from "src/api/types/orders";
import { IRiderListItem, RiderSorts } from "src/api/types/riders";
import { VehicleType } from "src/api/types/vehicle";
import { BrowserBack } from "src/components/app/BrowserBack";
import {
  DEFAULT_COUNT,
  DEFAULT_OFFSET,
} from "src/constants/api-default-values";
import { API_ROUTES } from "src/constants/api-routes";
import dateHelper from "src/utils/date-helper";
import { getAuthorization } from "src/utils/get-authorization";
import { adminCreateUpdateOrderValidation } from "src/validation-schemas/admin-create-update-order";
import { compressImage } from "src/utils/compressImage";
import { ordersService } from "src/api/services/orders";
import CreateOrderByAdminConfirmationModal from "../components/CreateOrderByAdminConfirmationModal";
import { toast } from "src/utils/toast";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import "./styles.css";
import SPSpinner from "src/components/app/SPSpinner";
import { ControlledSelectBox } from "../../../../components/app/SelectBox";
import { unique } from "../../../../utils/iterate";
import { debounce } from "lodash";

let currentOffset = 0;
const AdminCreateUpdateOrder: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [editable, setEditable] = useState<boolean>(id ? true : false);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    control,
  } = useForm<IAdminOrderFormData>({
    mode: "all",
    resolver: yupResolver(adminCreateUpdateOrderValidation(t)),
    defaultValues: {
      code: "",
      cost: 0,
      paymentType: OrderPaymentType.Cash,
      vehicleType: VehicleType.Bike,
      riderId: "",
      zoneId: "",
      groupId: "",
      registeredDate: "",
      imageUrl: "",
    },
  });

  const groupWatch = watch("groupId");
  const [orderInfo, setOrderInfo] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [orderLoading, setOrderLoading] = useState<boolean>(editable);
  const [zoneLoading, setZoneLoading] = useState<boolean>(false);
  const [ridersLoading, setRidersLoading] = useState<boolean>();
  const [riders, setRiders] = useState<IRiderListItem[]>([]);
  const [zones, setZones] = useState<IGroupZonesList[]>([]);
  const [orderZoneId, setOrderZoneId] = useState<string>("");
  const [groups, setGroups] = useState<IGroupListItem[]>([]);
  const [getRidersAbortController] = useState<AbortController>(
    new AbortController()
  );
  const [getZonesAbortController] = useState<AbortController>(
    new AbortController()
  );
  const [getGroupsAbortController] = useState<AbortController>(
    new AbortController()
  );
  const [abortController] = useState<AbortController>(new AbortController());
  const [orderReceiptImageSrc, setOrderReceiptImageSrc] = useState<string>("");
  const [loadingOrderReceipt, setLoadingOrderReceipt] =
    useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const getOrderInfo = async () => {
    console.log("STARTING GET ORDER");
    setOrderLoading(true);
    try {
      const response = await ordersService.getAdminOrder(id!);
      const result = response.data.data;
      await fetchRiders({
        offset: currentOffset,
        riderId: result.riderId,
      });
      setOrderInfo(result);
      setValue("code", result.code);
      setValue("cost", result.cost);
      setValue("paymentType", result.paymentType);
      setValue("vehicleType", result.vehicleType);
      setValue("riderId", result.riderId);
      setOrderZoneId(result.zoneId);
      setValue("groupId", result.groupId);
      setValue(
        "registeredDate",
        dateHelper.formatDate(result.date, "YYYY-MM-DDTHH:mm")
      );
      setValue("imageUrl", result.imageUrl!);
      setOrderReceiptImageSrc(result.imageUrl!);
    } catch (error) {
      console.log(error);
    } finally {
      console.log("DONE GET ORDER");
      setOrderLoading(false);
    }
  };

  const fetchRiders = async (options?: {
    offset?: number;
    keyword?: string;
    riderId?: IRiderListItem["id"];
  }) => {
    let { offset, riderId, keyword } = options || {};
    if (offset === undefined) offset = currentOffset;
    try {
      setRidersLoading(true);
      const result = await panelRidersService.getAll(
        offset,
        50,
        RiderSorts.ByNameAsc,
        getRidersAbortController.signal,
        keyword,
        riderId
      );
      if (!keyword) currentOffset += 50;
      setRiders((prevRiders) =>
        unique([...(prevRiders || []), ...result.data.data.items])
      );
    } catch (error) {
      console.log(error);
    } finally {
      setRidersLoading(false);
    }
  };

  const fetchZones = async () => {
    setZoneLoading(true);
    try {
      const result = await panelGroupsService.getZonesGroup(
        groupWatch,
        getZonesAbortController.signal
      );
      setZones(result.data.data.items);
      const findedZone =
        result.data.data.items.filter((item) => item.id === orderZoneId)
          .length > 0
          ? result.data.data.items.filter((item) => item.id === orderZoneId)[0]
              .id
          : "";
      setValue("zoneId", findedZone);
    } catch (error) {
      console.log(error);
    } finally {
      setZoneLoading(false);
    }
  };

  const fetchGroups = async () => {
    try {
      const result = await panelGroupsService.getAll(
        DEFAULT_OFFSET,
        DEFAULT_COUNT,
        getGroupsAbortController.signal
      );
      setGroups(result.data.data.items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (groupWatch !== "") {
      fetchZones();
    }
  }, [groupWatch]);

  useEffect(() => {
    fetchGroups();
    if (id) {
      getOrderInfo();
      setEditable(true);
    } else {
      fetchRiders();
    }
  }, [id]);

  const onSubmit = async (data: IAdminOrderFormData) => {
    setLoading(true);
    try {
      const orderDate = dateHelper.formatDate(
        data.registeredDate.toString(),
        "YYYY-MM-DDTHH:mm:ssZ"
      );
      if (Date.parse(orderDate) > new Date().getTime()) {
        toast.error("Order date can't greater that now date.");
        return;
      }
      const orderData: IAdminOrderFormData = {
        ...data,
        registeredDate: orderDate,
      };
      if (editable && id) {
        await ordersService.updateOrderByAdmin(id, orderData);
        toast.success(
          t("messages.entityWasActionedSuccessfully", {
            entity: "Order",
            state: "updated",
          })
        );
      } else {
        await ordersService.createOrderByAdmin(
          orderData,
          abortController.signal
        );
        setOpenConfirmationModal(true);
      }
      navigate(-1);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      abortController.abort();
      getRidersAbortController.abort();
      getZonesAbortController.abort();
      getGroupsAbortController.abort();
    };
  }, []);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      console.log("File doesn't select yet");
      return;
    }
    setLoadingOrderReceipt(true);
    setOrderReceiptImageSrc("");

    try {
      const compressedFile = await compressImage(files[0]);
      const formData = new FormData();
      formData.append("File", compressedFile, "order_image.jpg");

      const response = await fetch(
        ApiConfig.baseURL + API_ROUTES.ORDERS.UPLOAD_ORDER_RECEIPT,
        {
          method: "POST",
          headers: {
            Authorization: getAuthorization()!,
            Accept: "*/*",
          },
          body: formData,
        }
      );
      const result = await response.json();
      clearErrors("imageUrl");
      setLoadingOrderReceipt(false);
      setOrderReceiptImageSrc(result.data.imageId);
      setValue("imageUrl", result.data.imageId);
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleContinueToAddNewOrder = () => {
    setOrderReceiptImageSrc("");
    if (inputFileRef.current?.value) {
      inputFileRef.current.value = "";
    }
    reset();
    setOpenConfirmationModal(false);
  };

  const sanitizeRiderName = (rider?: IRiderListItem) => {
    if (!rider) return "";
    return `${rider.employeeId} - ${rider.firstName} ${rider.lastName}`;
  };

  const debouncedFetchRiders = useCallback(
    debounce((keyword) => {
      if (!!keyword && !ridersLoading) {
        fetchRiders({ keyword, offset: 0 });
      }
    }, 500),
    [fetchRiders]
  );

  const debouncedScroll = useCallback(
    debounce(() => {
      fetchRiders({});
    }, 500),
    [fetchRiders]
  );
  return (
    <>
      {orderLoading ? (
        <Center minH="500px">
          <SPSpinner size="lg" />
        </Center>
      ) : (
        <Box display="flex" flexDir="column" mb={1}>
          <Box display="flex" alignItems="center">
            <Text as="h3" fontSize="3xl">
              {id ? t("titles.editOrder") : t("titles.addOrder")}
            </Text>
            <BrowserBack />
          </Box>
          {orderInfo.modifiedByAdmin && (
            <Box
              display="flex"
              alignItems="center"
              fontSize="14px"
              color="#4D4D4D"
            >
              <Icon size="20px" path={mdiTimerOutline} />
              &nbsp;
              <Text>
                <Text as="span">Last Update:</Text>&nbsp;
                <Text as="span" color="#F39905" fontWeight="bold">
                  {dateHelper.formatDate(orderInfo.modifiedByAdmin)}
                </Text>
              </Text>
            </Box>
          )}
          <Box
            display="flex"
            alignItems="stretch"
            gap={8}
            bg="#fff"
            padding={5}
            borderRadius="8px"
            mt={2}
          >
            <Box flex={1}>
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" flexDir="column" gap={3}>
                  <Box display="flex" gap={3}>
                    <FormControl isInvalid={!!errors.code}>
                      <FormLabel>{t("fields.orderId")}</FormLabel>
                      <Input
                        type="text"
                        size="lg"
                        autoComplete="off"
                        maxLength={95}
                        {...register("code")}
                        autoFocus
                      />
                      <FormErrorMessage>
                        {!!errors.code && errors.code.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.vehicleType}>
                      <FormLabel htmlFor="vehicleType">
                        {t("fields.vehicleType")}
                      </FormLabel>
                      <Select
                        id="vehicleType"
                        size="lg"
                        {...register("vehicleType")}
                        defaultValue={VehicleType.Bike}
                      >
                        <option value={VehicleType.Bike}>
                          {VehicleType.Bike}
                        </option>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box display="flex" gap={3}>
                    <FormControl isInvalid={!!errors.paymentType}>
                      <FormLabel htmlFor="paymentType">
                        {t("fields.OrderPaymentType")}
                      </FormLabel>
                      <Select
                        id="paymentType"
                        size="lg"
                        {...register("paymentType")}
                        defaultValue={OrderPaymentType.Cash}
                      >
                        <option value={OrderPaymentType.Cash}>
                          {OrderPaymentType.Cash}
                        </option>
                        <option value={OrderPaymentType.Paid}>
                          {OrderPaymentType.Paid}
                        </option>
                      </Select>
                    </FormControl>
                    <FormControl isInvalid={!!errors.cost}>
                      <FormLabel>{t("fields.orderValue")}</FormLabel>
                      <InputGroup>
                        <Input
                          type="text"
                          size="lg"
                          autoComplete="off"
                          maxLength={95}
                          {...register("cost")}
                        />
                        <InputRightElement>KD</InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {!!errors.cost && errors.cost.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box display="flex" gap={3}>
                    <FormControl isInvalid={!!errors.registeredDate}>
                      <FormLabel>Order Date</FormLabel>
                      <Input
                        type="datetime-local"
                        size="lg"
                        autoComplete="off"
                        maxLength={95}
                        {...register("registeredDate")}
                      />
                      <FormErrorMessage>
                        {!!errors.registeredDate &&
                          errors.registeredDate.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl isInvalid={!!errors.riderId}>
                      <FormLabel htmlFor="riderId">Rider</FormLabel>
                      <ControlledSelectBox
                        control={control}
                        name={"riderId"}
                        placeholder={"Select a Rider"}
                        options={riders.map((rider) => ({
                          value: rider.id,
                          label: sanitizeRiderName(rider),
                        }))}
                        isLoading={ridersLoading}
                        onMenuScrollToBottom={() => debouncedScroll()}
                        onInputChange={(keyword) =>
                          debouncedFetchRiders(keyword)
                        }
                      />
                      <FormErrorMessage>
                        {!!errors.riderId && errors.riderId.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl isInvalid={!!errors.groupId}>
                      <FormLabel htmlFor="groupId">
                        {t("fields.groupId")}
                      </FormLabel>
                      <Select id="groupId" size="lg" {...register("groupId")}>
                        <option value="">Select a group</option>
                        {groups.map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.nameEn}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {!!errors.groupId && errors.groupId.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box>
                    {zoneLoading ? (
                      <SPSpinner />
                    ) : (
                      <FormControl isInvalid={!!errors.zoneId}>
                        <FormLabel htmlFor="zoneId">
                          {t("fields.zoneName")}
                        </FormLabel>
                        <Select id="zoneId" size="lg" {...register("zoneId")}>
                          <option value="">Select a zone</option>
                          {zones.map((zone) => (
                            <option key={zone.id} value={zone.id}>
                              {zone.nameEn}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>
                          {!!errors.zoneId && errors.zoneId.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Box>
                  <Box display="flex" alignItems="center" gap="12px">
                    <Button
                      size="lg"
                      flex="1 1 0px"
                      variant="outline"
                      disabled={loading}
                      onClick={() => navigate(-1)}
                    >
                      {t("actions.cancel")}
                    </Button>
                    <Button
                      size="lg"
                      flex="1 1 0px"
                      variant="orange"
                      type="submit"
                      isLoading={loading}
                    >
                      {t("actions.save")}
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
            <Box flex={1} display="flex" flexDir="column">
              <Box
                flex={1}
                border="1px solid #ccc"
                bg="#fefefe"
                borderRadius="12px"
                overflow="hidden"
                marginBottom="10px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                maxHeight="570px"
              >
                {loadingOrderReceipt ? (
                  <SPSpinner size="xl" />
                ) : (
                  orderReceiptImageSrc !== "" && (
                    <img
                      style={{ height: "100%" }}
                      src={orderReceiptImageSrc}
                      {...register("imageUrl")}
                    />
                  )
                )}
              </Box>
              <Box className="image_upload">
                <FormControl isInvalid={!!errors.imageUrl}>
                  <FormLabel
                    htmlFor="order-upload-file"
                    textAlign="center"
                    width="100%"
                    cursor="pointer"
                    backgroundColor={
                      loadingOrderReceipt ? "#e6e6e6" : "#FCC976"
                    }
                    padding={3}
                    borderRadius="8px"
                    pos="relative"
                  >
                    <Input
                      ref={inputFileRef}
                      type="file"
                      id="order-upload-file"
                      onChange={handleFileChange}
                      accept="image/*"
                      width="1px"
                      position="absolute"
                      disabled={loadingOrderReceipt}
                    />
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      gap={2}
                    >
                      <Icon path={mdiImage} size="26px" />
                      Upload Receipt
                    </Box>
                  </FormLabel>
                  <FormErrorMessage>
                    {!!errors.imageUrl && errors.imageUrl.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </Box>
          </Box>
          {openConfirmationModal && (
            <CreateOrderByAdminConfirmationModal
              openConfirmationModal={openConfirmationModal}
              setOpenConfirmationModal={setOpenConfirmationModal}
              handleContinueToAddNewOrder={handleContinueToAddNewOrder}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default AdminCreateUpdateOrder;
