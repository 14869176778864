import React, { useEffect, useMemo, useState } from "react";
import { Box, Text, FormLabel, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import { useNavigate, useParams } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { INewZoneData, IZoneListItem } from "src/api/types/zones";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ContentView, {
  ContentViewBody,
  ContentViewHeader,
} from "src/components/app/ContentView";
import { panelZonesService } from "src/api/services/zones";
import { toast } from "src/utils/toast";
import { MapChooseLocation } from "src/components/app/Map/MapChooseLocation";
import { DEFAULT_CENTER } from "src/constants/map-data";
import { ILatLng } from "src/types/map";
import { BrowserBack } from "src/components/app/BrowserBack";
import { getCreateRiderFormItems } from "./form-item";
import { useAbortController } from "src/hooks/useAbortController";
import { useFormItems } from "src/hooks/useFormItems";
import FormGenerator from "src/components/FormGenerator";
import { useAsync } from "src/hooks/useAsync";
import { modalActions } from "src/global-context/modals";
import { MODAL_TYPES } from "src/types/modals";
import { newZoneValidationSchema } from "./validation-schema";

const CreateZone: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const params = useParams<{ id: string }>();
  const [mapMarkerRadius, setMapMarkerRadius] = useState<number>(0);
  const { getAbortSignal } = useAbortController();
  const [location, setLocation] = useState<ILatLng>(DEFAULT_CENTER);
  const [center, setCenter] = useState<ILatLng>(DEFAULT_CENTER);

  useTitle(t(editable ? "pageTitles.editZone" : "pageTitles.newZone"));

  const createRiderFormItems = useMemo(
    () =>
      getCreateRiderFormItems({
        t,
        getAbortSignal,
      }),
    [t, getAbortSignal, editable]
  );
  const { formItems } = useFormItems(createRiderFormItems);

  const useFormReturn = useForm<INewZoneData>({
    resolver: yupResolver(newZoneValidationSchema(t)),
    mode: "all",
    defaultValues: {
      nameEn: "",
      nameAr: "",
      descriptionEn: "",
      descriptionAr: "",
      validRadius: 0,
      pagingRadius: 0,
      groupId: "",
      addressEn: "",
      addressAr: "",
      expectedOrderNumberForWeekends: 0,
      expectedOrderNumberForRegularDays: 0,
    },
  });

  const { run: getZoneDetails, data: zonesData } = useAsync<IZoneListItem, any>(
    () => {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await panelZonesService.get(
            params.id!,
            getAbortSignal("fetchZoneDetails").signal
          );
          const { ...rest } = result.data.data;
          useFormReturn.reset(rest);
          setLocation({
            lat: rest.location.latitude,
            lng: rest.location.longitude,
          });
          setCenter({
            lat: rest.location.latitude,
            lng: rest.location.longitude,
          });
          setMapMarkerRadius(parseInt(rest.validRadius + ""));
          resolve(rest as IZoneListItem);
        } catch (e) {
          reject([]);
        }
      });
    }
  );

  useEffect(() => {
    if (params.id) {
      setEditable(true);
      getZoneDetails({});
    }
  }, [params]);

  useEffect(() => {
    const subscription = useFormReturn.watch((value, { name, type }) => {
      if (name === "validRadius" && value.validRadius) {
        setMapMarkerRadius(parseInt(value.validRadius + ""));
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [useFormReturn.watch]);

  const onCustomLocationEntered = (lat: number, lng: number) => {
    setCenter({ lat, lng });
  };

  const onSubmit = async (data: INewZoneData) => {
    setLoading(true);
    data.location = {
      latitude: location.lat,
      longitude: location.lng,
    };
    try {
      if (editable) {
        await panelZonesService.update(
          params.id!,
          data,
          getAbortSignal("saveZoneDetails").signal
        );
      } else {
        await panelZonesService.create(
          data,
          getAbortSignal("saveZoneDetails").signal
        );
      }
      toast.success(
        t(
          editable
            ? "messages.zoneUpdatedSuccessfully"
            : "messages.zoneCreatedSuccessfully"
        )
      );
      editable
        ? navigate(-1)
        : navigate(ROUTE_CONSTANTS.DASHBOARD.ZONES.ROOT.ABSOLUTE);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onLocationChanged = (data: ILatLng) => {
    setLocation(data);
  };

  return (
    <>
      <ContentView>
        <ContentViewHeader>
          <Box display="flex" alignItems="center">
            <Text as="h3" fontSize="3xl">
              {t(editable ? "titles.editZone" : "titles.newZone")}
            </Text>
            <BrowserBack />
          </Box>
        </ContentViewHeader>
        <ContentViewBody>
          <FormGenerator
            before={<></>}
            isCancel={true}
            submitText={t("actions.save")}
            cancelText={t("actions.cancel")}
            onCancel={() =>
              editable
                ? navigate(-1)
                : navigate(ROUTE_CONSTANTS.DASHBOARD.ZONES.ROOT.ABSOLUTE)
            }
            submitLoading={loading}
            formItems={formItems}
            onSubmit={onSubmit}
            useFormReturn={useFormReturn as any}
            after={
              <Box>
                <Box display="flex" alignItems="center" mb="3">
                  <FormLabel m="0">{t("fields.location")}</FormLabel>
                  <Button
                    size="sm"
                    ml="2"
                    type="button"
                    onClick={() =>
                      modalActions.addModal(
                        MODAL_TYPES.EDIT_ZONE_LOCATION_MODAL,
                        { onConfirm: onCustomLocationEntered }
                      )
                    }
                    colorScheme="secondary"
                  >
                    Enter Manually
                  </Button>
                </Box>
                <Box mb="3">
                  <span>Selected Coordinates: </span>
                  <Text
                    as="span"
                    fontWeight="bold"
                  >{`${location.lat}, ${location.lng}`}</Text>
                </Box>
                <MapChooseLocation
                  center={center}
                  zoom={16}
                  marker={location}
                  onChange={onLocationChanged}
                  zoneRadius={mapMarkerRadius}
                  fullscreenControl
                />
              </Box>
            }
          />
        </ContentViewBody>
      </ContentView>
    </>
  );
};

export default CreateZone;
