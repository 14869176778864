import React from "react";
import { Box, Button, IconButton, Select, Text } from "@chakra-ui/react";
import {
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiChevronLeft,
  mdiChevronRight,
} from "@mdi/js";
import Icon from "@mdi/react";
import usePagination from "src/hooks/usePagination";
import "./styles.css";

export interface IPaginationProps {
  entityTitle: string;
  pageTotalCount: number;
  perPageItems: number;
  defdefaultViewNumber?: number;
  isVisiblePageSizeSelector?: boolean;
  passPaginateValues: (offset: number, size: number) => void;
  isStoreOnLocalStorage?: boolean;
  loading?: boolean;
}
export const Pagination: React.FC<IPaginationProps> = (props) => {
  const {
    entityTitle,
    defdefaultViewNumber = 3,
    pageTotalCount,
    perPageItems,
    isVisiblePageSizeSelector = true,
    passPaginateValues,
    isStoreOnLocalStorage = false,
    loading = false,
  } = props;
  const {
    prevPage,
    nextPage,
    jumpToPage,
    currentPage,
    pageSize,
    maxPage,
    changePageSize,
  } = usePagination(
    pageTotalCount,
    perPageItems,
    isStoreOnLocalStorage,
    entityTitle
  );
  const pageSizeOptions = [10, 20, 30, 40, 50];

  const renderPagination = (
    defaultViewNumber: number,
    offset: number,
    count: number
  ) => {
    let pages = [];

    if (maxPage <= defaultViewNumber) {
      for (let i = 0; i < maxPage; i++) {
        pages.push(
          <Button
            key={i}
            onClick={() => doPaginate("page", i + 1)}
            className={currentPage - 1 === i ? "active" : ""}
          >
            {i + 1}
          </Button>
        );
      }
    } else {
      let test =
        currentPage + defaultViewNumber > maxPage
          ? maxPage + 1
          : currentPage + defaultViewNumber;
      for (let i = test - defaultViewNumber; i < test; i++) {
        pages.push(
          <Button
            key={i}
            className={i === currentPage ? "active" : ""}
            onClick={() => doPaginate("page", i)}
          >
            {i}
          </Button>
        );
      }
    }

    return (
      <Box className="paginate_info">
        <Text as="span" mr="2" color="#888" fontSize="0.875rem">
          Showing {offset + 1} to{" "}
          {offset + count > pageTotalCount ? pageTotalCount : offset + count} of{" "}
          {pageTotalCount}{" "}
          {pageTotalCount > 1 ? renderEntityTitle(true) : renderEntityTitle()}
        </Text>
        <Box className="paginate_buttons">
          {/* paginate buttons */}
          <IconButton
            aria-label="first"
            disabled={currentPage === 1 ? true : false}
            onClick={() => doPaginate("first")}
            icon={<Icon path={mdiChevronDoubleLeft} size="22px" />}
          />
          <IconButton
            aria-label="previous"
            disabled={currentPage === 1 ? true : false}
            onClick={() => doPaginate("prev")}
            icon={<Icon path={mdiChevronLeft} size="22px" />}
          />
          {pages}
          <IconButton
            aria-label="next"
            disabled={currentPage === maxPage ? true : false}
            onClick={() => doPaginate("next")}
            icon={<Icon path={mdiChevronRight} size="22px" />}
          />
          <IconButton
            aria-label="last"
            disabled={currentPage === maxPage ? true : false}
            onClick={() => doPaginate("last", maxPage)}
            icon={<Icon path={mdiChevronDoubleRight} size="22px" />}
          />
        </Box>
      </Box>
    );
  };

  const doPaginate = (type: string, pageNumber?: number) => {
    let cp = 1;
    switch (type) {
      case "first":
        jumpToPage(1);
        break;
      case "prev":
        let a = prevPage();
        cp = a;
        break;
      case "next":
        let b = nextPage();
        cp = b;
        break;
      case "last":
      case "page":
        let c = jumpToPage(pageNumber!);
        cp = c;
        break;
    }
    passPaginateValues(cp * pageSize - pageSize, pageSize);
  };

  const handleChangePage = (newSize: number) => {
    changePageSize(newSize);
    passPaginateValues(0, newSize);
  };

  const renderEntityTitle = (isPlural: boolean = false) => {
    const pattern = /^Record/; // a general name
    if (pattern.test(entityTitle)) {
      return isPlural ? "Record(s)" : "Record";
    } else {
      return isPlural ? `${entityTitle}(s)` : entityTitle;
    }
  };

  return (
    <>
      {!loading && pageTotalCount > 0 ? (
        <Box className="paginate_container">
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            className="select_pagesize"
          >
            {isVisiblePageSizeSelector && (
              <>
                <Text as="span">view</Text>
                <Select
                  value={pageSize}
                  onChange={(e) => handleChangePage(+e.target.value)}
                  width="auto"
                  mr="2"
                  ml="2"
                >
                  {pageSizeOptions.map((option, ind) => {
                    return (
                      <option key={ind} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </Select>
                <Text as="span">{renderEntityTitle(true)} per page</Text>
              </>
            )}
          </Box>
          {renderPagination(
            defdefaultViewNumber,
            currentPage * pageSize - pageSize,
            pageSize
          )}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};
