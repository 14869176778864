import { Box, Center, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { IUnreachableRidertem } from "src/api/types/riders";
import { NotFoundResult } from "src/components/app/NotFoundResult";
import SPSpinner from "src/components/app/SPSpinner";
import dateHelper from "src/utils/date-helper";
import "./styles.css";

interface IProps {
    items: IUnreachableRidertem[];
    isLoading: boolean;
    notFoundStatus: boolean;
}

const UnreachableRiders: React.FC<IProps> = ({ items, isLoading, notFoundStatus }) => {
    return isLoading
        ? <Center height="100%">
            <SPSpinner size='lg'/>
        </Center>
        : <Box className="table_container unreachable_parent_table">
            {
                !notFoundStatus
                    ? <>
                        <Table style={{ tableLayout: "fixed" }}>
                            <colgroup>
                                <col span={1} width="100px" />
                                <col span={1} width="35%" />
                                <col span={1} width="15%" />
                                <col span={1} width="20%" />
                                <col span={1} />
                            </colgroup>
                            <Thead>
                                <Tr>
                                    <Th>No.</Th>
                                    <Th>Name</Th>
                                    <Th>Phone</Th>
                                    <Th>Zone</Th>
                                    <Th>Unreachable From</Th>
                                </Tr>
                            </Thead>
                            <Tbody></Tbody>
                        </Table>
                        <Box className="inactive_table_container">
                            <Table fontSize="0.875rem">
                                <colgroup>
                                    <col span={1} width="100px" />
                                    <col span={1} width="35%" />
                                    <col span={1} width="15%" />
                                    <col span={1} width="20%" />
                                    <col span={1} />
                                </colgroup>
                                <Tbody>
                                    {items.map((rider, index) => (
                                        <Tr key={index}>
                                            <Td>{index + 1}</Td>
                                            <Td>{rider.name}</Td>
                                            <Td>{rider?.phone ? rider?.phone.replace(/\s/g, '') : "---"}</Td>
                                            <Td>{rider?.zone || "---"}</Td>
                                            <Td>{dateHelper.formatDate(rider.unreachableTime)}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                    </>
                    : <NotFoundResult />
            }
        </Box>
}

export default UnreachableRiders;