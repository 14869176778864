import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { ApiConfig } from "./config";
import { requestInterceptors, responseInterceptors } from "./interceptors";

declare module "axios" {
  export interface AxiosRequestConfig {
    customCache?: boolean;
  }
}

export interface IAxiosErrorResponse {
  response?: {
    status?: string;
    statusText?: string;
    data?: {
      status?: string;
      message?: string;
    };
  };
}

export interface IAxiosRequestConfig extends AxiosRequestConfig {
  showError?: boolean;
}

const APIInstance = () => {
  const instance = Axios.create(ApiConfig);

  instance.interceptors.response.use(
    responseInterceptors.successInterceptor,
    responseInterceptors.errorInterceptor
  );

  instance.interceptors.request.use(
    requestInterceptors.beforeSent,
    requestInterceptors.onError
  );

  return {
    get: <T, R = AxiosResponse<T>>(
      url: string,
      config?: IAxiosRequestConfig,
      cache: boolean = false
    ): Promise<R> => {
      return instance.get(url, {
        ...config,
        customCache: cache,
      });
    },

    post: <T, B = {}, R = AxiosResponse<T>>(
      url: string,
      data?: B,
      config?: IAxiosRequestConfig
    ): Promise<R> => {
      return instance.post(url, data, config);
    },

    put: <T, B = {}, R = AxiosResponse<T>>(
      url: string,
      data?: B,
      config?: IAxiosRequestConfig
    ): Promise<R> => {
      return instance.put(url, data, config);
    },

    path: <T, B = {}, R = AxiosResponse<T>>(
      url: string,
      data?: B,
      config?: IAxiosRequestConfig
    ): Promise<R> => {
      return instance.patch(url, data, config);
    },

    delete: <T, R = AxiosResponse<T>>(
      url: string,
      config?: IAxiosRequestConfig
    ): Promise<R> => {
      return instance.delete(url, config);
    },

    deleteByBody: <T, B = {}, R = AxiosResponse<T>>(
      url: string,
      data?: B,
      config?: IAxiosRequestConfig
    ): Promise<R> => {
      return instance.delete(url, {
        data,
        ...config,
      });
    },
  };
};

export const AxiosInstance = APIInstance();
