import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
type Options = {
  initialFields: Record<string, any>;
};
const useQueryParam = (options?: Options) => {
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams, setQueryParams] = useState<Record<string, any>>({});

  const mountedRef = useRef(true);

  const updateQueryParams = (newParams: any, fromInitiator?: boolean) => {
    if (!mountedRef.current) return;
    if (!initialized) {
      if (!fromInitiator) return;
    }
    const searchParams = new URLSearchParams();

    // Merge newParams with existing queryParams
    const mergedParams = { ...queryParams, ...newParams };
    Object.entries(mergedParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => searchParams.append(`${key}`, v));
      } else {
        if (value !== undefined && String(value).length > 0)
          searchParams.set(key, String(value));
        else searchParams.delete(key);
      }
    });

    setQueryParams(() => ({
      ...mergedParams,
    }));
    navigate({ search: searchParams.toString() });
  };

  const getFieldsFromQuery = () => {
    const searchParams = new URLSearchParams(location.search);
    const params: Record<string, string | string[]> = {};
    // @ts-ignore
    for (const [key, value] of searchParams.entries()) {
      const existingValue = params[key];
      if (Array.isArray(existingValue)) {
        params[key] = [...existingValue, value];
      } else if (existingValue !== undefined) {
        params[key] = [existingValue, value];
      } else {
        params[key] = value;
      }
    }
    return params;
  };
  const doInit = (initialFields: Options["initialFields"]) => {
    updateQueryParams(
      {
        ...initialFields,
        ...getFieldsFromQuery(),
      },
      true
    );
    setInitialized(true);
  };

  useEffect(() => {
    if (initialized) return;
    if (options?.initialFields) {
      setTimeout(() => {
        doInit(options.initialFields);
      }, 500);
    }
  }, [options?.initialFields]);

  useLayoutEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return {
    queryParams,
    updateQueryParams,
    initialized,
  };
};

export default useQueryParam;
