import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

interface IEnterLocaionModal {
  closeModal: (clearModels?: boolean) => void;
  data: {
    onConfirm: (lat: number, lng: number) => void;
  };
}

interface IErrors {
  lat: string[];
  lng: string[];
}

export const EnterLocationModal: React.FC<IEnterLocaionModal> = (props) => {
  const { closeModal, data } = props;
  const { onConfirm } = data;

  const [errors, setErrors] = useState<IErrors>({
    lat: [],
    lng: [],
  });

  const onFormSubmitted = (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      lat: { value: string };
      lng: { value: string };
    };
    const tempErrors: IErrors = { lat: [], lng: [] };
    if (!target.lat.value) {
      tempErrors.lat = ["Latitude is Required"];
    } else {
      if (isNaN(parseFloat(target.lat.value))) {
        tempErrors.lat = [...tempErrors.lat, "Latitude should be a number"];
      }
    }
    if (!target.lng.value) {
      tempErrors.lng = ["Latitude is Required"];
    } else {
      if (isNaN(parseFloat(target.lng.value))) {
        tempErrors.lng = [...tempErrors.lng, "Longitude should be a number"];
      }
    }
    setErrors(tempErrors);
    if (!(tempErrors.lat.length || tempErrors.lng.length)) {
      onConfirm(parseFloat(target.lat.value), parseFloat(target.lng.value));
    }
    closeModal(true);
  };

  return (
    <Modal isOpen={true} size="2xl" onClose={() => closeModal(true)}>
      <ModalOverlay />
      <ModalContent>
        <Box p="4">
          <form noValidate onSubmit={onFormSubmitted}>
            <Box mb="3" fontWeight="500" fontSize="1.2rem">
              Enter Latitude and Longitude
            </Box>
            <Box mb="3">
              <FormControl isRequired isInvalid={!!errors.lat.length}>
                <FormLabel htmlFor="lat">Latitude</FormLabel>
                <Input name="lat" id="lat" />
                {errors.lat.map((er) => (
                  <Text key={er} color="red.500" fontSize="0.875rem" mt="1">
                    {er}
                  </Text>
                ))}
              </FormControl>
            </Box>
            <Box mb="3">
              <FormControl isRequired isInvalid={!!errors.lng.length}>
                <FormLabel htmlFor="lng">Longitude</FormLabel>
                <Input name="lng" id="lng" />
                {errors.lng.map((er) => (
                  <Text key={er} color="red.500" fontSize="0.875rem" mt="1">
                    {er}
                  </Text>
                ))}
              </FormControl>
            </Box>
            <Box>
              <Button colorScheme="primary" size="lg" type="submit" mr="3">
                Confirm
              </Button>
              <Button
                colorScheme="secondary"
                size="lg"
                type="button"
                onClick={() => closeModal(true)}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </ModalContent>
    </Modal>
  );
};
