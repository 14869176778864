import { Spinner, SpinnerProps, ThemingProps } from "@chakra-ui/react";

interface IProps extends SpinnerProps {
}

const SPSpinner: React.FC<IProps> = (props) => {
    const { thickness = 4,
        size = "md",
        emptyColor = "gray.200",
        color = "blue.500",
        speed = "0.65s",
    } = props;

    return <Spinner
        thickness={`${thickness}px`}
        size={size}
        emptyColor={emptyColor}
        color={color}
        speed={speed}
        {...props}
    />
}

export default SPSpinner;