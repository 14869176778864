import { Center, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { NotFoundResult } from "src/components/app/NotFoundResult";
import { Pagination } from "src/components/app/Pagination";
import SPSpinner from "src/components/app/SPSpinner";
import useUserListData from "src/hooks/useUserListData";
import { USER_ROLE } from "src/types/user-management";
import { useUserManagementData } from "../../UserManagementProvider";
import UserDataRow from "../UserDataRow";

interface UserListTableProps {
    tabIsActive: boolean;
}

const UserListTable:React.FC<UserListTableProps> = ({ tabIsActive }) => {
    const { t } = useTranslation();
    const { selectedRoleTab } = useUserManagementData();

    const {loading, userlist, pageTotalCount, handlePassPaginateValues, searchQuery} = useUserListData({ tabIsActive });
    
    const handleZoneGroupTH = () => {
        if(selectedRoleTab === USER_ROLE.GroupAgent) {
            return <Th>Group</Th>;
        } else if(selectedRoleTab === USER_ROLE.ZoneAgent) {
            return <Th>Zone</Th>
        } else {
            return null;
        }
    }

    const renderTable = () => {
        return <>
            <Table size="md" border="1px solid #EEEEEE">
                <Thead pt={2} pb={2} bg="#E6E6E633">
                    <Tr>
                        <Th>{t("fields.firstName")}</Th>
                        <Th>{t("fields.lastName")}</Th>
                        <Th>{t("fields.userName")}</Th>
                        {handleZoneGroupTH()}
                        <Th>{t("fields.createdAt")}</Th>
                        <Th>{t("fields.lastLogin")}</Th>
                        <Th>{t("fields.enableDisable")}</Th>
                        <Th>{t("fields.action")}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    { userlist.map(user => <UserDataRow key={user.id} user={user}/>) }
                </Tbody>
            </Table>
            <Table>
                <Thead></Thead>
                <Tbody>
                    <Tr>
                        <Td colSpan={9} border="none" padding="0">
                            <Pagination
                                entityTitle={"User"}
                                pageTotalCount={pageTotalCount}
                                passPaginateValues={handlePassPaginateValues}
                                isVisiblePageSizeSelector={false}
                                isStoreOnLocalStorage={true}
                                perPageItems={searchQuery.size} />
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
        </>;
    }

    return <>
        <TableContainer background="#fff" borderRadius="8px">
            {
                loading 
                ? <Center minHeight="200px">
                    <SPSpinner/>
                </Center>
                : userlist.length > 0 ? renderTable() : <NotFoundResult/>
            }
        </TableContainer>
    </>;
}

export default UserListTable;