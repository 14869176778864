import { string, object } from "yup";

export const getRiderValidation = (t: any, operation: "create" | "update") => {
  const baseValidations: any = {
    firstName: string().required(
      t("validationMessages.fieldIsRequired", {
        field: t("fields.firstName"),
      })
    ),
    lastName: string().required(
      t("validationMessages.fieldIsRequired", { field: t("fields.lastName") })
    ),
    employeeId: string().required(
      t("validationMessages.fieldIsRequired", {
        field: t("fields.employeeId"),
      })
    ),
  };
  if (operation === "create") {
    baseValidations["rawPassword"] = string().required(
      t("validationMessages.fieldIsRequired", {
        field: t("fields.password"),
      })
    );
  }
  return object().shape(baseValidations);
};
