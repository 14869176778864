import { IPaginatedResult } from "./axios-response";
import { IMiniRiderData } from "./riders";

export enum VehicleType {
    Car = "Car",
    Bike = "Bike"
}
/* 
This interface use for get vehicle information from create/update form, it this scenario plateNumber geted in two part left and right 
*/
export interface IVehicleOfCreateForm {
    vehicleType: VehicleType;
    plateNumber_left: string;
    plateNumber_right: string;
    chassis: string;
}

export interface IBaseVehicleItem {
    vehicleType: VehicleType;
    plateNumber: string;
    chassis: string;
}

export interface IVehicleListItem extends IBaseVehicleItem {
    id: string;
    rider?: IMiniRiderData;
}

export interface IVehicleAssignToRider {
    riderId: string;
    isForcedToAssign: boolean;
}

export interface IVehicleListPayload extends IPaginatedResult<IVehicleListItem> { }

export type TVehicleTypes = VehicleType.Bike | VehicleType.Car; 