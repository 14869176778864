import { FormItem, FormItemObject } from "../types/form";
import { useState } from "react";

export const useFormItems = (initialItems: FormItemObject) => {
  const [formItems, setFormItems] = useState<FormItemObject>(initialItems);

  const updateFormItemProperty = (
    itemName: string,
    propertyName: keyof FormItem,
    value: any
  ) => {
    setFormItems((prevItems) => ({
      ...prevItems,
      [itemName]: {
        ...prevItems[itemName],
        [propertyName]: value,
      },
    }));
  };

  const invokeFormItemProperty = (itemName: string, args?: any) => {
    if (formItems[itemName]?.revoke) return formItems[itemName].revoke!(args);
  };

  const removeFormItem = (itemName: string) => {
    delete formItems[itemName];
  };

  const refreshFormItems = (items: FormItemObject) => {
    setFormItems(items);
  };

  return {
    formItems,
    updateFormItemProperty,
    invokeFormItemProperty,
    removeFormItem,
    refreshFormItems,
  };
};
