import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { panelRidersService } from "src/api/services/riders";
import { IRiderListItem } from "src/api/types/riders";
import ContentView, {
  ContentViewBody,
  ContentViewHeader,
} from "src/components/app/ContentView";
import {
  Box,
  Button,
  Center,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import FormRow from "src/components/app/FormRow";
import dateHelper from "src/utils/date-helper";
import {
  mdiAccount,
  mdiCar,
  mdiCarOff,
  mdiKeyVariant,
  mdiPencil,
} from "@mdi/js";
import Icon from "@mdi/react";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import RiderChangePasswordModal from "src/components/app/RiderChangePasswordModal";
import { IVehicleListItem } from "src/api/types/vehicle";
import VehicleAssignmentModal from "src/components/app/VehicleAssignmentModal";
import VehicleUnassignModal from "src/components/app/VehicleAssignmentModal/VehicleUnassignModal";
import { BrowserBack } from "src/components/app/BrowserBack";
import RiderSwitchState from "./components/RiderSwitchState";
import SPSpinner from "src/components/app/SPSpinner";
import { useAsync } from "../../../../hooks/useAsync";
import { panelTargetGroupsService } from "../../../../api/services/target-groups";
import { IPaginatedResult } from "../../../../api/types/axios-response";
import { IRiderTargetGroup } from "../../../../api/types/target-groups";
import HorizontalLoading from "../../../../components/app/HorizontalLoading";

const RiderDetails: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.riderDetails"));

  const params = useParams<{ id: string }>();
  const [getDataAbortController] = useState<AbortController>(
    new AbortController()
  );
  const [riderDetails, setRiderDetails] = useState<IRiderListItem>();
  const [loading, setLoading] = useState<boolean>(true);
  const [openChangePasswordModal, setOpenChangePasswordModal] =
    useState<boolean>(false);
  const [openVehicleAssignmentModal, setOpenVehicleAssignmentModal] =
    useState<boolean>(false);
  const [openVehicleUnassignmentModal, setopenVehicleUnassignmentModal] =
    useState<boolean>(false);
  const [mustBeRefreshComponent, setMustBeRefreshComponent] =
    useState<boolean>(false);
  const [isChangingVehicleState, setIsChangingVehicleState] =
    useState<boolean>(false);
  const [isChangingZoneState, setIsChangingZoneState] =
    useState<boolean>(false);

  const getRiderDetail = useCallback(async () => {
    try {
      const result = await panelRidersService.get(
        params.id!,
        getDataAbortController.signal
      );
      setRiderDetails(result.data.data);
      setTimeout(() => {
        setIsChangingVehicleState(false);
        setIsChangingZoneState(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [getDataAbortController, params, mustBeRefreshComponent]);

  const getTargetGroupsAsync = useAsync<
    IPaginatedResult<IRiderTargetGroup>,
    void
  >(
    () =>
      new Promise(async (resolve, reject) => {
        console.log("getTargetGroupsAsync");
        try {
          const response = await panelTargetGroupsService.getRiderTargetGroups(
            params.id!
          );
          console.log("getTargetGroupsAsync RES ", response);
          resolve(response.data.data);
        } catch (e) {
          reject(e);
        }
      }),
    {
      immediate: true,
    }
  );

  useEffect(() => {
    getRiderDetail();
  }, [getRiderDetail]);

  const zoneInfo = () => {
    if (riderDetails?.zone) {
      return (
        <Box>
          <FormRow
            bidirectional
            leftContent={
              <Box mb="2">
                <Text as="span" fontWeight="500">
                  {t("fields.zone")}:{" "}
                </Text>
                {isChangingZoneState ? (
                  <SPSpinner ml="2" />
                ) : (
                  <Text
                    ml="2"
                    color="blue.400"
                    as={Link}
                    to={
                      ROUTE_CONSTANTS.DASHBOARD.ZONES.DETAILS.BY_DATA(
                        riderDetails.zone?.id
                      ).ABSOLUTE
                    }
                  >
                    {riderDetails?.zone?.nameEn || "---"}
                  </Text>
                )}
              </Box>
            }
            rightContent=""
          ></FormRow>
        </Box>
      );
    }
  };

  const renderVehicleSection = (vehicle: IVehicleListItem) => {
    if (isChangingVehicleState) {
      return <SPSpinner />;
    }

    if (vehicle) {
      return (
        <>
          <Text
            as="span"
            ml="2"
            mr="2"
          >{`${riderDetails?.vehicle?.plateNumber} - ${riderDetails?.vehicle?.chassis}`}</Text>
          <Button
            colorScheme="red"
            onClick={() => setopenVehicleUnassignmentModal(true)}
            disabled={!riderDetails?.isActive}
          >
            <Icon size="22px" path={mdiCarOff} />
            <Text as="span" ml="1">
              Unassign
            </Text>
          </Button>
        </>
      );
    }

    return (
      <Text as="span" ml="2">
        <Button
          bg="#F39905"
          color="#000"
          onClick={() => setOpenVehicleAssignmentModal(true)}
          disabled={!riderDetails?.isActive}
        >
          <Icon size="22px" path={mdiCar} />
          <Text as="span" ml="1">
            Assign
          </Text>
        </Button>
      </Text>
    );
  };

  const onAssignUnassignVehicle = () => {
    setIsChangingVehicleState(true);
    setMustBeRefreshComponent(!mustBeRefreshComponent);
  };

  const handleChangeRiderStatus = () => {
    setIsChangingVehicleState(true);
    setIsChangingZoneState(true);
    setMustBeRefreshComponent(!mustBeRefreshComponent);
  };

  return (
    <>
      <Box display="flex" flexDirection="row">
        <ContentView>
          {loading && (
            <Center>
              <SPSpinner size="xl" />
            </Center>
          )}
          {riderDetails && (
            <>
              <ContentViewHeader>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Text as="h3" fontSize="1.4em" display="inline-block">
                      Rider Details
                    </Text>
                    <IconButton
                      aria-label="Edit rider"
                      as={Link}
                      to={
                        ROUTE_CONSTANTS.DASHBOARD.RIDERS.UPDATE.BY_DATA(
                          riderDetails.id
                        ).ABSOLUTE
                      }
                      ml="4"
                      icon={<Icon path={mdiPencil} size="28px" />}
                      colorScheme="primary"
                      variant="outline"
                      size="sm"
                    />
                    <BrowserBack />
                  </Box>
                  <Button
                    bg="#F39905"
                    color="#000"
                    onClick={() => setOpenChangePasswordModal(true)}
                  >
                    <Icon size="20px" path={mdiKeyVariant} />
                    <Text as="span" ml="1">
                      Change Password
                    </Text>
                  </Button>
                </Box>
              </ContentViewHeader>
              <ContentViewBody>
                <FormRow
                  bidirectional
                  leftContent={
                    riderDetails.avatar ? (
                      <img
                        style={{
                          borderRadius: "50%",
                          width: "150px",
                          height: "150px",
                          objectFit: "cover",
                        }}
                        src={riderDetails.avatar}
                        alt="rider avatar"
                      />
                    ) : (
                      <Icon
                        path={mdiAccount}
                        size="150px"
                        style={{ opacity: 0.4 }}
                      />
                    )
                  }
                ></FormRow>
                <RiderSwitchState
                  riderDetail={riderDetails}
                  handleUpdateDetails={handleChangeRiderStatus}
                />
                <Box mb="6">
                  <Box>
                    <Text as="span" fontWeight="500">
                      {t("fields.employeeId")}:{" "}
                    </Text>
                    <Text as="span" ml="2">
                      {riderDetails?.employeeId || "---"}
                    </Text>
                  </Box>
                </Box>
                <FormRow
                  bidirectional
                  leftContent={
                    <Box>
                      <Text as="span" fontWeight="500">
                        {t("fields.firstName")}:{" "}
                      </Text>
                      <Text as="span" ml="2">
                        {riderDetails?.firstName || "---"}
                      </Text>
                    </Box>
                  }
                  rightContent=""
                ></FormRow>
                <FormRow
                  bidirectional
                  leftContent={
                    <Box>
                      <Text as="span" fontWeight="500">
                        {t("fields.lastName")}:{" "}
                      </Text>
                      <Text as="span" ml="2">
                        {riderDetails?.lastName || "---"}
                      </Text>
                    </Box>
                  }
                  rightContent=""
                ></FormRow>
                <FormRow
                  bidirectional
                  leftContent={
                    <Box>
                      <Text as="span" fontWeight="500">
                        {t("fields.riderType")}:{" "}
                      </Text>
                      <Text as="span" ml="2">
                        {riderDetails?.riderType || "---"}
                      </Text>
                    </Box>
                  }
                  rightContent=""
                ></FormRow>
                <FormRow
                  bidirectional
                  leftContent={
                    <Box>
                      <Text as="span" fontWeight="500">
                        {t("fields.workingStatus")}:{" "}
                      </Text>
                      <Text as="span" ml="2">
                        {riderDetails?.workingStatus?.title || "---"}
                      </Text>
                    </Box>
                  }
                  rightContent=""
                ></FormRow>
                <FormRow
                  bidirectional
                  leftContent={
                    <Box>
                      <Text as="span" fontWeight="500">
                        {t("fields.riderCategory")}:{" "}
                      </Text>
                      <Text as="span" ml="2">
                        {riderDetails?.riderCategory || "---"}
                      </Text>
                    </Box>
                  }
                  rightContent=""
                ></FormRow>
                <Box mb="6">
                  <Box>
                    <Text as="span" fontWeight="500">
                      {t("fields.driverLicenseNo")}:{" "}
                    </Text>
                    <Text as="span" ml="2">
                      {riderDetails?.driverLicenseNo || "---"}
                    </Text>
                  </Box>
                </Box>
                <Box mb="6">
                  <Box>
                    <Text as="span" fontWeight="500">
                      {t("fields.createdAt")}:{" "}
                    </Text>
                    <Text as="span" ml="2">
                      {dateHelper.formatDate(riderDetails.registerDate) ||
                        "---"}
                    </Text>
                  </Box>
                </Box>
                <Box mb="6">
                  <Box display="flex" alignItems="center">
                    <Text as="span" fontWeight="500" mr="2">
                      {t("fields.vehicle")}:{" "}
                    </Text>
                    {renderVehicleSection(riderDetails.vehicle)}
                  </Box>
                </Box>
                {zoneInfo()}
                <hr />
                {getTargetGroupsAsync.isLoading && <HorizontalLoading />}
                <Table
                  variant="simple"
                  colorScheme="blackAlpha"
                  size="md"
                  boxShadow="md"
                  backgroundColor="white"
                  borderRadius="md"
                  mt={"4"}
                >
                  <Thead>
                    <Tr>
                      <Th>Target Group</Th>
                      <Th>From</Th>
                      <Th>To</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {getTargetGroupsAsync.data?.items?.map((targetGroupRow) => (
                      <Tr>
                        <Td>{targetGroupRow.targetGroupName}</Td>
                        <Td>
                          {targetGroupRow.startDate
                            ? dateHelper.formatDate(targetGroupRow.startDate)
                            : "------"}
                        </Td>
                        <Td>
                          {targetGroupRow.endDate
                            ? dateHelper.formatDate(targetGroupRow.endDate)
                            : "------"}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </ContentViewBody>
            </>
          )}
        </ContentView>
      </Box>
      {openChangePasswordModal && (
        <RiderChangePasswordModal
          riderId={params.id!}
          openChangePasswordModal={openChangePasswordModal}
          setOpenChangePasswordModal={setOpenChangePasswordModal}
        />
      )}
      {openVehicleAssignmentModal && (
        <VehicleAssignmentModal
          rider={riderDetails!}
          openAssignmentModal={openVehicleAssignmentModal}
          setIsOpen={() => setOpenVehicleAssignmentModal(false)}
          handleOnAssign={onAssignUnassignVehicle}
        />
      )}
      {openVehicleUnassignmentModal && (
        <VehicleUnassignModal
          riderId={riderDetails?.id!}
          openUnassignModal={openVehicleUnassignmentModal}
          setIsOpen={() => setopenVehicleUnassignmentModal(false)}
          handleOnUnassign={onAssignUnassignVehicle}
        />
      )}
    </>
  );
};

export default RiderDetails;
