import { Box } from "@chakra-ui/react";
import { mdiCheck } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

interface ICheckboxProps {
    selected: boolean;
}

const Checkbox: React.FC<ICheckboxProps> = ({ selected }) => {
    return <Box width="24px" height="24px" borderRadius={6} border="1px solid" borderColor={selected ? "#FE9920" : "#DDDDDD"}
        display="flex" alignItems="center" justifyContent="center" color="#FFFFFF" bg={selected ? "#FE9920" : "transparent"}>
        {selected && <Icon size="18px" path={mdiCheck} />}
    </Box>;
}

export default Checkbox;