import React from "react";
import { Box, Text } from "@chakra-ui/react";

interface IFileExceptions {
    exceptionMessage:string[];
}
export const ShowFileExceptions:React.FC<IFileExceptions> = ({exceptionMessage}) => {

    const showSubExceptions = (subExceptionStr:string , spliter:string) => {
        let subExpArr = [];
        if(subExceptionStr) {
            if(subExceptionStr.indexOf(spliter) > -1) {
                let subExptionsArr = subExceptionStr.split(spliter);
                subExptionsArr.forEach((se,ind) => {
                    subExpArr.push(<Text key={ind} fontSize="1em" color="#888">{se}</Text>);
                });
            } else {
                subExpArr.push(<Text fontSize="1em" color="#888">{subExceptionStr}</Text>);
            }
        }

        return subExpArr;
    }

    let exceptionBox: any = [];
    exceptionMessage.forEach((exp , ind) => {
        let expArray = exp.split("raised exception :"); // convention with backend: this text are puted to exception message that received from backend
        exceptionBox.push(<div key={ind} className="exp_item">
            <Box fontSize="0.875rem">
                <Text color="#333" fontWeight="500">{expArray[0]}</Text>
                { showSubExceptions(expArray[1],"#") }
            </Box>
        </div>);
    });

    return <Box className="exceptions_container"><Text as="h4" fontWeight="bold" color="#D51F27" mb="2">Errors</Text>{exceptionBox}</Box>;
}