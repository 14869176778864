import React, { useEffect, useState } from "react";
import { Center, Input } from "@chakra-ui/react";
import { ILocationLog } from "src/api/types/rider-locations";
import { NotFoundResult } from "src/components/app/NotFoundResult";
import {
  useExtendedLiveMapDispatch,
  useExtendedLiveMapState,
} from "../../ExtendedLiveMapContext";
import { ExtendedLiveMapActionKind } from "../../ExtendedLiveMapStore/ExtendedLiveMap-Actions";
import { RiderItem } from "./RiderItem";
import { panelRiderLocationService } from "src/api/services/rider-locations";
import { TIMER_TICK_TIME } from "src/constants/timer";
import SPSpinner from "src/components/app/SPSpinner";
import "./Riders.css";

export const Riders: React.FC = () => {
  const { locationLogs, loadingRiders, activeZone } = useExtendedLiveMapState();
  const dispatch = useExtendedLiveMapDispatch();
  const [searched, setSearched] = useState<string>("");
  const [getZoneRidersAbortController] = useState<AbortController>(
    new AbortController()
  );

  const onRiderItemClicked = (sender: ILocationLog) => {
    let updatedMapCenter = sender.location
      ? { lat: sender.location.latitude, lng: sender.location.longitude }
      : {};
    dispatch({
      type: ExtendedLiveMapActionKind.SET_ACTIVE_LOCATION_LOG,
      data: {
        activeLocationLog: sender,
        hasLocation: !!sender.location,
        mapCenter: updatedMapCenter,
      },
    });
  };

  const getZoneRiders = async (zoneId: string) => {
    try {
      if (zoneId) {
        const result = await panelRiderLocationService.getAllByZoneId(
          zoneId,
          getZoneRidersAbortController.signal
        );
        const {
          items,
          totalRiders,
          activeRidersCount,
          busyRidersCount,
          idleRidersCount,
          unreachableRidersCount,
        } = result.data.data;
        dispatch({
          type: ExtendedLiveMapActionKind.SET_LOCATION_LOGS,
          data: {
            locationLogs: items,
            activeZoneStatistics: {
              totalRiders,
              activeRidersCount,
              busyRidersCount,
              idleRidersCount,
              unreachableRidersCount,
            },
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: ExtendedLiveMapActionKind.SET_LOADING_RIDERS,
        data: { loadingRiders: false },
      });
    }
  };

  const refreshLogs = () => {
    let activeZoneId = activeZone.id;
    return getZoneRiders(activeZoneId);
  };

  useEffect(() => {
    setSearched("");
    if (activeZone?.id) {
      dispatch({
        type: ExtendedLiveMapActionKind.SET_MAP_CENTER,
        data: {
          mapCenter: {
            lat: activeZone.location.latitude,
            lng: activeZone.location.longitude,
          },
        },
      });
      getZoneRiders(activeZone.id);
      let timeInterval = setInterval(refreshLogs, TIMER_TICK_TIME);
      return () => {
        clearInterval(timeInterval);
      };
    }
  }, [activeZone]);

  const generateRidersList = () => {
    let riderItems = [];

    if (locationLogs.length === 0) {
      return (
        <Center>
          <NotFoundResult />
        </Center>
      );
    }

    riderItems = locationLogs
      .filter((lg) => {
        if (
          lg.rider.firstName.trim().toLocaleLowerCase().includes(searched) ||
          lg.rider.lastName.trim().toLocaleLowerCase().includes(searched) ||
          lg.rider.employeeId.trim().includes(searched) ||
          `${lg.rider.firstName.trim().toLocaleLowerCase()} ${lg.rider.lastName
            .trim()
            .toLocaleLowerCase()}`.includes(searched)
        ) {
          return lg;
        }
      })
      .map((lg, index) => {
        return <RiderItem data={lg} key={index} onClick={onRiderItemClicked} />;
      });

    return (
      <>
        <Input
          type="text"
          placeholder="Search a rider"
          value={searched}
          onChange={(e) => setSearched(e.target.value)}
        />
        <div className="l-m-riders-list">{riderItems}</div>
      </>
    );
  };

  return (
    <div className="l-m-riders">
      {!loadingRiders && generateRidersList()}
      {loadingRiders && (
        <div className="empty-list">
          <SPSpinner colorScheme="whiteAlpha" />
        </div>
      )}
    </div>
  );
};
