import {
  Box,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Button,
} from "@chakra-ui/react";
import { mdiCheckboxMarkedCircle, mdiCheckCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";

interface ICreateOrderByAdminConfirmationModalProps {
  openConfirmationModal: boolean;
  setOpenConfirmationModal: React.Dispatch<SetStateAction<boolean>>;
  handleContinueToAddNewOrder: () => void;
}

const CreateOrderByAdminConfirmationModal: React.FC<
  ICreateOrderByAdminConfirmationModalProps
> = ({
  openConfirmationModal,
  setOpenConfirmationModal,
  handleContinueToAddNewOrder,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={openConfirmationModal}
      onClose={() => setOpenConfirmationModal(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          textAlign="center"
          fontSize="1.3rem"
          fontWeight="600"
          mt="3"
        >
          Order Submitted
        </ModalHeader>
        <ModalBody pb="10">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDir="column"
            gap="8px"
            mt={-3}
          >
            <Text fontSize="20px"></Text>
            <Icon path={mdiCheckCircleOutline} size="150px" color="#24A771" />
            <Text as="p">Your order submitted successfuly!</Text>
          </Box>
          <Box display="flex" justifyContent="center" gap="8px" mt={4}>
            <Button
              variant="outline"
              colorScheme="gray"
              onClick={() =>
                navigate(
                  ROUTE_CONSTANTS.DASHBOARD.PAGING_ORDERS_HISTORY.ABSOLUTE
                )
              }
            >
              Back to list
            </Button>
            <Button variant="yellow_100" onClick={handleContinueToAddNewOrder}>
              Add New Order
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateOrderByAdminConfirmationModal;
