import axios from "axios";
import { ACCESS_TOKEN } from "src/constants/access-token";
import { HTTP_STATUS_CODES } from "src/constants/http-status-codes";
import { getAuthorization } from "src/utils/get-authorization";
import { toast } from "src/utils/toast";

import { cacheService } from "./cache-service";

export const responseInterceptors = {
  successInterceptor(response: any) {
    if (response.config.customCache) {
      cacheService.setItem(response.config.cacheKey, response);
    }
    return response;
  },
  errorInterceptor(error: any) {
    if (axios.isCancel(error) || error.config.showError === false) {
      return Promise.reject(error);
    }

    const statusCode = error?.response?.status;
    let url = error?.response?.config?.url;

    let assignVehicleApiPattern =
      /^admin-api\/v1\/vehicles\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/rider$/;
    if (
      assignVehicleApiPattern.test(url) &&
      statusCode === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY
    ) {
      // on 422 status code
      return error.response;
    }

    if (statusCode) {
      switch (statusCode) {
        case HTTP_STATUS_CODES.CACHED_RESPONSE:
          return Promise.resolve(error.response.data);
        case HTTP_STATUS_CODES.VALIDATION_ERROR:
          toast.error(error.response?.data?.meta?.message);
          break;
        case HTTP_STATUS_CODES.SERVER_ERROR:
          toast.error(error.response?.data?.meta?.message);
          break;
        case HTTP_STATUS_CODES.NOT_FOUND:
          toast.error(error.response?.data?.meta?.message);
          break;
        case HTTP_STATUS_CODES.UN_AUTHORIZED:
          toast.error(error.response?.data?.meta?.message);
          localStorage.removeItem(ACCESS_TOKEN);
          window.location.reload();
          break;
        case HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY:
          toast.error(error.response?.data?.meta?.message);
          break;
        default:
          return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
};

export const requestInterceptors = {
  beforeSent(config: any) {
    if (
      config.url.includes("admin-api/") ||
      config.url.includes("paging-api/")
    ) {
      const token = getAuthorization();
      config.headers = {
        ...config.headers,
      };

      if (token) {
        config.headers.Authorization = token;
      }
    }

    return cacheService.handleCached(config);
  },
  onError(error: any) {
    return Promise.reject(error);
  },
};
