export enum  USER_ROLE {
    None = "None",
    Rider = "Rider",
    Admin = "Admin",
    GroupAgent = "GroupAgent",
    ZoneAgent = "ZoneAgent"
}

export type USER_ROLE_TYPES = USER_ROLE.Admin | USER_ROLE.GroupAgent | USER_ROLE.ZoneAgent;
export type TCountBadge = Record<USER_ROLE.Admin | USER_ROLE.GroupAgent | USER_ROLE.ZoneAgent, number>;

export interface IuseUserManagementContext {
    selectedRoleTab: USER_ROLE_TYPES;
    setSelectedRoleTab: React.Dispatch<React.SetStateAction<USER_ROLE_TYPES>>;
    searchKeyword: string;
    setSearchKeyword: React.Dispatch<React.SetStateAction<string>>;
    triggerRefreshUserList: boolean;
    setTriggerRefreshUserList: React.Dispatch<React.SetStateAction<boolean>>;
    counts: TCountBadge,
    setCounts: React.Dispatch<React.SetStateAction<TCountBadge>>;
}

export interface IROleData {
    tabIndex: number;
    role: USER_ROLE_TYPES;
    title: string;
}

export interface ICreateUserFormData {
    firstName: string;
    lastName: string;
    username: string;
    rawPassword: string;
    confirmPassword: string;
    role: USER_ROLE_TYPES;
    zoneId: string | null;
    groupId: string | null;
    roleDependency: string;
}

export interface IUpdateUserFormData extends Omit<ICreateUserFormData, "rawPassword" | "confirmPassword">{ id: string };
export interface IUserResetPasswordFormData extends Pick<ICreateUserFormData, "rawPassword" | "confirmPassword" | "username">{};