import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { panelDashboardService } from "src/api/services/dashboard";
import { IStatisticsData } from "src/api/types/dashboard";
import CircleSkeleton from "src/components/app/CircleSkeleton";
import "./styles.css";
import Icon from "@mdi/react";
import {
  mdiAccountGroup,
  mdiBasket,
  mdiBlockHelper,
  mdiCounter,
  mdiCountertop,
  mdiHomeGroupPlus,
  mdiHomeMapMarker,
} from "@mdi/js";
import { useGlobal } from "../../../../../global-context/GlobalContext";
import { useNavigate } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";

interface IDashboardStatisticsProps {
  timestamp: number;
}

const DashBoardStatistics: React.FC<IDashboardStatisticsProps> = ({
  timestamp,
}) => {
  const navigate = useNavigate();
  const { statistics, updateStatistics } = useGlobal();
  const [loading, setLoading] = useState<boolean>(true);
  const [statisticsAbortController] = useState<AbortController>(
    new AbortController()
  );

  const fetchStatistics = useCallback(async () => {
    try {
      const result = await panelDashboardService.getStatistics(
        statisticsAbortController.signal
      );
      updateStatistics({
        ...result.data.data,
        updateTime: new Date(),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  useEffect(() => {
    fetchStatistics();
  }, [fetchStatistics]);

  useEffect(() => {
    return () => {
      statisticsAbortController.abort();
    };
  }, []);

  const handleRidersNumber = () => {
    let totalRidersCount = statistics?.totalRidersCount;
    let activeRidersNumber = statistics?.checkInRidersCount;

    return (
      <>
        <CircleSkeleton loadingSize="l-sm" isLoaded={loading}>
          <Box className="statistics-item">
            <Box>
              <Box className="title">Total Riders</Box>
              <Box className="value">{totalRidersCount}</Box>
            </Box>
            <Box className="icon-container totalRiders">
              <Icon path={mdiAccountGroup} size="22px" />
            </Box>
          </Box>
        </CircleSkeleton>
        <CircleSkeleton loadingSize="l-sm" isLoaded={loading}>
          <Box className="statistics-item">
            <Box>
              <Box className="title">Active Riders</Box>
              <Box className="value">{activeRidersNumber}</Box>
            </Box>
            <Box className="icon-container activeRiders">
              <Icon path={mdiAccountGroup} size="22px" />
            </Box>
          </Box>
        </CircleSkeleton>
      </>
    );
  };

  return (
    <Box className="statistics_container">
      {handleRidersNumber()}
      <CircleSkeleton loadingSize="l-sm" isLoaded={loading}>
        <Box
          className="statistics-item clickable"
          onClick={() =>
            navigate(
              `${ROUTE_CONSTANTS.DASHBOARD.RIDERS.ROOT.ABSOLUTE}?offset=0&size=10&status=${statistics.riderOnLeaveWorkingStatusId}`
            )
          }
        >
          <Box>
            <Box className="title">On-Leave Riders</Box>
            <Box className="value">{statistics?.totalRiderOnLeaveCount}</Box>
            <Box className="desc">
              updated time : {statistics?.updateTime?.toLocaleTimeString()}
            </Box>
          </Box>
          <Box className="icon-container zones">
            <Icon path={mdiHomeMapMarker} size="22px" />
          </Box>
        </Box>
      </CircleSkeleton>
      <CircleSkeleton loadingSize="l-sm" isLoaded={loading}>
        <Box className="statistics-item">
          <Box>
            <Box className="title">Busy Riders</Box>
            <Box className="value">{statistics?.totalRiderBusyCount}</Box>
            <Box className="desc">
              updated time : {statistics?.updateTime?.toLocaleTimeString()}
            </Box>
          </Box>
          <Box className="icon-container groups">
            <Icon path={mdiBlockHelper} size="22px" />
          </Box>
        </Box>
      </CircleSkeleton>
      <CircleSkeleton loadingSize="l-sm" isLoaded={loading}>
        <Box className="statistics-item">
          <Box>
            <Box className="title">Today Delivered Orders</Box>
            <Box className="value">{statistics?.totalDeliveredOrdersCount}</Box>
          </Box>
          <Box className="icon-container vehicles">
            <Icon path={mdiBasket} size="22px" />
          </Box>
        </Box>
      </CircleSkeleton>
    </Box>
  );
};

export default DashBoardStatistics;
