import React, { useEffect, useState } from "react";
import {
  Td,
  Tr,
  Table,
  Thead,
  Th,
  Tbody,
  Text,
  Center,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import Icon from "@mdi/react";
import { panelAttendanceLogService } from "src/api/services/attendance-log";
import {
  IAttendanceSummationItem,
  IAttendanceReportItem,
} from "src/api/types/attendance-log";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import dateHelper from "src/utils/date-helper";
import { toast } from "src/utils/toast";
import { Pagination } from "src/components/app/Pagination";
import { useSearch } from "src/hooks/useSearch";
import "./styles.css";
import SPSpinner from "src/components/app/SPSpinner";

export interface IFiltersItem {
  count?: number;
  fromDate: string;
  toDate: string;
}

interface IAggregationRowRecordProps {
  index: string;
  rowData: IAttendanceSummationItem;
  filters: IFiltersItem;
  pageChanged: boolean;
}

export const AggregationRowRecord: React.FC<IAggregationRowRecordProps> = ({
  index,
  rowData,
  filters,
  pageChanged,
}) => {
  const { fromDate, toDate } = filters;
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [detailsData, setDetailsData] = useState<IAttendanceReportItem[]>([]);
  const [abortController] = useState<AbortController>(new AbortController());
  const [, setChanged] = useState<boolean>(true);
  const [pageTotalCount, setPageTotalCount] = useState<number>(0);
  const { searchQuery, updateSearchParameters } = useSearch({
    isStoreOnLocalStorage: true,
    entity: `Record-${rowData.rider.employeeId}-${rowData.zone.id}`,
    fields: { size: 10, offset: 0 },
  });

  useEffect(() => {
    // when filters have changed open details must be closed
    setIsOpened(false);
    setChanged(true);
  }, [filters, pageChanged]);

  const getSummationAggregateReports = async (offset: number, size: number) => {
    if (fromDate > toDate) {
      toast.error("End date should be bigger than start date");
      return;
    }

    setIsLoading(true);

    if (isOpened) {
      try {
        const dates = dateHelper.getStartEndByDatesAndTimes(fromDate, toDate);
        const result =
          await panelAttendanceLogService.getSummationAggregateReports(
            offset,
            size,
            dates.start,
            dates.end,
            rowData.rider.id,
            rowData.zone.id,
            rowData.group.id,
            abortController.signal
          );
        setDetailsData(result.data.data.items);
        setPageTotalCount(result.data.data.totalCount);
        setChanged(false);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getSummationAggregateReports(searchQuery.offset, searchQuery.size);
    return () => {
      localStorage.removeItem(
        `Record-${rowData.rider.employeeId}-${rowData.zone.id}`
      );
    };
  }, []);

  useEffect(() => {
    isOpened &&
      getSummationAggregateReports(searchQuery.offset, searchQuery.size);
  }, [isOpened]);

  const handleToggleRow = () => {
    if (isOpened) {
      updateSearchParameters({ offset: 0 });
    }
    setIsOpened(!isOpened);
  };

  const handlePassPaginateValues = (offset: number, size: number) => {
    updateSearchParameters({ offset, size });
    getSummationAggregateReports(offset, size);
  };

  return (
    <>
      <Tr
        cursor="pointer"
        key={index}
        onClick={handleToggleRow}
        style={
          isOpened
            ? {
                border: "2px solid black",
                borderBottom: "none",
                backgroundColor: "#333",
                color: "#fff",
              }
            : {}
        }
      >
        <Td>
          {isOpened ? (
            <Icon className="row-chevron" path={mdiChevronUp} size="24px" />
          ) : (
            <Icon
              className="row-chevron closed"
              path={mdiChevronDown}
              size="24px"
            />
          )}
        </Td>
        <Td>{rowData.rider.employeeId}</Td>
        <Td>{`${rowData.rider.firstName} ${rowData.rider.lastName}`}</Td>
        <Td>{rowData.group.nameEn}</Td>
        <Td>{rowData.zone.nameEn}</Td>
        <Td>
          {dateHelper.getDuration(Math.ceil(rowData.checkInDuration), false)}
        </Td>
        <Td>{rowData.exitCount}</Td>
        <Td>{rowData.entranceCount}</Td>
      </Tr>
      {isOpened && (
        <Tr
          style={
            isOpened ? { border: "2px solid black", borderTop: "none" } : {}
          }
        >
          <Td colSpan={8} bg="#ddd">
            {isLoading ? (
              <Center>
                <SPSpinner size="lg" />
              </Center>
            ) : (
              <>
                <Table
                  variant="striped"
                  colorScheme="blackAlpha"
                  size="md"
                  boxShadow="md"
                  backgroundColor="#fff"
                  borderRadius="md"
                >
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Total Hours</Th>
                      <Th>Total Outside Hours</Th>
                      <Th>Number of Exit</Th>
                      <Th>Number of Enterance</Th>
                      <Th>Number of Check-in</Th>
                      <Th>Number of Check-out</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {detailsData.map((detail, ind) => {
                      return (
                        <Tr key={ind}>
                          <Td>
                            {dateHelper.formatDate(
                              detail.date,
                              "ddd YYYY-MM-DD"
                            )}
                          </Td>
                          <Td>
                            {dateHelper.getDuration(
                              Math.ceil(detail.checkInDuration),
                              false
                            )}
                          </Td>
                          <Td>
                            {dateHelper.getDuration(
                              Math.ceil(detail.outsideDuration),
                              false
                            )}
                          </Td>
                          <Td>{detail.exitCount}</Td>
                          <Td>{detail.entranceCount}</Td>
                          <Td>{detail.checkInCount}</Td>
                          <Td>{detail.checkOutCount}</Td>
                          <Td>
                            <Text
                              as={Link}
                              to={`${
                                ROUTE_CONSTANTS.DASHBOARD.ATTENDANCE_TIMELINE
                                  .ROOT.ABSOLUTE
                              }?riderId=${
                                detail.rider.id
                              }&date=${dateHelper.formatDate(
                                detail.date,
                                "YYYY-MM-DD"
                              )}`}
                              color="blue.500"
                            >
                              View Timeline
                            </Text>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
                <Table>
                  <Thead></Thead>
                  <Tbody>
                    <Tr>
                      <Td colSpan={9} border="none" padding="0">
                        <Pagination
                          entityTitle={`Record-${rowData.rider.employeeId}-${rowData.zone.id}`}
                          pageTotalCount={pageTotalCount}
                          passPaginateValues={handlePassPaginateValues}
                          isVisiblePageSizeSelector={false}
                          isStoreOnLocalStorage={true}
                          perPageItems={searchQuery.size}
                        />
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </>
            )}
          </Td>
        </Tr>
      )}
    </>
  );
};
