// @flow
import * as React from "react";
import Select from "react-select";
import { Props as SelectProps } from "react-select";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";

type Props = SelectProps & {
  control: Control<any, any>;
  name: string;
};

export const ControlledSelectBox = (props: Props) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field, fieldState, formState }) => {
        const value = props.isMulti
          ? props.value
          : props.options?.find((option: any) => option.value === field.value);
        return (
          <Select
            styles={{
              indicatorSeparator: () => ({
                display: "none",
              }),
              dropdownIndicator: (base, props) => ({
                ...base,
                paddingRight: 10,
                color: "rgba(0,0,0,0.6)",
              }),
              placeholder: (base, props) => ({
                ...base,
                paddingTop: 8,
                paddingBottom: 8,
                color: "#000000",
              }),
              ...(props.styles || {}),
            }}
            onChange={(selectedValue: any) =>
              field.onChange(selectedValue.value)
            }
            options={props.options}
            value={value}
            {...props}
          />
        );
      }}
    />
  );
};
