import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import CircleSkeleton from "src/components/app/CircleSkeleton";
import { useDashboardRiderList } from "src/hooks/useDashboardRiderList";
import IdleRiders from "../IdleRiders";
import UnreachableRiders from "../UnreachableRiders";
import "./styles.css";

const RiderListTabs: React.FC = () => {
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    const [sectionLoading, setSectionLoading] = useState<boolean>(true);
    const { idleloading,
        unreachableLoading,
        idleNotFound,
        setIdleNotFound,
        unreachableNotFound,
        setUnreachableNotFound,
        idleRiders,
        unreachableRiders } = useDashboardRiderList(activeTabIndex);

    // we use sectionLoading to show loading on the dashboard first view (all section show the loading at the first load)
    // we clear loading after the websocket connect (we check this by idleloading because default tab is Idle riders)
    useEffect(() => {
        idleloading && setSectionLoading(false);
    }, [idleRiders]);

    return <CircleSkeleton loadingSize="l-lg" extraClass="idle_riders box_area" isLoaded={sectionLoading}>
        <Tabs onChange={(index) => setActiveTabIndex(index)} height="100%">
            <TabList className="fixed_tab">
                <Tab _selected={{ color: "#000", borderBottom: "2px solid #000", fontWeight: 600 }}>Riders Monitoring {activeTabIndex === 0 && `(${idleRiders.length})`}</Tab>
                <Tab _selected={{ color: "#000", borderBottom: "2px solid #000", fontWeight: 600 }}>Unreachable Riders {activeTabIndex === 1 && `(${unreachableRiders.length})`}</Tab>
            </TabList>
            <TabPanels height="100%">
                <TabPanel height="100%" >
                    <IdleRiders items={idleRiders} isLoading={idleloading} notFoundStatus={idleNotFound}/>
                </TabPanel>
                <TabPanel height="100%" >
                    <UnreachableRiders items={unreachableRiders} isLoading={unreachableLoading} notFoundStatus={unreachableNotFound}/>
                </TabPanel>
            </TabPanels>
        </Tabs>
    </CircleSkeleton>
}

export default RiderListTabs;