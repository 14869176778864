import React from "react";
import { Box, Text } from "@chakra-ui/react";
import Icon from "@mdi/react";
import { mdiAlertCircleOutline } from "@mdi/js";

const VehicleConfirmationAssign: React.FC = () => {
  return <Box p="3" mb="2" mt="8" display="flex" alignItems="flex-start">
    <Icon color="#cb2e2e" size="26px" path={mdiAlertCircleOutline} />
    <Box ml="1">
      <Text fontWeight="500" color="#cb2e2e">Your selected vehicle is already assigned to another rider.</Text>
      <Text mt="2" fontWeight="500">Are you sure you want to assign this vehicle?</Text>
    </Box>
  </Box>
}

export default VehicleConfirmationAssign;