import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import { useOngoingOrders } from "../../../../hooks/useOngoingOrders";
import OngoingOrderList from "../components/OngoingOrderList";
import OngoingPendingOrderList from "../components/OngoingPendingOrderList";
import { useOrderDataDispatch, useOrderDataState } from "../OrdersDataContext";
import { OrdersDataStoreActionKind } from "../OrdersDataStore/OrdersDataStore-Actions";
import "./styles.css";

export const OnGoingOrders: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.pagingOrders"));

    const dispatch = useOrderDataDispatch();
    const { pendingOrdersCount } = useOrderDataState();
    const { newOrder, acceptedOrder, changedOrder, allowToCallAPI } = useOngoingOrders();
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

    useEffect(() => {
        if (Object.keys(newOrder).length > 0) {
            console.log("New Order---: ", newOrder);
            dispatch({
                type: OrdersDataStoreActionKind.ADD_NEW_ORDER,
                data: { newOrder }
            });
        }
    }, [newOrder]);

    useEffect(() => {
        if (Object.keys(acceptedOrder).length > 0) {
            console.log("Accept Order---: ", acceptedOrder);
            dispatch({
                type: OrdersDataStoreActionKind.ACCEPT_ORDER,
                data: {
                    id: acceptedOrder.id,
                    status: acceptedOrder.status,
                    availableActions: acceptedOrder.availableActions,
                    histories: acceptedOrder.histories,
                    acceptedRiders: acceptedOrder.acceptedRiders
                }
            });
        }
    }, [acceptedOrder]);

    useEffect(() => {
        if (Object.keys(changedOrder).length > 0) {
            console.log("Changed Order ---: ", changedOrder);
            dispatch({
                type: OrdersDataStoreActionKind.UPDATE_ORDER,
                data: {
                    id: changedOrder.id,
                    status: changedOrder.status,
                    availableActions: changedOrder.availableActions,
                    histories: changedOrder.histories,
                    onTheWayDuration: changedOrder.onTheWayDuration
                }
            });
        }
    }, [changedOrder]);

    const handlePendingTabStyles = (pendingCount: number, selectedTab: boolean = false) => {
        let pendingColor = "#000";
        if (pendingCount <= 1) {
            pendingColor = "#00CE52"; // green
        } else if (pendingCount >= 2 && pendingCount <= 4) {
            pendingColor = "#F39905"; // orange
        } else if (pendingCount >= 5) {
            pendingColor = "#C01C23"; //red
        }

        return selectedTab ? { borderBottom: `2px solid ${pendingColor}`, color: pendingColor, fontWeight: 600 } : { color: pendingColor };
    }

    return <Box className="order_main_wrapper">
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", minHeight: "60px" }}>
            <Text as="h3" fontSize="3xl">
                {t("titles.pagingOrders")}
            </Text>
        </Box>
        <Tabs className="order_table_wrapper" onChange={(index) => setActiveTabIndex(index)}>
            <TabList className="fixed_tab">
                <Tab _selected={{ color: "#000", borderBottom: "2px solid #000", fontWeight: 600 }}>All</Tab>
                <Tab
                    _selected={handlePendingTabStyles(pendingOrdersCount, true)}
                    style={handlePendingTabStyles(pendingOrdersCount)}>Pending&nbsp;{pendingOrdersCount !== undefined ? `(${pendingOrdersCount})` : ""}</Tab>
            </TabList>
            <Box>
                <TabPanels>
                    <TabPanel pr={0} pl={0}>
                        <OngoingOrderList
                            allowToCallAPI={allowToCallAPI}
                            tabIsActive={activeTabIndex === 0 ? true : false}
                        />
                    </TabPanel>
                    <TabPanel pr={0} pl={0}>
                        <OngoingPendingOrderList
                            allowToCallAPI={allowToCallAPI}
                            tabIsActive={activeTabIndex === 1 ? true : false}
                        />
                    </TabPanel>
                </TabPanels>
            </Box>
        </Tabs>
    </Box>
}