import { Tr, Td, IconButton, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { IUserManagementItem } from "src/api/types/user-management";
import dateHelper from "src/utils/date-helper";
import Icon from "@mdi/react";
import { mdiAccountEditOutline, mdiDotsVertical, mdiKeyOutline } from "@mdi/js";
import { useState } from "react";
import EditUser from "../EditUser";
import ResetPassword from "../ResetPassword";
import UserStateSwitch from "../UserStateSwitch";
import { panelUserManagementServices } from "src/api/services/user-management";

interface IUserDataRowProps {
    user: IUserManagementItem;
}

const UserDataRow:React.FC<IUserDataRowProps> = (props) => {
    const { user: userItem } = props;
    const [openSwitchConfirmationModal, setOpenSwitchConfirmationModal] = useState<boolean>(false);
    const [openEditUserModal, setOpenEditUserModal] = useState<boolean>(false);
    const [openResetPasswordModal, setOpenResetPasswordModal] = useState<boolean>(false);
    const [user, setUser] = useState<IUserManagementItem>(userItem);
    const [abortController] = useState<AbortController>(new AbortController());

    const getUserInfo = async () => {
        try {
            const result = await panelUserManagementServices.getUser(user.id, abortController.signal);
            setUser(result.data.data);
        } catch(error) {
            console.log(error);
        }
    }
    
    const handleZoneGroupTD = () => {
        if(user.groupId) {
            return <Td>{user.groupName}</Td>;
        } else if(user.zoneId) {
            return <Td>{user.zoneName}</Td>;
        } else {
            return null;
        }
    }

    return <>
        <Tr key={user.id}>
            <Td>{user.firstName}</Td>
            <Td>{user.lastName}</Td>
            <Td>{user.username}</Td>
            {handleZoneGroupTD()}
            <Td>{dateHelper.formatDate(user.registerDate)}</Td>
            <Td>{dateHelper.formatDate(user.lastLogin)}</Td>
            <Td>{<UserStateSwitch
                user={user} 
                openSwitchConfirmationModal={openSwitchConfirmationModal}
                setOpenSwitchConfirmationModal={setOpenSwitchConfirmationModal}/>}
            </Td>
            <Td>
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label='Options'
                        icon={<Icon path={mdiDotsVertical} size="25px" />}
                        variant="ghost"
                    />
                    <MenuList>
                        <MenuItem onClick={() => setOpenResetPasswordModal(true)} icon={<Icon path={mdiKeyOutline} size="25px" />}>Reset Password</MenuItem>
                        <MenuItem onClick={() => setOpenEditUserModal(true)} icon={<Icon path={mdiAccountEditOutline} size="25px" />}>Edit User</MenuItem>
                    </MenuList>
                </Menu>
            </Td>
        </Tr>
        {
            openEditUserModal &&
                <EditUser
                    user={user}
                    openEditUserModal={openEditUserModal}
                    setOpenEditUserModal={setOpenEditUserModal}
                    handleUserUpdateInform={getUserInfo}/>
        }
        {
            openResetPasswordModal &&
                <ResetPassword
                    id={user.id}
                    username={user.username}
                    openResetPasswordModal={openResetPasswordModal}
                    setOpenReseetPasswordModal={setOpenResetPasswordModal}/>
        }
    </>;
}

export default UserDataRow;