import { Box, Button, Center, FormControl, FormErrorMessage, FormLabel, Input, Spinner, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { panelThirdPartiesService } from "src/api/services/third-party";
import { BrowserBack } from "src/components/app/BrowserBack";
import ContentView, { ContentViewBody, ContentViewHeader } from "src/components/app/ContentView";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { IThirdPartyCreateUpdateFormData } from "src/types/third-party";
import { toast } from "src/utils/toast";
import { thirdPartyValidation } from "src/validation-schemas/create-update-third-party";
import SPSpinner from "src/components/app/SPSpinner";

const ThirdPartyCreateUpdate: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams<{ id: string }>();
    const [editable, setEditable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingGetInfo, setLaodingGetInfo] = useState<boolean>(false);
    const [abortController] = useState<AbortController>(new AbortController());
    const [abortControllerGetInfo] = useState<AbortController>(new AbortController());

    useEffect(() => {
        if (params && params.id) {
            setEditable(true);
            setLaodingGetInfo(true);
            getThirdPartyInfo(params.id);
        }
    }, [params.id]);

    const { register, handleSubmit, formState: { errors, isValid }, setValue } = useForm<IThirdPartyCreateUpdateFormData>({
        resolver: yupResolver(thirdPartyValidation(t)),
        mode: "all",
        defaultValues: {
            companyNameEn: "",
            companyNameAr: "",
            domain: "",
            contactNo: "",
            address: "",
            changeStatusRoute: ""
        }
    });

    const getThirdPartyInfo = async (id: string) => {
        try {
            const result = await panelThirdPartiesService.getInfo(id, abortControllerGetInfo.signal);
            const data = result.data.data;
            setValue("companyNameEn", data.companyNameEn);
            setValue("companyNameAr", data.companyNameAr);
            setValue("domain", data.domain);
            setValue("contactNo", data.contactNo);
            setValue("address", data.address);
            setValue("changeStatusRoute", data.changeStatusRoute);
        } catch (error) {
            console.log(error);
        } finally {
            setLaodingGetInfo(false);
        }
    }

    const onSubmit = async (data: IThirdPartyCreateUpdateFormData) => {
        let formData: IThirdPartyCreateUpdateFormData = { ...data, location: { longitude: 0, latitude: 0 } }
        console.log(formData);
        setLoading(true);
        try {
            if (editable) {
                await panelThirdPartiesService.update(params.id!, formData, abortController.signal);
                toast.success(t("messages.entityWasActionedSuccessfully", { entity: "third party", state: "updated" }));
            } else {
                await panelThirdPartiesService.create(formData, abortController.signal);
                toast.success(t("messages.entityWasActionedSuccessfully", { entity: "third party", state: "created" }));
            }

            editable ? navigate(-1) : navigate(ROUTE_CONSTANTS.DASHBOARD.THIRD_PARTIES.ROOT.ABSOLUTE);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        return () => {
            abortController.abort();
            abortControllerGetInfo.abort();
        }
    }, []);

    return (
        <ContentView>
            <ContentViewHeader>
                <Box display="flex" alignItems="center">
                    <Text as="h3" fontSize="3xl">
                        {t(editable ? "titles.editThirdParty" : "titles.newThirdParty")}
                    </Text>
                    <BrowserBack />
                </Box>
            </ContentViewHeader>
            <ContentViewBody>
                {
                    loadingGetInfo ?
                        <Center height="500px">
                            <SPSpinner size="lg"/>
                        </Center>
                        : <form noValidate style={{ display: "flex", flexDirection: "column", gap: "12px" }} onSubmit={handleSubmit(onSubmit)}>
                            <Box display="flex" alignItems="flex-start" gap={4}>
                                <FormControl isInvalid={!!errors.companyNameEn} isRequired>
                                    <FormLabel>Company Name</FormLabel>
                                    <Input type="text" size="lg" autoComplete="off" maxLength={95} {...register("companyNameEn")} />
                                    <FormErrorMessage>{!!errors.companyNameEn && errors.companyNameEn.message}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!errors.companyNameAr}>
                                    <FormLabel textAlign="right">{t("fields.nameAr")}</FormLabel>
                                    <Input type="text" size="lg" autoComplete="off" maxLength={95} {...register("companyNameAr")} />
                                </FormControl>
                            </Box>
                            <FormControl isInvalid={!!errors.domain}>
                                <FormLabel>Domain</FormLabel>
                                <Input type="text" size="lg" autoComplete="off" maxLength={95} {...register("domain")} />
                                <FormErrorMessage>{!!errors.domain && errors.domain.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors.changeStatusRoute}>
                                <FormLabel>Change Status URL</FormLabel>
                                <Input type="text" size="lg" autoComplete="off" maxLength={95} {...register("changeStatusRoute")} />
                                <FormErrorMessage>{!!errors.changeStatusRoute && errors.changeStatusRoute.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors.contactNo}>
                                <FormLabel>Contact No</FormLabel>
                                <Input type="text" size="lg" autoComplete="off" maxLength={95} {...register("contactNo")} />
                                <FormErrorMessage>{!!errors.contactNo && errors.contactNo.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors.address}>
                                <FormLabel>Address</FormLabel>
                                <Input type="text" size="lg" autoComplete="off" maxLength={95} {...register("address")} />
                                <FormErrorMessage>{!!errors.address && errors.address.message}</FormErrorMessage>
                            </FormControl>
                            <Box display="flex" alignItems="center" gap="12px" mt={3}>
                                <Button size="lg" flex="1 1 0px" variant="outline" disabled={loading}
                                    onClick={() => editable ? navigate(-1) : navigate(ROUTE_CONSTANTS.DASHBOARD.THIRD_PARTIES.ROOT.ABSOLUTE)}>{t("actions.cancel")}</Button>
                                <Button size="lg" flex="1 1 0px" variant="yellow_100" type="submit" isLoading={loading} disabled={!isValid}>{t("actions.save")}</Button>
                            </Box>
                        </form>
                }
            </ContentViewBody>
        </ContentView>
    );
}

export default ThirdPartyCreateUpdate;