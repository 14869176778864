import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { mdiCloseCircle } from "@mdi/js";
import Icon from "@mdi/react";

interface IChoosenFileBoxProps {
    fileName:string;
    isUploading:boolean;
    removeSelectedFile:()=>void;
}

export const ChoosenFileBox:React.FC<IChoosenFileBoxProps> = ({isUploading, fileName , removeSelectedFile}) => {
    return <Box className="choosenFile">
			<Text as="span" fontSize="0.9rem" color="#999">{fileName}<Text as="span" ml="2" color="#222" fontSize="0.9em" fontWeight="500">(Ready to upload)</Text></Text>
			{!isUploading && <Text as="span" onClick={() => removeSelectedFile()} cursor="pointer" ml="3"><Icon size="24px" path={mdiCloseCircle} color="#E75C63" /></Text>}
		</Box>
}