import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Link } from "@chakra-ui/react";
import { mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";

interface IBrowserBack {
    size?: string;
}

export const BrowserBack:React.FC<IBrowserBack> = (props) => {
    const {size = "28px"} = props;
    const navigate = useNavigate();
    
    return <IconButton
        aria-label="Back"
        as={Link}
        ml="4"
        icon={<Icon path={mdiArrowLeft} size={size}/>}
        colorScheme="secondary"
        variant="outline"
        size="sm"
        onClick={() => navigate(-1)}/>;
};