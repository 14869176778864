import React, { Dispatch, useReducer } from "react";
import { contextFactory } from "src/utils/context-factory";
import { extendedLiveMapReducer, ExtendedLiveMapInitialState, IExtendedLiveMapInitialState } from "../ExtendedLiveMapStore/ExtendedLiveMap-Reducer";
import { ExtendedLiveMapAction } from "../ExtendedLiveMapStore/ExtendedLiveMap-Actions" ;

export const [ExtendedLiveMapStateContext , useExtendedLiveMapState] = contextFactory<IExtendedLiveMapInitialState>("ExtendedLiveMapStateContext", "useExtendedLiveMapState");
export const [ExtendedLiveMapDispatchContext , useExtendedLiveMapDispatch] = contextFactory<Dispatch<ExtendedLiveMapAction<any>>>("ExtendedLiveMapDispatchContext", "useExtendedLiveMapDispatch");
export const ExtendedLiveMapProvider:React.FC = ({ children }) => {
    const [liveMapStates , dispatch] = useReducer(extendedLiveMapReducer, ExtendedLiveMapInitialState);

    return <ExtendedLiveMapStateContext.Provider value={liveMapStates}>
            <ExtendedLiveMapDispatchContext.Provider value={dispatch}>
                { children }
            </ExtendedLiveMapDispatchContext.Provider>
        </ExtendedLiveMapStateContext.Provider>
}