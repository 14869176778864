import { FormControl, FormLabel, Switch, Text } from "@chakra-ui/react";
import { useState } from "react";
import { IRiderListItem } from "src/api/types/riders";
import RiderSwitchConfirmationModal from "../RiderSwitchConfirmationModal";

interface IRiderSwitchStateProps {
    riderDetail: IRiderListItem;
    handleUpdateDetails: () => void;
}

const RiderSwitchState: React.FC<IRiderSwitchStateProps> = ({ riderDetail, handleUpdateDetails }) => {
    const { isActive } = riderDetail;
    const [openSwitchConfirmationModal, setOpenSwitchConfirmationModal] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(isActive);

    const handleConfirmChangeState = (updatedState: boolean) => {
        setIsChecked(updatedState);
        handleUpdateDetails();
    }

    return <>
        <FormControl display="flex" alignItems="center" mb="6">
            <FormLabel htmlFor="rider-status" mb="0">
                {isActive ? <Text color="yellow.500">Enable</Text> : <Text>Disable</Text>}
            </FormLabel>
            <Switch id="rider-status" colorScheme={isActive ? "yellow" : "gray"} onChange={() => setOpenSwitchConfirmationModal(true)} isChecked={isChecked} />
        </FormControl>
        {
            openSwitchConfirmationModal &&
            <RiderSwitchConfirmationModal
                riderDetail={riderDetail}
                handleConfirmChangeState={handleConfirmChangeState}
                openSwitchConfirmationModal={openSwitchConfirmationModal}
                setOpenSwitchConfirmationModal={setOpenSwitchConfirmationModal} />
        }
    </>
}

export default RiderSwitchState;