import { Box , Text} from "@chakra-ui/react";
import { mdiConnection } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";

export const ConnectionAlert: React.FC = () => {
    const [connectionState , setConnectionState] = useState<string>("");
    const [hideConnectedState , setHideConnectedState] = useState<boolean>(false);

    window.addEventListener("offline", () => {
        setConnectionState("offline");
    });
    
    window.addEventListener("online", () => {
        setConnectionState("online");
        setHideConnectedState(true);
        setTimeout(() => {
            setHideConnectedState(false);
        } , 1500);
    });
    
    
    const renderConnectionAlert = () => {
        const connectionAlertStyle = {
            margin: "0 2px 15px 0",
            borderRadius: "4px",
            padding: "10px 12px",
            display: "flex"
        };

        if (connectionState === "offline" || !navigator.onLine) {
            return <Box style={connectionAlertStyle} bg="red.100">
                <Text as="span" mr="2"><Icon path={mdiConnection} size="22px"/></Text><p>Your device lost its internet connection</p>
            </Box>
        } else {
            return (hideConnectedState) ? <Box style={connectionAlertStyle} bg="green.100">Your device is connected to the internet</Box> : <></>
        }
    }

    return renderConnectionAlert();

}