import { Box, Td, Tr, Tooltip, Button } from "@chakra-ui/react";
import { mdiAccountCheckOutline, mdiAccountMultipleOutline, mdiBicycle, mdiCar, mdiCurrencyUsd, mdiPencil, mdiWalletOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { useNavigate } from "react-router-dom";
import { VehicleType } from "src/api/types/vehicle";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { IOngoingOrders, IOrderStatusEnum, IOrderType, OrderPaymentType, TOrderStatus } from "../../../../../api/types/orders";
import dateHelper from "../../../../../utils/date-helper";

interface IOrderHistoryItemProps {
    orderItem: IOngoingOrders;
    setIsOpenAcceptedRiderModal: (isOpen: boolean) => void;
    setIsOpenEligibleRiderModal: (isOpen: boolean) => void;
    setSelectedOrder: (orderItem: IOngoingOrders) => void;
}

const OrderHistoryItem: React.FC<IOrderHistoryItemProps> = ({ orderItem, setIsOpenAcceptedRiderModal, setIsOpenEligibleRiderModal, setSelectedOrder }) => {
    const navigate = useNavigate();

    const getStateFromOrderHistory = (colStatusType: TOrderStatus, completeFormat: boolean = false): {
        toastForm: string;
        valueForm: string
    } => {
        let item = orderItem.histories.filter(orderHistory => orderHistory.status === colStatusType);

        if (item.length > 0) {
            return completeFormat ? {
                toastForm: dateHelper.formatDate(item[0].date, "DD/MM/YYYY HH:mm"),
                valueForm: dateHelper.formatDate(item[0].date, "DD/MM/YYYY HH:mm")
            } : {
                toastForm: dateHelper.formatDate(item[0].date, "DD/MM/YYYY HH:mm"),
                valueForm: dateHelper.formatDate(item[0].date, "HH:mm")
            }
        } else {
            return {
                toastForm: "",
                valueForm: "-"
            }
        }
    }

    const renderActionButttons = () => {
        let disableAcceptedRiderBtn: boolean = false;

        if ([IOrderStatusEnum.Pending, IOrderStatusEnum.ZoneCanceled].includes(orderItem.status)) {
            disableAcceptedRiderBtn = true;
        }

        return <Box display="flex" alignItems="center">
            {/* Eligible Rider Button */}
            <Tooltip label="Available Riders" sx={{ borderRadius: "8px" }}>
                <Button variant="link" onClick={() => {
                    setIsOpenEligibleRiderModal(true);
                    setSelectedOrder(orderItem);
                }}>
                    <Icon path={mdiAccountMultipleOutline} size="25px" />
                </Button>
            </Tooltip>
            {/* Accepted Rider Button*/}
            <Tooltip label="Accepted Rider" sx={{ borderRadius: "8px" }}>
                <Button variant="link" onClick={() => {
                    setIsOpenAcceptedRiderModal(true);
                    setSelectedOrder(orderItem);
                }} disabled={disableAcceptedRiderBtn}>
                    <Icon path={mdiAccountCheckOutline} size="25px" />
                </Button>
            </Tooltip>
            <Tooltip label="Edit Order" sx={{ borderRadius: "8px" }}>
                <Button variant="link" disabled={![IOrderType.ByAdmin, IOrderType.ByRider].includes(orderItem.orderType)}
                    onClick={
                        () => orderItem.orderType !== IOrderType.ByAdmin
                            ? navigate(ROUTE_CONSTANTS.DASHBOARD.PAGING_ORDERS.UPDATE.BY_DATA(orderItem.id).ABSOLUTE)
                            : navigate(ROUTE_CONSTANTS.DASHBOARD.PAGING_ORDERS_HISTORY.UPDATE_ORDER_BY_ADMIN.BY_DATA(orderItem.id).ABSOLUTE)
                    }>
                    <Icon path={mdiPencil} size="25px" />
                </Button>
            </Tooltip>
        </Box>;
    }

    const handleOrderItemClassName = () => {
        return orderItem.status === IOrderStatusEnum.ZoneCanceled ? "order_item_row canceled" : "order_item_row";
    }

    const handleOrderValue = () => {
        const icon = orderItem.paymentType === OrderPaymentType.Paid
            ? <Icon path={mdiWalletOutline} size="20px" style={{ marginRight: "4px" }} />
            : <Icon path={mdiCurrencyUsd} size="20px" style={{ marginRight: "4px" }} />;

        return <Tooltip label={orderItem.paymentType}>
            <Box display="flex" alignItems="center">{icon}{orderItem.cost}</Box>
        </Tooltip>
    }

    const renderOrderVehicleType = () => {
        return orderItem.vehicleType === VehicleType.Bike
            ? <Icon path={mdiBicycle} size="20px" style={{ minWidth: "20px", marginRight: "4px" }} />
            : <Icon path={mdiCar} size="20px" style={{ minWidth: "20px", marginRight: "4px" }} />;
    }

    return <Tr key={orderItem.id} className={handleOrderItemClassName()}>
        <Td sx={{ maxWidth: "130px", wordWrap: "break-word" }}>
            <Box display="flex" alignItems="center">{renderOrderVehicleType()}{orderItem.code}</Box>
        </Td>
        <Td>{handleOrderValue()}</Td>
        <Td>{orderItem.group.nameEn}</Td>
        <Td>{orderItem.applicant}</Td>
        <Tooltip label={getStateFromOrderHistory(IOrderStatusEnum.Pending, true).toastForm}>
            <Td>{getStateFromOrderHistory(IOrderStatusEnum.Pending, true).valueForm}</Td>
        </Tooltip>
        <Tooltip label={getStateFromOrderHistory(IOrderStatusEnum.RiderAccepted, true).toastForm}>
            <Td>{getStateFromOrderHistory(IOrderStatusEnum.RiderAccepted).valueForm}</Td>
        </Tooltip>
        <Tooltip label={getStateFromOrderHistory(IOrderStatusEnum.PickedUp, true).toastForm}>
            <Td>{getStateFromOrderHistory(IOrderStatusEnum.PickedUp).valueForm}</Td>
        </Tooltip>
        <Tooltip label={getStateFromOrderHistory(IOrderStatusEnum.Delivered, true).toastForm}>
            <Td>{getStateFromOrderHistory(IOrderStatusEnum.Delivered).valueForm}</Td>
        </Tooltip>
        <Td>{renderActionButttons()}</Td>
    </Tr>;
}

export default OrderHistoryItem;