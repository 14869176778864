import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { mdiTimerOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ordersService } from "src/api/services/orders";
import {
  IOrderInfoPayload,
  OrderPaymentType,
  TOrderPaymentType,
} from "src/api/types/orders";
import { BrowserBack } from "src/components/app/BrowserBack";
import SPSpinner from "src/components/app/SPSpinner";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import dateHelper from "src/utils/date-helper";
import FormHelper from "src/utils/form-helper";
import { toast } from "src/utils/toast";
import { editOrderValidation } from "src/validation-schemas/editOrderValidation";
import ZoomableOrderImage from "../components/ZoomableOrderImage";
import "./styles.css";

const OrderUpdate: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [getOrderAbortController] = useState<AbortController>(
    new AbortController()
  );
  const [updateOrderController] = useState<AbortController>(
    new AbortController()
  );
  const [orderInfo, setOrderInfo] = useState<IOrderInfoPayload>(
    {} as IOrderInfoPayload
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const formHelperInstance = new FormHelper();

  const { register, setValue, handleSubmit } = useForm<any>({
    resolver: yupResolver(editOrderValidation(t)),
    mode: "all",
  });

  const getOrderInfo = async () => {
    setLoading(true);
    try {
      const result = await ordersService.getOrderById(
        id!,
        getOrderAbortController.signal
      );
      setOrderInfo(result.data.data);
      setValue("paymentType", result.data.data.paymentType);
      setValue("cost", result.data.data.cost);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrderInfo();
    return () => {
      getOrderAbortController.abort();
      updateOrderController.abort();
    };
  }, []);

  const onSubmit = async (data: {
    cost: string;
    paymentType: TOrderPaymentType;
  }) => {
    // console.log(data);
    setUpdateLoading(true);
    try {
      await ordersService.updateOrder(
        id!,
        {
          cost: Number(data.cost),
          paymentType: data.paymentType,
        },
        updateOrderController.signal
      );

      toast.success(t("messages.orderUpdatedSuccessfully"));
      handleRedirect();
    } catch (error) {
      console.log(error);
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleRedirect = () => {
    setTimeout(() => {
      if (dateHelper.compareDateWithToday(orderInfo.date, "minutes") > 120) {
        navigate(-1);
      } else {
        navigate(ROUTE_CONSTANTS.DASHBOARD.PAGING_ORDERS.ROOT.ABSOLUTE);
      }
    }, 1000);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Box display="flex" alignItems="center">
          <Text as="h3" fontSize="3xl">
            {t("titles.editOrder")}
          </Text>
          <BrowserBack />
        </Box>
        {orderInfo.modifiedByAdmin && (
          <Box
            display="flex"
            alignItems="center"
            fontSize="14px"
            color="#4D4D4D"
          >
            <Icon size="20px" path={mdiTimerOutline} />
            &nbsp;
            <Text>
              <Text as="span">Last Update:</Text>&nbsp;
              <Text as="span" color="#F39905" fontWeight="bold">
                {dateHelper.formatDate(orderInfo.modifiedByAdmin)}
              </Text>
            </Text>
          </Box>
        )}
      </Box>
      <Box>
        {loading ? (
          <Center minHeight="600px">
            <SPSpinner size="lg" />
          </Center>
        ) : (
          <form
            className="order_update_container"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box className="form_inputs_container">
              <Box>
                <FormLabel htmlFor="Order ID" display="inline">
                  Order ID:
                </FormLabel>
                <Text as="span" color="#999999">
                  {orderInfo.code}
                </Text>
              </Box>
              <Box>
                <FormLabel htmlFor="Order ID" display="inline">
                  Created At:
                </FormLabel>
                <Text as="span" color="#999999">
                  {dateHelper.formatDate(orderInfo.date)}
                </Text>
              </Box>
              <Box>
                <FormLabel htmlFor="Vehicle" display="inline">
                  Vehicle:
                </FormLabel>
                <Text as="span" color="#999999">
                  {orderInfo.vehicleType}
                </Text>
              </Box>
              <Box>
                <FormLabel htmlFor="Order Type" display="inline">
                  Order Type:
                </FormLabel>
                <Text as="span" color="#999999">
                  {orderInfo.orderType}
                </Text>
              </Box>
              <Box>
                <FormLabel htmlFor="Zone" display="inline">
                  Zone:
                </FormLabel>
                <Text as="span" color="#999999">
                  {orderInfo.applicant}
                </Text>
              </Box>
              <Box>
                <FormLabel htmlFor="paymentType">
                  {t("fields.OrderPaymentType")}
                </FormLabel>
                <Select
                  id="paymentType"
                  size="lg"
                  {...register("paymentType")}
                  defaultValue={OrderPaymentType.Cash}
                  autoFocus
                >
                  <option value={OrderPaymentType.Cash}>
                    {OrderPaymentType.Cash}
                  </option>
                  <option value={OrderPaymentType.Paid}>
                    {OrderPaymentType.Paid}
                  </option>
                </Select>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel htmlFor="cost">{t("fields.orderValue")}</FormLabel>
                  <InputGroup>
                    <InputRightElement pointerEvents="none">
                      KD
                    </InputRightElement>
                    <Input
                      id="cost"
                      type="text"
                      size="lg"
                      {...register("cost", { required: true })}
                      autoComplete="off"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formHelperInstance.enterJustNumber(e)
                      }
                    />
                  </InputGroup>
                </FormControl>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <Button
                  size="lg"
                  flex="1 1 0px"
                  variant="outline"
                  disabled={updateLoading}
                  onClick={handleRedirect}
                >
                  {t("actions.cancel")}
                </Button>
                <Button
                  size="lg"
                  flex="1 1 0px"
                  variant="orange"
                  type="submit"
                  isLoading={updateLoading}
                >
                  {t("actions.save")}
                </Button>
              </Box>
            </Box>
            {orderInfo.imageUrl && (
              <ZoomableOrderImage imageUrl={orderInfo.imageUrl} />
            )}
          </form>
        )}
      </Box>
    </>
  );
};

export default OrderUpdate;
