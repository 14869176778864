import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Tr,
} from "@chakra-ui/react";
import { mdiDotsVertical, mdiKeyVariant, mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { panelThirdPartiesService } from "src/api/services/third-party";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { IThirdPartyItem } from "src/types/third-party";
import dateHelper from "src/utils/date-helper";
import APIKeyConfirmation from "../APIKeyConfirmation";
import EnableDisableButton from "../EnableDisableButton";
import { useAbortController } from "src/hooks/useAbortController";
import { useTranslation } from "react-i18next";
import { toast } from "src/utils/toast";
interface IThirdPartyRowProps {
  item: IThirdPartyItem;
}

const ThirdPartyRow: React.FC<IThirdPartyRowProps> = ({ item }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [openAPIKeyConfirmationModal, setOpenAPIKeyConfirmationModal] =
    useState<boolean>(false);
  const [thirdPartyInfo, setThirdPartyInfo] = useState<IThirdPartyItem>(item);
  const { getAbortSignal } = useAbortController();

  const getThirdPartyInfo = async () => {
    try {
      const result = await panelThirdPartiesService.getInfo(
        thirdPartyInfo.id,
        getAbortSignal("getThirdPartyInfo").signal
      );
      setThirdPartyInfo(result.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEnableDisableStatus = async (status: boolean) => {
    try {
      if (status) {
        await panelThirdPartiesService.disableOTP(
          item.id,
          getAbortSignal("disableThirdParty").signal
        );
        toast.success(
          t("messages.entityWasActionedSuccessfully", {
            entity: "Third Party",
            state: "disabled",
          })
        );
      } else {
        await panelThirdPartiesService.enableOTP(
          item.id,
          getAbortSignal("enableThirdParty").signal
        );
        toast.success(
          t("messages.entityWasActionedSuccessfully", {
            entity: "Third Party",
            state: "enabled",
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEnableDisableOTP = async (status: boolean) => {
    try {
      if (status) {
        await panelThirdPartiesService.disableOTP(
          item.id,
          getAbortSignal("enableThirdParty").signal
        );
        toast.success(
          t("messages.entityWasActionedSuccessfully", {
            entity: "OTP",
            state: "disabled",
          })
        );
      } else {
        await panelThirdPartiesService.enableOTP(
          item.id,
          getAbortSignal("disableThirdParty").signal
        );
        toast.success(
          t("messages.entityWasActionedSuccessfully", {
            entity: "OTP",
            state: "enabled",
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Tr>
        <Td>{thirdPartyInfo.companyNameEn}</Td>
        <Td>{thirdPartyInfo.domain || "---"}</Td>
        <Td>{thirdPartyInfo.contactNo || "---"}</Td>
        <Td>{dateHelper.formatDate(thirdPartyInfo.registerDate)}</Td>
        <Td>
          {
            <EnableDisableButton
              value={!!thirdPartyInfo.active}
              item={item}
              handleStatusChange={handleEnableDisableStatus}
            />
          }
        </Td>
        <Td>{dateHelper.formatDate(thirdPartyInfo.registeredApiKeyDate)}</Td>
        <Td>
          {
            <EnableDisableButton
              isOTP={true}
              value={!!thirdPartyInfo.otpStatus}
              item={item}
              handleStatusChange={handleEnableDisableOTP}
            />
          }
        </Td>
        <Td>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<Icon path={mdiDotsVertical} size="25px" />}
              variant="ghost"
            />
            <MenuList>
              <MenuItem
                onClick={() =>
                  navigate(
                    ROUTE_CONSTANTS.DASHBOARD.THIRD_PARTIES.UPDATE.BY_DATA(
                      thirdPartyInfo.id
                    ).ABSOLUTE
                  )
                }
                icon={<Icon path={mdiPencil} size="25px" />}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => setOpenAPIKeyConfirmationModal(true)}
                icon={<Icon path={mdiKeyVariant} size="25px" />}
              >
                Generate API key
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      </Tr>
      {openAPIKeyConfirmationModal && (
        <APIKeyConfirmation
          item={{ id: thirdPartyInfo.id, name: thirdPartyInfo.companyNameEn }}
          openAPIKeyConfirmationModal={openAPIKeyConfirmationModal}
          setOpenAPIKeyConfirmationModal={setOpenAPIKeyConfirmationModal}
          handleGenerateNewKey={getThirdPartyInfo}
        />
      )}
    </>
  );
};

export default ThirdPartyRow;
