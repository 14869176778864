import { Box, Button, FormControl, FormErrorMessage, FormLabel, IconButton, Input, InputGroup, InputRightElement, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { panelUserManagementServices } from "src/api/services/user-management";
import { IUserResetPasswordFormData } from "src/types/user-management";
import { toast } from "src/utils/toast";
import { userResetPasswordValidation } from "src/validation-schemas/user-reset-password";

interface IResetPasswordProps {
    id: string;
    username: string;
    openResetPasswordModal: boolean;
    setOpenReseetPasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResetPassword:React.FC<IResetPasswordProps> = (props) => {
    const {id, username, openResetPasswordModal, setOpenReseetPasswordModal} = props;
    const { t } = useTranslation()
    const [loading, setLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [abortController] = useState<AbortController>(new AbortController());

    const { register, reset, handleSubmit, formState: {isValid, errors}} = useForm<IUserResetPasswordFormData>({
        resolver: yupResolver(userResetPasswordValidation(t)),
        mode: "all",
        defaultValues: {
            username,
            rawPassword: "",
            confirmPassword: ""
        }
    });

    const onSubmit = async (data: IUserResetPasswordFormData) => {
        const {confirmPassword, ...userFormData} = data;
        setLoading(true);
        try {
            await panelUserManagementServices.resetPassword(userFormData, abortController.signal);
            toast.success(t("messages.userPasswordUpdatedSuccessfully"));
            setOpenReseetPasswordModal(false);
        } catch(error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const closeModal = () => {
        setOpenReseetPasswordModal(false);
        reset();
    }

    return <Modal closeOnOverlayClick={false} isOpen={openResetPasswordModal} onClose={closeModal} size="2xl">
        <ModalOverlay />
        <ModalContent p={9}>
            <form noValidate style={{display:"flex", flexDirection: "column", gap: "12px"}} onSubmit={handleSubmit(onSubmit)}>
                <p style={{fontWeight: "600", fontSize: "20px"}}>Reset Password</p>
                <FormControl>
                    <FormLabel>Username</FormLabel>
                    <Input type="text" size="lg" disabled value={username} bgColor="#E6E6E6"/>
                </FormControl>
                <FormControl isInvalid={!!errors.rawPassword}>
                    <FormLabel>Password</FormLabel>
                    <InputGroup size="lg">
                        <Input type={showPassword ? "text" : "password"} size="lg" maxLength={8} autoComplete="off" autoFocus {...register("rawPassword")}/>
                        <InputRightElement>
                            <IconButton
                                aria-label="show-hide-password"
                                variant="ghost" 
                                onClick={() => setShowPassword(!showPassword)} 
                                icon={<Icon size="22px" path={showPassword ? mdiEyeOutline : mdiEyeOffOutline}/>}/>
                        </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{!!errors.rawPassword && errors.rawPassword.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.confirmPassword}>
                    <FormLabel>Confirm Password</FormLabel>
                    <InputGroup size="lg">
                        <Input type={showPassword ? "text" : "password"} size="lg" maxLength={8} autoComplete="off" {...register("confirmPassword")}/>
                        <InputRightElement>
                            <IconButton 
                                aria-label="show-hide-password"
                                variant="ghost" 
                                onClick={() => setShowPassword(!showPassword)} 
                                icon={<Icon size="22px" path={showPassword ? mdiEyeOutline : mdiEyeOffOutline}/>}/>
                        </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{!!errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
                </FormControl>
                <Box display="flex" alignItems="center" gap="12px" mt={3}>
                    <Button size="lg" flex="1 1 0px" variant="outline" disabled={loading} onClick={closeModal}>{t("actions.cancel")}</Button>
                    <Button size="lg" flex="1 1 0px" variant="yellow_100" type="submit" isLoading={loading} disabled={!isValid}>{t("actions.save")}</Button>
                </Box>
            </form>
        </ModalContent>
    </Modal>;
}

export default ResetPassword;