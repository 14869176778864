export const calcAndShowFileSize = (maxValidSize:string) => {
    const fileSize = +maxValidSize.split("-")[0]
    const fileUnit = maxValidSize.split("-")[1];

    let validSizeNumber;
    switch(fileUnit.toLocaleLowerCase()){
        case "kb":
            validSizeNumber = fileSize * 1024;
            break;
        case "mb":
            validSizeNumber = fileSize * 1024 * 1024;
            break;
        default:
            validSizeNumber = fileSize;
            break;
    }

    return {
        "viewForm" : fileSize + fileUnit,
        "fileSize": validSizeNumber
    }
}