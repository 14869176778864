export interface AnyObject {
    [key: string]: any;
}

export function unique<T extends AnyObject>(arr: T[], key?: keyof T): T[] {
    if (key) {
        const seen: Record<string, boolean> = {};
        return arr.filter(item => {
            const value = item[key];
            if (seen[value]) {
                return false;
            } else {
                seen[value] = true;
                return true;
            }
        });
    } else {
        return Array.from(new Set(arr.map(item => JSON.stringify(item)))).map(str => JSON.parse(str));
    }
}
export function toArray(data : any[]) {
    if(!data || data.includes(undefined)) return []
    return Array.isArray(data) ? data : [data];
}